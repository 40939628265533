import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  Map: { input: any; output: any };
};

export type ApiToken = {
  __typename: "APIToken";
  createdAt: Scalars["String"]["output"];
  creator?: Maybe<ApiTokenCreator>;
  description?: Maybe<Scalars["String"]["output"]>;
  lastUsed?: Maybe<Scalars["String"]["output"]>;
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  roles: Array<Role>;
  scopeMrn: Scalars["String"]["output"];
  /** @deprecated Use scopeMrn */
  spaceMrn: Scalars["String"]["output"];
};

export type ApiTokenConnection = {
  __typename: "APITokenConnection";
  edges?: Maybe<Array<ApiTokenEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ApiTokenCreator = {
  __typename: "APITokenCreator";
  email?: Maybe<Scalars["String"]["output"]>;
  mrn?: Maybe<Scalars["String"]["output"]>;
};

export type ApiTokenEdge = {
  __typename: "APITokenEdge";
  cursor: Scalars["ID"]["output"];
  node?: Maybe<ApiToken>;
};

export type ApiTokenOrder = {
  direction: OrderDirection;
  field: ApiTokenOrderField;
};

export enum ApiTokenOrderField {
  CreatedAt = "CREATED_AT",
  LastUsed = "LAST_USED",
  Name = "NAME",
}

/** AWS configuration options */
export type AwsConfigurationOptions = {
  __typename: "AWSConfigurationOptions";
  accountIds?: Maybe<Array<Scalars["String"]["output"]>>;
  cloudFormationTemplateUrl: Scalars["String"]["output"];
  isOrganization?: Maybe<Scalars["Boolean"]["output"]>;
  originAWSAccountId: Scalars["String"]["output"];
  region: Scalars["String"]["output"];
  scanConfiguration: ScanConfiguration;
  snsEndpoint: Scalars["String"]["output"];
};

/** AWS integration input */
export type AwsConfigurationOptionsInput = {
  accountIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isOrganization?: InputMaybe<Scalars["Boolean"]["input"]>;
  region: Scalars["String"]["input"];
  scanConfiguration: ScanConfigurationInput;
  v2Template?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** AWSEventPattern are the event pattern based scan triggers for the AWS integration */
export type AwsEventPattern = {
  __typename: "AWSEventPattern";
  eventDetailType: Scalars["String"]["output"];
  eventSource: Scalars["String"]["output"];
  scanType: Scalars["String"]["output"];
};

/** AWSEventPatternInput describes the options for event pattern based scan triggers */
export type AwsEventPatternInput = {
  eventDetailType: Scalars["String"]["input"];
  eventSource: Scalars["String"]["input"];
  scanType: Scalars["String"]["input"];
};

export type AwsRoleCredential = {
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  role: Scalars["String"]["input"];
};

export type AwsSecretKeyCredential = {
  accessKeyId: Scalars["String"]["input"];
  secretAccessKey: Scalars["String"]["input"];
};

export type AcceptLegalPolicy = {
  policyId?: InputMaybe<Scalars["String"]["input"]>;
  resourceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

/** Access level of the object */
export enum Access {
  All = "ALL",
  Authenticated = "AUTHENTICATED",
  Private = "PRIVATE",
  Public = "PUBLIC",
}

/** Action type used when triggering action on client integration */
export enum ActionType {
  ClearScanQueue = "CLEAR_SCAN_QUEUE",
  Diagnostics = "DIAGNOSTICS",
  Metrics = "METRICS",
  Pause = "PAUSE",
  RetrySetup = "RETRY_SETUP",
  RunExport = "RUN_EXPORT",
  RunImport = "RUN_IMPORT",
  RunScan = "RUN_SCAN",
  Unpause = "UNPAUSE",
  Update = "UPDATE",
}

/** Activate user in region payload */
export type ActivateUserInRegionPayload = {
  __typename: "ActivateUserInRegionPayload";
  /** User */
  user: User;
};

/** Active policies connection */
export type ActivePoliciesConnection = {
  __typename: "ActivePoliciesConnection";
  /** Active policy edges */
  edges?: Maybe<Array<ActivePolicyEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** The total count of active policies */
  totalCount: Scalars["Int"]["output"];
};

/** Active policies input */
export type ActivePoliciesInput = {
  /** The ordering of the returned policies */
  orderBy?: InputMaybe<ActivePolicyOrder>;
  /** Filters the policies with the given query. Partial matches by name are supported. */
  query?: InputMaybe<Scalars["String"]["input"]>;
  /** Scope MRN the scope for which to grab the active policies. Currently, only spaces are supported. */
  scopeMrn: Scalars["String"]["input"];
};

/** Active policy */
export type ActivePolicy = {
  __typename: "ActivePolicy";
  /** Policy action determining whether the policy is preview or not */
  action: PolicyAction;
  /** Policy blast radius for the given scope */
  blastRadius: BlastRadius;
  /** Policy icon */
  icon: Icon_Ids;
  /** Policy MRN */
  mrn: Scalars["String"]["output"];
  /** Policy name */
  name: Scalars["String"]["output"];
  /** Policy last updated timestamp */
  updatedAt: Scalars["String"]["output"];
};

/** Active policy edge */
export type ActivePolicyEdge = {
  __typename: "ActivePolicyEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Active policy */
  node?: Maybe<ActivePolicy>;
};

/** Active policy order */
export type ActivePolicyOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: ActivePolicyOrderField;
};

export enum ActivePolicyOrderField {
  AffectedAssets = "AFFECTED_ASSETS",
  Name = "NAME",
}

export type AddSshKeyPayload = {
  __typename: "AddSSHKeyPayload";
  success: Scalars["Boolean"]["output"];
};

export type AddSshKeyRequest = {
  content: Scalars["String"]["input"];
  mrn: Scalars["String"]["input"];
};

/** advisory connection object */
export type AdvisoriesConnection = {
  __typename: "AdvisoriesConnection";
  /** advisories */
  edges?: Maybe<Array<AdvisoryEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Total count of advisories */
  totalCount: Scalars["Int"]["output"];
};

/** Advisory */
export type Advisory = LibraryNode & {
  __typename: "Advisory";
  /**
   * Packages affected by this advisory
   * @deprecated Use `affectedPackages`.
   */
  affected?: Maybe<Array<Package>>;
  /** Packages affected by this advisory */
  affectedPackages?: Maybe<Array<Package>>;
  /** only set in context of space */
  assets?: Maybe<Array<AssetItem>>;
  /** Advisory CVEs */
  cves?: Maybe<Array<Cve>>;
  /** Advisory scores */
  cvssScore: CvssScore;
  /** Advisory description */
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * Advisory source
   * external URL to the spcific advisory
   */
  externalUrls?: Maybe<Array<Maybe<Reference>>>;
  /**
   * Packages which fix this advisory
   * @deprecated Use `fixedByPackages`.
   */
  fixed?: Maybe<Array<Package>>;
  /** Packages which fix this advisory */
  fixedByPackages?: Maybe<Array<Package>>;
  /**
   * A short string to identify the icon in the UI
   * @deprecated Deprecated: Use iconIDEnum. (remove in v12)
   */
  iconID?: Maybe<Scalars["String"]["output"]>;
  /** Same as iconID, but as an enum */
  iconIDEnum?: Maybe<Icon_Ids>;
  /** Advisory id */
  id: Scalars["String"]["output"];
  /**
   * Advisory modified date
   * @deprecated Use `modifiedAt`.
   */
  modified?: Maybe<Scalars["String"]["output"]>;
  /** Advisory modified date */
  modifiedAt?: Maybe<Scalars["String"]["output"]>;
  /** Advisory mrn */
  mrn: Scalars["String"]["output"];
  /**
   * Advisory published date
   * @deprecated Use `publishedAt`.
   */
  published?: Maybe<Scalars["String"]["output"]>;
  /** Advisory published date */
  publishedAt?: Maybe<Scalars["String"]["output"]>;
  /**
   * Advisory score
   * @deprecated Use `cvssScore`.
   */
  score: CvssScore;
  /**
   * Advisory source
   * This includes the generic source, like Mozilla, but not the specific URL for a single advisory.
   * For the specific URL, use `externalUrls`.
   * @deprecated No longer supported. (remove in v12)
   */
  source: Source;
  /** Advisory title */
  title?: Maybe<Scalars["String"]["output"]>;
  /** Advisory vendorscore */
  vendorscore?: Maybe<Scalars["Int"]["output"]>;
  /** VulnerabilityRiskFactors: exploitable, RCE, ... */
  vulnerabilityRiskFactors: VulnerabilityRiskFactors;
};

/** Advisory edge object */
export type AdvisoryEdge = {
  __typename: "AdvisoryEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real advisory */
  node?: Maybe<Advisory>;
};

/** Advisory filters */
export type AdvisoryFilter = {
  /**
   * filter by textfield input. ex.: ["CHROME-2023"]
   * elements will be ANDed
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** advisory order object */
export type AdvisoryOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: AdvisoryOrderField;
};

/** possible advisory order fields */
export enum AdvisoryOrderField {
  Id = "ID",
  Published = "PUBLISHED",
  Score = "SCORE",
  Title = "TITLE",
}

/** possible Advisory filters */
export enum AdvisoryPlatformFilter {
  Alpine = "ALPINE",
  Amazon = "AMAZON",
  Debian = "DEBIAN",
  Oracle = "ORACLE",
  Redhat = "REDHAT",
  Suse = "SUSE",
  Ubuntu = "UBUNTU",
}

/** Advisory Summary connection object */
export type AdvisorySummariesConnection = {
  __typename: "AdvisorySummariesConnection";
  /** advisories */
  edges?: Maybe<Array<AdvisorySummaryEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Statistics */
  stats?: Maybe<AdvisorySummariesConnectionStats>;
  /** Total count of advisories with filtering applied */
  totalCount: Scalars["Int"]["output"];
};

/** Advisory stats counts for the AssetVulnerabilityReport */
export type AdvisorySummariesConnectionStats = {
  __typename: "AdvisorySummariesConnectionStats";
  /** Count of advisories affecting the asset (w/o any filtering) */
  totalAssetAdvisories: Scalars["Int"]["output"];
  /** Total count of advisories present in the MVD */
  totalDatabaseAdvisories: Scalars["Int"]["output"];
};

/** AdvisorySummary */
export type AdvisorySummary = {
  __typename: "AdvisorySummary";
  /** Advisory data */
  advisory: Advisory;
  /** scoped to advisory */
  affectedAssetStats: AffectedAssetStats;
  /** should be assetSummary, will include even if fixed */
  assetSummaries?: Maybe<VulnerabilityReportAssetSummariesConnection>;
  /** cve summaries for this particular advisory */
  cveSummaries?: Maybe<Array<CveSummary>>;
  /** discovered at */
  discoveredAt?: Maybe<Scalars["String"]["output"]>;
  /** Advisory ID */
  id: Scalars["String"]["output"];
  /** space mrn */
  spaceMrn: Scalars["ID"]["output"];
};

/** AdvisorySummary */
export type AdvisorySummaryAssetSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AssetSummaryOrder>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Advisory Summary edge object */
export type AdvisorySummaryEdge = {
  __typename: "AdvisorySummaryEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real advisory */
  node?: Maybe<AdvisorySummary>;
};

/** possible advisory summary order object */
export type AdvisorySummaryOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: AdvisorySummaryOrderField;
};

/** possible advisory summary order fields */
export enum AdvisorySummaryOrderField {
  Id = "ID",
  Published = "PUBLISHED",
  Riskfactors = "RISKFACTORS",
  Score = "SCORE",
  Title = "TITLE",
}

/** Advisory stats for an asset */
export type AffectedAssetStats = {
  __typename: "AffectedAssetStats";
  /** count of assets with unfixed packages */
  affectedCount: Scalars["Int"]["output"];
  /** count of assets with fixed packages */
  fixedCount: Scalars["Int"]["output"];
  /** total count of assets */
  totalCount: Scalars["Int"]["output"];
};

/** Describes an agent (managed client) */
export type Agent = {
  __typename: "Agent";
  createdAt: Scalars["String"]["output"];
  errors?: Maybe<Array<AgentError>>;
  hostname: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  ipaddress: Scalars["String"]["output"];
  labels: Array<KeyValue>;
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  notes: Scalars["String"]["output"];
  organizations?: Maybe<Array<Organization>>;
  platform?: Maybe<Platform>;
  spaces?: Maybe<Array<Space>>;
  status?: Maybe<AgentStatus>;
};

/** Agents list edge */
export type AgentEdge = {
  __typename: "AgentEdge";
  cursor: Scalars["ID"]["output"];
  node?: Maybe<Agent>;
};

/** Describes an agent error */
export type AgentError = {
  __typename: "AgentError";
  message?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

/** Options for agents */
export type AgentOptions = {
  __typename: "AgentOptions";
  hostname: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  ipaddress: Scalars["String"]["output"];
  notes: Scalars["String"]["output"];
  platform?: Maybe<Platform>;
  version?: Maybe<Scalars["String"]["output"]>;
};

/** Sorting for the agents */
export type AgentOrder = {
  direction: OrderDirection;
  field: AgentOrderField;
};

/** Supported sorting fields for agents */
export enum AgentOrderField {
  Hostname = "HOSTNAME",
  Id = "ID",
  Ip = "IP",
  LastCheckin = "LAST_CHECKIN",
  Name = "NAME",
  Platform = "PLATFORM",
  Status = "STATUS",
  Version = "VERSION",
}

/** Describes agent state */
export enum AgentState {
  Active = "ACTIVE",
  Missing = "MISSING",
}

/** Agent statistics */
export type AgentStatistics = {
  __typename: "AgentStatistics";
  /** Bad count */
  bad: Scalars["Int"]["output"];
  /** Good count */
  good: Scalars["Int"]["output"];
  /** Pending count */
  pending: Scalars["Int"]["output"];
  /** Total count */
  total: Scalars["Int"]["output"];
};

/** Describes agent status */
export type AgentStatus = {
  __typename: "AgentStatus";
  lastCheckin?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<AgentState>;
  version?: Maybe<Scalars["String"]["output"]>;
};

/** Input used to filter the agents by version */
export type AgentVersionFilter = {
  not?: Scalars["Boolean"]["input"];
  version: Scalars["String"]["input"];
};

/** Agents list */
export type AgentsConnection = {
  __typename: "AgentsConnection";
  edges?: Maybe<Array<AgentEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Aggregate score node object */
export type AggregateScore = {
  __typename: "AggregateScore";
  /** Base score ranging between 0 - 100 */
  baseScore?: Maybe<Scalars["Int"]["output"]>;
  /** Aggregate score blast radius */
  blastRadius?: Maybe<BlastRadius>;
  /** CVSS information for this aggregate score (optional, only for vulns, and software) */
  cvss?: Maybe<CvssScore>;
  /** Aggregate score description */
  description: Scalars["String"]["output"];
  /** Asset or Space */
  entity?: Maybe<Entity>;
  /** EPSS information for this aggregate score (optional, only for vulns, and software) */
  epss?: Maybe<EpssScore>;
  /** MRN of the scored object (eg: check) */
  findingMrn: Scalars["String"]["output"];
  /** Timestamp, when the package data was last updated (RFC3339) */
  firstDetectedAt: Scalars["String"]["output"];
  /** Icon identifier for the score */
  iconId: Icon_Ids;
  /** Aggregate score id */
  id: Scalars["ID"]["output"];
  /** Timestamp, when the package data was first collected (RFC3339) */
  lastScannedAt?: Maybe<Scalars["String"]["output"]>;
  /** Risk ranking */
  rank: Scalars["Float"]["output"];
  /** Timestamp, when the package data was last updated (RFC3339) */
  remediatedAt: Scalars["String"]["output"];
  /** Risk factors */
  riskFactors?: Maybe<Array<Maybe<RiskFactorStats>>>;
  /** Risk score ranging between 0 - 100, calculated based on base score and risk factors */
  riskScore?: Maybe<Scalars["Int"]["output"]>;
  /** Aggregate score type */
  scoreType: AggregateScoreType;
  /** The space that the entity belongs to */
  spaceId: Scalars["String"]["output"];
  /** Aggregate score state (PREVIEW, ENABLED) */
  state: AggregateScoreState;
  /** Aggregate score tags */
  tags?: Maybe<Array<Tag>>;
  /** Aggregate score title */
  title: Scalars["String"]["output"];
  /** Version Distribution of software across the space (optional, only for software) */
  versionDistribution?: Maybe<VersionDistribution>;
};

/** An aggregate score edge object */
export type AggregateScoreEdge = {
  __typename: "AggregateScoreEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** Aggregate score node object */
  node?: Maybe<AggregateScore>;
};

/** Aggregate score filters */
export type AggregateScoreFilter = {
  /** filter by aggregated score by finding */
  findingMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** filter to find all scores that are lower or equal than the provided score */
  maxRiskScore?: InputMaybe<Scalars["Int"]["input"]>;
  /** Retrieve check scores by policy MRN */
  policyMrn?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * filter by textfield input. ex.: ["CHROME-2023"]
   * elements will be ANDed
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** filter by risk factor mrn */
  risks?: InputMaybe<RiskFactorFilter>;
  /** filter by aggregated score type */
  scoreType?: InputMaybe<AggregateScoreType>;
  /**
   * filter aggregated scores by findingMrn of an unversioned software package
   * e.g., finding versions of bash by searching for //.../software/.../name/bash
   * This filter should be used in conjunction with the scoreType filter: VERSIONED_SOFTWARE
   */
  softwareFindingMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** filter by aggregate score state. If not provided, all states are returned */
  state?: InputMaybe<AggregateScoreState>;
};

/** Aggregate score order object */
export type AggregateScoreOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** AggregateScore field */
  field: AggregateScoreOrderField;
};

/** Aggregate score order field */
export enum AggregateScoreOrderField {
  /** Risk score blast radius */
  BlastRadius = "BLAST_RADIUS",
  /** Risk rank */
  Rank = "RANK",
  /** Risk score field */
  RiskScore = "RISK_SCORE",
  /** Title */
  Title = "TITLE",
}

export enum AggregateScoreState {
  Disabled = "DISABLED",
  Enabled = "ENABLED",
  Preview = "PREVIEW",
  Snoozed = "SNOOZED",
}

/** Aggregate score type field */
export enum AggregateScoreType {
  Advisory = "ADVISORY",
  Asset = "ASSET",
  Check = "CHECK",
  Control = "CONTROL",
  Other = "OTHER",
  Policy = "POLICY",
  Risk = "RISK",
  /** Aggregate score for a software package across all versions currently affected by a vulnerability */
  Software = "SOFTWARE",
  /** Aggregate score for a software package with a specific version affected by a vulnerability */
  VersionedSoftware = "VERSIONED_SOFTWARE",
  Vulnerability = "VULNERABILITY",
}

/** aggregate score connection object */
export type AggregateScoresConnection = {
  __typename: "AggregateScoresConnection";
  /** Risk score edges */
  edges?: Maybe<Array<AggregateScoreEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Total count of cves */
  totalCount: Scalars["Int"]["output"];
};

/** Aggregate score refresh info */
export type AggregateScoresRefreshInfo = {
  __typename: "AggregateScoresRefreshInfo";
  /** Number of assets in the last refresh */
  assets?: Maybe<Scalars["Int"]["output"]>;
  /** Timestamp, when the aggregate scores were last updated (RFC3339) */
  lastUpdatedAt?: Maybe<Scalars["String"]["output"]>;
  /** Whether the refresh is currently in progress */
  refreshInProgress: Scalars["Boolean"]["output"];
};

export type AggregateScoresResponse =
  | AggregateScoresConnection
  | NotFoundError
  | RequestError;

/** Input data for analyzing an asset */
export type AnalyseAssetVulnerabilitiesInput = {
  /** Asset MRN */
  mrn: Scalars["ID"]["input"];
};

/** Data needed for analyzing an asset without mrn */
export type AnalyseIncognitoAssetInput = {
  /** optional kernel information for OS */
  kernelVersion?: InputMaybe<Scalars["String"]["input"]>;
  /** Asset packages */
  packages: Array<PackageInput>;
  /** Asset platform */
  platform: PlatformInput;
};

/** The input to apply an exception to multiple scopes */
export type ApplyExceptionForScopesInput = {
  /**
   * How this exception should be handled
   * Snooze the controls or disable them
   * "enable" will delete the supplied control mrn from any exception
   */
  action: ExceptionMutationAction;
  /** List of control mrns that are expected. Applies only for compliance exceptions */
  controlMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** The justification for the exception */
  justification?: InputMaybe<Scalars["String"]["input"]>;
  /** List of query mrns that are expected. Applies only for security exceptions */
  queryMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** The scopes of the exceptions. Can be a space or an asset mrns */
  scopeMrns: Array<Scalars["String"]["input"]>;
  /**
   * RFC3339 timestamp
   * The date, from which on the exception is no longer valid.
   * Only applies to action IGNORE, empty is interpreted as forever
   */
  validUntil?: InputMaybe<Scalars["String"]["input"]>;
};

export type ApplySpacePlanError = {
  __typename: "ApplySpacePlanError";
  /**
   * INVALID: The space plan is not valid
   * PERMISSION_DENIED: User does not have permissions to modify the space plan
   */
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type ApplySpacePlanInput = {
  /**
   * deltas is the list of changes to apply. Assigning a query
   * to a milestone that doesn't already exist and isn't specified
   * in the milestones field is considered an error.
   */
  deltas?: InputMaybe<Array<SpacePlanQueryMutationDeltaInput>>;
  /** Set or update milestone metadata in the milestones field. */
  milestones?: InputMaybe<Array<PlanningMilestoneMetadataMutationInput>>;
  /** mrn is the space mrn */
  mrn: Scalars["String"]["input"];
};

export type ApplySpacePlanPayload = ApplySpacePlanError | ApplySpacePlanSuccess;

export type ApplySpacePlanSuccess = {
  __typename: "ApplySpacePlanSuccess";
  success: Scalars["Boolean"]["output"];
};

/** Asset */
export type Asset = {
  __typename: "Asset";
  /** Annotations */
  annotations: Array<KeyValue>;
  /** Asset type */
  asset_type: Scalars["String"]["output"];
  /** Creation time of the asset */
  createdAt: Scalars["String"]["output"];
  /** End-of-life */
  eol?: Maybe<EolAnalysis>;
  /** Unique asset id */
  id: Scalars["ID"]["output"];
  /** Asset insights */
  insights?: Maybe<AssetInsights>;
  /** Labels are optimized for searching */
  labels: Array<KeyValue>;
  /** Policies applied to the asset */
  listPolicies?: Maybe<AssetPolicyListConnection>;
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** Human readable name of the asset */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Overview groups */
  overviewGroups: Array<AssetOverviewGroup>;
  /** The following properties are scanner specific */
  platform?: Maybe<Platform>;
  /** Reference IDs */
  referenceIDs: Array<Scalars["String"]["output"]>;
  /** Related assets */
  relatedAssets?: Maybe<RelatedAssets>;
  /**
   * Report
   * @deprecated Use the query assetVulnerabilityReport instead
   */
  report?: Maybe<Report>;
  /** Score & affected from latest report */
  score: Score;
  /** State */
  state: AssetState;
  /** Latest update for the asset */
  updatedAt?: Maybe<Scalars["String"]["output"]>;
};

/** Asset */
export type AssetListPoliciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<AssetListPoliciesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

/** An exception for an advisory that is defined on an asset level */
export type AssetAdvisoryException = {
  __typename: "AssetAdvisoryException";
  /** CVSS information for this aggregate score (optional, only for vulns, and software) */
  cvss?: Maybe<CvssScore>;
  /** Icon identifier */
  iconId: Icon_Ids;
  /** Vulnerability ID */
  id: Scalars["String"]["output"];
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Risk score ranging between 0 - 100, calculated based on base score and risk factors */
  riskScore?: Maybe<Scalars["Int"]["output"]>;
  /** Title */
  title: Scalars["String"]["output"];
};

/** Asset advisory info */
export type AssetAdvisoryInfo = {
  __typename: "AssetAdvisoryInfo";
  /** Active advisory count */
  activeAdvisoryCount?: Maybe<Scalars["Int"]["output"]>;
  /** Worst CVSS score */
  worstCvssScore?: Maybe<CvssScore>;
};

/** An exception for a check that is defined on an asset level */
export type AssetCheckException = {
  __typename: "AssetCheckException";
  /** Impact */
  impact: Impact;
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Policy MRN */
  policyMrn: Scalars["String"]["output"];
  /** Policy name */
  policyName: Scalars["String"]["output"];
  /** Score */
  score?: Maybe<Score>;
  /** Title */
  title: Scalars["String"]["output"];
};

/** Stats for the checks */
export type AssetCheckStats = {
  __typename: "AssetCheckStats";
  /** errored */
  errored: Scalars["Int"]["output"];
  /** failed */
  failed: Scalars["Int"]["output"];
  /** passed */
  passed: Scalars["Int"]["output"];
};

/** An exception for a CVE that is defined on an asset level */
export type AssetCveException = {
  __typename: "AssetCveException";
  /** MRN */
  mrn: Scalars["String"]["output"];
};

/** Asset edge */
export type AssetEdge = {
  __typename: "AssetEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<Asset>;
};

/** Asset end-of-life filter */
export type AssetEolFilter = {
  /** Is end-of-life */
  isEol?: Scalars["Boolean"]["input"];
  /** Is not end-of-life */
  isNotEol?: Scalars["Boolean"]["input"];
};

/** Asset exploitable filter */
export type AssetExploitableFilter = {
  /** Is exploitable */
  isExploitable?: Scalars["Boolean"]["input"];
  /** Is not exploitable */
  isNotExploitable?: Scalars["Boolean"]["input"];
};

/** Asset grade distribution */
export type AssetGradeDistribution = {
  __typename: "AssetGradeDistribution";
  /** The number of Assets with grade A */
  A: Scalars["Int"]["output"];
  /** The number of Assets with grade B */
  B: Scalars["Int"]["output"];
  /** The number of Assets with grade C */
  C: Scalars["Int"]["output"];
  /** The number of Assets with grade D */
  D: Scalars["Int"]["output"];
  /** The number of Assets with grade F */
  F: Scalars["Int"]["output"];
  /** The number of Assets with grade U (unrated) */
  U: Scalars["Int"]["output"];
  /** The number of Assets with grade X (error) */
  X: Scalars["Int"]["output"];
  /** Total */
  total: Scalars["Int"]["output"];
};

/** Asset group order */
export type AssetGroupOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: Scalars["String"]["input"];
};

/** Asset group statistics */
export type AssetGroupStatistics = {
  __typename: "AssetGroupStatistics";
  /** Count */
  count: Scalars["Int"]["output"];
  /** Group type */
  groupType: Scalars["String"]["output"];
  /** List asset types */
  listsAssetTypes: Array<AssetTypeInfo>;
  /** Statistics */
  statistics?: Maybe<Array<AssetTypeStatistics>>;
};

/** Asset grouping */
export type AssetGrouping = {
  __typename: "AssetGrouping";
  /** Group */
  group: Scalars["Map"]["output"];
  /** Items */
  items: Array<AssetItem>;
  /** Statistics */
  stats: ReportStatsAsset;
};

/**
 * Asset vulnerability report for assets without mrn
 * This is used by cnquery/cnspec shell/vuln/run
 */
export type AssetIncognitoVulnerabilityReport = {
  __typename: "AssetIncognitoVulnerabilityReport";
  /** Advisories affecting the asset */
  advisories: Array<Advisory>;
  /** Cves affecting the asset */
  cves: Array<Cve>;
  /** Packages affected by advisories and cves */
  packages: Array<Package>;
  /** Stats for the asset */
  stats?: Maybe<ReportStats>;
};

/**
 * special type for cnspec shell
 * in these cases we don't have an asset mrn
 * but the AssetVulnerabilityReport is intercepted and requires an asset mrn to work
 */
export type AssetIncognitoVulnerabilityReportResponse =
  | AssetIncognitoVulnerabilityReport
  | NotFoundError;

/** Asset insights */
export type AssetInsights = {
  __typename: "AssetInsights";
  /** Security insights */
  security: AssetInsightsSecurity;
  /**
   * Vulnerability insights. This can be empty if the platform vulnerability
   * policy is disabled for the space
   */
  vulnerability?: Maybe<AssetInsightsVulnerability>;
};

/** Asset security insights */
export type AssetInsightsSecurity = {
  __typename: "AssetInsightsSecurity";
  /** Total number of enabled policies for the asset */
  policyCount: Scalars["Int"]["output"];
  /** Security score */
  score: Score;
};

/** Asset vulnerability insights */
export type AssetInsightsVulnerability = {
  __typename: "AssetInsightsVulnerability";
  /** Vulnerability score */
  score: Score;
};

/** AssetItem is a subset of Asset */
export type AssetItem = {
  __typename: "AssetItem";
  /** Advisory info */
  advisoryInfo?: Maybe<AssetAdvisoryInfo>;
  /** ID */
  id: Scalars["ID"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** Platform name */
  platformName?: Maybe<Scalars["String"]["output"]>;
  /** Platform version */
  platformVersion?: Maybe<Scalars["String"]["output"]>;
  /** Score */
  score: Score;
};

/** Query data for a single assets package update time */
export type AssetLastPackageUpdateTimeInput = {
  /** Asset MRN */
  mrn: Scalars["ID"]["input"];
};

/** Response data for a single assets package update time */
export type AssetLastPackageUpdateTimeResponse = {
  __typename: "AssetLastPackageUpdateTimeResponse";
  /** RFC3339 timestamp of the last update of the packages for the asset */
  lastUpdated: Scalars["String"]["output"];
};

/** Asset link info */
export type AssetLinkInfo = CiAssetLinkInfo | FleetAssetLinkInfo;

/** Asset link info input */
export type AssetLinkInfoInput = {
  /** Asset ID */
  assetId: Scalars["String"]["input"];
  /** Space ID */
  spaceID: Scalars["String"]["input"];
};

/** Asset link type */
export enum AssetLinkType {
  Ci = "CI",
  Fleet = "FLEET",
}

/** Filter for the asset policies */
export type AssetListPoliciesFilter = {
  /** Filters policies by their category */
  categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Filters policies by their grade */
  grades?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Filters policies partially matching on policy name and MRN */
  query?: InputMaybe<Scalars["String"]["input"]>;
};

/** Asset OS reboot filter */
export type AssetOsRebootFilter = {
  /** No reboot */
  noReboot?: Scalars["Boolean"]["input"];
  /** Pending reboot */
  pendingReboot?: Scalars["Boolean"]["input"];
};

/** Asset order */
export type AssetOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: AssetOrderField;
};

/** Asset order field */
export enum AssetOrderField {
  Id = "ID",
  LastUpdated = "LAST_UPDATED",
  Name = "NAME",
  Platform = "PLATFORM",
  Score = "SCORE",
}

/** An array value for an item for an asset overview. */
export type AssetOverviewArrayValue = {
  __typename: "AssetOverviewArrayValue";
  value?: Maybe<Array<AssetOverviewValueUnion>>;
};

/** A boolean value for an item for an asset overview. */
export type AssetOverviewBoolValue = {
  __typename: "AssetOverviewBoolValue";
  value: Scalars["Boolean"]["output"];
};

/** A dict value for an item for an asset overview. */
export type AssetOverviewDictValue = {
  __typename: "AssetOverviewDictValue";
  value?: Maybe<Array<KeyValue>>;
};

/** An error value for an item for an asset overview. */
export type AssetOverviewError = {
  __typename: "AssetOverviewError";
  value: AssetOverviewErrorValueUnion;
};

/** An error value for an item for an asset overview when execution of a query failed. */
export type AssetOverviewErrorExecutionFailed = {
  __typename: "AssetOverviewErrorExecutionFailed";
  msg: Scalars["String"]["output"];
  queryMrn?: Maybe<Scalars["String"]["output"]>;
};

/** An error value for an item for an asset overview when there is no data available. */
export type AssetOverviewErrorNoData = {
  __typename: "AssetOverviewErrorNoData";
  msg: Scalars["String"]["output"];
  providedByPolicyMrn: Scalars["String"]["output"];
};

/** An enumeration of the possible error types for an item for an asset overview. */
export type AssetOverviewErrorValueUnion =
  | AssetOverviewErrorExecutionFailed
  | AssetOverviewGenericError;

/** An error value for an item for an asset overview. */
export type AssetOverviewGenericError = {
  __typename: "AssetOverviewGenericError";
  code: Scalars["String"]["output"];
  msg: Scalars["String"]["output"];
};

/** A group of curated items for an asset overview. */
export type AssetOverviewGroup = {
  __typename: "AssetOverviewGroup";
  items: Array<AssetOverviewItem>;
  key: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

/** A curated item for an asset overview. */
export type AssetOverviewItem = {
  __typename: "AssetOverviewItem";
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  sources?: Maybe<Array<AssetOverviewItemSource>>;
  values: Array<AssetOverviewValueUnion>;
};

export type AssetOverviewItemSource = KeyValueSource | QuerySource;

/** A null value for an item for an asset overview. */
export type AssetOverviewNullValue = {
  __typename: "AssetOverviewNullValue";
  value?: Maybe<Scalars["String"]["output"]>;
};

/** Asset overview page info */
export type AssetOverviewPageInfo = {
  /** Asset MRN */
  assetMrn: Scalars["String"]["input"];
  /** Organization MRN */
  orgMrn: Scalars["String"]["input"];
  /** Space MRN */
  spaceMrn: Scalars["String"]["input"];
  /**
   * Which tab is selected: Overview, Policies, Controls,
   * Platform Vulnerabilities, Resources
   */
  tab: Scalars["String"]["input"];
};

/** An enumeration of the possible reference types for a  item for an asset overview. */
export enum AssetOverviewReferenceTypeEnum {
  Asset = "ASSET",
  Integration = "INTEGRATION",
}

/** A reference value for an item for an asset overview. */
export type AssetOverviewReferenceValue = {
  __typename: "AssetOverviewReferenceValue";
  displayValue: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  referenceType: AssetOverviewReferenceTypeEnum;
};

/** A string value for an item for an asset overview. */
export type AssetOverviewStringValue = {
  __typename: "AssetOverviewStringValue";
  value: Scalars["String"]["output"];
};

/** A time value for an item for an asset overview. */
export type AssetOverviewTimeValue = {
  __typename: "AssetOverviewTimeValue";
  value: Scalars["String"]["output"];
};

/** A URL value for an item for an asset overview. */
export type AssetOverviewUrlValue = {
  __typename: "AssetOverviewURLValue";
  displayValue: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

/** A union type representing the possible values of an item for an asset overview. */
export type AssetOverviewValueUnion =
  | AssetOverviewArrayValue
  | AssetOverviewBoolValue
  | AssetOverviewDictValue
  | AssetOverviewError
  | AssetOverviewNullValue
  | AssetOverviewReferenceValue
  | AssetOverviewStringValue
  | AssetOverviewTimeValue
  | AssetOverviewUrlValue;

/** Software Package details */
export type AssetPackageDetails = {
  __typename: "AssetPackageDetails";
  /** Advisories affecting the software package */
  advisorySummaries?: Maybe<AdvisorySummariesConnection>;
  /** Infomration about the asset these packages are for */
  asset?: Maybe<EntityInfoAsset>;
  /** Asset mrn */
  assetMrn: Scalars["ID"]["output"];
  /** Package available version */
  available?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp, when the package data was first collected (RFC3339) */
  createdAt?: Maybe<Scalars["String"]["output"]>;
  /** Cves affecting the software package */
  cveSummaries?: Maybe<CveSummariesConnection>;
  /** Package cvss score */
  cvssScore: CvssScore;
  /** Package description */
  description?: Maybe<Scalars["String"]["output"]>;
  /**
   * Package epss data
   * Probability and percentile
   */
  epssScore?: Maybe<EpssScore>;
  /**
   * Is the package affected by a CVE which has an exploit
   * @deprecated Use `vulnerabilityRiskFactors`.
   */
  exploitable?: Maybe<Scalars["Boolean"]["output"]>;
  /** iconID for the package */
  iconIDEnum?: Maybe<Icon_Ids>;
  /** Package id */
  id: Scalars["ID"]["output"];
  /** Package name */
  name: Scalars["String"]["output"];
  /** Referenmces to NVD, CISA, etc. */
  references?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** Timestamp, when the package data was last updated (RFC3339) */
  updatedAt: Scalars["String"]["output"];
  /** Package version */
  version: Scalars["String"]["output"];
  /** VulnerabilityRiskFactors: exploitable, RCE, ... */
  vulnerabilityRiskFactors: VulnerabilityRiskFactors;
};

/** Software Package details */
export type AssetPackageDetailsAdvisorySummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdvisorySummaryOrder>;
};

/** Software Package details */
export type AssetPackageDetailsCveSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CveSummaryOrder>;
};

/** Input for the Software package details */
export type AssetPackageDetailsInput = {
  /** Asset MRN */
  mrn: Scalars["ID"]["input"];
  /** Package name */
  name: Scalars["String"]["input"];
  /** Package version */
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** Possible responses for the asset software details */
export type AssetPackageDetailsResponse = AssetPackageDetails | NotFoundError;

/** Asset policy details */
export type AssetPolicy = {
  __typename: "AssetPolicy";
  /** The Action */
  action: PolicyAction;
  /** Category */
  category: Scalars["String"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["ID"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** The score */
  score: Score;
  /** Score statistics */
  scoreStats?: Maybe<ScoreStats>;
};

/** Asset policy connection */
export type AssetPolicyListConnection = {
  __typename: "AssetPolicyListConnection";
  /** Edges */
  edges: Array<AssetPolicyListEdge>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Asset policy edge */
export type AssetPolicyListEdge = {
  __typename: "AssetPolicyListEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node: AssetPolicy;
};

/** Asset report */
export type AssetReport = {
  __typename: "AssetReport";
  /** Analysis */
  analysis?: Maybe<AssetReportAnalysis>;
  /** Asset */
  asset: Asset;
  /** Checks applied to the asset */
  checks?: Maybe<AssetReportQueryConnection>;
  /** Data queries applied to the asset */
  dataQueries?: Maybe<AssetReportQueryConnection>;
  /** Asset insights */
  insights?: Maybe<AssetInsights>;
  /** Last completed score time */
  lastCompletedScoreTime?: Maybe<Scalars["String"]["output"]>;
  /** Policies applied to the asset */
  listPolicies?: Maybe<AssetReportPolicyListConnection>;
  /** Overview groups */
  overviewGroups: Array<AssetOverviewGroup>;
  /**
   * Policies applied to the asset
   * @deprecated Use listPolicies
   */
  policies?: Maybe<AssetReportPolicyConnection>;
  /** Policy distribution */
  policyDistribution?: Maybe<Array<Maybe<PolicyDistributionCount>>>;
  /**
   * Queries applied to the asset
   * @deprecated Use dataQueries and checks
   */
  queries?: Maybe<AssetReportQueryConnection>;
  /** Provide recommendations by severity */
  recommendedActions?: Maybe<AssetReportRecommendedActionsConnection>;
  /** The score */
  score: Score;
  /** Score statistics */
  scoreStats: ScoreStats;
  /** The mrn of the space */
  spaceMrn: Scalars["ID"]["output"];
};

/** Asset report */
export type AssetReportChecksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  impact?: InputMaybe<Array<Scalars["String"]["input"]>>;
  orderBy?: InputMaybe<AssetReportQueryOrder>;
  policyFilter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  policyMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Asset report */
export type AssetReportDataQueriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  packsFilter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Asset report */
export type AssetReportListPoliciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<AssetReportListPoliciesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AssetReportPolicyOrder>;
};

/** Asset report */
export type AssetReportPoliciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AssetReportPolicyOrder>;
  policyFilter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Asset report */
export type AssetReportQueriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AssetReportQueryOrder>;
  policyFilter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Asset report */
export type AssetReportRecommendedActionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Asset report analysis */
export type AssetReportAnalysis = {
  __typename: "AssetReportAnalysis";
  /** End-of-life */
  eol?: Maybe<EolAnalysis>;
};

/**
 * Deprecated: use CheckException instead
 * Asset report exception
 */
export type AssetReportException = {
  __typename: "AssetReportException";
  /** Action */
  action: ExceptionMutationAction;
  /** ID */
  id: Scalars["String"]["output"];
  /** Justification */
  justification: Scalars["String"]["output"];
  /** Review status */
  reviewStatus: ReviewStatus;
};

/** Input data for an asset report */
export type AssetReportInput = {
  assetMrn: Scalars["ID"]["input"];
  formatType?: InputMaybe<FormatType>;
  policyFilter?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Filter for the asset report's policies */
export type AssetReportListPoliciesFilter = {
  /** Filters policies by their category */
  categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Filters policies by their grade */
  grades?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Filters policies partially matching on policy name and MRN */
  query?: InputMaybe<Scalars["String"]["input"]>;
};

/** Asset report policy details */
export type AssetReportPolicy = {
  __typename: "AssetReportPolicy";
  /** Action */
  action: PolicyAction;
  /** Category */
  category: Scalars["String"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["ID"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** The score */
  score: Score;
  /** Score statistics */
  scoreStats: ScoreStats;
  /** Version */
  version: Scalars["String"]["output"];
};

/** Asset report policy connection */
export type AssetReportPolicyConnection = {
  __typename: "AssetReportPolicyConnection";
  /** Edges */
  edges: Array<AssetReportPolicyEdge>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Asset report policy details */
export type AssetReportPolicyDetails = {
  __typename: "AssetReportPolicyDetails";
  /** The mrn of the asset */
  assetMrn: Scalars["ID"]["output"];
  /** Policy */
  policy: Policy;
  /** Queries */
  queries?: Maybe<Array<AssetReportQuery>>;
  /** The score */
  score: Score;
  /** Score statistics */
  scoreStats: ScoreStats;
};

/** Asset report policy edge */
export type AssetReportPolicyEdge = {
  __typename: "AssetReportPolicyEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node: AssetReportPolicyDetails;
};

/** Asset report policy connection */
export type AssetReportPolicyListConnection = {
  __typename: "AssetReportPolicyListConnection";
  /** Edges */
  edges: Array<AssetReportPolicyListEdge>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Asset report policy edge */
export type AssetReportPolicyListEdge = {
  __typename: "AssetReportPolicyListEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node: AssetReportPolicy;
};

/** Asset report policy order */
export type AssetReportPolicyOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: AssetReportQueryOrderField;
};

/** Asset report query */
export type AssetReportQuery = {
  __typename: "AssetReportQuery";
  /** Action */
  action: PolicyReportScoreEntryAction;
  /** Assessment */
  assessment?: Maybe<PolicyQueryAssessment>;
  /** Data */
  data?: Maybe<Scalars["Any"]["output"]>;
  /** Exception */
  exception?: Maybe<AssetReportException>;
  /** ID */
  id: Scalars["ID"]["output"];
  /** Mquery */
  mquery: Mquery;
  /** Mquery state */
  mqueryState: MqueryState;
  /** Mquery type */
  mqueryType: MqueryType;
  /** Policies */
  policies?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Policy ID */
  policyId: Scalars["String"]["output"];
  /** Policy name */
  policyName: Scalars["String"]["output"];
  /** The score */
  score: Score;
};

/** Asset report query connection */
export type AssetReportQueryConnection = {
  __typename: "AssetReportQueryConnection";
  /** Edges */
  edges: Array<AssetReportQueryEdge>;
  /** Page info */
  pageInfo: PageInfo;
  /** Summary of failed, passed, skipped checks */
  stats?: Maybe<CheckStatsSummary>;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Asset report query edge */
export type AssetReportQueryEdge = {
  __typename: "AssetReportQueryEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node: AssetReportQuery;
};

/** Asset report query order */
export type AssetReportQueryOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: AssetReportQueryOrderField;
};

/** Asset report query order field */
export enum AssetReportQueryOrderField {
  Score = "SCORE",
  Severity = "SEVERITY",
  Title = "TITLE",
}

/** Asset report recommended action */
export type AssetReportRecommendedAction =
  | RecommendedActionItemAdvisory
  | RecommendedActionItemControl;

/** Asset report recommended actions connection */
export type AssetReportRecommendedActionsConnection = {
  __typename: "AssetReportRecommendedActionsConnection";
  /** Edges */
  edges: Array<AssetReportRecommendedActionsEdge>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Asset report recommended actions edge */
export type AssetReportRecommendedActionsEdge = {
  __typename: "AssetReportRecommendedActionsEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node: AssetReportRecommendedAction;
};

/** Possible responses for the asset report */
export type AssetReportResponse =
  | AssetReport
  | CsvReport
  | InvalidDataError
  | NotFoundError;

export type AssetResourceInput = {
  assetMrn: Scalars["String"]["input"];
  selectedNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  selectedPaths?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type AssetResourceResponse = {
  __typename: "AssetResourceResponse";
  resources: Array<Resource>;
  selectedNames?: Maybe<Array<Scalars["String"]["output"]>>;
  selectedPaths?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** Asset scores distribution */
export type AssetScoresDistribution = {
  __typename: "AssetScoresDistribution";
  /** Date */
  date: Scalars["String"]["output"];
  /** Scores */
  scores: RiskDistribution;
};

export type AssetSearchInput = {
  /** Filter assets by their hierarchy */
  assetUrlFilter?: InputMaybe<Array<AssetUrlSegmentInput>>;
  /** orgMrn is the mrn of the organization to search assets for */
  orgMrn?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Full text search query. Each term in the query will be ANDed together. The
   * query language supports the following qualifiers:
   * - `name`: Search for assets containing a specific name. This is the default
   * qualifier if none is specified
   * Example: `name:my-asset-name`
   * - `asset`: Alias for `name`
   * - `id`: Search for assets containing a specific asset ID. MRNs are
   * also accepted
   * Example: `id:my-asset-id`
   * - `asset-id`: Alias for `id`
   * - `label`: Search for assets containing a label a specific key and optional value.
   * If no value is specified, the tag must exist with any value. If a value
   * is specified, the tag must exist with that value.
   * Example: `label:my-tag` or `label:my-tag=*` or `label:my-tag="my-value"`
   * - `asset-label`: Alias for `label`
   * - `annotation`: Search for assets containing a annotation a specific key and optional value.
   * If no value is specified, the tag must exist with any value. If a value
   * is specified, the tag must exist with that value.
   * Example: `annotation:my-tag` or `annotation:my-tag=*` or `annotation:my-tag="my-value"`
   * - `asset-annotation`: Alias for `annotation`
   * - `platform`: Search for assets containing a specific platform
   * Example: `platform:my-platform`
   */
  query: Scalars["String"]["input"];
  /**
   * spaceMrns is a list of space mrns to search assets for. If none are provided,
   * all spaces in the organization will be searched
   */
  spaceMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum AssetSearchOrderField {
  Id = "ID",
  Name = "NAME",
}

export type AssetSearchSuggestions = {
  __typename: "AssetSearchSuggestions";
  suggestions: Array<Suggestion>;
};

export type AssetSearchSuggestionsInput = {
  isCiCd?: InputMaybe<Scalars["Boolean"]["input"]>;
  labelFilter?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  relatedAssetMrn?: InputMaybe<Scalars["String"]["input"]>;
  searchKey?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn: Scalars["String"]["input"];
};

export type AssetSearchSuggestionsResponse = AssetSearchSuggestions;

/** Asset state */
export enum AssetState {
  Deleted = "DELETED",
  Error = "ERROR",
  Offline = "OFFLINE",
  Online = "ONLINE",
  Pending = "PENDING",
  Reboot = "REBOOT",
  Running = "RUNNING",
  Shutdown = "SHUTDOWN",
  Stopped = "STOPPED",
  Stopping = "STOPPING",
  Terminated = "TERMINATED",
  Unknown = "UNKNOWN",
}

/** Asset summary order */
export type AssetSummaryOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: AssetSummaryOrderField;
};

/** Asset summary order field */
export enum AssetSummaryOrderField {
  Id = "ID",
  Modified = "MODIFIED",
  Published = "PUBLISHED",
  Score = "SCORE",
  Title = "TITLE",
}

/** Asset type info */
export type AssetTypeInfo = {
  __typename: "AssetTypeInfo";
  /** Aliases */
  aliases?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Asset type */
  assetType: Scalars["String"]["output"];
  /** Display name */
  displayName: Scalars["String"]["output"];
  /** Short name */
  shortName: Scalars["String"]["output"];
};

/** Asset type statistics */
export type AssetTypeStatistics = {
  __typename: "AssetTypeStatistics";
  /** Count */
  count: Scalars["Int"]["output"];
  /** Type */
  type: AssetTypeInfo;
};

/** Asset update filter */
export type AssetUpdateFilter = {
  /** After */
  after?: InputMaybe<Scalars["String"]["input"]>;
  /** Before */
  before?: InputMaybe<Scalars["String"]["input"]>;
};

/** The distribution of asset scores */
export type AssetUrlScoreDistribution = {
  __typename: "AssetUrlScoreDistribution";
  Critical: Scalars["Int"]["output"];
  High: Scalars["Int"]["output"];
  Low: Scalars["Int"]["output"];
  Medium: Scalars["Int"]["output"];
  Ok: Scalars["Int"]["output"];
  Unscored: Scalars["Int"]["output"];
};

export type AssetUrlSegment = {
  __typename: "AssetUrlSegment";
  /** The key */
  key: Scalars["String"]["output"];
  /** The value */
  value: Scalars["String"]["output"];
};

export type AssetUrlSegmentInput = {
  /** The key of the URL segment to filter by */
  key: Scalars["String"]["input"];
  /** The value of the URL segment to filter by */
  value: Scalars["String"]["input"];
};

export type AssetUrlStats = {
  __typename: "AssetUrlStats";
  /**
   * Asset url is an array of key value pairs. For example, tech=aws service=ec2 would be represented as
   * [{"key": "tech", "value": "aws"}, {"key": "service", "value": "ec2"}]
   */
  assetUrl: Array<AssetUrlSegment>;
  /** The distribution of asset scores */
  scoreDistribution: AssetUrlScoreDistribution;
};

export type AssetUrlStatsInput = {
  /**
   * Scope to score by. Default is ALL, which is a combination of
   * the security and vulnerabilities scores
   */
  scope?: InputMaybe<AssetUrlStatsScope>;
  /** Space MRN */
  spaceMrn: Scalars["String"]["input"];
};

export type AssetUrlStatsPayload = {
  __typename: "AssetUrlStatsPayload";
  /** Asset url stats */
  stats?: Maybe<Array<AssetUrlStats>>;
};

export enum AssetUrlStatsScope {
  /** Score based on vulnerabilities and security */
  All = "ALL",
  /** Score based on security */
  Security = "SECURITY",
  /** Score based on vulnerabilities */
  Vulnerabilities = "VULNERABILITIES",
}

/**
 * This is used by cnquery/cnspec
 * No pagination, no filtering, no ordering, ...
 */
export type AssetVulnerabilityCompactReport = {
  __typename: "AssetVulnerabilityCompactReport";
  /**
   * Advisories affecting the asset
   * @deprecated This approach is deprecated, do not use it.
   */
  advisories: Array<Advisory>;
  /**
   * Asset mrn
   * @deprecated This approach is deprecated, do not use it.
   */
  assetMrn: Scalars["String"]["output"];
  /**
   * Cves affecting the asset
   * @deprecated This approach is deprecated, do not use it.
   */
  cves: Array<Cve>;
  /**
   * Packages affected by advisories and cves
   * @deprecated This approach is deprecated, do not use it.
   */
  packages: Array<Package>;
  /**
   * Stats for the asset
   * @deprecated This approach is deprecated, do not use it.
   */
  stats?: Maybe<ReportStats>;
};

/** Asset vulnerability report */
export type AssetVulnerabilityReport = {
  __typename: "AssetVulnerabilityReport";
  /** Advisories affecting the asset */
  advisorySummaries?: Maybe<AdvisorySummariesConnection>;
  /** Asset mrn */
  assetMrn: Scalars["ID"]["output"];
  /**
   * Cves affecting the asset
   * @deprecated Use aggregatedScores instead
   */
  cveSummaries?: Maybe<CveSummariesConnection>;
  /**
   * Packages affected by advisories and cves
   * @deprecated Use `PackageScores` instead.
   */
  packages?: Maybe<PackagesConnection>;
  /** Asset platform */
  platform: Platform;
};

/** Asset vulnerability report */
export type AssetVulnerabilityReportAdvisorySummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<AdvisoryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdvisorySummaryOrder>;
};

/** Asset vulnerability report */
export type AssetVulnerabilityReportCveSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<CveFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CveSummaryOrder>;
};

/** Asset vulnerability report */
export type AssetVulnerabilityReportPackagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<PackageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isAffected?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderBy?: InputMaybe<PackagesOrder>;
};

/** Input data for an asset vulnerability report */
export type AssetVulnerabilityReportInput = {
  assetMrn: Scalars["ID"]["input"];
  formatType?: InputMaybe<FormatType>;
};

/** Possible responses for the asset vulnerability report */
export type AssetVulnerabilityReportResponse =
  | AssetVulnerabilityCompactReport
  | AssetVulnerabilityReport
  | NotFoundError
  | NotSupported;

/** Assets connection */
export type AssetsConnection = {
  __typename: "AssetsConnection";
  /** Edges */
  edges?: Maybe<Array<AssetEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Assets info */
export type AssetsInfo = {
  __typename: "AssetsInfo";
  /** Improved assets this week */
  improved: Scalars["Int"]["output"];
  /** Regressed assets this week */
  regressed: Scalars["Int"]["output"];
  /** Scores */
  scores: RiskDistribution;
  /** Unchanged assets this week */
  unchanged: Scalars["Int"]["output"];
};

export type AuditLogConnection = {
  __typename: "AuditLogConnection";
  edges?: Maybe<Array<AuditLogEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AuditLogEdge = {
  __typename: "AuditLogEdge";
  cursor: Scalars["ID"]["output"];
  node?: Maybe<AuditLogEntry>;
};

export type AuditLogEntry = {
  __typename: "AuditLogEntry";
  action: Scalars["String"]["output"];
  identity: Identity;
  ip?: Maybe<Scalars["String"]["output"]>;
  msg?: Maybe<Scalars["String"]["output"]>;
  parent?: Maybe<Scalars["String"]["output"]>;
  resource: Scalars["String"]["output"];
  timestamp: Scalars["String"]["output"];
};

export type AuditLogOrder = {
  direction: OrderDirection;
  field: AuditLogOrderField;
};

export enum AuditLogOrderField {
  Timestamp = "TIMESTAMP",
}

/** Authentication required error */
export type AuthenticationRequired = Error & {
  __typename: "AuthenticationRequired";
  /** Code */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

export type Author = {
  __typename: "Author";
  email: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** AWS S3 configuration options */
export type AwsS3ConfigurationOptions = {
  __typename: "AwsS3ConfigurationOptions";
  accessKey: Scalars["String"]["output"];
  bucket: Scalars["String"]["output"];
  output: BucketOutputType;
  region: Scalars["String"]["output"];
};

/** AWS S3 integration input */
export type AwsS3ConfigurationOptionsInput = {
  accessKey: Scalars["String"]["input"];
  bucket: Scalars["String"]["input"];
  output: BucketOutputType;
  region: Scalars["String"]["input"];
  secretAccessKey: Scalars["String"]["input"];
};

/** Scan type provided when triggering a scan on an AWS integration */
export type AwsScanOptions = {
  scanType: Scalars["String"]["input"];
};

/** Azure blob configuration options */
export type AzureBlobConfigurationOptions = {
  __typename: "AzureBlobConfigurationOptions";
  output: BucketOutputType;
};

/** Azure Blob integration input */
export type AzureBlobConfigurationOptionsInput = {
  blobSasURL: Scalars["String"]["input"];
  output: BucketOutputType;
};

/** Azure configuration options */
export type AzureConfigurationOptions = {
  __typename: "AzureConfigurationOptions";
  clientId: Scalars["String"]["output"];
  scanVms?: Maybe<Scalars["Boolean"]["output"]>;
  secretType?: Maybe<Scalars["String"]["output"]>;
  subscriptionsAllowlist?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Use subscriptionsDenylist */
  subscriptionsBlacklist?: Maybe<Array<Scalars["String"]["output"]>>;
  subscriptionsDenylist?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Use subscriptionsAllowlist */
  subscriptionsWhitelist?: Maybe<Array<Scalars["String"]["output"]>>;
  tenantId: Scalars["String"]["output"];
};

/** Azure integration input */
export type AzureConfigurationOptionsInput = {
  /** TODO: temporary optional, fix by having separate update models */
  certificate?: InputMaybe<Scalars["String"]["input"]>;
  clientId: Scalars["String"]["input"];
  clientSecret?: InputMaybe<Scalars["String"]["input"]>;
  scanVms?: InputMaybe<Scalars["Boolean"]["input"]>;
  subscriptionsAllowlist?: InputMaybe<Array<Scalars["String"]["input"]>>;
  subscriptionsBlacklist?: InputMaybe<Array<Scalars["String"]["input"]>>;
  subscriptionsDenylist?: InputMaybe<Array<Scalars["String"]["input"]>>;
  subscriptionsWhitelist?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tenantId: Scalars["String"]["input"];
};

export type AzureDevopsJob = {
  __typename: "AzureDevopsJob";
  branch: Scalars["String"]["output"];
  buildUrl: Scalars["String"]["output"];
  commitSha: Scalars["String"]["output"];
  grade: Grade;
  id: Scalars["String"]["output"];
  job: Scalars["String"]["output"];
  jobId: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  pipelineKind: GitPipelineKind;
  projectUrl: Scalars["String"]["output"];
  repositoryUrl: Scalars["String"]["output"];
  target?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
};

/** BigQuery configuration options */
export type BigqueryConfigurationOptions = {
  __typename: "BigqueryConfigurationOptions";
  datasetId: Scalars["String"]["output"];
};

/** BigQuery integration input */
export type BigqueryConfigurationOptionsInput = {
  datasetId: Scalars["String"]["input"];
  /** note: this is the content of the service account JSON file */
  serviceAccount: Scalars["String"]["input"];
};

export type BillingSession = {
  __typename: "BillingSession";
  url: Scalars["String"]["output"];
};

export type BillingSessionInput = {
  orgMrn: Scalars["ID"]["input"];
  returnURL: Scalars["String"]["input"];
};

export type BlastRadius = {
  __typename: "BlastRadius";
  /** Total number of affected assets */
  affected: Scalars["Int"]["output"];
  /** Total number of assets (including assets with no risk) */
  assets: Scalars["Int"]["output"];
  /** Number of assets with critical risk */
  critical: Scalars["Int"]["output"];
  /** Number of assets with disabled finding */
  disabled: Scalars["Int"]["output"];
  /** Number of assets with an error */
  error: Scalars["Int"]["output"];
  /** Number of assets with high risk */
  high: Scalars["Int"]["output"];
  /** Risk score indicator eg. S, M or L */
  indicator: Scalars["String"]["output"];
  /** Number of assets with low risk */
  low: Scalars["Int"]["output"];
  /** Number of assets with medium risk */
  medium: Scalars["Int"]["output"];
  /** Number of assets with no risk */
  none: Scalars["Int"]["output"];
  /** Number of assets that passed */
  pass: Scalars["Int"]["output"];
  /** Number of assets with snoozed finding */
  snoozed: Scalars["Int"]["output"];
};

/** Format of the reports for the bucket */
export enum BucketOutputType {
  Csv = "CSV",
  Jsonl = "JSONL",
  Unknown = "UNKNOWN",
}

/** Case */
export type Case = {
  __typename: "Case";
  /**
   * The affected assets for the case. This includes all assets that still need to be
   * mitigated.
   */
  affectedAssets?: Maybe<CaseAssetsConnection>;
  /** The amount of unique assets in the case */
  assetsCount: Scalars["Int"]["output"];
  /** Total count of closed refs (across queries, controls and vulnerabilities) */
  closedRefsCount: Scalars["Int"]["output"];
  /** References to compliance controls */
  controlRefs?: Maybe<Array<Maybe<CaseRef>>>;
  /** RFC 3339 timestamp */
  createdAt: Scalars["String"]["output"];
  /** The mitigated assets for the case. This includes all assets that have been mitigated. */
  mitigatedAssets?: Maybe<CaseAssetsConnection>;
  /** RFC 3339 timestamp */
  modifiedAt: Scalars["String"]["output"];
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Owner MRN */
  ownerMrn: Scalars["String"]["output"];
  /** References to queries */
  queryRefs?: Maybe<Array<Maybe<CaseRef>>>;
  /** Status */
  status: CaseStatus;
  /** Tags */
  tags?: Maybe<Array<Tag>>;
  /** Ticket references */
  ticketRefs?: Maybe<Array<TicketRef>>;
  /** Title */
  title: Scalars["String"]["output"];
  /** Total count of refs (across queries, controls and vulnerabilities) */
  totalRefsCount: Scalars["Int"]["output"];
  /** References to vulnerabilities (CVEs and advisories) */
  vulnerabilityRefs?: Maybe<Array<Maybe<CaseRef>>>;
};

/** Case */
export type CaseAffectedAssetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Case */
export type CaseMitigatedAssetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Case asset edge */
export type CaseAssetEdge = {
  __typename: "CaseAssetEdge";
  /** Cursor */
  cursor: Scalars["String"]["output"];
  /** Node */
  node: EntityInfoAsset;
};

/** Case assets connection */
export type CaseAssetsConnection = {
  __typename: "CaseAssetsConnection";
  /** Edges */
  edges?: Maybe<Array<CaseAssetEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Case content */
export type CaseContent = {
  __typename: "CaseContent";
  /** Description */
  description: Scalars["String"]["output"];
  /** Title */
  title: Scalars["String"]["output"];
};

/** Case content input */
export type CaseContentInput = {
  /** References */
  references: Array<CaseRefInput>;
  /** Ticket type to generate content for */
  type: TicketRefType;
};

/** Cases edge */
export type CaseEdge = {
  __typename: "CaseEdge";
  /** Cursor */
  cursor: Scalars["String"]["output"];
  /** Node */
  node: Case;
};

/** Case reference */
export type CaseRef = {
  __typename: "CaseRef";
  /** MRN pointing to a query, control, CVE or advisory MRN */
  findingMrn: Scalars["String"]["output"];
  /** Scope MRN pointing to a space or an asset MRN */
  scopeMrn: Scalars["String"]["output"];
  /** Status */
  status: CaseRefStatus;
};

/** Case reference input */
export type CaseRefInput = {
  /** MRN. Must be a query, control, CVE or advisory MRN */
  findingMrn: Scalars["String"]["input"];
  /** Scope MRN. Must be a space or an asset MRN */
  scopeMrn: Scalars["String"]["input"];
};

/** Case reference status */
export enum CaseRefStatus {
  Closed = "CLOSED",
  Open = "OPEN",
}

export type CaseResponse = Case | NotFoundError | RequestError;

/** Case status */
export enum CaseStatus {
  Closed = "CLOSED",
  Error = "ERROR",
  Open = "OPEN",
  Pending = "PENDING",
}

/** Ticket configuration input */
export type CaseTicketConfigInput = {
  /** Email ticket configuration */
  email?: InputMaybe<EmailTicketConfigInput>;
  /** Github ticket configuration */
  github?: InputMaybe<GithubTicketConfigInput>;
  /** Gitlab ticket configuration */
  gitlab?: InputMaybe<GitlabTicketConfigInput>;
  /** Jira ticket configuration */
  jira?: InputMaybe<JiraTicketConfigInput>;
};

/** Cases configuration */
export type CasesConfiguration = {
  __typename: "CasesConfiguration";
  /**
   * Aggregate findings for the same asset within this window. The value is
   * specified in hours. 0 means no aggregation
   */
  aggregationWindow: Scalars["Int"]["output"];
  /** Whether to enable auto-create cases on drift */
  autoCreate: Scalars["Boolean"]["output"];
};

/** Cases configuration input */
export type CasesConfigurationInput = {
  /**
   * Aggregate findings for the same asset within this window. The value is
   * specified in hours. 0 means no aggregation
   */
  aggregationWindow?: InputMaybe<Scalars["Int"]["input"]>;
  /** Whether to enable auto-create cases on drift */
  autoCreate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Paginated list of cases */
export type CasesConnection = {
  __typename: "CasesConnection";
  /** Edges */
  edges?: Maybe<Array<CaseEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Cases input */
export type CasesInput = {
  /** Finding MRNs. If set, will only return cases with the specified finding MRNs */
  findingMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** The scope of the cases to list */
  scopeMrn: Scalars["String"]["input"];
  /** Case statuses. If set, will only return cases with the specified statuses */
  statuses?: InputMaybe<Array<CaseStatus>>;
};

/** Type of the object */
export enum CatalogType {
  All = "ALL",
  Policy = "POLICY",
  Query = "QUERY",
  Querypack = "QUERYPACK",
}

/** Input for retrieving a list of categories for the scope */
export type CategoriesListInput = {
  /**
   * scopeMrn is the space mrn the user is requesting categories for. The returned
   * response will contain all public categories unioned with categories appearing
   * in content for this space
   */
  scopeMrn: Scalars["String"]["input"];
};

/** Category */
export type Category = {
  __typename: "Category";
  /** Name */
  name: Scalars["String"]["output"];
  /** Packs */
  packs: Scalars["Int"]["output"];
  /** Policies */
  policies: Scalars["Int"]["output"];
  /** Queries */
  queries: Scalars["Int"]["output"];
};

/** Name of the certifying authority */
export type Certifier = {
  __typename: "Certifier";
  name: Scalars["String"]["output"];
};

export type ChangeSubscriptionPlanAction =
  | BillingSession
  | ChangeSubscriptionSuccess
  | PaymentPreview;

export type ChangeSubscriptionPlanInput = {
  confirm: Scalars["Boolean"]["input"];
  items?: InputMaybe<Array<SubscriptionItem>>;
  orgMrn: Scalars["ID"]["input"];
  prorationDate?: InputMaybe<Scalars["Int"]["input"]>;
  returnURL: Scalars["String"]["input"];
};

export type ChangeSubscriptionSuccess = {
  __typename: "ChangeSubscriptionSuccess";
  amountPaid: Scalars["Int"]["output"];
  currency: Scalars["String"]["output"];
};

/**
 * Represents a check that builds up a control.
 * It can either be a simple check (an MQL query) or an entire policy
 */
export type Check = PolicyCheck | QueryCheck;

/** Check exception */
export type CheckException = {
  __typename: "CheckException";
  /** Action */
  action: ExceptionMutationAction;
  /** ID */
  id: Scalars["String"]["output"];
  /** Justification */
  justification: Scalars["String"]["output"];
  /** Review status */
  reviewStatus: ReviewStatus;
};

/** Check score node object */
export type CheckScore = {
  __typename: "CheckScore";
  /** Asset information */
  asset?: Maybe<EntityInfoAsset>;
  /** Base score ranging between 0 - 100 */
  baseScore?: Maybe<Scalars["Int"]["output"]>;
  /** Exception for this check, if exists */
  exception?: Maybe<CheckException>;
  /** Icon identifier for the score */
  iconId: Icon_Ids;
  /** Check ID */
  id: Scalars["String"]["output"];
  /** Query Impact (critical, high, medium, low, none) */
  impactRating: QueryImpact;
  /** Last updated time (RFC3339) */
  lastUpdated: Scalars["String"]["output"];
  /** MRN of the Check */
  mrn: Scalars["String"]["output"];
  /** Asset platform */
  platform?: Maybe<Platform>;
  /** Query State (snoozed, disabled, etc) */
  queryState: MqueryState;
  /** Result Type */
  resultType: ScoreResultType;
  /** Risk factors */
  riskFactors?: Maybe<Array<Maybe<RiskFactorStats>>>;
  /** Risk score ranging between 0 - 100, calculated based on base score and risk factors */
  riskScore?: Maybe<Scalars["Int"]["output"]>;
  /** Severity */
  severity: ScoreSeverity;
  /** State */
  state: ScoreState;
  /** Risk score tags */
  tags?: Maybe<Array<Tag>>;
  /** Title */
  title: Scalars["String"]["output"];
};

/** Check score filters */
export type CheckScoreFilter = {
  /** Filter the result by a check mrn */
  checkMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** Filter by impact */
  impact?: InputMaybe<Array<QueryImpact>>;
  /** Whether to include checks for CI/CD assets in the results */
  includeCicd?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter the result by a policy mrn */
  policyMrn?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Optional query terms. Will return only vulnerabilities containing
   * the term in their MRN
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Filter by state */
  state?: InputMaybe<ScoreStateFilter>;
};

/** Check score order */
export type CheckScoreOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: CheckScoreOrderField;
};

/** Asset order field */
export enum CheckScoreOrderField {
  AssetName = "ASSET_NAME",
  LastUpdated = "LAST_UPDATED",
  Score = "SCORE",
}

/** Check scores connection */
export type CheckScoresConnection = {
  __typename: "CheckScoresConnection";
  /** Edges */
  edges?: Maybe<Array<CheckScoresEdge>>;
  /** Total count with filters applied */
  filteredTotalCount: Scalars["Int"]["output"];
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Check scores edge */
export type CheckScoresEdge = {
  __typename: "CheckScoresEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<CheckScore>;
};

export type CheckScoresResponse =
  | CheckScoresConnection
  | NotFoundError
  | RequestError;

/** Check state */
export enum CheckState {
  Active = "ACTIVE",
  Disabled = "DISABLED",
  Snoozed = "SNOOZED",
}

/** Check statistics */
export type CheckStats = {
  __typename: "CheckStats";
  /** Passed across all assets */
  passedAcrossAllAssets: Scalars["Int"]["output"];
  /** Severity */
  severity: Scalars["String"]["output"];
  /** Total */
  total: Scalars["Int"]["output"];
};

/** Check stats summary */
export type CheckStatsSummary = {
  __typename: "CheckStatsSummary";
  /** critical */
  critical: AssetCheckStats;
  /** high */
  high: AssetCheckStats;
  /** low */
  low: AssetCheckStats;
  /** medium */
  medium: AssetCheckStats;
  /** unscored */
  unscored: Scalars["Int"]["output"];
};

/** Checks connection */
export type ChecksConnection = {
  __typename: "ChecksConnection";
  /** Edges */
  edges?: Maybe<Array<ChecksEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Checks edge */
export type ChecksEdge = {
  __typename: "ChecksEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<Check>;
};

/** Checks order */
export type ChecksOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: ChecksOrderField;
};

/** Checks order field */
export enum ChecksOrderField {
  AssetsCount = "ASSETS_COUNT",
  Completion = "COMPLETION",
  Name = "NAME",
}

/** Checks search input */
export type ChecksSearchInput = {
  /** Framework MRN */
  frameworkMrn: Scalars["String"]["input"];
  /** Determines the ordering */
  orderBy?: InputMaybe<ChecksOrder>;
  /** Query to search checks by, currently searches by the check name only. */
  query?: InputMaybe<Scalars["String"]["input"]>;
  /** Scope MRN */
  scopeMrn: Scalars["String"]["input"];
};

/** CI asset link info */
export type CiAssetLinkInfo = {
  __typename: "CiAssetLinkInfo";
  /** Asset link type */
  assetLinkType: AssetLinkType;
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** Project ID */
  projectID: Scalars["String"]["output"];
};

export type CiCdJobPageInfo = {
  assetMrn: Scalars["String"]["input"];
  orgMrn: Scalars["String"]["input"];
  spaceMrn: Scalars["String"]["input"];
  tab: Scalars["String"]["input"];
};

export type CiCdProjectPageInfo = {
  orgMrn: Scalars["String"]["input"];
  projectMrn: Scalars["String"]["input"];
  spaceMrn: Scalars["String"]["input"];
};

export type CicdProject = {
  __typename: "CicdProject";
  createTime: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  labels?: Maybe<Array<KeyValue>>;
  mrn: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  owner: Scalars["String"]["output"];
  projectID?: Maybe<Scalars["String"]["output"]>;
  projectURL: Scalars["String"]["output"];
  runsCount: Scalars["Int"]["output"];
  type: Scalars["String"]["output"];
};

export type CicdProjectEdge = {
  __typename: "CicdProjectEdge";
  cursor: Scalars["ID"]["output"];
  node: CicdProject;
};

export type CicdProjectJobs = {
  __typename: "CicdProjectJobs";
  id: Scalars["ID"]["output"];
  jobs?: Maybe<JobsConnection>;
  project: CicdProject;
  spaceMrn: Scalars["ID"]["output"];
};

export type CicdProjectJobsJobsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  labelFilter?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
};

export type CicdProjectJobsInput = {
  projectId: Scalars["ID"]["input"];
  spaceMrn: Scalars["ID"]["input"];
};

export type CicdProjectJobsResponse = CicdProjectJobs;

export type CicdProjectOrder = {
  direction: OrderDirection;
  field: CicdProjectOrderField;
};

export enum CicdProjectOrderField {
  Name = "NAME",
}

export type CicdProjects = {
  __typename: "CicdProjects";
  projects: CicdProjectsConnection;
  spaceMrn: Scalars["ID"]["output"];
};

export type CicdProjectsProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CicdProjectOrder>;
};

export type CicdProjectsConnection = {
  __typename: "CicdProjectsConnection";
  edges: Array<CicdProjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CicdProjectsInput = {
  spaceMrn: Scalars["ID"]["input"];
  typeFilter?: InputMaybe<Scalars["String"]["input"]>;
};

export type CicdProjectsResponse = CicdProjects;

export type CircleCiJob = {
  __typename: "CircleCIJob";
  associatedPullRequests: Scalars["String"]["output"];
  branch: Scalars["String"]["output"];
  buildUrl: Scalars["String"]["output"];
  commitSha: Scalars["String"]["output"];
  grade: Grade;
  id: Scalars["String"]["output"];
  job: Scalars["String"]["output"];
  jobId: Scalars["String"]["output"];
  mergeRequestUrl: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  pipelineKind: GitPipelineKind;
  projectUrl: Scalars["String"]["output"];
  repositoryUrl: Scalars["String"]["output"];
  target?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
  workflowId: Scalars["String"]["output"];
  workflowJobId: Scalars["String"]["output"];
  workflowWorkspaceId: Scalars["String"]["output"];
};

/** ClientIntegration is the details of an integration */
export type ClientIntegration = {
  __typename: "ClientIntegration";
  assetCounts?: Maybe<Array<IntegrationAssetCount>>;
  configurationOptions?: Maybe<ClientIntegrationConfigurationOptions>;
  created: Scalars["String"]["output"];
  createdBy?: Maybe<Author>;
  errors?: Maybe<Array<IntegrationError>>;
  evaluatedConfigurations?: Maybe<Array<EvaluatedConfiguration>>;
  labels?: Maybe<Array<KeyValue>>;
  lastCheckin?: Maybe<Scalars["String"]["output"]>;
  lastStateInfo?: Maybe<ClientIntegrationState>;
  messages?: Maybe<Array<IntegrationMessage>>;
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  token?: Maybe<Scalars["String"]["output"]>;
  type: ClientIntegrationType;
};

/** Configuration options for client integrations */
export type ClientIntegrationConfigurationInput = {
  awsConfigurationOptions?: InputMaybe<AwsConfigurationOptionsInput>;
  awsHostedConfigurationOptions?: InputMaybe<HostedAwsConfigurationOptionsInput>;
  awsS3ConfigurationOptions?: InputMaybe<AwsS3ConfigurationOptionsInput>;
  azureBlobConfigurationOptions?: InputMaybe<AzureBlobConfigurationOptionsInput>;
  azureConfigurationOptions?: InputMaybe<AzureConfigurationOptionsInput>;
  bigqueryConfigurationOptions?: InputMaybe<BigqueryConfigurationOptionsInput>;
  emailConfigurationOptions?: InputMaybe<EmailConfigurationOptionsInput>;
  gcpConfigurationOptions?: InputMaybe<GcpConfigurationOptionsInput>;
  gcsBucketConfigurationOptions?: InputMaybe<GcsBucketConfigurationOptionsInput>;
  githubConfigurationOptions?: InputMaybe<GithubConfigurationOptionsInput>;
  githubTicketingConfigurationOptions?: InputMaybe<GithubTicketingConfigurationOptionsInput>;
  gitlabConfigurationOptions?: InputMaybe<GitlabConfigurationOptionsInput>;
  gitlabTicketingConfigurationOptions?: InputMaybe<GitlabTicketingConfigurationOptionsInput>;
  googleWorkspaceConfigurationOptions?: InputMaybe<GoogleWorkspaceConfigurationOptionsInput>;
  hostConfigurationOptions?: InputMaybe<HostConfigurationOptionsInput>;
  jiraConfigurationOptions?: InputMaybe<JiraConfigurationOptionsInput>;
  k8sConfigurationOptions?: InputMaybe<K8sConfigurationOptionsInput>;
  microsoftDefenderConfigurationOptions?: InputMaybe<MicrosoftDefenderConfigurationOptionsInput>;
  ms365ConfigurationOptions?: InputMaybe<Ms365ConfigurationOptionsInput>;
  ociConfigurationOptions?: InputMaybe<OciConfigurationOptionsInput>;
  oktaConfigurationOptions?: InputMaybe<OktaConfigurationOptionsInput>;
  postgresConfigurationOptions?: InputMaybe<PostgresConfigurationOptionsInput>;
  s3ConfigurationOptions?: InputMaybe<S3ConfigurationOptionsInput>;
  slackConfigurationOptions?: InputMaybe<SlackConfigurationOptionsInput>;
  snowflakeConfigurationOptions?: InputMaybe<SnowflakeConfigurationOptionsInput>;
  zendeskConfigurationOptions?: InputMaybe<ZendeskConfigurationOptionsInput>;
};

/** ClientIntegrationConfigurationOptions is a union of all configuration options for the integrations */
export type ClientIntegrationConfigurationOptions =
  | AwsConfigurationOptions
  | AgentOptions
  | AwsS3ConfigurationOptions
  | AzureBlobConfigurationOptions
  | AzureConfigurationOptions
  | BigqueryConfigurationOptions
  | EmailConfigurationOptions
  | GcpConfigurationOptions
  | GcsBucketConfigurationOptions
  | GithubConfigurationOptions
  | GithubTicketingConfigurationOptions
  | GitlabConfigurationOptions
  | GitlabTicketingConfigurationOptions
  | GoogleWorkspaceConfigurationOptions
  | HostConfigurationOptions
  | HostedAwsConfigurationOptions
  | JiraConfigurationOptions
  | K8sConfigurationOptions
  | MicrosoftDefenderConfigurationOptions
  | Ms365ConfigurationOptions
  | OciConfigurationOptions
  | OktaConfigurationOptions
  | PostgresConfigurationOptions
  | S3ConfigurationOptions
  | SlackConfigurationOptions
  | SnowflakeConfigurationOptions
  | ZendeskConfigurationOptions;

/** Input for client integration */
export type ClientIntegrationInput = {
  mrn: Scalars["String"]["input"];
  withToken?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Response when requesting a client integration */
export type ClientIntegrationPayload = {
  __typename: "ClientIntegrationPayload";
  integration: ClientIntegration;
};

/** Union type for the client integration state */
export type ClientIntegrationState = K8sIntegrationLastState;

/** Type of the client integration */
export enum ClientIntegrationType {
  Aws = "AWS",
  AwsHosted = "AWS_HOSTED",
  AwsS3 = "AWS_S3",
  Azure = "AZURE",
  AzureBlob = "AZURE_BLOB",
  Bigquery = "BIGQUERY",
  Gcp = "GCP",
  GcsBucket = "GCS_BUCKET",
  Github = "GITHUB",
  Gitlab = "GITLAB",
  GoogleWorkspace = "GOOGLE_WORKSPACE",
  Host = "HOST",
  HostedSlack = "HOSTED_SLACK",
  K8S = "K8S",
  ManagedClient = "MANAGED_CLIENT",
  MicrosoftDefender = "MICROSOFT_DEFENDER",
  Ms365 = "MS365",
  Oci = "OCI",
  Okta = "OKTA",
  Postgres = "POSTGRES",
  S3 = "S3",
  Snowflake = "SNOWFLAKE",
  TicketSystemEmail = "TICKET_SYSTEM_EMAIL",
  TicketSystemGithub = "TICKET_SYSTEM_GITHUB",
  TicketSystemGitlab = "TICKET_SYSTEM_GITLAB",
  TicketSystemJira = "TICKET_SYSTEM_JIRA",
  TicketSystemZendesk = "TICKET_SYSTEM_ZENDESK",
  Unknown = "UNKNOWN",
}

/** Close case input */
export type CloseCaseInput = {
  /** MRN of the case to close */
  mrn: Scalars["String"]["input"];
};

/** Comparison operators for filtering */
export enum ComparisonOperator {
  /** Greater than */
  Gt = "GT",
  /** Less than */
  Lt = "LT",
}

/** Represents an asset in the context of a compliance framework or control */
export type ComplianceAsset = {
  __typename: "ComplianceAsset";
  /** The asset */
  asset: Asset;
  /** The compliance percentage of the asset with respect to the control or framework it is being fetched for. */
  compliancePercentage?: Maybe<Scalars["Int"]["output"]>;
  /**
   * The exception for the control for which the asset is fetched. This is only set when scoreType is
   * OUT_OF_SCOPE or DISABLED
   */
  exception?: Maybe<ControlException>;
  /** currently not implemented */
  relatedAssets?: Maybe<Scalars["Int"]["output"]>;
  /** The score type of the asset */
  scoreType?: Maybe<ScoreType>;
};

/** Compliance asset edge */
export type ComplianceAssetEdge = {
  __typename: "ComplianceAssetEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<ComplianceAsset>;
};

/** Compliance asset order */
export type ComplianceAssetOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: ComplianceAssetOrderField;
};

/** Compliance asset order field */
export enum ComplianceAssetOrderField {
  Completion = "COMPLETION",
  Id = "ID",
  LastUpdated = "LAST_UPDATED",
  Name = "NAME",
  Platform = "PLATFORM",
}

/** Compliance assets connection */
export type ComplianceAssetsConnection = {
  __typename: "ComplianceAssetsConnection";
  /** Edges */
  edges?: Maybe<Array<ComplianceAssetEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Compliance assets search input */
export type ComplianceAssetsSearchInput = {
  /**
   * after is the pagination cursor. To paginate to the end of the search results,
   * keep calling search the the endCursor from the pageInfo response while the
   * hasNextPage field is true
   */
  after?: InputMaybe<Scalars["String"]["input"]>;
  /** first is the number of items to return. Defaults to 100 */
  first?: InputMaybe<Scalars["Int"]["input"]>;
  /** The asset group filters that match the assets */
  groups?: InputMaybe<Array<GroupFilter>>;
  /** Order by */
  orderBy?: InputMaybe<ComplianceAssetOrder>;
  /** Search query */
  query: Scalars["String"]["input"];
};

/** Compliance control */
export type ComplianceControl = {
  __typename: "ComplianceControl";
  /** The asset group statistics for all assets that are in scope of the compliance control. */
  assetGroupStatistics: Array<AssetGroupStatistics>;
  /** Allows retrieval of the assets in scope for the compliance control. */
  assets?: Maybe<ComplianceAssetsConnection>;
  /** Assets statistics */
  assetsStats: ControlAssetsStats;
  /** Allows filtering for all checks in the control. */
  checks?: Maybe<ChecksConnection>;
  /** Checks statistics */
  checksStats: ControlChecksStats;
  /** Completion */
  completion: Scalars["Int"]["output"];
  /**
   * Allows filtering for all data queries in the control.
   * Note that this returns a QueriesConnection and not a ChecksConnection as data queries cannot be represented by entire policies.
   */
  dataQueries?: Maybe<QueriesConnection>;
  /** Description */
  description: Scalars["String"]["output"];
  /** Gets the exception for this control, if any. Rejected exceptions aren't returned. */
  exception?: Maybe<ControlException>;
  /**
   * Gets the list of all exceptions that have been applied to the control.
   * @deprecated Use exception instead
   */
  exceptions: Array<ControlException>;
  /** The framework to which this control belongs to. */
  framework?: Maybe<ComplianceFramework>;
  /** Whether the control is manual */
  manual: Scalars["Boolean"]["output"];
  /** MRN */
  mrn: Scalars["String"]["output"];
  /**
   * The MRN of the scope in which this control was requested. Used in the control's resolvers
   * to avoid having to include it in multiple functions' parameters.
   */
  scopeMrn: Scalars["String"]["output"];
  /** State */
  state: ControlState;
  /** Tags */
  tags: Array<Maybe<Tag>>;
  /** Title */
  title: Scalars["String"]["output"];
};

/** Compliance control */
export type ComplianceControlAssetsArgs = {
  input: ComplianceAssetsSearchInput;
};

/** Compliance control */
export type ComplianceControlChecksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  input: ChecksSearchInput;
};

/** Compliance control */
export type ComplianceControlDataQueriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  input: ChecksSearchInput;
};

/** Compliance control input */
export type ComplianceControlInput = {
  /** Control MRN */
  controlMrn: Scalars["String"]["input"];
  /** Framework MRN */
  frameworkMrn: Scalars["String"]["input"];
  /** Scope MRN */
  scopeMrn: Scalars["String"]["input"];
};

/** Compliance framework */
export type ComplianceFramework = {
  __typename: "ComplianceFramework";
  /** The asset group statistics for all assets that are in scope of the compliance framework. */
  assetGroupStatistics: Array<AssetGroupStatistics>;
  /** Allows retrieval of the assets that are in scope of the compliance framework. */
  assets?: Maybe<ComplianceAssetsConnection>;
  /** Authors */
  authors?: Maybe<Array<Author>>;
  /**
   * The completion percentage for the framework.
   * Calculated by dividing the amount of compliant assets against the total count of assets
   */
  completion?: Maybe<Scalars["Int"]["output"]>;
  /**
   * Allows retrieval of the framework's controls' metadata. It includes no information about the checks
   * which build up the control.
   */
  controls?: Maybe<ControlsConnection>;
  /** RFC 3339 timestamp */
  createdAt: Scalars["String"]["output"];
  /** Description */
  description: Scalars["String"]["output"];
  /**
   * RFC 3339 timestamp
   * can be empty, when the exception wasn't reviewed yet
   */
  modifiedAt: Scalars["String"]["output"];
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /**
   * The list of all policies, referenced by any framework map that belongs to the framework itself.
   * These serve as a suggestion/hint towards which policies should be active for the controls to start reporting.
   */
  policiesMrns: Array<Scalars["String"]["output"]>;
  /**
   * The previous known completion scores for the framework. Compared to 'completion',
   * this can determine if there has been an improvement in the framework scoring.
   * Currently not implemented and will always return an empty list.
   */
  previousCompletionScores?: Maybe<ComplianceScoresHistory>;
  /**
   * The MRN of the scope in which this framework was requested. Used in the framework's resolvers (such as controls)
   * to avoid having to include it in multiple functions' parameters.
   */
  scopeMrn: Scalars["String"]["output"];
  /** State */
  state: ComplianceFrameworkState;
  /** Statistics */
  stats: ComplianceFrameworkStats;
  /** Summary */
  summary: Scalars["String"]["output"];
  /** Tags */
  tags?: Maybe<Array<Tag>>;
  /** Version */
  version: Scalars["String"]["output"];
};

/** Compliance framework */
export type ComplianceFrameworkAssetsArgs = {
  input: ComplianceAssetsSearchInput;
};

/** Compliance framework */
export type ComplianceFrameworkControlsArgs = {
  input: ControlsSearchInput;
};

/** Compliance framework input */
export type ComplianceFrameworkInput = {
  /** The compliance framework mrn. */
  frameworkMrn: Scalars["String"]["input"];
  /** The scope mrn for which to fetch the compliance framework. */
  scopeMrn: Scalars["String"]["input"];
};

/** Compliance framework mutation action */
export enum ComplianceFrameworkMutationAction {
  Disable = "DISABLE",
  Enable = "ENABLE",
  Preview = "PREVIEW",
}

/** Compliance framework mutation input */
export type ComplianceFrameworkMutationInput = {
  /** Action */
  action: ComplianceFrameworkMutationAction;
  /** Framework MRN */
  frameworkMrn: Scalars["String"]["input"];
  /** Scope MRN */
  scopeMrn: Scalars["String"]["input"];
};

/** Compliance framework state */
export enum ComplianceFrameworkState {
  Active = "ACTIVE",
  Disabled = "DISABLED",
  Preview = "PREVIEW",
}

/** Compliance framework statistics */
export type ComplianceFrameworkStats = {
  __typename: "ComplianceFrameworkStats";
  /** The total amount of assets that have reported in for the framework. */
  assets: Scalars["Int"]["output"];
  /** The total amount of checks, implemented by controls in the framework. */
  checks: Scalars["Int"]["output"];
  /** Controls */
  controls: ControlsStats;
  /** The total amount of controls, checks and assets that have been exempt. */
  exceptions: Scalars["Int"]["output"];
};

/** Compliance frameworks input */
export type ComplianceFrameworksInput = {
  /** The scope mrn for which to fetch compliance frameworks. Currently only supported is a space mrn. */
  scopeMrn: Scalars["String"]["input"];
  /** The framework state to filter by. */
  state?: InputMaybe<ComplianceFrameworkState>;
  /** The framework states to filter by. */
  states?: InputMaybe<Array<ComplianceFrameworkState>>;
};

/** Represents a point in time and a compliance score at that moment */
export type ComplianceScoreEntry = {
  __typename: "ComplianceScoreEntry";
  /**
   * The compliance score. For compliance scores this is the percentage of compliant assets with regards to the
   * control or framework
   */
  score: Scalars["Int"]["output"];
  /** The RFC 3339 timestamp at which the compliance score was taken */
  timestamp: Scalars["String"]["output"];
};

/** Compliance scores history */
export type ComplianceScoresHistory = {
  __typename: "ComplianceScoresHistory";
  /** Entries */
  entries: Array<ComplianceScoreEntry>;
};

export type Consent = {
  __typename: "Consent";
  email: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  signatureAt: Scalars["String"]["output"];
};

/** Platform information */
export type ContentPlatform = {
  __typename: "ContentPlatform";
  /** The platform identifier */
  id: Scalars["ID"]["output"];
  /** The name of the platform */
  name: Scalars["String"]["output"];
  /** The version of the platform */
  version: Scalars["String"]["output"];
};

/** Input for searching for policies or registries */
export type ContentSearchInput = {
  /**
   * after is the pagination cursor. To paginate to the end of the search results,
   * keep calling search the the endCursor from the pageInfo response while the
   * hasNextPage field is true
   */
  after?: InputMaybe<Scalars["String"]["input"]>;
  /** If assignedOnly is set to true, only policies that are assigned will be considered */
  assignedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** catalogType is the type of content items to consider */
  catalogType?: CatalogType;
  /**
   * A list of categories that a content item can match. Content matching at
   * least one of the provided will be considered
   */
  categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * contentMrns is a list of mrns to consider. If none are provided, all will be considered
   * Useful for text searching a specific set of content, like active policies
   */
  contentMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** includePrivate indicates whether to include private content. Defaults to true */
  includePrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** includePublic indicates whether to include mondoo provided content. Defaults to true */
  includePublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** limit is the number of items to return. Defaults to 100 */
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ContentSearchResultItemOrder>;
  /**
   * A list of platforms that a content item must match. Only content matching
   * all platforms will be considered
   */
  platforms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Full text search query. Each term in the query will be ANDed together */
  query?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * scopeMrn is the space mrn the user is requesting content for. Private
   * content will only be searched from this space
   */
  scopeMrn: Scalars["String"]["input"];
};

/** Search result type */
export type ContentSearchResultItem = Policy;

/** Connection for content search results */
export type ContentSearchResultItemConnection = {
  __typename: "ContentSearchResultItemConnection";
  /** The edges for each item */
  edges: Array<ContentSearchResultItemEdge>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** The total number of items matching the search criteria */
  totalCount: Scalars["Int"]["output"];
};

/** Edge for a content search result item */
export type ContentSearchResultItemEdge = {
  __typename: "ContentSearchResultItemEdge";
  /** Pagination cursor */
  cursor: Scalars["ID"]["output"];
  /** The search result item */
  node: ContentSearchResultItem;
};

/** User defined order direction and field */
export type ContentSearchResultItemOrder = {
  /** The direction to order the results */
  direction: OrderDirection;
  /** The field to order the results */
  field: ContentSearchResultItemOrderField;
};

/** Order fields for content search results */
export enum ContentSearchResultItemOrderField {
  Name = "NAME",
}

/** Control assets statistics */
export type ControlAssetsStats = {
  __typename: "ControlAssetsStats";
  /** Compliant assets count */
  compliantAssets: Scalars["Int"]["output"];
  /** Non-compliant assets count */
  failedAssets: Scalars["Int"]["output"];
  /** Total assets count */
  totalAssets: Scalars["Int"]["output"];
};

/** Control checks statistics */
export type ControlChecksStats = {
  __typename: "ControlChecksStats";
  /** Active checks count */
  activeChecks: Scalars["Int"]["output"];
  /** Active queries count */
  activeQueries: Scalars["Int"]["output"];
  /** Disabled checks count */
  disabledChecks: Scalars["Int"]["output"];
  /** Disabled queries count */
  disabledQueries: Scalars["Int"]["output"];
  /** Snoozed checks count */
  snoozedChecks: Scalars["Int"]["output"];
  /** Snoozed queries count */
  snoozedQueries: Scalars["Int"]["output"];
  /** Total checks count */
  totalChecks: Scalars["Int"]["output"];
  /** Total queries count */
  totalQueries: Scalars["Int"]["output"];
};

/** Input options for the controls of the document */
export type ControlDocumentOptionsInput = {
  /** The MRN of the control */
  controlMRN: Scalars["String"]["input"];
  /** The MRN of the framework the control is for */
  frameworkMRN: Scalars["String"]["input"];
};

/** An exception for a compliance control */
export type ControlException = {
  __typename: "ControlException";
  /**
   * How this exception should be handled
   * Snooze the controls or disable them
   */
  action: ExceptionMutationAction;
  /** The user who created the exception */
  author: Author;
  /** RFC 3339 timestamp */
  createdAt: Scalars["String"]["output"];
  /** The id to use to reference the exception */
  id: Scalars["String"]["output"];
  /** The justification for the exception */
  justification?: Maybe<Scalars["String"]["output"]>;
  /**
   * RFC 3339 timestamp
   * can be empty, when the exception wasn't reviewed yet
   */
  modifiedAt?: Maybe<Scalars["String"]["output"]>;
  /** The review status for the exception */
  reviewStatus: ReviewStatus;
  /**
   * The user who rejected/approved the exception
   * The email is expected
   * This can be empty, when the exception wasn't reviewed yet
   */
  reviewer?: Maybe<Author>;
  /** Title of the exception */
  title: Scalars["String"]["output"];
};

/** Control score node object */
export type ControlScore = {
  __typename: "ControlScore";
  /** Asset information */
  asset?: Maybe<EntityInfoAsset>;
  /** Base score ranging between 0 - 100 */
  baseScore?: Maybe<Scalars["Int"]["output"]>;
  /** Asset ID */
  id: Scalars["String"]["output"];
  /** Last updated time (RFC3339) */
  lastUpdated: Scalars["String"]["output"];
  /** MRN of the Control */
  mrn: Scalars["String"]["output"];
  /** Asset platform */
  platform?: Maybe<Platform>;
  /** State */
  state: ScoreState;
};

/** Control score filters */
export type ControlScoreFilter = {
  /** Filter the result by a control mrn */
  controlMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** Whether to include controls for CI/CD assets in the results */
  includeCicd?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Optional query terms. Will return only vulnerabilities containing
   * the term in their MRN
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Filter by state */
  state?: InputMaybe<ScoreStateFilter>;
};

/** Control score order */
export type ControlScoreOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: ControlScoreOrderField;
};

/** Control Score order field */
export enum ControlScoreOrderField {
  AssetName = "ASSET_NAME",
  LastUpdated = "LAST_UPDATED",
  Score = "SCORE",
}

/** Control scores connection */
export type ControlScoresConnection = {
  __typename: "ControlScoresConnection";
  /** Edges */
  edges?: Maybe<Array<ControlScoresEdge>>;
  /** Total count with filters applied */
  filteredTotalCount: Scalars["Int"]["output"];
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Control scores edge */
export type ControlScoresEdge = {
  __typename: "ControlScoresEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<ControlScore>;
};

export type ControlScoresResponse =
  | ControlScoresConnection
  | NotFoundError
  | RequestError;

/** Control state */
export enum ControlState {
  Active = "ACTIVE",
  Disabled = "DISABLED",
  OutOfScope = "OUT_OF_SCOPE",
  Snoozed = "SNOOZED",
}

/** Connection for compliance controls */
export type ControlsConnection = {
  __typename: "ControlsConnection";
  /** Edges */
  edges?: Maybe<Array<ControlsEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** An edge in the compliance controls connection. Represents a single control */
export type ControlsEdge = {
  __typename: "ControlsEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The control */
  node?: Maybe<ComplianceControl>;
};

/** Controls order */
export type ControlsOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: ControlsOrderField;
};

/** Controls order field */
export enum ControlsOrderField {
  Assets = "ASSETS",
  Checks = "CHECKS",
  Completion = "COMPLETION",
  Exceptions = "EXCEPTIONS",
  Id = "ID",
  Queries = "QUERIES",
  Title = "TITLE",
}

/** Controls search input */
export type ControlsSearchInput = {
  /**
   * after is the pagination cursor. To paginate to the end of the search results,
   * keep calling search the the endCursor from the pageInfo response while the
   * hasNextPage field is true
   */
  after?: InputMaybe<Scalars["String"]["input"]>;
  /** limit is the number of items to return. Defaults to 100 */
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** Order by */
  orderBy?: InputMaybe<ControlsOrder>;
  /** query, used to search on controls' or policies' description */
  query?: InputMaybe<Scalars["String"]["input"]>;
};

/** Control statistics */
export type ControlsStats = {
  __typename: "ControlsStats";
  /** Active controls count */
  activeControls: Scalars["Int"]["output"];
  /** Average completion */
  averageCompletion: Scalars["Int"]["output"];
  /** Disabled controls count */
  disabledControls: Scalars["Int"]["output"];
  /** Out of scope controls count */
  outOfScopeControls: Scalars["Int"]["output"];
  /** Previous average completion */
  previousAverageCompletion: Scalars["Int"]["output"];
  /** Snoozed controls count */
  snoozedControls: Scalars["Int"]["output"];
  /** Total controls count */
  totalControls: Scalars["Int"]["output"];
};

/** The input to create a case */
export type CreateCaseInput = {
  /** Ticketing integration MRN */
  integrationMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** References */
  references: Array<CaseRefInput>;
  /** Ticket configuration */
  ticketConfig: CaseTicketConfigInput;
  /** Title */
  title: Scalars["String"]["input"];
};

/** Input provided when creating a client integration */
export type CreateClientIntegrationInput = {
  configurationOptions: ClientIntegrationConfigurationInput;
  longLivedToken: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  spaceMrn: Scalars["String"]["input"];
  type: ClientIntegrationType;
};

/** Response from creating a client integration */
export type CreateClientIntegrationPayload = {
  __typename: "CreateClientIntegrationPayload";
  integration: ClientIntegration;
};

export type CreateInvitationInput = {
  expiresAt?: InputMaybe<Scalars["String"]["input"]>;
  inviteeEmail?: InputMaybe<Scalars["String"]["input"]>;
  inviteeMrn?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  resourceMrn: Scalars["String"]["input"];
  roleMrn: Scalars["String"]["input"];
};

/** Create organization input */
export type CreateOrganizationInput = {
  /** ID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Name */
  name: Scalars["String"]["input"];
};

export type CreateServiceAccountInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  labels?: InputMaybe<Array<KeyValueInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<Array<RoleInput>>;
  scopeMrn: Scalars["String"]["input"];
  validUntil?: InputMaybe<Scalars["String"]["input"]>;
};

/** Create space input */
export type CreateSpaceInput = {
  /** ID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Name */
  name: Scalars["String"]["input"];
  /** Organization MRN */
  orgMrn: Scalars["String"]["input"];
  /** Settings */
  settings?: InputMaybe<SpaceSettingsInput>;
};

/** CsvReport */
export type CsvReport = {
  __typename: "CsvReport";
  /** csv data */
  data: Scalars["String"]["output"];
  /** report description */
  description: Scalars["String"]["output"];
  /** asset/space mrn */
  subjectMrn: Scalars["ID"]["output"];
};

/** CVE */
export type Cve = LibraryNode & {
  __typename: "Cve";
  /** Aggregate scores for advisories containing the cve */
  advisoryAggregateScores?: Maybe<AggregateScoresResponse>;
  /**
   * only set in context of space
   * @deprecated Use vulnerabilityScores API to get the affected assets. This will be removed
   */
  assets?: Maybe<Array<AssetItem>>;
  /** CVE score */
  cvssScore: CvssScore;
  /** CVE scores */
  cvssScores: Array<Maybe<CvssScore>>;
  /** CVE CWE */
  cwe?: Maybe<Scalars["String"]["output"]>;
  /** CVE description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** EPSS score for this CVE */
  epssScore?: Maybe<EpssScore>;
  /**
   * Exploits are now attached to CVE when available
   * @deprecated Use `vulnerabilityRiskFactors`.
   */
  exploitable?: Maybe<Scalars["Boolean"]["output"]>;
  /** List of known exploits for this CVE */
  exploits?: Maybe<Array<Maybe<Exploit>>>;
  /** CVE id */
  id: Scalars["String"]["output"];
  /**
   * CVE modified date
   * @deprecated Use `modifiedAt`.
   */
  modified?: Maybe<Scalars["String"]["output"]>;
  /** CVE modified date */
  modifiedAt?: Maybe<Scalars["String"]["output"]>;
  /** CVE mrn */
  mrn: Scalars["String"]["output"];
  /**
   * CVE published date
   * @deprecated Use `publishedAt`.
   */
  published?: Maybe<Scalars["String"]["output"]>;
  /** CVE published date */
  publishedAt?: Maybe<Scalars["String"]["output"]>;
  /** References to NVD, CISA, etc. */
  references?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /**
   * Risk score ranging between 0 - 100, calculated based on base score and risk factors.
   * Only set in context of space
   */
  riskScore?: Maybe<Scalars["Int"]["output"]>;
  /**
   * CVE score
   * @deprecated Use `cvssScore`.
   */
  score: CvssScore;
  /** CVE source */
  source: Source;
  /** CVE state */
  state?: Maybe<CveState>;
  /**
   * CVE summary
   * @deprecated Use `description`.
   */
  summary?: Maybe<Scalars["String"]["output"]>;
  /** CVE title */
  title?: Maybe<Scalars["String"]["output"]>;
  /**
   * CVE url
   * @deprecated Use `references`.
   */
  url?: Maybe<Scalars["String"]["output"]>;
  /** VulnerabilityRiskFactors: exploitable, RCE, ... */
  vulnerabilityRiskFactors: VulnerabilityRiskFactors;
};

/** CVE */
export type CveAdvisoryAggregateScoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  spaceMrn: Scalars["String"]["input"];
};

/** An cve edge object */
export type CveEdge = {
  __typename: "CveEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real cve */
  node?: Maybe<Cve>;
};

/** CVE filters */
export type CveFilter = {
  /**
   * filter by textfield input. ex.: ["CHROME-2023"]
   * elements will be ANDed
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** cve order object */
export type CveOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: CveOrderField;
};

/** CVE order fields */
export enum CveOrderField {
  Id = "ID",
  Modified = "MODIFIED",
  Published = "PUBLISHED",
  Score = "SCORE",
  Title = "TITLE",
}

/** Possible CVE states */
export enum CveState {
  Invalid = "INVALID",
  MergedTo = "MERGED_TO",
  Public = "PUBLIC",
  ReplacedBy = "REPLACED_BY",
  Reserved = "RESERVED",
  SplitFrom = "SPLIT_FROM",
}

/** cve connection object */
export type CveSummariesConnection = {
  __typename: "CveSummariesConnection";
  /** cves */
  edges?: Maybe<Array<CveSummaryEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Statistics */
  stats?: Maybe<CveSummariesConnectionStats>;
  /** Total count of cves */
  totalCount: Scalars["Int"]["output"];
};

/** CVE stats counts for the AssetVulnerabilityReport */
export type CveSummariesConnectionStats = {
  __typename: "CveSummariesConnectionStats";
  /** Count of CVEs affecting the asset (w/o any filtering) */
  totalAssetCves: Scalars["Int"]["output"];
  /** Total count of CVEs present in the MVD */
  totalDatabaseCves: Scalars["Int"]["output"];
};

/** CVE summary */
export type CveSummary = {
  __typename: "CveSummary";
  /** statistics about affected assets */
  affectedAssetStats: AffectedAssetStats;
  /**
   * only set in context of space
   * assetSummary, always include once affected
   */
  assetSummaries?: Maybe<VulnerabilityReportAssetSummariesConnection>;
  /** CVE data */
  cve: Cve;
  /** discovered at */
  discoveredAt?: Maybe<Scalars["String"]["output"]>;
  /** CVE id */
  id: Scalars["String"]["output"];
  /** space mrn */
  spaceMrn: Scalars["String"]["output"];
};

/** CVE summary */
export type CveSummaryAssetSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AssetSummaryOrder>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** An cve edge object */
export type CveSummaryEdge = {
  __typename: "CveSummaryEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real cve */
  node?: Maybe<CveSummary>;
};

/** cve summary order object */
export type CveSummaryOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: CveSummaryOrderField;
};

/** possible cve summary order field */
export enum CveSummaryOrderField {
  Id = "ID",
  Modified = "MODIFIED",
  Published = "PUBLISHED",
  Riskfactors = "RISKFACTORS",
  Score = "SCORE",
  Title = "TITLE",
}

/** cve connection object */
export type CvesConnection = {
  __typename: "CvesConnection";
  /** cves */
  edges?: Maybe<Array<CveEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Total count of cves */
  totalCount: Scalars["Int"]["output"];
};

/** CVSS score */
export type CvssScore = {
  __typename: "CvssScore";
  /** ID */
  id: Scalars["String"]["output"];
  /** Source */
  source: Scalars["String"]["output"];
  /** Type */
  type: Scalars["Int"]["output"];
  /** Value */
  value: Scalars["Int"]["output"];
  /** Vector */
  vector: Scalars["String"]["output"];
};

/** Data queries connection */
export type DataQueriesConnection = {
  __typename: "DataQueriesConnection";
  /** Edges */
  edges?: Maybe<Array<DataQueriesEdge>>;
  /** Total count with filters applied */
  filteredTotalCount: Scalars["Int"]["output"];
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Data queries edge */
export type DataQueriesEdge = {
  __typename: "DataQueriesEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<DataQuery>;
};

export type DataQueriesResponse =
  | DataQueriesConnection
  | NotFoundError
  | RequestError;

/** Data query node object */
export type DataQuery = {
  __typename: "DataQuery";
  /** Asset information */
  asset?: Maybe<EntityInfoAsset>;
  /** Icon identifier for the score */
  iconId: Icon_Ids;
  /** Query ID */
  id: Scalars["String"]["output"];
  /** Last updated time (RFC3339) */
  lastUpdated: Scalars["String"]["output"];
  /** The mquery */
  mquery?: Maybe<Mquery>;
  /** MRN of the query */
  mrn: Scalars["String"]["output"];
  /** Asset platform */
  platform?: Maybe<Platform>;
  /** Risk score tags */
  tags?: Maybe<Array<Tag>>;
  /**
   * Title
   * @deprecated Use mquery.title
   */
  title: Scalars["String"]["output"];
  /** The value of the data query */
  value?: Maybe<Scalars["Any"]["output"]>;
};

/** Data query filters */
export type DataQueryFilter = {
  /** Whether to include checks for CI/CD assets in the results */
  includeCicd?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter the result by a policy mrn */
  policyMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** Filter the result by a query mrn */
  queryMrn?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Optional query terms. Will return only vulnerabilities containing
   * the term in their MRN
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Data query order */
export type DataQueryOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: DataQueryOrderField;
};

/** Data query order field */
export enum DataQueryOrderField {
  AssetName = "ASSET_NAME",
  LastUpdated = "LAST_UPDATED",
}

export type DeleteApiTokenInput = {
  mrn: Scalars["String"]["input"];
};

export type DeleteApiTokenResponse = {
  __typename: "DeleteAPITokenResponse";
  ok: Scalars["Boolean"]["output"];
};

/** Input used when deleting an agent */
export type DeleteAgentsInput = {
  agentMrns: Array<Scalars["String"]["input"]>;
  spaceMrn: Scalars["String"]["input"];
};

/** Response when deleting an agent */
export type DeleteAgentsPayload = {
  __typename: "DeleteAgentsPayload";
  agentMrns: Array<Scalars["String"]["output"]>;
  errors: Scalars["Map"]["output"];
};

/** Delete assets input */
export type DeleteAssetsInput = {
  /** Asset MRNs */
  assetMrns?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Space MRN */
  spaceMrn: Scalars["String"]["input"];
};

/** Delete assets payload */
export type DeleteAssetsPayload = {
  __typename: "DeleteAssetsPayload";
  /** Asset MRNs */
  assetMrns?: Maybe<Array<Scalars["ID"]["output"]>>;
  /** Errors */
  errors: Scalars["Map"]["output"];
  /** Space MRN */
  spaceMrn: Scalars["String"]["output"];
};

/** Delete cases input */
export type DeleteCasesInput = {
  /** MRNs of cases to delete */
  mrns: Array<Scalars["String"]["input"]>;
};

/** Input for deleting a client integration */
export type DeleteClientIntegrationInput = {
  mrn: Scalars["String"]["input"];
};

/** Response when deleting an integration */
export type DeleteClientIntegrationPayload = {
  __typename: "DeleteClientIntegrationPayload";
  mrn: Scalars["String"]["output"];
};

/** Input for deleting a policy */
export type DeleteCustomPolicyInput = {
  /** The mrn of the policy */
  policyMrn: Scalars["String"]["input"];
};

/** Result of deleting a policy */
export type DeleteCustomPolicyPayload = {
  __typename: "DeleteCustomPolicyPayload";
  /** The mrn of the policy */
  policyMrn: Scalars["String"]["output"];
};

/** Input for deleting a query pack */
export type DeleteCustomQueryPackInput = {
  /** The mrn of the query pack */
  queryPackMrn: Scalars["String"]["input"];
};

/** Result of deleting a query pack */
export type DeleteCustomQueryPackPayload = {
  __typename: "DeleteCustomQueryPackPayload";
  /** The mrn of the query pack */
  queryPackMrn: Scalars["String"]["output"];
};

/** Delete framework input */
export type DeleteFrameworkInput = {
  /** Framework MRN */
  mrn: Scalars["String"]["input"];
};

export type DeleteProjectsInput = {
  mrns: Array<Scalars["String"]["input"]>;
};

export type DeleteProjectsPayload = {
  __typename: "DeleteProjectsPayload";
  mrns: Array<Scalars["String"]["output"]>;
};

export type DeleteSshKeyPayload = {
  __typename: "DeleteSSHKeyPayload";
  success: Scalars["Boolean"]["output"];
};

export type DeleteSshKeyRequest = {
  fingerprint: Scalars["String"]["input"];
  mrn: Scalars["String"]["input"];
};

export type DeleteSsoProviderInput = {
  organizationMrn: Scalars["String"]["input"];
};

export type DeleteServiceAccountsInput = {
  mrns: Array<Scalars["String"]["input"]>;
  scopeMrn: Scalars["String"]["input"];
};

export type DeleteServiceAccountsPayload = {
  __typename: "DeleteServiceAccountsPayload";
  errors: Scalars["Map"]["output"];
  mrns: Array<Scalars["String"]["output"]>;
};

/** Discovery statistics */
export type DiscoveryStat = {
  __typename: "DiscoveryStat";
  /** Count */
  count: Scalars["Int"]["output"];
  /** Identifier */
  identifier?: Maybe<Scalars["String"]["output"]>;
  /** Query */
  query: Scalars["String"]["output"];
  /** The title */
  title: Scalars["String"]["output"];
};

/** Response when downloading document */
export type DocumentDownloadPayload = {
  __typename: "DocumentDownloadPayload";
  /** The signed URL for containing any collected debug information */
  debugDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  /** The signed URL for downloading the document */
  downloadUrl: Scalars["String"]["output"];
  /** The time at which the download URL expires */
  expiresAt: Scalars["String"]["output"];
};

/** Document format */
export enum DocumentFormat {
  Pdf = "PDF",
}

/** List of document metadata */
export type DocumentMetadata = {
  __typename: "DocumentMetadata";
  /** The MRN of the user who requested the document */
  createdBy?: Maybe<Author>;
  /** The format of the document */
  format: DocumentFormat;
  /** The time at which the document was generated */
  generatedAt?: Maybe<Scalars["String"]["output"]>;
  /** The MRN of the document */
  mrn: Scalars["String"]["output"];
  /** The name of the document */
  name: Scalars["String"]["output"];
  /** The time at which the document was requested */
  requestedAt: Scalars["String"]["output"];
  /** The status of the document */
  status: DocumentStatus;
  /** The message associated with the document status */
  statusMessage?: Maybe<Scalars["String"]["output"]>;
  /** The type of the document */
  type: DocumentType;
};

/** List of document metadata */
export type DocumentMetadataConnection = {
  __typename: "DocumentMetadataConnection";
  /** A list of document metadata */
  edges: Array<DocumentMetadataEdge>;
  /** Information about the current page */
  pageInfo: PageInfo;
  /** The total number of documents */
  totalCount: Scalars["Int"]["output"];
};

/** List of document metadata edge */
export type DocumentMetadataEdge = {
  __typename: "DocumentMetadataEdge";
  /** The cursor for this edge */
  cursor: Scalars["String"]["output"];
  /** The document metadata for this edge */
  node: DocumentMetadata;
};

/** Document status */
export enum DocumentStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  Queued = "QUEUED",
  Running = "RUNNING",
}

/** Document type */
export enum DocumentType {
  ControlReport = "CONTROL_REPORT",
  FrameworkReport = "FRAMEWORK_REPORT",
}

/** Download bundle input */
export type DownloadBundleInput = {
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["input"];
};

/** Download bundle payload */
export type DownloadBundlePayload = PolicyBundleYaml;

/** Download framework input */
export type DownloadFrameworkInput = {
  /**
   * Whether to include all evidence in the bundle. By default, only
   * evidence for activated policies is returned.
   */
  allEvidence?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Framework MRN */
  mrn: Scalars["String"]["input"];
  /** Scope MRN */
  scopeMrn: Scalars["String"]["input"];
};

/** End-of-life analysis */
export type EolAnalysis = {
  __typename: "EOLAnalysis";
  /** Date */
  date: Scalars["String"]["output"];
  /** Status */
  status: EolStatus;
};

/** End-of-life status */
export enum EolStatus {
  Eol = "EOL",
  Scheduled = "SCHEDULED",
}

/** EBS scan options for the AWS integration */
export type EbsScanOptions = {
  __typename: "EbsScanOptions";
  /** @deprecated Will be removed in the future */
  maxAsgInstances?: Maybe<Scalars["Int"]["output"]>;
  targetInstancesPerScanner?: Maybe<Scalars["Int"]["output"]>;
};

/** Options for EBS scanning with the AWS integration */
export type EbsScanOptionsInput = {
  maxAsgInstances?: InputMaybe<Scalars["Int"]["input"]>;
  targetInstancesPerScanner?: InputMaybe<Scalars["Int"]["input"]>;
};

/** EC2 scan options for the AWS integration */
export type Ec2ScanOptions = {
  __typename: "Ec2ScanOptions";
  /** @deprecated Will be removed in the future */
  awsSecretsManagerVault?: Maybe<Scalars["Boolean"]["output"]>;
  ebsScanOptions?: Maybe<EbsScanOptions>;
  ebsVolumeScan: Scalars["Boolean"]["output"];
  excludedInstanceIdsFilter?: Maybe<Array<Scalars["String"]["output"]>>;
  excludedRegionsFilter?: Maybe<Array<Scalars["String"]["output"]>>;
  excludedTagsFilter?: Maybe<Scalars["Map"]["output"]>;
  instanceConnect: Scalars["Boolean"]["output"];
  instanceIdsFilter?: Maybe<Array<Scalars["String"]["output"]>>;
  regionsFilter?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Will be removed in the future */
  secretsMetadataQuery?: Maybe<Scalars["String"]["output"]>;
  ssm: Scalars["Boolean"]["output"];
  tagsFilter: Scalars["Map"]["output"];
  /** @deprecated Will be removed in the future */
  vaultType?: Maybe<Scalars["String"]["output"]>;
};

/** Options for EC2 scans with the AWS integration */
export type Ec2ScanOptionsInput = {
  ebsScanOptions?: InputMaybe<EbsScanOptionsInput>;
  ebsVolumeScan?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludedInstanceIdsFilter?: InputMaybe<Array<Scalars["String"]["input"]>>;
  excludedRegionsFilter?: InputMaybe<Array<Scalars["String"]["input"]>>;
  excludedTagsFilter?: InputMaybe<Scalars["Map"]["input"]>;
  instanceConnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  instanceIdsFilter?: InputMaybe<Array<Scalars["String"]["input"]>>;
  regionsFilter?: InputMaybe<Array<Scalars["String"]["input"]>>;
  ssm?: InputMaybe<Scalars["Boolean"]["input"]>;
  tagsFilter?: InputMaybe<Scalars["Map"]["input"]>;
};

/** Email configuration options */
export type EmailConfigurationOptions = {
  __typename: "EmailConfigurationOptions";
  /**
   * Whether to auto-close tickets for closed cases
   * @deprecated do not use
   */
  autoCloseTickets: Scalars["Boolean"]["output"];
  /** Whether to auto-create tickets for new cases */
  autoCreateTickets: Scalars["Boolean"]["output"];
  /** A list of potential email recipients */
  recipients: Array<EmailRecipient>;
};

/** Email integration input */
export type EmailConfigurationOptionsInput = {
  /** Whether to auto-close tickets for closed cases */
  autoCloseTickets?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Whether to auto-create tickets for new cases */
  autoCreateTickets?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A list of potential email recipients */
  recipients: Array<EmailRecipientInput>;
};

export type EmailPreference = {
  __typename: "EmailPreference";
  listID: EmailPreferenceList;
  subscribed: Scalars["Boolean"]["output"];
  subscribedAt: Scalars["String"]["output"];
};

export type EmailPreferenceInput = {
  listID: EmailPreferenceList;
  subscribe: Scalars["Boolean"]["input"];
};

export enum EmailPreferenceList {
  NewsletterEvents = "NEWSLETTER_EVENTS",
  NewsletterGeneral = "NEWSLETTER_GENERAL",
  NewsletterProduct = "NEWSLETTER_PRODUCT",
  NotificationSpaceAlerts = "NOTIFICATION_SPACE_ALERTS",
  NotificationWeeklyReports = "NOTIFICATION_WEEKLY_REPORTS",
}

/** Email recipient */
export type EmailRecipient = {
  __typename: "EmailRecipient";
  /** Email of the recipient */
  email: Scalars["String"]["output"];
  /** Whether this recipient is the default recipient */
  isDefault: Scalars["Boolean"]["output"];
  /** Name of the recipient */
  name: Scalars["String"]["output"];
  /**
   * An optional reference URL that will get attached to the ticket reference
   * created by this integration
   */
  referenceUrl?: Maybe<Scalars["String"]["output"]>;
};

/** Email recipient input */
export type EmailRecipientInput = {
  /** Email of the recipient */
  email: Scalars["String"]["input"];
  /** Whether this recipient is the default recipient */
  isDefault: Scalars["Boolean"]["input"];
  /** Name of the recipient */
  name: Scalars["String"]["input"];
  /**
   * An optional reference URL that will get attached to the ticket reference
   * created by this integration
   */
  referenceUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** Email ticket configuration input */
export type EmailTicketConfigInput = {
  /** Email address to send the ticket to */
  emailAddress: Scalars["String"]["input"];
};

/** This type represents an email ticket context. It contains the recipient's email address and display name. */
export type EmailTicketContextPayload = {
  __typename: "EmailTicketContextPayload";
  /** The recipients that are available for creating a ticket. */
  recipients: Array<EmailRecipient>;
};

export type EnableSsoProviderInput = {
  enabled: Scalars["Boolean"]["input"];
  organizationMrn: Scalars["String"]["input"];
};

export type Entitlement = {
  __typename: "Entitlement";
  description: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  units: Scalars["String"]["output"];
  value: Scalars["Int"]["output"];
};

/** Information about the entity, eg. asset or space */
export type Entity = EntityInfoAsset | EntityInfoSpace;

/** Asset Information */
export type EntityInfoAsset = {
  __typename: "EntityInfoAsset";
  /** Asset icon */
  icon?: Maybe<Icon_Ids>;
  /** Asset id */
  id: Scalars["String"]["output"];
  /** Last updated timestamp */
  lastUpdated: Scalars["String"]["output"];
  /** Asset mrn */
  mrn: Scalars["String"]["output"];
  /** Asset name */
  name: Scalars["String"]["output"];
  /** Asset tags */
  tags?: Maybe<Array<Maybe<Tag>>>;
};

/** Space Information */
export type EntityInfoSpace = {
  __typename: "EntityInfoSpace";
  /** Space id */
  id: Scalars["String"]["output"];
  /** Space mrn */
  mrn: Scalars["String"]["output"];
  /** Space name */
  name: Scalars["String"]["output"];
};

/** eol assets configuration input */
export type EolAssetsConfiguration = {
  __typename: "EolAssetsConfiguration";
  /** Whether to enable EOL assets analysis */
  enable: Scalars["Boolean"]["output"];
  /** How many months in advance should EOL be applied as risk factor */
  monthsInAdvance: Scalars["Int"]["output"];
};

/** eol assets configuration input */
export type EolAssetsConfigurationInput = {
  /** Whether to enable EOL assets analysis */
  enable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** How many months in advance should EOL be applied as risk factor */
  monthsInAdvance?: InputMaybe<Scalars["Int"]["input"]>;
};

/** EPSS (Exploit Prediction Scoring System) score for CVEs */
export type EpssScore = {
  __typename: "EpssScore";
  /** Percentile */
  percentile: Scalars["Float"]["output"];
  /** Probability */
  probability: Scalars["Float"]["output"];
};

/** Error */
export type Error = {
  /** Code */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

/** EvaluatedConfiguration contains more details about the configuration of the integration, specific to the k8s integration */
export type EvaluatedConfiguration = {
  __typename: "EvaluatedConfiguration";
  applied?: Maybe<Scalars["Boolean"]["output"]>;
  configured?: Maybe<Scalars["Boolean"]["output"]>;
  msg?: Maybe<Scalars["String"]["output"]>;
  msgStatus?: Maybe<IntegrationMessageStatus>;
  operatorMsg?: Maybe<Scalars["String"]["output"]>;
  operatorMsgStatus?: Maybe<IntegrationMessageStatus>;
  option?: Maybe<Scalars["String"]["output"]>;
};

/** Exception group */
export type ExceptionGroup = {
  __typename: "ExceptionGroup";
  /**
   * How this exception should be handled
   * Snooze the controls or disable them
   */
  action: ExceptionMutationAction;
  /** The user who created the exception. For legacy exceptions this is empty */
  author?: Maybe<Author>;
  /** RFC 3339 timestamp */
  createdAt: Scalars["String"]["output"];
  /** The members of this exception group. Those are always 1 type and cannot be mixed */
  exceptions: Array<Maybe<ExceptionMember>>;
  /** The id to use to reference the exception */
  id: Scalars["String"]["output"];
  /** The justification for the exception group */
  justification?: Maybe<Scalars["String"]["output"]>;
  /**
   * RFC 3339 timestamp
   * can be empty, when the exception wasn't reviewed yet
   */
  modifiedAt?: Maybe<Scalars["String"]["output"]>;
  /** The review status for the exception group */
  reviewStatus: ReviewStatus;
  /**
   * The user who rejected/approved the exception
   * The email is expected
   * This can be empty, when the exception wasn't reviewed yet
   */
  reviewer?: Maybe<Author>;
  /** The scope of the exception. Can be a space or an asset mrn */
  scopeMrn: Scalars["String"]["output"];
  /** Title of the exception group */
  title: Scalars["String"]["output"];
};

/** The input to get a list of exception groups */
export type ExceptionGroupsInput = {
  /** The action of the exception groups. Empty list means all. */
  actions?: InputMaybe<Array<ExceptionMutationAction>>;
  /** The mrn to filter on. It can be a query, control, policy or a framework */
  mrn?: InputMaybe<Scalars["String"]["input"]>;
  /** The mrn of the space/asset */
  scopeMrn: Scalars["String"]["input"];
  /** Deprecated. Use the types field */
  type?: InputMaybe<ExceptionType>;
  /** The types of the exceptions */
  types?: InputMaybe<Array<ExceptionType>>;
};

/** A union of all the possible members of an exception group */
export type ExceptionMember =
  | AssetAdvisoryException
  | AssetCheckException
  | AssetCveException
  | ControlsEdge
  | SpaceCheckException;

/** The action to apply to the exception */
export enum ExceptionMutationAction {
  Disable = "DISABLE",
  Enable = "ENABLE",
  /** Applicable only for compliance */
  OutOfScope = "OUT_OF_SCOPE",
  Snooze = "SNOOZE",
}

/** The input to apply an exception */
export type ExceptionMutationInput = {
  /**
   * How this exception should be handled
   * Snooze the controls or disable them
   * "enable" will delete the supplied control mrn from any exception
   */
  action: ExceptionMutationAction;
  /** List of advisory mrns that are excepted. Applies only for cve exceptions */
  advisoryMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Apply the exception to the CVEs that are part of the advisories. Applies only for advisory exceptions */
  applyToCves?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** List of control mrns that are excepted. Applies only for compliance exceptions */
  controlMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** List of cve mrns that are excepted. Applies only for cve exceptions */
  cveMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * DEPRECATED. WILL BE REMOVED ONCE THE UI IS OUT
   * List of mrns that are excepted
   */
  exceptionMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** The justification for the exception */
  justification?: InputMaybe<Scalars["String"]["input"]>;
  /** List of query mrns that are excepted. Applies only for security exceptions */
  queryMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**
   * DEPRECATED. WILL BE REMOVED ONCE THE UI IS OUT
   * the resource to apply the exception to, e.g. a framework
   */
  resourceMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** The scope of the exception. Can be a space or an asset mrn */
  scopeMrn: Scalars["String"]["input"];
  /**
   * RFC3339 timestamp
   * The date, from which on the exception is no longer valid.
   * Only applies to action IGNORE, empty is interpreted as forever
   */
  validUntil?: InputMaybe<Scalars["String"]["input"]>;
};

/** The type of review action */
export enum ExceptionReviewAction {
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

/** The input to apply a review to an exception */
export type ExceptionReviewInput = {
  /** The review action to be applied */
  action: ExceptionReviewAction;
  /** The id of the exception to review */
  exceptionId: Scalars["String"]["input"];
  /** The scope of the exception. Can be a space or an asset mrn */
  scopeMrn: Scalars["String"]["input"];
};

/** The type of the exception */
export enum ExceptionType {
  Advisory = "ADVISORY",
  Compliance = "COMPLIANCE",
  Cve = "CVE",
  Security = "SECURITY",
}

/** Exploit */
export type Exploit = LibraryNode & {
  __typename: "Exploit";
  /**
   * only set in context of space
   * @deprecated No longer supported. (remove in v12)
   */
  assets?: Maybe<Array<AssetItem>>;
  /**
   * CVEs to which the exploit applies
   * @deprecated No longer supported. (remove in v12)
   */
  cves?: Maybe<Array<Cve>>;
  /** Exploit description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Exploit id */
  id: Scalars["String"]["output"];
  /** Exploit modified date */
  modified?: Maybe<Scalars["String"]["output"]>;
  /**
   * Exploit mrn
   * @deprecated No longer supported. (remove in v12)
   */
  mrn: Scalars["String"]["output"];
  /** Exploit published date */
  published?: Maybe<Scalars["String"]["output"]>;
  /**
   * Exploit score
   * @deprecated No longer supported. (remove in v12)
   */
  score: CvssScore;
  /**
   * Exploit source
   * @deprecated No longer supported. (remove in v12)
   */
  source: Source;
  /** Exploit title */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Field = {
  __typename: "Field";
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nestedContent: Scalars["Boolean"]["output"];
  numElements: Scalars["Int"]["output"];
  path?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
  value?: Maybe<Scalars["Any"]["output"]>;
};

/** Fleet asset link info */
export type FleetAssetLinkInfo = {
  __typename: "FleetAssetLinkInfo";
  /** Asset link type */
  assetLinkType: AssetLinkType;
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
};

/** Fork framework input */
export type ForkFrameworkInput = {
  /**
   * Whether to include all evidence in the bundle. By default, only
   * evidence for activated policies is returned.
   */
  allEvidence?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The MRN of the framework to fork */
  mrn: Scalars["String"]["input"];
  /** Space MRN */
  spaceMrn: Scalars["String"]["input"];
};

/** Output format */
export enum FormatType {
  Csv = "CSV",
  Json = "JSON",
}

/** Framework bundle */
export type FrameworkBundle = {
  __typename: "FrameworkBundle";
  /** YAML */
  yaml: Scalars["String"]["output"];
};

/** Input options for framework document */
export type FrameworkDocumentOptionsInput = {
  /** The MRN of the framework */
  frameworkMRN: Scalars["String"]["input"];
};

/** Garbage collect assets configuration */
export type GarbageCollectAssetsConfiguration = {
  __typename: "GarbageCollectAssetsConfiguration";
  /** After how many days to garbage collect */
  afterDays: Scalars["Int"]["output"];
  /** Whether to enable garbage collection */
  enable: Scalars["Boolean"]["output"];
};

/** Garbage collect assets configuration input */
export type GarbageCollectAssetsConfigurationInput = {
  /** After how many days to garbage collect */
  afterDays?: InputMaybe<Scalars["Int"]["input"]>;
  /** Whether to enable garbage collection */
  enable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** GCP configuration options */
export type GcpConfigurationOptions = {
  __typename: "GcpConfigurationOptions";
  discoverAll?: Maybe<Scalars["Boolean"]["output"]>;
  organizationId?: Maybe<Scalars["String"]["output"]>;
  projectId?: Maybe<Scalars["String"]["output"]>;
};

/** GCP integration input */
export type GcpConfigurationOptionsInput = {
  discoverAll?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["String"]["input"]>;
  /** note: this is the content of the service account JSON file */
  serviceAccount?: InputMaybe<Scalars["String"]["input"]>;
};

/** GCS configuration options */
export type GcsBucketConfigurationOptions = {
  __typename: "GcsBucketConfigurationOptions";
  bucket: Scalars["String"]["output"];
  output: BucketOutputType;
};

/** GCS Bucket integration input */
export type GcsBucketConfigurationOptionsInput = {
  bucket: Scalars["String"]["input"];
  output: BucketOutputType;
  /** note: this is the content of the service account JSON file */
  serviceAccount: Scalars["String"]["input"];
};

export type GenerateApiTokenInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  roles?: InputMaybe<Array<RoleInput>>;
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenerateApiTokenResponse = {
  __typename: "GenerateAPITokenResponse";
  createdAt: Scalars["String"]["output"];
  createdBy: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  scopeMrn: Scalars["String"]["output"];
  /** @deprecated Use scopeMrn */
  spaceMrn?: Maybe<Scalars["String"]["output"]>;
  token: Scalars["String"]["output"];
};

/** Input when generating a document */
export type GenerateDocumentInput = {
  /** Whether to capture debug information */
  captureDebug?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Options for generating a control document */
  controlOptions?: InputMaybe<ControlDocumentOptionsInput>;
  /** The format of the document */
  format: DocumentFormat;
  /** Options for generating a framework document */
  frameworkOptions?: InputMaybe<FrameworkDocumentOptionsInput>;
  /** The name of the document to generate */
  name: Scalars["String"]["input"];
  /** The MRN of the space */
  scopeMRN: Scalars["String"]["input"];
  /** The MRN of the resource for which the document is being generated */
  targetMRN?: InputMaybe<Scalars["String"]["input"]>;
  /** The type of the document */
  type: DocumentType;
};

/** Response when generating a document */
export type GenerateDocumentPayload = {
  __typename: "GenerateDocumentPayload";
  /** The MRN of the document */
  mrn: Scalars["String"]["output"];
};

/** Inputs used to get the delete command for a client integration (used for K8s) */
export type GetClientIntegrationDeleteCommandInput = {
  mrn: Scalars["String"]["input"];
  type: ClientIntegrationType;
};

/** Response from requesting the delete command for a client integration (used for K8s) */
export type GetClientIntegrationDeleteCommandPayload = {
  __typename: "GetClientIntegrationDeleteCommandPayload";
  message: Scalars["String"]["output"];
};

/** Input provided when fetching a token for a client integration */
export type GetClientIntegrationTokenInput = {
  longLivedToken?: InputMaybe<Scalars["Boolean"]["input"]>;
  mrn: Scalars["String"]["input"];
};

/** Response when fetching token for client integration */
export type GetClientIntegrationTokenPayload = {
  __typename: "GetClientIntegrationTokenPayload";
  token: Scalars["String"]["output"];
};

export enum GitPipelineKind {
  Branch = "BRANCH",
  PullRequest = "PULL_REQUEST",
  Tag = "TAG",
  Unknown = "UNKNOWN",
}

/** Github configuration options */
export type GithubConfigurationOptions = {
  __typename: "GithubConfigurationOptions";
  /** Discover k8s manifests in the repositories */
  discoverK8sManifests: Scalars["Boolean"]["output"];
  /** Discover Terraform files in the repositories */
  discoverTerraform: Scalars["Boolean"]["output"];
  /** Github Enterprise Server URL */
  enterpriseUrl?: Maybe<Scalars["String"]["output"]>;
  /**
   * Organization. If specified, the integration will scan the
   * complete organization.
   */
  organization?: Maybe<Scalars["String"]["output"]>;
  /** Repository owner. Must be provided in combination with repository. */
  owner?: Maybe<Scalars["String"]["output"]>;
  /** Scan only the repositories matching these glob patterns. Only applicable when type is ORG. */
  reposAllowList?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Scan all repositories except the ones matching these glob patterns. Only applicable when type is ORG. */
  reposDenyList?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Repository name. Must be provided in combination with owner. */
  repository?: Maybe<Scalars["String"]["output"]>;
  type: GithubIntegrationType;
};

/** Github integration input */
export type GithubConfigurationOptionsInput = {
  /** Discover k8s manifests in the repositories */
  discoverK8sManifests?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Discover Terraform files in the repositories */
  discoverTerraform?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Github Enterprise Server URL */
  enterpriseUrl?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Organization. If specified, the integration will scan the
   * complete organization.
   */
  organization?: InputMaybe<Scalars["String"]["input"]>;
  /** Repository owner. Must be provided in combination with repository. */
  owner?: InputMaybe<Scalars["String"]["input"]>;
  /** Scan only the repositories matching these glob patterns. Only applicable when type is ORG. */
  reposAllowList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Scan all repositories except the ones matching these glob patterns. Only applicable when type is ORG. */
  reposDenyList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Repository name. Must be provided in combination with owner. */
  repository?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  type: GithubIntegrationType;
};

/** Github integration type */
export enum GithubIntegrationType {
  Org = "ORG",
  Repo = "REPO",
}

export type GithubJob = {
  __typename: "GithubJob";
  action: Scalars["String"]["output"];
  actor: Scalars["String"]["output"];
  baseRef: Scalars["String"]["output"];
  eventName: Scalars["String"]["output"];
  eventPath: Scalars["String"]["output"];
  grade: Grade;
  headRef: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  job: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  pipelineKind: GitPipelineKind;
  ref: Scalars["String"]["output"];
  repository: Scalars["String"]["output"];
  runId: Scalars["String"]["output"];
  runNumber: Scalars["String"]["output"];
  serverUrl: Scalars["String"]["output"];
  sha: Scalars["String"]["output"];
  target?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  workflow: Scalars["String"]["output"];
  workspace: Scalars["String"]["output"];
};

/** Github ticket configuration input */
export type GithubTicketConfigInput = {
  /** The Github repo owner */
  owner: Scalars["String"]["input"];
  /** The Github repo */
  repo: Scalars["String"]["input"];
};

/** Github ticketing configuration options */
export type GithubTicketingConfigurationOptions = {
  __typename: "GithubTicketingConfigurationOptions";
  autoCloseTickets: Scalars["Boolean"]["output"];
  autoCreateTickets: Scalars["Boolean"]["output"];
  defaultRepoName?: Maybe<Scalars["String"]["output"]>;
  defaultRepoOwner?: Maybe<Scalars["String"]["output"]>;
  enterpriseUrl?: Maybe<Scalars["String"]["output"]>;
};

/** Github ticketing integration input */
export type GithubTicketingConfigurationOptionsInput = {
  autoCloseTickets: Scalars["Boolean"]["input"];
  autoCreateTickets: Scalars["Boolean"]["input"];
  defaultRepoName?: InputMaybe<Scalars["String"]["input"]>;
  defaultRepoOwner?: InputMaybe<Scalars["String"]["input"]>;
  enterpriseUrl?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** GitlabConfigurationOptions defines the scan configuration for the gitlab integration */
export type GitlabConfigurationOptions = {
  __typename: "GitlabConfigurationOptions";
  baseUrl: Scalars["String"]["output"];
  discoverGroups?: Maybe<Scalars["Boolean"]["output"]>;
  discoverK8sManifests: Scalars["Boolean"]["output"];
  discoverProjects?: Maybe<Scalars["Boolean"]["output"]>;
  discoverTerraform?: Maybe<Scalars["Boolean"]["output"]>;
  group: Scalars["String"]["output"];
  type: GitlabIntegrationType;
};

/** Gitlab integration input */
export type GitlabConfigurationOptionsInput = {
  baseUrl?: InputMaybe<Scalars["String"]["input"]>;
  discoverGroups?: InputMaybe<Scalars["Boolean"]["input"]>;
  discoverK8sManifests?: InputMaybe<Scalars["Boolean"]["input"]>;
  discoverProjects?: InputMaybe<Scalars["Boolean"]["input"]>;
  discoverTerraform?: InputMaybe<Scalars["Boolean"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  type: GitlabIntegrationType;
};

/** Gitlab configuration options */
export enum GitlabIntegrationType {
  /** denotes a limiting group for the integration, we do not want to discover groups not related to this group */
  Group = "GROUP",
  /** nothing is limited, discover all groups */
  None = "NONE",
}

export type GitlabJob = {
  __typename: "GitlabJob";
  commitDescription: Scalars["String"]["output"];
  commitRefName: Scalars["String"]["output"];
  commitSha: Scalars["String"]["output"];
  defaultBranch: Scalars["String"]["output"];
  grade: Grade;
  id: Scalars["String"]["output"];
  jobId: Scalars["String"]["output"];
  jobName: Scalars["String"]["output"];
  jobStage: Scalars["String"]["output"];
  jobURL: Scalars["String"]["output"];
  mergeRequestId: Scalars["String"]["output"];
  mergeRequestProjectUrl: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  pipelineKind: GitPipelineKind;
  pipelineUrl: Scalars["String"]["output"];
  projectId: Scalars["String"]["output"];
  projectTitle: Scalars["String"]["output"];
  projectUrl: Scalars["String"]["output"];
  target?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  userEmail: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
};

/** Gitlab ticket configuration input */
export type GitlabTicketConfigInput = {
  /** The Gitlab project */
  project: Scalars["String"]["input"];
};

/** Gitlab ticketing configuration options */
export type GitlabTicketingConfigurationOptions = {
  __typename: "GitlabTicketingConfigurationOptions";
  autoCloseTickets: Scalars["Boolean"]["output"];
  autoCreateTickets: Scalars["Boolean"]["output"];
  baseUrl?: Maybe<Scalars["String"]["output"]>;
  defaultProject?: Maybe<Scalars["String"]["output"]>;
};

/** Gitlab ticketing integration input */
export type GitlabTicketingConfigurationOptionsInput = {
  autoCloseTickets: Scalars["Boolean"]["input"];
  autoCreateTickets: Scalars["Boolean"]["input"];
  baseUrl?: InputMaybe<Scalars["String"]["input"]>;
  defaultProject?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Google Workspace configuration options */
export type GoogleWorkspaceConfigurationOptions = {
  __typename: "GoogleWorkspaceConfigurationOptions";
  customerId: Scalars["String"]["output"];
  impersonatedUserEmail: Scalars["String"]["output"];
};

/** Google Workspace integration input */
export type GoogleWorkspaceConfigurationOptionsInput = {
  customerId: Scalars["String"]["input"];
  impersonatedUserEmail: Scalars["String"]["input"];
  /** note: this is the content of the service account JSON file */
  serviceAccount?: InputMaybe<Scalars["String"]["input"]>;
};

/** deprecated, use Score.grade instead */
export enum Grade {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  F = "F",
  U = "U",
}

/** Group filter */
export type GroupFilter = {
  /** Asset types */
  assetTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Group type */
  groupType: Scalars["String"]["input"];
};

/** Host configuration options */
export type HostConfigurationOptions = {
  __typename: "HostConfigurationOptions";
  host: Scalars["String"]["output"];
  http?: Maybe<Scalars["Boolean"]["output"]>;
  https?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Host integration input */
export type HostConfigurationOptionsInput = {
  host: Scalars["String"]["input"];
  http?: InputMaybe<Scalars["Boolean"]["input"]>;
  https?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Hosted AWS integration configuration */
export type HostedAwsConfigurationOptions = {
  __typename: "HostedAwsConfigurationOptions";
  accessKeyId?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
};

/** Hosted AWS integration input */
export type HostedAwsConfigurationOptionsInput = {
  keyCredential?: InputMaybe<AwsSecretKeyCredential>;
  roleCredential?: InputMaybe<AwsRoleCredential>;
};

/** ENUM for icon ids */
export enum Icon_Ids {
  Aix = "AIX",
  AlmaLinux = "ALMA_LINUX",
  Alpine = "ALPINE",
  Amazon = "AMAZON",
  Arch = "ARCH",
  Arista = "ARISTA",
  Asset = "ASSET",
  Atlassian = "ATLASSIAN",
  Aws = "AWS",
  Azure = "AZURE",
  Busybox = "BUSYBOX",
  Centos = "CENTOS",
  Check = "CHECK",
  Cve = "CVE",
  Debian = "DEBIAN",
  Default = "DEFAULT",
  Dns = "DNS",
  Equinix = "EQUINIX",
  EuroLinux = "EURO_LINUX",
  Fedora = "FEDORA",
  Gcp = "GCP",
  Gentoo = "GENTOO",
  Github = "GITHUB",
  Gitlab = "GITLAB",
  GoogleChrome = "GOOGLE_CHROME",
  GoogleWorkspace = "GOOGLE_WORKSPACE",
  Ipmi = "IPMI",
  K8S = "K8S",
  Kali = "KALI",
  Linux = "LINUX",
  LinuxMint = "LINUX_MINT",
  Macos = "MACOS",
  Manjaro = "MANJARO",
  Microsoft = "MICROSOFT",
  MicrosoftDotnet = "MICROSOFT_DOTNET",
  MicrosoftEdge = "MICROSOFT_EDGE",
  MicrosoftVisualStudioCode = "MICROSOFT_VISUAL_STUDIO_CODE",
  Mozilla = "MOZILLA",
  MozillaFirefox = "MOZILLA_FIREFOX",
  Ms365 = "MS365",
  Oci = "OCI",
  Okta = "OKTA",
  Opcua = "OPCUA",
  Openwrt = "OPENWRT",
  Oracle = "ORACLE",
  Other = "OTHER",
  Policy = "POLICY",
  Pop = "POP",
  Raspbian = "RASPBIAN",
  Redhat = "REDHAT",
  Risk = "RISK",
  RockyLinux = "ROCKY_LINUX",
  ScientificLinux = "SCIENTIFIC_LINUX",
  Slack = "SLACK",
  Suse = "SUSE",
  Terraform = "TERRAFORM",
  Ubios = "UBIOS",
  Ubuntu = "UBUNTU",
  Vcd = "VCD",
  Vmware = "VMWARE",
  VmwarePhoton = "VMWARE_PHOTON",
  Windows = "WINDOWS",
  WrLinux = "WR_LINUX",
}

/** ID suggestion input */
export type IdSuggestionInput = {
  /** ID available */
  idAvailable?: InputMaybe<Scalars["String"]["input"]>;
  /** Parent MRN */
  parentMrn?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response for Organization and Space ID suggestion */
export type IdSuggestionResponse = {
  __typename: "IDSuggestionResponse";
  /** Available */
  available: Scalars["Boolean"]["output"];
  /** ID */
  id: Scalars["String"]["output"];
};

export type Identity = {
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** Impact */
export type Impact = {
  __typename: "Impact";
  /** Rating */
  rating: Scalars["String"]["output"];
  /** Value */
  value: Scalars["Int"]["output"];
};

/** Describes a chatops integration */
export type Integration = {
  __typename: "Integration";
  enabled: Scalars["Boolean"]["output"];
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  options?: Maybe<IntegrationOptions>;
};

/** IntegrationAssetCount describes the amount of inventory assets discovered by the integration */
export type IntegrationAssetCount = {
  __typename: "IntegrationAssetCount";
  assetType: Scalars["String"]["output"];
  total: Scalars["Int"]["output"];
};

/** IntegrationError describes an error reported by the integration */
export type IntegrationError = {
  __typename: "IntegrationError";
  error: Scalars["String"]["output"];
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

/** Input options provided when creating a chatops integration */
export type IntegrationInput = {
  enabled: Scalars["Boolean"]["input"];
  mrn: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  options?: InputMaybe<IntegrationOptionsInput>;
};

/** IntegrationMessage is a message reported by the integration. It can be informational or serve as a warning/error */
export type IntegrationMessage = {
  __typename: "IntegrationMessage";
  identifier?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  status: IntegrationMessageStatus;
  suppressedByUser?: Maybe<Scalars["Boolean"]["output"]>;
  timestamp?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

/** IntegrationMessageStatus denotes the status of the message reported by the integration */
export enum IntegrationMessageStatus {
  Error = "ERROR",
  Info = "INFO",
  Warning = "WARNING",
}

/** Describes options for a chatops integration */
export type IntegrationOptions = {
  __typename: "IntegrationOptions";
  chatid?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

/** Options for chatops integration */
export type IntegrationOptionsInput = {
  chatid?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** Integration statistics */
export type IntegrationStatistics = {
  __typename: "IntegrationStatistics";
  /** Active count */
  active: Scalars["Int"]["output"];
  /** Errored count */
  errored: Scalars["Int"]["output"];
  /** Pending count */
  pending: Scalars["Int"]["output"];
  /** Total count */
  total: Scalars["Int"]["output"];
  /** Integration type */
  type: IntegrationType;
};

/** Summary of client integrations */
export enum IntegrationType {
  Aws = "AWS",
  AwsHosted = "AWS_HOSTED",
  AwsS3 = "AWS_S3",
  Azure = "AZURE",
  AzureBlob = "AZURE_BLOB",
  Bigquery = "BIGQUERY",
  Gcp = "GCP",
  GcsBucket = "GCS_BUCKET",
  Github = "GITHUB",
  Gitlab = "GITLAB",
  GoogleWorkspace = "GOOGLE_WORKSPACE",
  Host = "HOST",
  HostedSlack = "HOSTED_SLACK",
  Httppost = "HTTPPOST",
  K8S = "K8S",
  ManagedClient = "MANAGED_CLIENT",
  MicrosoftDefender = "MICROSOFT_DEFENDER",
  Ms365 = "MS365",
  Msteams = "MSTEAMS",
  Oci = "OCI",
  Okta = "OKTA",
  Postgres = "POSTGRES",
  S3 = "S3",
  Slack = "SLACK",
  Snowflake = "SNOWFLAKE",
  Telegram = "TELEGRAM",
  TicketSystemEmail = "TICKET_SYSTEM_EMAIL",
  TicketSystemGithub = "TICKET_SYSTEM_GITHUB",
  TicketSystemGitlab = "TICKET_SYSTEM_GITLAB",
  TicketSystemJira = "TICKET_SYSTEM_JIRA",
  TicketSystemZendesk = "TICKET_SYSTEM_ZENDESK",
  Unknown = "UNKNOWN",
}

/** Summary of a client integration type */
export type IntegrationTypeSummary = {
  __typename: "IntegrationTypeSummary";
  /** The count of active integrations of this type */
  activeCount: Scalars["Int"]["output"];
  /** The count of assets discovered by integrations of this type */
  assetsCount: Scalars["Int"]["output"];
  /** The count of checks, applicable to integrations of this type */
  checksCount: Scalars["Int"]["output"];
  /** The count of failed integrations of this type */
  failedCount: Scalars["Int"]["output"];
  /** The count of policies, applicable to integrations of this type */
  policiesCount: Scalars["Int"]["output"];
  /** The total count of integrations of this type */
  totalCount: Scalars["Int"]["output"];
  /** The type of the integration for which this summary is */
  type: IntegrationType;
  /** The count of integrations that have warnings of this type */
  warningCount: Scalars["Int"]["output"];
};

/** Input for summary of client integrations */
export type IntegrationsSummaryInput = {
  spaceMrn: Scalars["String"]["input"];
};

/** Summaries of client integrations */
export type IntegrationsSummaryPayload = {
  __typename: "IntegrationsSummaryPayload";
  integrationSummaries?: Maybe<Array<IntegrationTypeSummary>>;
  total: Scalars["Int"]["output"];
};

export type InvalidDataError = Error & {
  __typename: "InvalidDataError";
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type Invitation = {
  __typename: "Invitation";
  expiresAt: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  inviteeEmail: Scalars["String"]["output"];
  inviteeMrn: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  resourceMrn: Scalars["String"]["output"];
  roleMrn: Scalars["String"]["output"];
  senderEmail: Scalars["String"]["output"];
  state: InvitationState;
};

export type InvitationEdge = {
  __typename: "InvitationEdge";
  cursor: Scalars["ID"]["output"];
  node?: Maybe<Invitation>;
};

export type InvitationOrder = {
  direction: OrderDirection;
  field: InvitationOrderField;
};

export enum InvitationOrderField {
  Created = "CREATED",
}

export enum InvitationState {
  Accepted = "ACCEPTED",
  Canceled = "CANCELED",
  Declined = "DECLINED",
  Pending = "PENDING",
}

export type InvitationsConnection = {
  __typename: "InvitationsConnection";
  edges?: Maybe<Array<InvitationEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JenkinsJob = {
  __typename: "JenkinsJob";
  branch: Scalars["String"]["output"];
  buildUrl: Scalars["String"]["output"];
  commitSha: Scalars["String"]["output"];
  grade: Grade;
  id: Scalars["String"]["output"];
  job: Scalars["String"]["output"];
  jobId: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  pipelineKind: GitPipelineKind;
  projectUrl: Scalars["String"]["output"];
  repositoryUrl: Scalars["String"]["output"];
  target?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
};

/** Jira configuration options */
export type JiraConfigurationOptions = {
  __typename: "JiraConfigurationOptions";
  autoCloseTickets: Scalars["Boolean"]["output"];
  autoCreateCases: Scalars["Boolean"]["output"];
  defaultProject: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  host: Scalars["String"]["output"];
};

/** Jira integration input */
export type JiraConfigurationOptionsInput = {
  apiToken: Scalars["String"]["input"];
  autoCloseTickets?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoCreateCases?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultProject: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  host: Scalars["String"]["input"];
};

/** This type represents a Jira project. It contains the project's unique identifier and name. */
export type JiraProject = {
  __typename: "JiraProject";
  /** Whether this is the default project */
  isDefault: Scalars["Boolean"]["output"];
  /** The unique identifier of the project. */
  key: Scalars["String"]["output"];
  /** The name of the project. */
  name: Scalars["String"]["output"];
};

/** Jira ticket configuration input */
export type JiraTicketConfigInput = {
  /** The Jira issue type */
  issueType: Scalars["String"]["input"];
  /** The Jira project key */
  projectKey: Scalars["String"]["input"];
};

/** Parameters for JiraTicketContext */
export type JiraTicketContextInput = {
  /** The parameters for the ticket context. */
  projects?: InputMaybe<JiraTicketContextProjectsInput>;
  /** The type of the ticket context. */
  type: JiraTicketContextType;
  /** The parameters for the ticket context. */
  users?: InputMaybe<JiraTicketContextUsersInput>;
};

/** Jira Projects filter parameters for JiraTicketContext */
export type JiraTicketContextProjectsInput = {
  /** The Jira query to filter the projects by. */
  query?: InputMaybe<Scalars["String"]["input"]>;
};

/** This type represents a Jira ticket context. It contains the projects and users that are available for creating a ticket. */
export type JiraTicketContextProjectsPayload = {
  __typename: "JiraTicketContextProjectsPayload";
  /** The projects that are available for creating a ticket. */
  projects?: Maybe<Array<JiraProject>>;
};

/** The type of the ticket context. */
export enum JiraTicketContextType {
  /** The ticket context for Jira projects. */
  Projects = "PROJECTS",
  /** The ticket context for Jira users. */
  Users = "USERS",
}

/** Jira Users filter parameters for JiraTicketContext */
export type JiraTicketContextUsersInput = {
  /** The Jira project key to filter the users by. */
  projectKey: Scalars["String"]["input"];
  /** The query to filter the users by. */
  query?: InputMaybe<Scalars["String"]["input"]>;
};

/** This type represents a Jira ticket context. It contains the projects and users that are available for creating a ticket. */
export type JiraTicketContextUsersPayload = {
  __typename: "JiraTicketContextUsersPayload";
  /** The users that are available for creating a ticket. */
  users?: Maybe<Array<JiraUser>>;
};

/** This type represents a Jira user. It contains the user's unique identifier and display name. */
export type JiraUser = {
  __typename: "JiraUser";
  /** The unique identifier of the user. */
  accountId: Scalars["String"]["output"];
  /** The display name of the user. */
  displayName: Scalars["String"]["output"];
};

export type Job =
  | AzureDevopsJob
  | CircleCiJob
  | GithubJob
  | GitlabJob
  | JenkinsJob
  | KubernetesJob;

export type JobEdge = {
  __typename: "JobEdge";
  cursor: Scalars["ID"]["output"];
  node: Job;
};

export type JobsConnection = {
  __typename: "JobsConnection";
  edges?: Maybe<Array<JobEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** K8s configuration options */
export type K8sConfigurationOptions = {
  __typename: "K8sConfigurationOptions";
  certificateManager: Scalars["String"]["output"];
  namespaceAllowList?: Maybe<Array<Scalars["String"]["output"]>>;
  namespaceDenyList?: Maybe<Array<Scalars["String"]["output"]>>;
  scanDeploys?: Maybe<Scalars["Boolean"]["output"]>;
  scanNodes?: Maybe<Scalars["Boolean"]["output"]>;
  scanNodesStyle: K8sScanNodesStyle;
  scanPublicImages?: Maybe<Scalars["Boolean"]["output"]>;
  scanWorkloads?: Maybe<Scalars["Boolean"]["output"]>;
};

/** K8s integration input */
export type K8sConfigurationOptionsInput = {
  certificateManager?: InputMaybe<Scalars["String"]["input"]>;
  namespaceAllowList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  namespaceDenyList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  scanDeploys: Scalars["Boolean"]["input"];
  scanNodes: Scalars["Boolean"]["input"];
  scanNodesStyle?: InputMaybe<K8sScanNodesStyle>;
  scanPublicImages?: InputMaybe<Scalars["Boolean"]["input"]>;
  scanWorkloads: Scalars["Boolean"]["input"];
};

/** K8sIntegrationLastState describes the k8s and operator versions */
export type K8sIntegrationLastState = {
  __typename: "K8sIntegrationLastState";
  k8sVersion?: Maybe<Scalars["String"]["output"]>;
  namespace?: Maybe<Scalars["String"]["output"]>;
  operatorVersion?: Maybe<Scalars["String"]["output"]>;
};

/** K8s node scanning style */
export enum K8sScanNodesStyle {
  Cronjob = "CRONJOB",
  Daemonset = "DAEMONSET",
  Deployment = "DEPLOYMENT",
  Unknown = "UNKNOWN",
}

export type KeyValue = {
  __typename: "KeyValue";
  key: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type KeyValueInput = {
  key: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** A value with a KeyValueSource is derived from a key-value pair. */
export type KeyValueSource = {
  __typename: "KeyValueSource";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type KubernetesJob = {
  __typename: "KubernetesJob";
  author: Scalars["String"]["output"];
  clusterId: Scalars["String"]["output"];
  grade: Grade;
  id: Scalars["String"]["output"];
  kind: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  namespace: Scalars["String"]["output"];
  operation: Scalars["String"]["output"];
  ownerKind: Scalars["String"]["output"];
  ownerName: Scalars["String"]["output"];
  ownerUid: Scalars["String"]["output"];
  resourceVersion: Scalars["String"]["output"];
  target?: Maybe<Scalars["String"]["output"]>;
  uid: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
};

export type LegalPolicy = {
  __typename: "LegalPolicy";
  consent?: Maybe<Consent>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  version: Scalars["String"]["output"];
};

/** Possible Library items */
export type LibraryItem = Advisory | Cve | Exploit;

/** Library item connection */
export type LibraryItemConnection = {
  __typename: "LibraryItemConnection";
  /** Library items */
  edges?: Maybe<Array<LibraryItemEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Total count of library items */
  totalCount: Scalars["Int"]["output"];
};

/** Library item edge */
export type LibraryItemEdge = {
  __typename: "LibraryItemEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real library item */
  node?: Maybe<LibraryItem>;
};

/** LibraryItemType */
export enum LibraryItemType {
  Advisory = "ADVISORY",
  Cve = "CVE",
  Exploit = "EXPLOIT",
}

/** LibraryNode */
export type LibraryNode = {
  /** Library item description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Library item id */
  id: Scalars["String"]["output"];
  /** Library item modified date */
  modified?: Maybe<Scalars["String"]["output"]>;
  /** Library item mrn */
  mrn: Scalars["String"]["output"];
  /** Library item published date */
  published?: Maybe<Scalars["String"]["output"]>;
  /** Library item score */
  score: CvssScore;
  /** Library item source */
  source: Source;
  /** Library item title */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** Library order */
export type LibraryOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: LibraryOrderField;
};

/** Library order field */
export enum LibraryOrderField {
  Id = "ID",
  Modified = "MODIFIED",
  Published = "PUBLISHED",
  Score = "SCORE",
  Title = "TITLE",
}

/** Library query resolution */
export enum LibraryQueryResolution {
  Month = "MONTH",
  Year = "YEAR",
}

/** Library statistics entry */
export type LibraryStatisticsEntry = {
  __typename: "LibraryStatisticsEntry";
  /** Key */
  key: Scalars["String"]["output"];
  /** Values */
  values: ReportStatsCves;
};

/** ListClientIntegrationsFilterInput provides filtering options for listing the client integrations */
export type ListClientIntegrationsFilterInput = {
  managedClientsFilters?: InputMaybe<ManagedClientsFilters>;
  /** type is deprecated, use types */
  type?: InputMaybe<ClientIntegrationType>;
  types?: InputMaybe<Array<InputMaybe<ClientIntegrationType>>>;
};

/** ListClientIntegrationsInput describes the input for listing the integrations */
export type ListClientIntegrationsInput = {
  filterQuery?: InputMaybe<ListClientIntegrationsFilterInput>;
  spaceMrn: Scalars["String"]["input"];
};

/** ListClientIntegrationsPayload is the response to a list of client integrations */
export type ListClientIntegrationsPayload = {
  __typename: "ListClientIntegrationsPayload";
  integrations: Array<Maybe<ClientIntegration>>;
};

/** List discovery results input */
export type ListDiscoveryResultsInput = {
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["input"];
};

/** List discovery results payload */
export type ListDiscoveryResultsPayload = {
  __typename: "ListDiscoveryResultsPayload";
  /** Statistics */
  stats?: Maybe<Array<DiscoveryStat>>;
};

/** MProperty */
export type MProperty = {
  __typename: "MProperty";
  /** MQL */
  mql: Scalars["String"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["ID"]["output"];
  /**
   * setByPolicyMrn is the mrn of the policy that set the property. For
   * example, if the policy is overridden by the space, it will be the
   * space mrn
   */
  setByPolicyMrn?: Maybe<Scalars["String"]["output"]>;
  /** The title */
  title: Scalars["String"]["output"];
  /** Unique resource identifier (uid) */
  uid: Scalars["ID"]["output"];
};

/** Filters that can be used when listing managed clients */
export type ManagedClientsFilters = {
  orderBy?: InputMaybe<AgentOrder>;
  queryStrings?: InputMaybe<Array<Scalars["String"]["input"]>>;
  state?: InputMaybe<Array<AgentState>>;
  version?: InputMaybe<Array<AgentVersionFilter>>;
};

export type Member = {
  __typename: "Member";
  roles: Array<Role>;
  user: User;
};

export type MemberEdge = {
  __typename: "MemberEdge";
  cursor: Scalars["ID"]["output"];
  node?: Maybe<Member>;
};

export type MembersConnection = {
  __typename: "MembersConnection";
  edges?: Maybe<Array<MemberEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MembershipOrder = {
  direction: OrderDirection;
  field: MembershipOrderField;
};

export enum MembershipOrderField {
  Name = "NAME",
}

export type MetricEntry = {
  __typename: "MetricEntry";
  /** base64 arrow-encoded result  */
  arrowResult?: Maybe<Scalars["String"]["output"]>;
  /** Metric description */
  description: Scalars["String"]["output"];
  /** Asset or Space Mrm */
  entityMrn: Scalars["String"]["output"];
  /** Metric id */
  id: Scalars["ID"]["output"];
  /** json-encoded result (not for production) */
  jsonResult?: Maybe<Scalars["Any"]["output"]>;
  /** MRN of the metric object */
  metricMrn: Scalars["String"]["output"];
  /** Metric title */
  title: Scalars["String"]["output"];
};

/** metrics response object */
export type MetricsResponse = {
  __typename: "MetricsResponse";
  /**
   * Metrics edges
   * metrics that are not found are ignored
   * input is treated like a search
   */
  entries?: Maybe<Array<MetricEntry>>;
};

/** Microsoft Defender configuration options */
export type MicrosoftDefenderConfigurationOptions = {
  __typename: "MicrosoftDefenderConfigurationOptions";
  clientId: Scalars["String"]["output"];
  secretType?: Maybe<Scalars["String"]["output"]>;
  subscriptionsAllowlist?: Maybe<Array<Scalars["String"]["output"]>>;
  subscriptionsDenylist?: Maybe<Array<Scalars["String"]["output"]>>;
  tenantId: Scalars["String"]["output"];
};

/** Microsoft Defender integration input */
export type MicrosoftDefenderConfigurationOptionsInput = {
  /** certificate or client secret is required */
  certificate?: InputMaybe<Scalars["String"]["input"]>;
  clientId: Scalars["String"]["input"];
  clientSecret?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionsAllowlist?: InputMaybe<Array<Scalars["String"]["input"]>>;
  subscriptionsDenylist?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tenantId: Scalars["String"]["input"];
};

export type ModifyRiskFactorInput = {
  action?: InputMaybe<RiskFactorAction>;
  /** Risk factor magnitude modification */
  magnitude?: InputMaybe<RiskFactorMagnitudeModification>;
  /** Risk factor mrn */
  mrn: Scalars["String"]["input"];
};

/** Mquery */
export type Mquery = {
  __typename: "Mquery";
  /** Action */
  action: PolicyReportScoreEntryAction;
  /**
   * Code - deprecated
   * @deprecated Use mql
   */
  code: Scalars["String"]["output"];
  /**
   * CodeId - deprecated
   * @deprecated Use mrn
   */
  codeId: Scalars["String"]["output"];
  /** Docs */
  docs?: Maybe<MqueryDocs>;
  /** Impact */
  impact?: Maybe<Impact>;
  /** MQL */
  mql: Scalars["String"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["ID"]["output"];
  /** Parent */
  parent?: Maybe<Scalars["String"]["output"]>;
  /** Properties */
  properties?: Maybe<Array<MProperty>>;
  /**
   * Severity - deprecated
   * @deprecated Use impact
   */
  severity?: Maybe<Scalars["Int"]["output"]>;
  /** Tags */
  tags?: Maybe<Array<Tag>>;
  /** The title */
  title: Scalars["String"]["output"];
  /** unique resource identifier (uid) */
  uid: Scalars["ID"]["output"];
  /** Variant queries */
  variantQueries?: Maybe<Array<Maybe<MqueryVariant>>>;
  /** Variants */
  variants?: Maybe<Array<Variant>>;
};

/** Mquery asset data */
export type MqueryAssetData = MqueryAssetDataJson | MqueryAssetDataNone;

/** Mquery asset data connection */
export type MqueryAssetDataConnection = {
  __typename: "MqueryAssetDataConnection";
  /** Edges */
  edges?: Maybe<Array<MqueryAssetDataEdge>>;
  /** Mquery */
  mquery: Mquery;
  /** Page info */
  pageInfo: PageInfo;
};

/** Mquery asset data edge */
export type MqueryAssetDataEdge = {
  __typename: "MqueryAssetDataEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<QueryAssetData>;
};

/** Mquery asset data format */
export enum MqueryAssetDataFormat {
  Json = "JSON",
}

/** Mquery asset data input */
export type MqueryAssetDataInput = {
  /** Format */
  format?: InputMaybe<MqueryAssetDataFormat>;
  /** See AssetSearchInput */
  query?: InputMaybe<Scalars["String"]["input"]>;
  /** queryMrn is the MRN of the query to run */
  queryMrn: Scalars["String"]["input"];
  /** scopeMrn is the MRN of the space in which to search for assets */
  scopeMrn: Scalars["String"]["input"];
};

/** Mquery asset data JSON */
export type MqueryAssetDataJson = {
  __typename: "MqueryAssetDataJSON";
  /** Data */
  data: Scalars["String"]["output"];
};

/** Mquery asset data none */
export type MqueryAssetDataNone = {
  __typename: "MqueryAssetDataNone";
  /** Reason */
  reason?: Maybe<Scalars["String"]["output"]>;
};

/** Mquery asset scores connection */
export type MqueryAssetScoresConnection = {
  __typename: "MqueryAssetScoresConnection";
  /** Description */
  description: Scalars["String"]["output"];
  /** Docs */
  docs?: Maybe<MqueryDocs>;
  /** Edges */
  edges?: Maybe<Array<MqueryAssetScoresEdge>>;
  /** MQL */
  mql: Scalars["String"]["output"];
  /** Page info */
  pageInfo: PageInfo;
  /** Score distribution */
  scoreDistribution: MqueryResultsDistribution;
  /** The title */
  title: Scalars["String"]["output"];
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Mquery asset scores edge */
export type MqueryAssetScoresEdge = {
  __typename: "MqueryAssetScoresEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<QueryAssetScore>;
};

/** Mquery asset scores input */
export type MqueryAssetScoresInput = {
  /**
   * After is the pagination cursor. To paginate to the end of the search results,
   * keep calling search the the endCursor from the pageInfo response while the
   * hasNextPage field is true
   */
  after?: InputMaybe<Scalars["String"]["input"]>;
  /** Limit is the number of items to return. Defaults to 25 */
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** Order by */
  orderBy?: InputMaybe<AssetOrder>;
  /**
   * policyMrn is the MRN of the policy which the query is associated with.
   * If this is provided, the queryMrn will be converted to a code id.
   * This is temporary. We need to capture scores as MRNs, not code ids.
   * Until that happens, we need this.
   */
  policyMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** See AssetSearchInput */
  query?: InputMaybe<Scalars["String"]["input"]>;
  /** queryMrn is the MRN of the query to run */
  queryMrn: Scalars["String"]["input"];
  /** scopeMrn is the MRN of the space in which to search for assets */
  scopeMrn: Scalars["String"]["input"];
};

/** Mquery counts */
export type MqueryCounts = {
  __typename: "MqueryCounts";
  /** Data */
  data: Scalars["Int"]["output"];
  /** Scoring */
  scoring: Scalars["Int"]["output"];
  /** Total */
  total: Scalars["Int"]["output"];
};

/** Mquery docs */
export type MqueryDocs = {
  __typename: "MqueryDocs";
  /**
   * Audit (deprecated use remediations)
   * @deprecated Use remediations
   */
  audit: Scalars["String"]["output"];
  /** Description */
  desc: Scalars["String"]["output"];
  /** References */
  references?: Maybe<Array<MqueryRef>>;
  /**
   * Remediation (deprecated use remediations)
   * @deprecated Use remediations
   */
  remediation: Scalars["String"]["output"];
  /** Remediations */
  remediations?: Maybe<RemediationResult>;
};

/** MqueryInput */
export type MqueryInput = {
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["input"];
};

/** Mquery reference */
export type MqueryRef = {
  __typename: "MqueryRef";
  /** The title */
  title: Scalars["String"]["output"];
  /** URL */
  url: Scalars["String"]["output"];
};

/** Mquery remediation */
export type MqueryRemediation = {
  __typename: "MqueryRemediation";
  /** Description */
  desc: Scalars["String"]["output"];
  /** ID */
  id: Scalars["String"]["output"];
};

/** Mquery results distribution */
export type MqueryResultsDistribution = {
  __typename: "MqueryResultsDistribution";
  /** The number of assets with errors */
  error: Scalars["Int"]["output"];
  /** The number of assets that fail */
  fail: Scalars["Int"]["output"];
  /** The number of assets that pass */
  pass: Scalars["Int"]["output"];
  /** The total number of assets */
  total: Scalars["Int"]["output"];
  /** The number of assets with unknown score */
  unknown: Scalars["Int"]["output"];
};

/** Mquery score */
export type MqueryScore = {
  __typename: "MqueryScore";
  /** Query */
  query: Mquery;
  /** Score distribution */
  scoreDistribution: MqueryResultsDistribution;
  /** Variants */
  variants?: Maybe<Array<MqueryScore>>;
};

/** Mquery score input */
export type MqueryScoreInput = {
  /** queryMrn is the MRN of the query to run */
  queryMrn: Scalars["String"]["input"];
  /** scopeMrn is the MRN of the space in which to search for assets */
  scopeMrn: Scalars["String"]["input"];
};

/** Mquery state */
export enum MqueryState {
  Disabled = "DISABLED",
  Enabled = "ENABLED",
  /** @deprecated Use SNOOZED */
  Ignored = "IGNORED",
  Snoozed = "SNOOZED",
}

/** Mquery summary */
export type MquerySummary = {
  __typename: "MquerySummary";
  /** Asset grades */
  assetGrades: AssetGradeDistribution;
  /** Enhanced asset scores */
  enhancedAssetScores?: Maybe<Array<QueryAssetScore>>;
  /** Exception */
  exception?: Maybe<MquerySummaryException>;
  /** id */
  id: Scalars["ID"]["output"];
  /** Mquery */
  mquery: Mquery;
  /** Mquery state */
  mqueryState: MqueryState;
  /** Mquery type */
  mqueryType: MqueryType;
};

/** Mquery summary edge */
export type MquerySummaryEdge = {
  __typename: "MquerySummaryEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<MquerySummary>;
};

/** Mquery summary exception */
export type MquerySummaryException = {
  __typename: "MquerySummaryException";
  /** Action */
  action: ExceptionMutationAction;
  /** ID */
  id: Scalars["String"]["output"];
  /** Justification */
  justification: Scalars["String"]["output"];
  /** Review status */
  reviewStatus: ReviewStatus;
};

/** Mquery type */
export enum MqueryType {
  Data = "DATA",
  Scoring = "SCORING",
}

/** MqueryVariant */
export type MqueryVariant = {
  __typename: "MqueryVariant";
  /** Docs */
  docs?: Maybe<MqueryDocs>;
  /** Filter */
  filter: Scalars["String"]["output"];
  /** Filter identifier */
  filterID: Scalars["String"]["output"];
  /** Impact */
  impact?: Maybe<Impact>;
  /** MQL */
  mql: Scalars["String"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["ID"]["output"];
  /** Properties */
  properties?: Maybe<Array<Maybe<MProperty>>>;
  /** Tags */
  tags?: Maybe<Array<Tag>>;
  /** The title */
  title: Scalars["String"]["output"];
  /** Unique resource identifier (uid) */
  uid: Scalars["ID"]["output"];
};

/** MS365 configuration options */
export type Ms365ConfigurationOptions = {
  __typename: "Ms365ConfigurationOptions";
  clientId: Scalars["String"]["output"];
  tenantId: Scalars["String"]["output"];
};

/** MS365 integration input */
export type Ms365ConfigurationOptionsInput = {
  /** TODO: temporary optional, fix by having separate update models */
  certificate?: InputMaybe<Scalars["String"]["input"]>;
  clientId: Scalars["String"]["input"];
  tenantId: Scalars["String"]["input"];
};

export type Mutation = {
  __typename: "Mutation";
  acceptInvitation: Scalars["Boolean"]["output"];
  acceptLegalPolicy: Scalars["Boolean"]["output"];
  /** Activate a user in a region */
  activateUserInRegion: ActivateUserInRegionPayload;
  /**
   * DEPRECATED: use setPolicy instead
   * Uploads policy content
   * @deprecated use setCustomPolicy instead
   */
  addPolicy: Scalars["Boolean"]["output"];
  /**
   * DEPRECATED: use setQueryPack instead
   * Uploads query pack content
   * @deprecated use setCustomQueryPack instead
   */
  addQueryPack: Scalars["Boolean"]["output"];
  addSSHKey: AddSshKeyPayload;
  /** Analyse asset with mrn */
  analyseAssetVulnerabilities?: Maybe<AssetVulnerabilityReportResponse>;
  /** Apply an Exception to a control, check, CVE or advisory */
  applyException: Scalars["Boolean"]["output"];
  /** Apply an Exception to a control, check, CVE or advisory for multiple scopes */
  applyExceptionForScopes: Scalars["Boolean"]["output"];
  /** Apply a Review to an Exception (approve/reject) */
  applyExceptionReview: Scalars["Boolean"]["output"];
  /** Applies a mutation to a specific compliance framework */
  applyFrameworkMutation: Scalars["Boolean"]["output"];
  /** Applies a mutation to a property. */
  applyPropertyQueryMutation: PropertyQueryMutationPayload;
  applySpacePlan: ApplySpacePlanPayload;
  /** Assign a policy to a space */
  assignPolicy: Scalars["Boolean"]["output"];
  cancelInvitation: Scalars["Boolean"]["output"];
  changeSubscriptionPlan: ChangeSubscriptionPlanAction;
  changeViewerSetting: Scalars["Boolean"]["output"];
  /** Close case */
  closeCase: Scalars["Boolean"]["output"];
  createBillingSession: BillingSession;
  /** Create a case */
  createCase: Case;
  /** Create a client integration */
  createClientIntegration: CreateClientIntegrationPayload;
  createInvitation: Invitation;
  /** Create an organization */
  createOrganization?: Maybe<Organization>;
  createServiceAccount?: Maybe<ServiceAccountCredential>;
  /** Create a space */
  createSpace?: Maybe<Space>;
  declineInvitation: Scalars["Boolean"]["output"];
  deleteAPIToken?: Maybe<DeleteApiTokenResponse>;
  /** Delete agents (managed client) */
  deleteAgents: DeleteAgentsPayload;
  /** Delete assets */
  deleteAssets?: Maybe<DeleteAssetsPayload>;
  /** Delete cases */
  deleteCases: Scalars["Boolean"]["output"];
  deleteCicdProjects: DeleteProjectsPayload;
  /** Delete a client integration */
  deleteClientIntegration: DeleteClientIntegrationPayload;
  /** Deletes a query pack */
  deleteCustomPolicy: DeleteCustomPolicyPayload;
  /** Deletes a query pack */
  deleteCustomQueryPack: DeleteCustomQueryPackPayload;
  /** Delete a document */
  deleteDocument: Scalars["Boolean"]["output"];
  /** Deletes a custom compliance framework */
  deleteFramework: Scalars["Boolean"]["output"];
  /** Delete an organization */
  deleteOrganization?: Maybe<Scalars["ID"]["output"]>;
  /**
   * DEPRECATED: use deletePolicyV2 instead
   * Deletes a policy
   * @deprecated use deleteCustomPolicy instead
   */
  deletePolicy: Scalars["Boolean"]["output"];
  deleteSSHKey: DeleteSshKeyPayload;
  deleteServiceAccounts: DeleteServiceAccountsPayload;
  /** Delete a space */
  deleteSpace?: Maybe<Scalars["ID"]["output"]>;
  deleteViewerSetting: Scalars["Boolean"]["output"];
  enableSSOProvider: Scalars["Boolean"]["output"];
  /** Forks a compliance framework */
  forkFramework: Scalars["Boolean"]["output"];
  generateAPIToken: GenerateApiTokenResponse;
  /** Generate a document */
  generateDocument: GenerateDocumentPayload;
  generateRegistrationToken: RegistrationToken;
  modifyRiskFactors: Scalars["Boolean"]["output"];
  /** Process case events */
  processCaseEvents: Scalars["Boolean"]["output"];
  /** Refreshes the aggregate risk scores for the given group mrn */
  refreshAggregateScores: Scalars["Boolean"]["output"];
  removeOrganizationMembership: Scalars["Boolean"]["output"];
  removeSSOProvider: Scalars["Boolean"]["output"];
  removeSpaceMembership: Scalars["Boolean"]["output"];
  /** Notify that a report has been viewed */
  reportViewed: Scalars["Boolean"]["output"];
  /** Request s signed URL for downloading a document */
  requestDocumentDownloadUrl: DocumentDownloadPayload;
  resendInvitation: Scalars["Boolean"]["output"];
  revokeRegistrationToken?: Maybe<RevokeRegistrationTokenResponse>;
  setAssetAnnotations?: Maybe<SetAssetAnnotationsPayload>;
  /** Updates a policy with the provided content */
  setCustomPolicy: SetCustomPolicyPayload;
  /** Updates a query pack with the provided content */
  setCustomQueryPack: SetCustomQueryPackPayload;
  setEmailPreference: Scalars["Boolean"]["output"];
  /** Set an integration */
  setIntegration: Scalars["Boolean"]["output"];
  setOrganizationMembership: Scalars["Boolean"]["output"];
  setRoles?: Maybe<SetRolesPayload>;
  setSSOProvider: Scalars["Boolean"]["output"];
  /** Set the SCIM group to space mapping */
  setScimGroupMapping: SetScimGroupMappingPayload;
  setSpaceMembership: Scalars["Boolean"]["output"];
  /** Suppress an integration message */
  suppressIntegrationMessage: SuppressIntegrationMessagePayload;
  /** Unassign a policy from a space */
  unassignPolicy: Scalars["Boolean"]["output"];
  updateAPIToken?: Maybe<ApiToken>;
  /** Update an agent (managed client) */
  updateAgent: UpdateAgentPayload;
  /** Update a client integration */
  updateClientIntegrationConfiguration: UpdateClientIntegrationConfigurationPayload;
  /** Update client integration name */
  updateClientIntegrationName: UpdateClientIntegrationNamePayload;
  /** Update an organization */
  updateOrganization: UpdateOrganizationPayload;
  updateServiceAccount?: Maybe<ServiceAccount>;
  /** Update a space */
  updateSpace: UpdateSpacePayload;
  updateUser: UpdateUserPayload;
  /** Upload a compliance framework */
  uploadFramework: Scalars["Boolean"]["output"];
};

export type MutationAcceptInvitationArgs = {
  invitationMrn: Scalars["ID"]["input"];
};

export type MutationAcceptLegalPolicyArgs = {
  input: AcceptLegalPolicy;
};

export type MutationAddPolicyArgs = {
  input: Array<PolicyAddInput>;
};

export type MutationAddQueryPackArgs = {
  input: Array<QueryPackAddInput>;
};

export type MutationAddSshKeyArgs = {
  input: AddSshKeyRequest;
};

export type MutationAnalyseAssetVulnerabilitiesArgs = {
  input: AnalyseAssetVulnerabilitiesInput;
};

export type MutationApplyExceptionArgs = {
  input: ExceptionMutationInput;
};

export type MutationApplyExceptionForScopesArgs = {
  input: ApplyExceptionForScopesInput;
};

export type MutationApplyExceptionReviewArgs = {
  input: ExceptionReviewInput;
};

export type MutationApplyFrameworkMutationArgs = {
  input: ComplianceFrameworkMutationInput;
};

export type MutationApplyPropertyQueryMutationArgs = {
  input: PropertyQueryMutationInput;
};

export type MutationApplySpacePlanArgs = {
  input: ApplySpacePlanInput;
};

export type MutationAssignPolicyArgs = {
  input: PolicyAssignmentInput;
};

export type MutationCancelInvitationArgs = {
  invitationMrn: Scalars["ID"]["input"];
};

export type MutationChangeSubscriptionPlanArgs = {
  input: ChangeSubscriptionPlanInput;
};

export type MutationChangeViewerSettingArgs = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationCloseCaseArgs = {
  input: CloseCaseInput;
};

export type MutationCreateBillingSessionArgs = {
  input: BillingSessionInput;
};

export type MutationCreateCaseArgs = {
  input: CreateCaseInput;
};

export type MutationCreateClientIntegrationArgs = {
  input: CreateClientIntegrationInput;
};

export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateServiceAccountArgs = {
  input?: InputMaybe<CreateServiceAccountInput>;
};

export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput;
};

export type MutationDeclineInvitationArgs = {
  invitationMrn: Scalars["ID"]["input"];
};

export type MutationDeleteApiTokenArgs = {
  input: DeleteApiTokenInput;
};

export type MutationDeleteAgentsArgs = {
  input: DeleteAgentsInput;
};

export type MutationDeleteAssetsArgs = {
  input: DeleteAssetsInput;
};

export type MutationDeleteCasesArgs = {
  input: DeleteCasesInput;
};

export type MutationDeleteCicdProjectsArgs = {
  input: DeleteProjectsInput;
};

export type MutationDeleteClientIntegrationArgs = {
  input: DeleteClientIntegrationInput;
};

export type MutationDeleteCustomPolicyArgs = {
  input: DeleteCustomPolicyInput;
};

export type MutationDeleteCustomQueryPackArgs = {
  input: DeleteCustomQueryPackInput;
};

export type MutationDeleteDocumentArgs = {
  documentMRN: Scalars["String"]["input"];
};

export type MutationDeleteFrameworkArgs = {
  input: DeleteFrameworkInput;
};

export type MutationDeleteOrganizationArgs = {
  orgMrn: Scalars["ID"]["input"];
};

export type MutationDeletePolicyArgs = {
  input: PolicyDeleteInput;
};

export type MutationDeleteSshKeyArgs = {
  input: DeleteSshKeyRequest;
};

export type MutationDeleteServiceAccountsArgs = {
  input: DeleteServiceAccountsInput;
};

export type MutationDeleteSpaceArgs = {
  spaceMrn: Scalars["ID"]["input"];
};

export type MutationDeleteViewerSettingArgs = {
  key: Scalars["String"]["input"];
};

export type MutationEnableSsoProviderArgs = {
  input: EnableSsoProviderInput;
};

export type MutationForkFrameworkArgs = {
  input: ForkFrameworkInput;
};

export type MutationGenerateApiTokenArgs = {
  input: GenerateApiTokenInput;
};

export type MutationGenerateDocumentArgs = {
  input: GenerateDocumentInput;
};

export type MutationGenerateRegistrationTokenArgs = {
  input: RegistrationTokenInput;
};

export type MutationModifyRiskFactorsArgs = {
  riskFactors: Array<ModifyRiskFactorInput>;
  spaceMrn: Scalars["String"]["input"];
};

export type MutationProcessCaseEventsArgs = {
  input: ProcessCaseEventsInput;
};

export type MutationRefreshAggregateScoresArgs = {
  groupMrn: Scalars["String"]["input"];
};

export type MutationRemoveOrganizationMembershipArgs = {
  input: RemoveOrganizationMembershipInput;
};

export type MutationRemoveSsoProviderArgs = {
  input: DeleteSsoProviderInput;
};

export type MutationRemoveSpaceMembershipArgs = {
  input: RemoveSpaceMembershipInput;
};

export type MutationReportViewedArgs = {
  input: ReportViewedInput;
};

export type MutationRequestDocumentDownloadUrlArgs = {
  documentMRN: Scalars["String"]["input"];
};

export type MutationResendInvitationArgs = {
  invitationMrn: Scalars["ID"]["input"];
};

export type MutationRevokeRegistrationTokenArgs = {
  input: RevokeRegistrationTokenInput;
};

export type MutationSetAssetAnnotationsArgs = {
  input?: InputMaybe<SetAssetAnnotationsInput>;
};

export type MutationSetCustomPolicyArgs = {
  input: Array<SetCustomPolicyInput>;
};

export type MutationSetCustomQueryPackArgs = {
  input: Array<SetCustomQueryPackInput>;
};

export type MutationSetEmailPreferenceArgs = {
  input: Array<EmailPreferenceInput>;
};

export type MutationSetIntegrationArgs = {
  input: IntegrationInput;
};

export type MutationSetOrganizationMembershipArgs = {
  input: SetOrganizationMembershipInput;
};

export type MutationSetRolesArgs = {
  input: SetRolesInput;
};

export type MutationSetSsoProviderArgs = {
  input: SetSsoProviderInput;
};

export type MutationSetScimGroupMappingArgs = {
  input: SetScimGroupMappingInput;
};

export type MutationSetSpaceMembershipArgs = {
  input: SetSpaceMembershipInput;
};

export type MutationSuppressIntegrationMessageArgs = {
  input: SuppressIntegrationMessageInput;
};

export type MutationUnassignPolicyArgs = {
  input: PolicyAssignmentInput;
};

export type MutationUpdateApiTokenArgs = {
  input: UpdateApiTokenInput;
};

export type MutationUpdateAgentArgs = {
  input: UpdateAgentInput;
};

export type MutationUpdateClientIntegrationConfigurationArgs = {
  input: UpdateClientIntegrationConfigurationInput;
};

export type MutationUpdateClientIntegrationNameArgs = {
  input: UpdateClientIntegrationNameInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationUpdateServiceAccountArgs = {
  input: UpdateServiceAccountInput;
};

export type MutationUpdateSpaceArgs = {
  input: UpdateSpaceInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUploadFrameworkArgs = {
  input: UploadFrameworkInput;
};

/** Possible MVD entries */
export type MvdEntry = Advisory | Cve;

/** MVD entry connection */
export type MvdEntryConnection = {
  __typename: "MvdEntryConnection";
  /** MVD entries */
  edges?: Maybe<Array<MvdEntryEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Total count of entries */
  totalCount: Scalars["Int"]["output"];
};

/** MVD entry edge */
export type MvdEntryEdge = {
  __typename: "MvdEntryEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The MVD entry */
  node?: Maybe<MvdEntry>;
};

/** MVD entry type */
export enum MvdEntryType {
  Advisory = "ADVISORY",
  Cve = "CVE",
}

/** MVD order */
export type MvdOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: MvdOrderField;
};

/** MVD order field */
export enum MvdOrderField {
  Id = "ID",
  Modified = "MODIFIED",
  Published = "PUBLISHED",
  Score = "SCORE",
  Title = "TITLE",
}

/** Mondoo Vulnerability Database statistics */
export type MvdStatistics = {
  __typename: "MvdStatistics";
  /** Total count of advisories */
  totalAdvisories: Scalars["Int"]["output"];
  /** Total count of CVEs */
  totalCves: Scalars["Int"]["output"];
};

/** Vulnerability Details */
export type NewVulnerabilityInfo = {
  __typename: "NewVulnerabilityInfo";
  /** number of affected assets */
  assetsAffected: Scalars["Int"]["output"];
  /** CVE */
  cve: Cve;
  /** date */
  date: Scalars["String"]["output"];
};

/** Not found error */
export type NotFoundError = Error & {
  __typename: "NotFoundError";
  /** Code */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

/** Not supported error */
export type NotSupported = Error & {
  __typename: "NotSupported";
  /** Code */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

/** OCI integration input */
export type OciConfigurationOptions = {
  __typename: "OciConfigurationOptions";
  fingerprint: Scalars["String"]["output"];
  region: Scalars["String"]["output"];
  tenancyOcid: Scalars["String"]["output"];
  userOcid: Scalars["String"]["output"];
};

/** OCI integration input */
export type OciConfigurationOptionsInput = {
  fingerprint: Scalars["String"]["input"];
  privateKey?: InputMaybe<Scalars["String"]["input"]>;
  region: Scalars["String"]["input"];
  tenancyOcid: Scalars["String"]["input"];
  userOcid: Scalars["String"]["input"];
};

/** Okta configuration options */
export type OktaConfigurationOptions = {
  __typename: "OktaConfigurationOptions";
  organization: Scalars["String"]["output"];
};

/** Okta integration input */
export type OktaConfigurationOptionsInput = {
  organization: Scalars["String"]["input"];
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** Defines the possible directions in which to sort a list of items. */
export enum OrderDirection {
  /** Ascending order. For example, from A to Z or from lowest to highest. */
  Asc = "ASC",
  /** Descending order. For example, from Z to A or from highest to lowest. */
  Desc = "DESC",
}

/** Org view page info */
export type OrgViewPageInfo = {
  /** Organization MRN */
  orgMrn: Scalars["String"]["input"];
};

/** Organization */
export type Organization = {
  __typename: "Organization";
  /** Capabilities */
  capabilities: Array<Scalars["String"]["output"]>;
  /** ID */
  id: Scalars["ID"]["output"];
  /** List of members of the organization */
  members?: Maybe<MembersConnection>;
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /**
   * DEPRECATED
   * @deprecated Use spacesList instead
   */
  spaces: Array<Space>;
  /** Amount of spaces in the organization */
  spacesCount: Scalars["Int"]["output"];
  /** List of spaces in the organization */
  spacesList: SpaceConnection;
  /** Subscription info */
  subscriptionInfo?: Maybe<SubscriptionInfo>;
};

/** Organization */
export type OrganizationMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MembershipOrder>;
  rolesFilter?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Organization */
export type OrganizationSpacesListArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

/** Organization overview input */
export type OrganizationOverviewInput = {
  /** Organization MRN */
  organizationMrn: Scalars["String"]["input"];
};

/** Organization overview response */
export type OrganizationOverviewResponse = {
  __typename: "OrganizationOverviewResponse";
  /** Asset scores */
  assetScores?: Maybe<Array<AssetScoresDistribution>>;
  /** Assets */
  assets: AssetsInfo;
  /** End-of-life assets count */
  eolAssets: Scalars["Int"]["output"];
  /** Integration summaries */
  integrationSummaries?: Maybe<Array<IntegrationTypeSummary>>;
  /** New vulnerabilities */
  newVulnerabilities?: Maybe<Array<NewVulnerabilityInfo>>;
  /** Organization MRN */
  organizationMrn: Scalars["String"]["output"];
  /** Report date */
  reportDate?: Maybe<Scalars["String"]["output"]>;
  /** Spaces overview */
  spacesOverview?: Maybe<Array<SpaceInfo>>;
  /** Top vulnerabilities */
  topVulnerabilities?: Maybe<Array<VulnerabilityInfo>>;
  /** Vulnerabilities */
  vulnerabilities?: Maybe<Array<Vulnerabilities>>;
};

/** OS Package */
export type Package = {
  __typename: "Package";
  /** Package vulnerabilities */
  advisories: Array<Advisory>;
  /** Package arch */
  arch?: Maybe<Scalars["String"]["output"]>;
  /** Package available version */
  available?: Maybe<Scalars["String"]["output"]>;
  /** Package cves */
  cves?: Maybe<Array<Cve>>;
  /** Package cvss score */
  cvssScore: CvssScore;
  /** Package description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Package epss score */
  epssScore?: Maybe<EpssScore>;
  /**
   * Is the package affected by a CVE which has an exploit
   * @deprecated Use `vulnerabilityRiskFactors`.
   */
  exploitable?: Maybe<Scalars["Boolean"]["output"]>;
  /** Package format */
  format?: Maybe<Scalars["String"]["output"]>;
  /**
   * A short string to identify the icon in the UI
   * @deprecated Deprecated: Use iconIDEnum. (remove in v12)
   */
  iconID?: Maybe<Scalars["String"]["output"]>;
  /** Same as iconID, but as an enum */
  iconIDEnum?: Maybe<Icon_Ids>;
  /** Package id */
  id: Scalars["ID"]["output"];
  /** Package name */
  name: Scalars["String"]["output"];
  /** Package namespace, e.g., ubuntu:18.04 */
  namespace?: Maybe<Scalars["String"]["output"]>;
  /** Package origin */
  origin?: Maybe<Scalars["String"]["output"]>;
  /**
   * Package score
   * @deprecated Use `cvssScore`.
   */
  score: CvssScore;
  /** Package status */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Is it an os package or an app? */
  type: PackageType;
  /** Package version */
  version?: Maybe<Scalars["String"]["output"]>;
  /** When was the highest scored CVE first discovered */
  vulnDiscoveryTime?: Maybe<Scalars["String"]["output"]>;
  /** VulnerabilityRiskFactors: exploitable, RCE, ... */
  vulnerabilityRiskFactors: VulnerabilityRiskFactors;
};

/** Package filters */
export type PackageFilter = {
  /** is package affected by a CVE? */
  isVulnerable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * packageName is a filter with exact match
   * The queryTerms are a "contains" search. That's useful for the general search.
   * But for the softwareDetails we need to search for the exact package name.
   */
  packageName?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * filter by textfield input. ex.: ["Chrome", "Firefox"]
   * elements will be ANDed
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** os package or app */
  type?: InputMaybe<PackageType>;
};

/** Package input */
export type PackageInput = {
  /** Package arch */
  arch?: InputMaybe<Scalars["String"]["input"]>;
  /** Package format */
  format?: InputMaybe<Scalars["String"]["input"]>;
  /** Package name */
  name: Scalars["String"]["input"];
  /** Package origin, needed for debian */
  origin?: InputMaybe<Scalars["String"]["input"]>;
  /** Package version */
  version: Scalars["String"]["input"];
};

/** Remediation script type */
export enum PackageManager {
  /** DNF */
  Dnf = "DNF",
  /** DPKG */
  Dpkg = "DPKG",
  /** POWERSHELL */
  Powershell = "POWERSHELL",
  /** YUM */
  Yum = "YUM",
  /** ZYPPER */
  Zypper = "ZYPPER",
}

export type PackageScore = {
  __typename: "PackageScore";
  /** Asset information */
  asset?: Maybe<EntityInfoAsset>;
  /**
   * Asset MRN
   * @deprecated Use `asset.Mrn` instead.
   */
  assetMrn?: Maybe<Scalars["String"]["output"]>;
  /**
   * Asset name
   * @deprecated Use `asset.Name` instead.
   */
  assetName?: Maybe<Scalars["String"]["output"]>;
  /** Available version of the package */
  availableVersion?: Maybe<Scalars["String"]["output"]>;
  /** CVSS score for this package */
  cvssScore: CvssScore;
  /** EPSS score for this package */
  epssScore?: Maybe<EpssScore>;
  /** First found (RFC3339) */
  firstFound: Scalars["String"]["output"];
  /** Icon ID to determine the icon in the UI */
  iconIDEnum?: Maybe<Icon_Ids>;
  /** Score ID */
  id: Scalars["String"]["output"];
  /** Installed version of the package */
  installedVersion?: Maybe<Scalars["String"]["output"]>;
  /** Last updated (RFC3339) */
  lastUpdated: Scalars["String"]["output"];
  /** Package name */
  packageName?: Maybe<Scalars["String"]["output"]>;
  /** Is it an os package or an app? */
  packageType: PackageType;
  /** platform name and version as single string */
  platform?: Maybe<Scalars["String"]["output"]>;
  /** Risk factors */
  riskFactors?: Maybe<Array<Maybe<RiskFactorStats>>>;
  /** Risk Score */
  riskScore: Scalars["Int"]["output"];
  /** Base Score */
  score: Scalars["Int"]["output"];
  /** Package score tags, e.g., "epss", "available", ... */
  tags?: Maybe<Array<Tag>>;
};

export type PackageScoreEdge = {
  __typename: "PackageScoreEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real package score */
  node?: Maybe<PackageScore>;
};

export type PackageScores = {
  __typename: "PackageScores";
  /** Package scores */
  edges?: Maybe<Array<PackageScoreEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Statistics */
  stats?: Maybe<PackageScoresStats>;
  /** Total count of applications */
  totalApps: Scalars["Int"]["output"];
  /** Total count of scored packages */
  totalCount: Scalars["Int"]["output"];
  /** Total count of os packages */
  totalOsPackages: Scalars["Int"]["output"];
};

/** Package filters */
export type PackageScoresFilter = {
  /** is package affected by a CVE? */
  isVulnerable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Package version */
  packageVersion?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * filter by textfield input. ex.: ["Chrome", "Firefox"]
   * elements will be ANDed
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** os package or app */
  type?: InputMaybe<PackageType>;
};

/**
 * PackageScores input data
 * This requires eithher a spaceMrn AND a findingMrn OR only an assetMrn
 */
export type PackageScoresInput = {
  /** Asset or Space MRN */
  entityMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** Filter */
  filter?: InputMaybe<PackageScoresFilter>;
  /** Package/Finding mrn */
  findingMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** Package version (optional) */
  packageVersion?: InputMaybe<Scalars["String"]["input"]>;
  /** Space MRN */
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

/** PackageScores order */
export type PackageScoresOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: PackageScoresOrderField;
};

/** PackageScores order fields */
export enum PackageScoresOrderField {
  AssetName = "ASSET_NAME",
  FirstFound = "FIRST_FOUND",
  LastUpdated = "LAST_UPDATED",
  PackageName = "PACKAGE_NAME",
  RiskFactors = "RISK_FACTORS",
  RiskScore = "RISK_SCORE",
  Score = "SCORE",
}

/** PackageScores response types */
export type PackageScoresResponse = NotFoundError | PackageScores;

/** Package stats counts for the Package scores */
export type PackageScoresStats = {
  __typename: "PackageScoresStats";
  /** Count of packages installed on the asset */
  totalPackages: Scalars["Int"]["output"];
  /** Count of packages affected by advisories and cves (w/o any filtering) */
  vulnerablePackages: Scalars["Int"]["output"];
};

/** possible package types */
export enum PackageType {
  Application = "APPLICATION",
  OsPackage = "OS_PACKAGE",
}

/** package connection object */
export type PackagesConnection = {
  __typename: "PackagesConnection";
  /** packages */
  edges?: Maybe<Array<PackagesEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Statistics */
  stats?: Maybe<PackagesConnectionStats>;
  /** Total count of packages */
  totalCount: Scalars["Int"]["output"];
  /** Total count of applications */
  totalCountApps: Scalars["Int"]["output"];
  /** Total count of os packages */
  totalCountOsPackages: Scalars["Int"]["output"];
};

/** Package stats counts for the AssetVulnerabilityReport */
export type PackagesConnectionStats = {
  __typename: "PackagesConnectionStats";
  /** Count of packages installed on the asset */
  totalPackages: Scalars["Int"]["output"];
  /** Count of packages affected by advisories and cves (w/o any filtering) */
  vulnerablePackages: Scalars["Int"]["output"];
};

/**
 * An cve edge object
 * this should be singular
 */
export type PackagesEdge = {
  __typename: "PackagesEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real cve */
  node?: Maybe<Package>;
};

/** package order object */
export type PackagesOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** Order field */
  field: PackagesOrderField;
};

/** possible package order fields */
export enum PackagesOrderField {
  FirstFound = "FIRST_FOUND",
  Name = "NAME",
  RiskFactors = "RISK_FACTORS",
  Score = "SCORE",
}

/** PageInfo contains information to aid in pagination. */
export type PageInfo = {
  __typename: "PageInfo";
  /** The cursor for the last item on the current page. */
  endCursor: Scalars["ID"]["output"];
  /** Indicates if there are more items to be queried beyond the current page. */
  hasNextPage: Scalars["Boolean"]["output"];
  /** Indicates if there are more items that can be queried before the current page. */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** The cursor for the first item on the current page. */
  startCursor: Scalars["ID"]["output"];
};

export type PaymentPreview = {
  __typename: "PaymentPreview";
  currency: Scalars["String"]["output"];
  dueToday: Scalars["Int"]["output"];
  effectiveDate: Scalars["String"]["output"];
  prorationDate: Scalars["Int"]["output"];
  recurringCost: Scalars["Int"]["output"];
};

/** Input options for discovery job on client integration */
export type PerformDiscoveryInput = {
  mrn: Scalars["String"]["input"];
};

/** Response when performing discovery job on client integration */
export type PerformDiscoveryPayload = {
  __typename: "PerformDiscoveryPayload";
  mrn: Scalars["String"]["output"];
};

/** Inputs used to ping a client integration */
export type PingIntegrationInput = {
  /** Optional source identifier, e.g. AWS account id. */
  identifiers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  mrn: Scalars["String"]["input"];
};

/** Response from sending ping to client integration */
export type PingIntegrationPayload = {
  __typename: "PingIntegrationPayload";
  mrn: Scalars["String"]["output"];
};

export type PlanningCheck = {
  __typename: "PlanningCheck";
  mquery: Mquery;
  policies?: Maybe<Array<Scalars["String"]["output"]>>;
  scoreDistribution?: Maybe<MqueryResultsDistribution>;
};

export type PlanningGroup = {
  __typename: "PlanningGroup";
  queries?: Maybe<Array<PlanningCheck>>;
  title: Scalars["String"]["output"];
};

export type PlanningMilestone = {
  __typename: "PlanningMilestone";
  endDate?: Maybe<Scalars["String"]["output"]>;
  justification?: Maybe<Scalars["String"]["output"]>;
  queries?: Maybe<Array<PlanningCheck>>;
  title: Scalars["String"]["output"];
  uid: Scalars["String"]["output"];
};

export type PlanningMilestoneMetadataMutationInput = {
  /**
   * The endDate to set. If nil, this value is not modified. If
   * an empty string is passed, the value will be cleared
   */
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * The justification to set. If nil, this value is not modified.
   * If an empty string is passed, the value will be cleared
   */
  justification?: InputMaybe<Scalars["String"]["input"]>;
  /** The uid of the milestone to modify */
  uid: Scalars["String"]["input"];
};

/** Platform */
export type Platform = {
  __typename: "Platform";
  /** The architecture of the platform */
  arch: Scalars["String"]["output"];
  /** Family */
  family?: Maybe<Array<Scalars["String"]["output"]>>;
  /** The kind of the platform */
  kind: PlatformKind;
  /** Labels */
  labels?: Maybe<Array<KeyValue>>;
  /** The name of the platform */
  name: Scalars["String"]["output"];
  /**
   * The release of the platform (deprecated use version instead)
   * @deprecated Use version
   */
  release: Scalars["String"]["output"];
  /** The runtime of the platform */
  runtime: Scalars["String"]["output"];
  /** The title of the platform */
  title: Scalars["String"]["output"];
  /** The version of the platform */
  version: Scalars["String"]["output"];
};

/** Platform distribution statistics */
export type PlatformDistributionStats = {
  __typename: "PlatformDistributionStats";
  /** Assets count */
  assets: Scalars["Int"]["output"];
  /** Platform */
  platform?: Maybe<Platform>;
  /** Rating */
  rating: Scalars["String"]["output"];
};

/** Platform input */
export type PlatformInput = {
  /** The architecture of the platform */
  arch?: InputMaybe<Scalars["String"]["input"]>;
  /** The build of the platform */
  build?: InputMaybe<Scalars["String"]["input"]>;
  /** The platform families */
  family?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** The kind of the platform */
  kind?: InputMaybe<PlatformKind>;
  /** The labels of the platform */
  labels?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  /** The name of the platform */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** The release of the platform */
  release?: InputMaybe<Scalars["String"]["input"]>;
  /** The runtime of the platform */
  runtime?: InputMaybe<Scalars["String"]["input"]>;
};

/** Platform kind */
export enum PlatformKind {
  Api = "API",
  BareMetal = "BARE_METAL",
  Code = "CODE",
  Container = "CONTAINER",
  ContainerImage = "CONTAINER_IMAGE",
  Package = "PACKAGE",
  Process = "PROCESS",
  Unknown = "UNKNOWN",
  VirtualMachine = "VIRTUAL_MACHINE",
  VirtualMachineImage = "VIRTUAL_MACHINE_IMAGE",
}

/** Platform vulnerability configuration */
export type PlatformVulnerabilityConfiguration = {
  __typename: "PlatformVulnerabilityConfiguration";
  /** Whether to enable platform vulnerability analysis */
  enable: Scalars["Boolean"]["output"];
};

/** Platform vulnerability configuration input */
export type PlatformVulnerabilityConfigurationInput = {
  /** Whether to enable platform vulnerability analysis */
  enable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Input for retrieving a list of platforms for the scope */
export type PlatformsListInput = {
  /**
   * scopeMrn is the space mrn the user is requesting platforms for. The returned
   * response will contain all public platforms unioned with platforms appearing
   * in content for this space
   */
  scopeMrn: Scalars["String"]["input"];
};

/** Policy */
export type Policy = {
  __typename: "Policy";
  /** Access */
  access: Access;
  /** Action */
  action?: Maybe<PolicyAction>;
  /** Assigned */
  assigned: Scalars["Boolean"]["output"];
  /** Authors */
  authors?: Maybe<Array<Author>>;
  /** Category */
  category: Scalars["String"]["output"];
  /** Certified by */
  certifiedBy?: Maybe<Certifier>;
  /**
   * Checksum
   * @deprecated Internal detail
   */
  checksum: Scalars["String"]["output"];
  /** Created at */
  createdAt: Scalars["String"]["output"];
  /** The default scoring system intended for the policy */
  defaultScoringSystem?: Maybe<ScoringSystem>;
  /** Docs */
  docs: Scalars["String"]["output"];
  /** Featured */
  featured: Scalars["Boolean"]["output"];
  /** Github URL */
  githubUrl?: Maybe<Scalars["String"]["output"]>;
  /**
   * Graph content checksum
   * @deprecated Internal detail
   */
  graph_content_checksum: Scalars["String"]["output"];
  /**
   * Graph execution checksum
   * @deprecated Internal detail
   */
  graph_execution_checksum: Scalars["String"]["output"];
  /** Groups */
  groups?: Maybe<Array<PolicyGroup>>;
  /**
   * IsPublic - deprecated
   * @deprecated Use access and trustLevel
   */
  isPublic: Scalars["Boolean"]["output"];
  /**
   * Mquery counts
   * @deprecated Use statistics
   */
  mqueryCounts: MqueryCounts;
  /** The mrn is a unique identifier */
  mrn: Scalars["ID"]["output"];
  /** Mutation statistics */
  mutationStatistics?: Maybe<PolicyMutationStatistics>;
  /** Name */
  name: Scalars["String"]["output"];
  /** Platforms */
  platforms?: Maybe<Array<ContentPlatform>>;
  /** Properties */
  properties?: Maybe<Array<MProperty>>;
  /** Queries */
  queries?: Maybe<Array<Mquery>>;
  /** Run CLI */
  runCli?: Maybe<RunCli>;
  /** Scoring system, potentially overridden by the space */
  scoringSystem?: Maybe<ScoringSystem>;
  /** Statistics */
  statistics: PolicyStatistics;
  /**
   * Statistics
   * @deprecated Use statistics
   */
  stats?: Maybe<PolicyStatistics>;
  /** Summary */
  summary: Scalars["String"]["output"];
  /** Tags */
  tags?: Maybe<Array<Tag>>;
  /** Trust level */
  trustLevel: TrustLevel;
  /** Unique resource identifier (uid) */
  uid: Scalars["ID"]["output"];
  /** Updated at */
  updatedAt: Scalars["String"]["output"];
  /** variantPlatformFilters is a list of platform filters to filter variants by */
  variantPlatformFilters?: Maybe<Array<VariantPlatformFilter>>;
  /** Variants */
  variants?: Maybe<Array<Variant>>;
  /** Version */
  version: Scalars["String"]["output"];
};

/** Policy action */
export enum PolicyAction {
  Active = "ACTIVE",
  Ignore = "IGNORE",
}

/** PolicyAddInput */
export type PolicyAddInput = {
  /** RFC2397, base64 encoded policy bundle */
  dataurl: Scalars["String"]["input"];
  /** Force */
  force?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The mrn of the space */
  spaceMrn: Scalars["String"]["input"];
};

/** Policy assignment input */
export type PolicyAssignmentInput = {
  /** Action */
  action?: InputMaybe<PolicyAction>;
  /** The mrn of the asset */
  assetMrn: Scalars["String"]["input"];
  /** The mrn of the policy */
  policyMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** Policy MRNs */
  policyMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Scoring System */
  scoringSystem?: InputMaybe<ScoringSystem>;
};

/** Policy bundle YAML */
export type PolicyBundleYaml = {
  __typename: "PolicyBundleYaml";
  /** YAML */
  yaml: Scalars["String"]["output"];
};

/**
 * Represents a policy check that is part of a control. The policy is considered to be a single
 * check, passing only if the policy score is a 100.
 */
export type PolicyCheck = {
  __typename: "PolicyCheck";
  /** Amount of assets the check has been executed against */
  assets: Scalars["Int"]["output"];
  /** Completion */
  completion: Scalars["Int"]["output"];
  /** MRN of the policy the check is part of */
  policyMrn: Scalars["String"]["output"];
  /** Name of the policy the check is part of */
  policyName: Scalars["String"]["output"];
  /** State */
  state: CheckState;
};

/** Represents a reference to a policy and a specific group inside the policy. */
export type PolicyCheckRef = {
  __typename: "PolicyCheckRef";
  /** Group */
  group: Scalars["String"]["output"];
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
};

/** Policy dashboard statistics */
export type PolicyDashboardStats = {
  __typename: "PolicyDashboardStats";
  /** Asset statistics */
  assetStats?: Maybe<RiskDistribution>;
  /** Average policy scores */
  averagePolicyScores?: Maybe<AssetGradeDistribution>;
  /** The best scored policies across the space */
  bestPolicies?: Maybe<Array<Maybe<PolicyInfo>>>;
  /** Check statistics by severity */
  checkStatsBySeverity?: Maybe<Array<Maybe<CheckStats>>>;
  /** Distribution */
  distribution?: Maybe<Array<Maybe<PolicyDistributionCount>>>;
  /** The top policy actions across the space */
  topPolicyActions?: Maybe<Array<Maybe<QueryInfo>>>;
  /** The worst scored policies across the space */
  worstPolicies?: Maybe<Array<Maybe<PolicyInfo>>>;
};

/** Policy dashboard stats input */
export type PolicyDashboardStatsInput = {
  /** Space MRN */
  spaceMrn: Scalars["String"]["input"];
};

/** Policy delete input */
export type PolicyDeleteInput = {
  /** The mrn of the policy */
  policyMrn: Scalars["String"]["input"];
};

/** Policy distribution count */
export type PolicyDistributionCount = {
  __typename: "PolicyDistributionCount";
  /** Category */
  category: Scalars["String"]["output"];
  /** Count */
  count: Scalars["Int"]["output"];
};

/** PolicyGroup */
export type PolicyGroup = {
  __typename: "PolicyGroup";
  /** checks */
  checks?: Maybe<Array<QueryRef>>;
  /** policies */
  policies?: Maybe<Array<PolicyRef>>;
  /** queries */
  queries?: Maybe<Array<QueryRef>>;
  /** summary */
  summary: Scalars["String"]["output"];
  /** The title */
  title: Scalars["String"]["output"];
};

/** Policy info */
export type PolicyInfo = {
  __typename: "PolicyInfo";
  /** The mrn is a unique identifier */
  mrn?: Maybe<Scalars["String"]["output"]>;
  /** Name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Score */
  score?: Maybe<Scalars["Int"]["output"]>;
};

/** PolicyInput */
export type PolicyInput = {
  /** The mrn is a unique identifier */
  mrn?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * spaceMrn must be provided if you want to know if the policy
   * is assigned
   */
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** variant */
  variant?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * variantPlatformFilter allows filter the variants by a target
   * platform id.
   */
  variantPlatformFilter?: InputMaybe<Scalars["String"]["input"]>;
};

/** Policy mutation statistics */
export type PolicyMutationStatistics = {
  __typename: "PolicyMutationStatistics";
  /** Checks */
  checks: PolicyQueryStatistics;
  /** Queries */
  queries: PolicyQueryStatistics;
};

/** Represents a reference to either a policy or a query pack. */
export type PolicyOrPackRef = PolicyCheckRef | QueryPackCheckRef;

/** Policy query assessment */
export type PolicyQueryAssessment = {
  __typename: "PolicyQueryAssessment";
  /** Extended results */
  extendedResults?: Maybe<Array<PolicyQueryAssessmentResources>>;
  /** Needs upgrade */
  needsUpgrade: Scalars["Boolean"]["output"];
  /** Rendered */
  rendered: Scalars["String"]["output"];
  /** Results */
  results?: Maybe<Array<PolicyQueryAssessmentItem>>;
  /** State */
  state: PolicyReportEntryState;
};

/** Policy query assessment item */
export type PolicyQueryAssessmentItem = {
  __typename: "PolicyQueryAssessmentItem";
  /** Actual */
  actual: Scalars["String"]["output"];
  /** Error */
  error: Scalars["String"]["output"];
  /** Expected */
  expected: Scalars["String"]["output"];
};

/** Policy query assessment resources */
export type PolicyQueryAssessmentResources = {
  __typename: "PolicyQueryAssessmentResources";
  /** Actual */
  actual?: Maybe<Array<Resource>>;
  /** Number of resources */
  numResources: Scalars["Int"]["output"];
  /** Resource name */
  resourceName: Scalars["String"]["output"];
  /** Resource title */
  resourceTitle: Scalars["String"]["output"];
};

/** Policy query statistics */
export type PolicyQueryStatistics = {
  __typename: "PolicyQueryStatistics";
  /** Active */
  active: Scalars["Int"]["output"];
  /** Ignored */
  ignored: Scalars["Int"]["output"];
  /** Removed */
  removed: Scalars["Int"]["output"];
};

/** Policy reference */
export type PolicyRef = {
  __typename: "PolicyRef";
  /** Action */
  action: PolicyReportScoreEntryAction;
  /** Impact */
  impact?: Maybe<Impact>;
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
};

/** Policy report */
export type PolicyReport = {
  __typename: "PolicyReport";
  /** Client version */
  clientVersion: Scalars["String"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** Needs upgrade */
  needsUpgrade: Scalars["Boolean"]["output"];
  /** Results */
  results: Scalars["Map"]["output"];
  /** Scores */
  scores: Array<PolicyReportScoreEntry>;
};

/** Policy report asset summaries connection */
export type PolicyReportAssetSummariesConnection = {
  __typename: "PolicyReportAssetSummariesConnection";
  /** Edges */
  edges?: Maybe<Array<PolicyReportAssetSummaryEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Policy report asset summary */
export type PolicyReportAssetSummary = {
  __typename: "PolicyReportAssetSummary";
  /** Asset */
  asset: Asset;
  /** Grade */
  grade: Grade;
  /** ID */
  id: Scalars["ID"]["output"];
  /** Mquery results distribution */
  mqueryResultsDistribution: MqueryResultsDistribution;
  /** The mrn of the policy */
  policyMrn: Scalars["ID"]["output"];
  /** The score */
  score: Score;
};

/** Policy report asset summary edge */
export type PolicyReportAssetSummaryEdge = {
  __typename: "PolicyReportAssetSummaryEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<PolicyReportAssetSummary>;
};

/** Policy report entry state */
export enum PolicyReportEntryState {
  Error = "ERROR",
  Fail = "FAIL",
  Ignore = "IGNORE",
  Pass = "PASS",
}

/** Policy report mquery summaries connection */
export type PolicyReportMquerySummariesConnection = {
  __typename: "PolicyReportMquerySummariesConnection";
  /** Edges */
  edges?: Maybe<Array<MquerySummaryEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Policy report mquery summary order */
export type PolicyReportMquerySummaryOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: PolicyReportMquerySummaryOrderField;
};

/** Policy report mquery summary order field */
export enum PolicyReportMquerySummaryOrderField {
  Id = "ID",
  LastUpdated = "LAST_UPDATED",
  Name = "NAME",
  Platform = "PLATFORM",
  Score = "SCORE",
  Title = "TITLE",
}

/** Policy report score entry */
export type PolicyReportScoreEntry = {
  __typename: "PolicyReportScoreEntry";
  /** Action */
  action: PolicyReportScoreEntryAction;
  /** Assessment */
  assessment?: Maybe<PolicyQueryAssessment>;
  /** ID */
  id: Scalars["String"]["output"];
  /** The score */
  score: Score;
};

/** Policy report score entry action */
export enum PolicyReportScoreEntryAction {
  Deactivate = "DEACTIVATE",
  Ignore = "IGNORE",
  Unspecified = "UNSPECIFIED",
}

/** Policy report summaries connection */
export type PolicyReportSummariesConnection = {
  __typename: "PolicyReportSummariesConnection";
  /** Edges */
  edges?: Maybe<Array<PolicyReportSummaryEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Policy report summary */
export type PolicyReportSummary = {
  __typename: "PolicyReportSummary";
  /** Asset grades */
  assetGrades: AssetGradeDistribution;
  /** ID */
  id: Scalars["ID"]["output"];
  /** Policy */
  policy: Policy;
  /** The mrn of the policy */
  policyMrn: Scalars["ID"]["output"];
  /** Policy name */
  policyName: Scalars["ID"]["output"];
};

/** Policy report summary edge */
export type PolicyReportSummaryEdge = {
  __typename: "PolicyReportSummaryEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<PolicyReportSummary>;
};

/** Policy report summary order */
export type PolicyReportSummaryOrder = {
  /** Direction */
  direction: OrderDirection;
  /** Field */
  field: PolicyReportSummaryOrderField;
};

/** Policy report summary order field */
export enum PolicyReportSummaryOrderField {
  Id = "ID",
  Modified = "MODIFIED",
  Published = "PUBLISHED",
  Score = "SCORE",
  Title = "TITLE",
}

/** Policy statistics */
export type PolicyStatistics = {
  __typename: "PolicyStatistics";
  /** Number of checks in the policy */
  checks: Scalars["Int"]["output"];
  /**
   * Data queries
   * @deprecated Use policyMutationStatistics.checks
   */
  dataqueries: PolicyQueryStatistics;
  /** Number of policies */
  policies: Scalars["Int"]["output"];
  /** Number of queries in the policy */
  queries: Scalars["Int"]["output"];
  /**
   * Scoring queries
   * @deprecated Use policyMutationStatistics.checks
   */
  scoringqueries: PolicyQueryStatistics;
};

/** Postgres configuration options */
export type PostgresConfigurationOptions = {
  __typename: "PostgresConfigurationOptions";
  database: Scalars["String"]["output"];
  host: Scalars["String"]["output"];
  port: Scalars["String"]["output"];
  user: Scalars["String"]["output"];
};

/** Postgres integration input */
export type PostgresConfigurationOptionsInput = {
  database: Scalars["String"]["input"];
  host: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  port: Scalars["String"]["input"];
  user: Scalars["String"]["input"];
};

export type Price = {
  __typename: "Price";
  cost: Scalars["Int"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

/** Process case events input */
export type ProcessCaseEventsInput = {
  /** MRN of the scope to process events for */
  scopeMrn: Scalars["String"]["input"];
};

export type Product = {
  __typename: "Product";
  description: Scalars["String"]["output"];
  entitlements?: Maybe<Array<Entitlement>>;
  id: Scalars["String"]["output"];
  isSelfService: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  maxQuantity: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  price: Price;
};

export type ProductInfo = {
  __typename: "ProductInfo";
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  quantity: Scalars["Int"]["output"];
};

/** Property query mutation action */
export enum PropertyQueryMutationAction {
  /**
   * If the check action is used, the mutation will not take effect. We will attempt to validate
   * the target and check the mql
   */
  Check = "CHECK",
  /** If the set action is used, the existing property MQL will be overridden. */
  Set = "SET",
  /** If a property override is set for the property mrn, the UNSET action will remove it. */
  Unset = "UNSET",
}

/** Property query mutation input */
export type PropertyQueryMutationInput = {
  /** The action to apply */
  action: PropertyQueryMutationAction;
  /** A list of properties to apply the action to */
  props?: InputMaybe<Array<PropertyQueryOverride>>;
  /** Target to apply scoring mutation to. Can be an space/asset mrn. Or an asset filter */
  target: PropertyQueryMutationTarget;
};

/** This is returned if there is a problem with the property */
export type PropertyQueryMutationInvalidProperty = Error & {
  __typename: "PropertyQueryMutationInvalidProperty";
  /**
   * Potential codes:
   * - NOT_FOUND: You gave us a property we couldn't find
   * - COMPILE_ERROR: There was an error compiling the query
   * - INVALID_TYPE: The query did not return the expected type
   * - INVALID: Property wasn't properly specified
   * - UNKNOWN:
   */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

/** This is returned if there was a problem with the target */
export type PropertyQueryMutationInvalidTarget = Error & {
  __typename: "PropertyQueryMutationInvalidTarget";
  /**
   * Potential codes:
   * - NOT_FOUND: Couldn't find the mrn
   * - COMPILE_ERROR: Filter query didn't compile
   * - INVALID_TYPE: Filter query didn't return a boolean
   * - INVALID: Target wasn't property specified
   * - UNKNOWN:
   */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

/** Property query mutation payload */
export type PropertyQueryMutationPayload =
  | PropertyQueryMutationInvalidProperty
  | PropertyQueryMutationInvalidTarget
  | PropertyQueryMutationSuccess;

/** Property query mutation success */
export type PropertyQueryMutationSuccess = {
  __typename: "PropertyQueryMutationSuccess";
  /** Success */
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

/**
 * The target can be a mrn (space mrn) or a filter query.
 * It is only valid to provide one of the parameters
 */
export type PropertyQueryMutationTarget = {
  /**
   * For a future iteration.
   *
   * The mql for the filter to apply properties to
   */
  filter?: InputMaybe<Scalars["String"]["input"]>;
  /** MRN of the target. For example, a space MRN, or asset MRN */
  mrn?: InputMaybe<Scalars["String"]["input"]>;
};

/** Property query override */
export type PropertyQueryOverride = {
  /**
   * The property query. Must be provided for the SET and CHECK actions.
   * It must not be provided for the UNSET action
   */
  mql?: InputMaybe<Scalars["String"]["input"]>;
  /** The property to apply the action to */
  propertyMrn: Scalars["String"]["input"];
};

/** Queries connection */
export type QueriesConnection = {
  __typename: "QueriesConnection";
  /** Edges */
  edges?: Maybe<Array<QueryEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

export type Query = {
  __typename: "Query";
  /** Lists the active policies in the specified scope */
  activePolicies?: Maybe<ActivePoliciesConnection>;
  /**
   * advisories
   * @deprecated Use `mvd` instead.
   */
  advisories?: Maybe<AdvisoriesConnection>;
  /** advisory and cve information */
  advisory?: Maybe<Advisory>;
  /** Fetch an agent (managed client) */
  agent?: Maybe<Agent>;
  /** List of agents */
  agents?: Maybe<AgentsConnection>;
  aggregateScores?: Maybe<AggregateScoresResponse>;
  aggregateScoresRefreshInfo: AggregateScoresRefreshInfo;
  /**
   * Analyse asset without mrn
   * used for cnquery/cnspec shell/vuln/run
   * @deprecated This approach is deprecated, do not use it.
   */
  analyseIncognitoAssetVulnerabilities?: Maybe<AssetIncognitoVulnerabilityReportResponse>;
  apiTokens?: Maybe<ApiTokenConnection>;
  /** Get an asset by its MRN */
  asset?: Maybe<Asset>;
  /** Get an asset by its ID */
  assetByPlatformId?: Maybe<Asset>;
  /** Get asset group statistics */
  assetGroupStats: Array<AssetGrouping>;
  /**
   * Get last package update time for an asset
   * @deprecated This approach is deprecated, do not use it.
   */
  assetLastPackageUpdateTime?: Maybe<AssetLastPackageUpdateTimeResponse>;
  /**
   * Get software package details for an asset
   * @deprecated Use packageScores instead
   */
  assetPackageDetails?: Maybe<AssetPackageDetailsResponse>;
  /** Get asset report */
  assetReport?: Maybe<AssetReportResponse>;
  assetResources: AssetResourceResponse;
  /**
   * Perform a search for assets. Can be searched in the context of a space
   * or organization.
   */
  assetSearch?: Maybe<AssetsConnection>;
  assetSearchSuggestions: AssetSearchSuggestionsResponse;
  /** Returns a value indicating whether an asset supports vulnerabilities */
  assetSupportVulnerabilities: Scalars["Boolean"]["output"];
  /** Get the asset score distribution for a space grouped by the asset url */
  assetUrlStats?: Maybe<AssetUrlStatsPayload>;
  /**
   * Analyse asset with mrn, but return compact response
   * @deprecated This approach is deprecated, do not use it.
   */
  assetVulnerabilityCompactReport?: Maybe<AssetVulnerabilityReportResponse>;
  /**
   * Vulnerability Report  for an asset with mrn
   * @deprecated Use vulnerabilityScores instead
   */
  assetVulnerabilityReport?: Maybe<AssetVulnerabilityReportResponse>;
  /** List assets */
  assets?: Maybe<AssetsConnection>;
  auditlog?: Maybe<AuditLogConnection>;
  /**
   * Get best policies
   * @deprecated Use policyDashboardStats
   */
  bestPolicies?: Maybe<Array<Maybe<PolicyInfo>>>;
  /** Get a case */
  case: CaseResponse;
  /** Get case content suggestion */
  caseContent: CaseContent;
  /** List cases */
  cases: CasesConnection;
  /** All Categories */
  categories?: Maybe<Array<Maybe<Category>>>;
  /** Fetch the scores for an mquery for an asset or space */
  checkScores?: Maybe<CheckScoresResponse>;
  cicdProjectJobs?: Maybe<CicdProjectJobsResponse>;
  cicdProjects?: Maybe<CicdProjectsResponse>;
  /** Fetch a client integration */
  clientIntegration: ClientIntegrationPayload;
  /** Get a compliance control */
  complianceControl: ComplianceControl;
  /** Get a compliance framework */
  complianceFramework: ComplianceFramework;
  /** List compliance frameworks */
  complianceFrameworks?: Maybe<Array<ComplianceFramework>>;
  /** Perform a search across Policies and Query Packs */
  content?: Maybe<ContentSearchResultItemConnection>;
  /** Fetch the controls scores for an asset or space */
  controlScores?: Maybe<ControlScoresResponse>;
  /** single sve */
  cve?: Maybe<Cve>;
  /**
   * cves
   * @deprecated Use `mvd` instead.
   */
  cves?: Maybe<CvesConnection>;
  /** Fetch the data queries for an asset or space */
  dataQueries?: Maybe<DataQueriesResponse>;
  /** Downloads a bundle of policies and queries */
  downloadBundle: DownloadBundlePayload;
  /** Downloads a compliance framework as a bundle */
  downloadFramework: FrameworkBundle;
  emailPreferences: Array<Maybe<EmailPreference>>;
  /** Get a list of all the exception groups for the provided input */
  exceptionGroups: Array<Maybe<ExceptionGroup>>;
  /**
   * exploit
   * Deprecated: Remove in v12
   * @deprecated Use cve() instead
   */
  exploit?: Maybe<Exploit>;
  /** Get asset link info */
  getAssetLinkInfo?: Maybe<AssetLinkInfo>;
  /** Fetch the delete command for a client integration */
  getClientIntegrationDeleteCommand: GetClientIntegrationDeleteCommandPayload;
  /** Fetch a token for the client integration */
  getClientIntegrationToken: GetClientIntegrationTokenPayload;
  /** Fetch metadata for a document */
  getDocumentMetadata: DocumentMetadata;
  /** List of integrations */
  integrations: Array<Maybe<Integration>>;
  /** Fetch client integration summaries */
  integrationsSummary: IntegrationsSummaryPayload;
  invitations?: Maybe<InvitationsConnection>;
  legalPolicies: Array<Maybe<LegalPolicy>>;
  /**
   * base object for cve, advisory, ...
   * @deprecated Use `mvd` instead.
   */
  library?: Maybe<LibraryItemConnection>;
  /**
   * library stats
   * @deprecated Use `advisory` or `cve` stats instead.
   */
  libraryStats: Array<LibraryStatisticsEntry>;
  /** List client integrations */
  listClientIntegrations: ListClientIntegrationsPayload;
  /** List results from client integration discovery */
  listDiscoveryResults: ListDiscoveryResultsPayload;
  /** List documents */
  listDocuments: DocumentMetadataConnection;
  metrics?: Maybe<MetricsResponse>;
  /** Fetch a query */
  mquery?: Maybe<Mquery>;
  /** Fetch the data for an mquery for each asset */
  mqueryAssetData?: Maybe<MqueryAssetDataConnection>;
  /**
   * Fetch the scores for an mquery for each asset
   * @deprecated Use checkScores and dataQueries
   */
  mqueryAssetScores?: Maybe<MqueryAssetScoresConnection>;
  /** Fetches the score distribution for the given query and all of its variants in the provided scope */
  mqueryScore?: Maybe<MqueryScore>;
  /** List Mondoo Vulnerability database info (CVEs and advisories) */
  mvd?: Maybe<MvdEntryConnection>;
  /** Returns the Mondoo Vulnerability Database statistics */
  mvdStatistics: MvdStatistics;
  /** Get an organization by MRN */
  organization?: Maybe<Organization>;
  /** Get organization ID suggestions */
  organizationIDSuggestion?: Maybe<IdSuggestionResponse>;
  /** Get organization's overview */
  organizationOverview?: Maybe<OrganizationOverviewResponse>;
  /** packageScores returns a list of scores for a package across affected assets */
  packageScores?: Maybe<PackageScoresResponse>;
  /** Perform discovery action on a client integration */
  performDiscovery: PerformDiscoveryPayload;
  /** Send a ping to a client integration */
  pingIntegration: PingIntegrationPayload;
  /** All Platforms supported by Policies or Query Packs */
  platforms?: Maybe<Array<ContentPlatform>>;
  /** Fetch a Policy. */
  policy?: Maybe<Policy>;
  /** Get policy dashboard statistics */
  policyDashboardStats?: Maybe<PolicyDashboardStats>;
  /** Get policy report */
  policyReport?: Maybe<PolicyReport>;
  registrationTokens?: Maybe<RegistrationTokensConnection>;
  /** Get the remediation script(s) for a CVE, package, ... */
  remediation?: Maybe<RemediationQueryResponse>;
  /** Fetch the rendered data for a query/check for an asset */
  renderedAssetQueryData?: Maybe<RenderedAssetQueryDataResponse>;
  riskFactors: RiskFactorsResponse;
  /** Perform a search across resources, returning a maximum of 200 results. */
  search: SearchResultItemConnection;
  serviceAccount?: Maybe<ServiceAccount>;
  serviceAccounts?: Maybe<ServiceAccountConnection>;
  sharedSpaces?: Maybe<UserSharedSpacesConnection>;
  /** Get a space by MRN */
  space?: Maybe<Space>;
  /** Get space ID suggestions */
  spaceIDSuggestion?: Maybe<IdSuggestionResponse>;
  spacePlan?: Maybe<SpacePlanPayload>;
  /** Get space policy report */
  spacePolicyReport?: Maybe<SpacePolicyReportResponse>;
  /** Get space policy summary */
  spacePolicySummary?: Maybe<SpacePolicySummaryPayload>;
  /**
   * Get space report
   * @deprecated Use activePolicies instead
   */
  spaceReport?: Maybe<SpaceReportResponse>;
  /**
   * vulnerabilities for a space
   * @deprecated Use aggregatedScores instead
   */
  spaceVulnerabilities?: Maybe<Array<Maybe<VulnerabilityItem>>>;
  /**
   * Vulnerability Reports
   * @deprecated Use aggregatedScores instead
   */
  spaceVulnerabilityReport?: Maybe<SpaceVulnerabilityReportResponse>;
  ssoProvider: SsoProviderResponse;
  subscriptionInfo?: Maybe<SubscriptionInfo>;
  subscriptionPlans?: Maybe<Array<SubscriptionPlan>>;
  /** resourceMrn need to be a valid space mrn or organization mrn */
  testIamActions: Array<Scalars["String"]["output"]>;
  /** Get context information for a new ticket. */
  ticketContext: TicketContextPayload;
  /** List ticketing integrations */
  ticketingIntegrations: TicketingIntegrationsPayload;
  /**
   * Get top policy actions
   * @deprecated Use policyDashboardStats
   */
  topPolicyActions?: Maybe<Array<Maybe<QueryInfo>>>;
  /**
   * top vulnerabilities for a space
   * @deprecated This approach is no longer used.
   */
  topVulnerability?: Maybe<VulnerabilityItem>;
  /** Trigger an action on a client integration */
  triggerAction: TriggerActionPayload;
  viewer?: Maybe<User>;
  viewerSettings: Array<UserSetting>;
  /** statistics for the vulnerabilities dashboard */
  vulnDashboardStats?: Maybe<VulnDashboardStats>;
  /** List the vulnerability scores for an asset */
  vulnerabilityScores?: Maybe<VulnerabilityScoresResponse>;
  /**
   * Get worst policies
   * @deprecated Use policyDashboardStats
   */
  worstPolicies?: Maybe<Array<Maybe<PolicyInfo>>>;
};

export type QueryActivePoliciesArgs = {
  input: ActivePoliciesInput;
};

export type QueryAdvisoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdvisoryOrder>;
  platform?: InputMaybe<Array<AdvisoryPlatformFilter>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryAdvisoryArgs = {
  assetMrn?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryAgentArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryAgentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AgentOrder>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  spaceMrn: Scalars["String"]["input"];
  state?: InputMaybe<Array<AgentState>>;
  version?: InputMaybe<Array<AgentVersionFilter>>;
};

export type QueryAggregateScoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<AggregateScoreFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AggregateScoreOrder>;
};

export type QueryAggregateScoresRefreshInfoArgs = {
  groupMrn: Scalars["String"]["input"];
};

export type QueryAnalyseIncognitoAssetVulnerabilitiesArgs = {
  input: AnalyseIncognitoAssetInput;
};

export type QueryApiTokensArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ApiTokenOrder>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryAssetArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryAssetByPlatformIdArgs = {
  platformId: Scalars["String"]["input"];
  spaceMrn: Scalars["String"]["input"];
};

export type QueryAssetGroupStatsArgs = {
  groupBy: Scalars["String"]["input"];
  orderBy?: InputMaybe<AssetGroupOrder>;
  queryTerms?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  spaceMrn: Scalars["String"]["input"];
};

export type QueryAssetLastPackageUpdateTimeArgs = {
  input: AssetLastPackageUpdateTimeInput;
};

export type QueryAssetPackageDetailsArgs = {
  input: AssetPackageDetailsInput;
};

export type QueryAssetReportArgs = {
  input: AssetReportInput;
};

export type QueryAssetResourcesArgs = {
  input: AssetResourceInput;
};

export type QueryAssetSearchArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: Scalars["Int"]["input"];
  input: AssetSearchInput;
  orderBy?: InputMaybe<AssetOrder>;
};

export type QueryAssetSearchSuggestionsArgs = {
  input: AssetSearchSuggestionsInput;
};

export type QueryAssetSupportVulnerabilitiesArgs = {
  assetMrn: Scalars["String"]["input"];
};

export type QueryAssetUrlStatsArgs = {
  input: AssetUrlStatsInput;
};

export type QueryAssetVulnerabilityCompactReportArgs = {
  input: AssetVulnerabilityReportInput;
};

export type QueryAssetVulnerabilityReportArgs = {
  input: AssetVulnerabilityReportInput;
};

export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assetTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  assetUrlFilter?: InputMaybe<Array<AssetUrlSegmentInput>>;
  eol?: InputMaybe<AssetEolFilter>;
  exploitable?: InputMaybe<AssetExploitableFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  groups?: InputMaybe<Array<GroupFilter>>;
  labels?: InputMaybe<Array<KeyValueInput>>;
  orderBy?: InputMaybe<AssetOrder>;
  platformKind?: InputMaybe<Array<PlatformKind>>;
  platformName?: InputMaybe<Array<Scalars["String"]["input"]>>;
  platformRuntime?: InputMaybe<Array<Scalars["String"]["input"]>>;
  platformTitle?: InputMaybe<Array<Scalars["String"]["input"]>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  reboot?: InputMaybe<AssetOsRebootFilter>;
  relatedToAssetMrn?: InputMaybe<Scalars["String"]["input"]>;
  scoreRange?: InputMaybe<Array<ScoreRange>>;
  scoreType?: InputMaybe<ScoreType>;
  spaceMrn: Scalars["String"]["input"];
  state?: InputMaybe<Array<AssetState>>;
  unscored?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated?: InputMaybe<AssetUpdateFilter>;
};

export type QueryAuditlogArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AuditLogOrder>;
  resourceMrn: Scalars["String"]["input"];
  timestampFilter?: InputMaybe<TimestampFilter>;
};

export type QueryBestPoliciesArgs = {
  input: RatedPoliciesInput;
};

export type QueryCaseArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryCaseContentArgs = {
  input: CaseContentInput;
};

export type QueryCasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  input: CasesInput;
};

export type QueryCategoriesArgs = {
  input: CategoriesListInput;
};

export type QueryCheckScoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<CheckScoreFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CheckScoreOrder>;
};

export type QueryCicdProjectJobsArgs = {
  input: CicdProjectJobsInput;
};

export type QueryCicdProjectsArgs = {
  input: CicdProjectsInput;
};

export type QueryClientIntegrationArgs = {
  input: ClientIntegrationInput;
};

export type QueryComplianceControlArgs = {
  input: ComplianceControlInput;
};

export type QueryComplianceFrameworkArgs = {
  input: ComplianceFrameworkInput;
};

export type QueryComplianceFrameworksArgs = {
  input: ComplianceFrameworksInput;
};

export type QueryContentArgs = {
  input: ContentSearchInput;
};

export type QueryControlScoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<ControlScoreFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ControlScoreOrder>;
};

export type QueryCveArgs = {
  id: Scalars["ID"]["input"];
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCvesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CveOrder>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<CveState>>>;
};

export type QueryDataQueriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<DataQueryFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<DataQueryOrder>;
};

export type QueryDownloadBundleArgs = {
  input: DownloadBundleInput;
};

export type QueryDownloadFrameworkArgs = {
  input: DownloadFrameworkInput;
};

export type QueryExceptionGroupsArgs = {
  input: ExceptionGroupsInput;
};

export type QueryExploitArgs = {
  assetMrn?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAssetLinkInfoArgs = {
  input: AssetLinkInfoInput;
};

export type QueryGetClientIntegrationDeleteCommandArgs = {
  input: GetClientIntegrationDeleteCommandInput;
};

export type QueryGetClientIntegrationTokenArgs = {
  input: GetClientIntegrationTokenInput;
};

export type QueryGetDocumentMetadataArgs = {
  documentMRN: Scalars["String"]["input"];
};

export type QueryIntegrationsArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryIntegrationsSummaryArgs = {
  input: IntegrationsSummaryInput;
};

export type QueryInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  inviteeMrn?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<InvitationOrder>;
  resourceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLegalPoliciesArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryLibraryArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assetMrn?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<LibraryOrder>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  severity?: InputMaybe<Array<Severity>>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
  type?: LibraryItemType;
};

export type QueryLibraryStatsArgs = {
  resolution?: LibraryQueryResolution;
  type?: LibraryItemType;
};

export type QueryListClientIntegrationsArgs = {
  input: ListClientIntegrationsInput;
};

export type QueryListDiscoveryResultsArgs = {
  input: ListDiscoveryResultsInput;
};

export type QueryListDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: Scalars["Int"]["input"];
  scopeMRN: Scalars["String"]["input"];
};

export type QueryMetricsArgs = {
  entityMrn: Scalars["String"]["input"];
  metricMrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryMqueryArgs = {
  input: MqueryInput;
};

export type QueryMqueryAssetDataArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: Scalars["Int"]["input"];
  input: MqueryAssetDataInput;
  orderBy?: InputMaybe<AssetOrder>;
};

export type QueryMqueryAssetScoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: Scalars["Int"]["input"];
  input: MqueryAssetScoresInput;
  orderBy?: InputMaybe<AssetOrder>;
};

export type QueryMqueryScoreArgs = {
  input: MqueryScoreInput;
};

export type QueryMvdArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MvdOrder>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  severity?: InputMaybe<Array<Severity>>;
  type?: MvdEntryType;
};

export type QueryOrganizationArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryOrganizationIdSuggestionArgs = {
  input: IdSuggestionInput;
};

export type QueryOrganizationOverviewArgs = {
  input: OrganizationOverviewInput;
};

export type QueryPackageScoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  input?: InputMaybe<PackageScoresInput>;
  orderBy?: InputMaybe<PackageScoresOrder>;
};

export type QueryPerformDiscoveryArgs = {
  input: PerformDiscoveryInput;
};

export type QueryPingIntegrationArgs = {
  input: PingIntegrationInput;
};

export type QueryPlatformsArgs = {
  input: PlatformsListInput;
};

export type QueryPolicyArgs = {
  input: PolicyInput;
};

export type QueryPolicyDashboardStatsArgs = {
  input: PolicyDashboardStatsInput;
};

export type QueryPolicyReportArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryRegistrationTokensArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<RegistrationTokenOrder>;
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRemediationArgs = {
  input?: InputMaybe<RemediationInput>;
};

export type QueryRenderedAssetQueryDataArgs = {
  input: RenderedAssetQueryDataInput;
};

export type QueryRiskFactorsArgs = {
  mrns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  spaceMrn: Scalars["String"]["input"];
};

export type QuerySearchArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<SearchFilters>;
  first?: Scalars["Int"]["input"];
  query: Scalars["String"]["input"];
  scope?: InputMaybe<Scalars["String"]["input"]>;
  type: SearchType;
};

export type QueryServiceAccountArgs = {
  mrn: Scalars["String"]["input"];
};

export type QueryServiceAccountsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ServiceAccountOrder>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySharedSpacesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerySpaceArgs = {
  mrn: Scalars["String"]["input"];
};

export type QuerySpaceIdSuggestionArgs = {
  input: IdSuggestionInput;
};

export type QuerySpacePlanArgs = {
  input: SpacePlanInput;
};

export type QuerySpacePolicyReportArgs = {
  input: SpacePolicyReportInput;
};

export type QuerySpacePolicySummaryArgs = {
  input: SpacePolicySummaryInput;
};

export type QuerySpaceReportArgs = {
  input: SpaceReportInput;
};

export type QuerySpaceVulnerabilitiesArgs = {
  input: SpaceVulnerabilitiesInput;
};

export type QuerySpaceVulnerabilityReportArgs = {
  input: SpaceVulnerabilityReportInput;
};

export type QuerySsoProviderArgs = {
  mrn: Scalars["String"]["input"];
};

export type QuerySubscriptionInfoArgs = {
  orgMrn: Scalars["ID"]["input"];
};

export type QuerySubscriptionPlansArgs = {
  orgMrn: Scalars["ID"]["input"];
};

export type QueryTestIamActionsArgs = {
  actions: Array<Scalars["String"]["input"]>;
  resourceMrn: Scalars["String"]["input"];
};

export type QueryTicketContextArgs = {
  input: TicketContextInput;
};

export type QueryTicketingIntegrationsArgs = {
  input: TicketingIntegrationsInput;
};

export type QueryTopPolicyActionsArgs = {
  input: TopPolicyActionsInput;
};

export type QueryTopVulnerabilityArgs = {
  input: TopVulnerabilityInput;
};

export type QueryTriggerActionArgs = {
  input: TriggerActionInput;
};

export type QueryVulnDashboardStatsArgs = {
  input: VulnDashboardStatsInput;
};

export type QueryVulnerabilityScoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<VulnerabilityScoreFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<VulnerabilityScoreOrder>;
};

export type QueryWorstPoliciesArgs = {
  input: RatedPoliciesInput;
};

/** Query asset data */
export type QueryAssetData = {
  __typename: "QueryAssetData";
  /** The mrn of the asset */
  assetMrn?: Maybe<Scalars["String"]["output"]>;
  /** The name of the asset */
  assetName?: Maybe<Scalars["String"]["output"]>;
  /** Asset platform */
  assetPlatform?: Maybe<Platform>;
  /** Data */
  data?: Maybe<MqueryAssetData>;
};

/** Query asset score */
export type QueryAssetScore = {
  __typename: "QueryAssetScore";
  /** The mrn of the asset */
  assetMrn?: Maybe<Scalars["String"]["output"]>;
  /** The name of the asset */
  assetName?: Maybe<Scalars["String"]["output"]>;
  /** Asset platform */
  assetPlatform?: Maybe<Platform>;
  /** The score */
  score?: Maybe<Score>;
};

/** Represents a simple query check that is part of a control. It represents a MQL query. The query can be either a scoring or a data one */
export type QueryCheck = {
  __typename: "QueryCheck";
  /** The amount of assets this query has been executed against */
  assets: Scalars["Int"]["output"];
  /** The completion of the query. May be 0 if referencing a data query. */
  completion: Scalars["Int"]["output"];
  /** Gets the exception for this query, if any. Rejected exceptions aren't returned. */
  exception?: Maybe<QueryCheckException>;
  /** The MQL query for the check */
  mquery: Mquery;
  /** Represents the query stats for that check within the requested scope. May be nil if referencing a data query. */
  queryStats?: Maybe<ScoreStats>;
  /** The list of policies or query packs that reference the query */
  refs: Array<PolicyOrPackRef>;
  /** State */
  state: CheckState;
};

/** Query check exception */
export type QueryCheckException = {
  __typename: "QueryCheckException";
  /** Action */
  action: ExceptionMutationAction;
  /** ID */
  id: Scalars["String"]["output"];
  /** Justification */
  justification: Scalars["String"]["output"];
  /** Review status */
  reviewStatus: ReviewStatus;
};

/** Query edge */
export type QueryEdge = {
  __typename: "QueryEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<QueryCheck>;
};

/** QueryImpact maps an impact number to a human readable string */
export enum QueryImpact {
  /** Impact is critical when 90 - 100 */
  Critical = "CRITICAL",
  /** Impact is high when between 70 - 89 */
  High = "HIGH",
  /** Impact is none when 0 */
  Info = "INFO",
  /** Impact is low when between 1 - 39 */
  Low = "LOW",
  /** Impact is medium when between 40 - 69 */
  Medium = "MEDIUM",
}

/** Query info */
export type QueryInfo = {
  __typename: "QueryInfo";
  /** Code ID */
  codeId?: Maybe<Scalars["String"]["output"]>;
  /** Name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Score */
  score?: Maybe<AssetGradeDistribution>;
  /** Severity */
  severity: Scalars["Int"]["output"];
};

/** Query pack add input */
export type QueryPackAddInput = {
  /** RFC2397, base64 encoded policy bundle */
  dataurl: Scalars["String"]["input"];
  /** Force */
  force?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The mrn of the space */
  spaceMrn: Scalars["String"]["input"];
};

/** Represents a reference to a query pack and a specific group inside the pack. */
export type QueryPackCheckRef = {
  __typename: "QueryPackCheckRef";
  /** Group */
  group: Scalars["String"]["output"];
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
};

/** Query reference */
export type QueryRef = {
  __typename: "QueryRef";
  /** Action */
  action: PolicyReportScoreEntryAction;
  /** Impact */
  impact?: Maybe<Impact>;
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
};

/** A value with a QuerySource is derived from a query. */
export type QuerySource = {
  __typename: "QuerySource";
  mrn: Scalars["String"]["output"];
};

/** Rated policies input */
export type RatedPoliciesInput = {
  /** Count */
  count?: InputMaybe<Scalars["Int"]["input"]>;
  /** The mrn of the space */
  spaceMrn: Scalars["String"]["input"];
};

/** Recommended action item advisory */
export type RecommendedActionItemAdvisory = {
  __typename: "RecommendedActionItemAdvisory";
  /** ID */
  id: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
  /** Severity */
  severity: Scalars["Int"]["output"];
};

/** Recommended action item control */
export type RecommendedActionItemControl = {
  __typename: "RecommendedActionItemControl";
  /**
   * Code ID
   * @deprecated Use mrn
   */
  codeId: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** The score */
  score: Score;
  /** Severity */
  severity: Scalars["Int"]["output"];
};

/** Reference to an URL */
export type Reference = {
  __typename: "Reference";
  /** icon id */
  iconIDEnum?: Maybe<Icon_Ids>;
  /** Reference name */
  title?: Maybe<Scalars["String"]["output"]>;
  /** Reference URL */
  url: Scalars["String"]["output"];
};

export type RegistrationToken = {
  __typename: "RegistrationToken";
  createdAt: Scalars["String"]["output"];
  createdBy: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  expiresAt: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  labels: Array<KeyValue>;
  mrn: Scalars["String"]["output"];
  revoked: Scalars["Boolean"]["output"];
  token: Scalars["String"]["output"];
};

export type RegistrationTokenEdge = {
  __typename: "RegistrationTokenEdge";
  cursor: Scalars["ID"]["output"];
  node: RegistrationToken;
};

export type RegistrationTokenInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  expiresIn?: InputMaybe<Scalars["Int"]["input"]>;
  labels?: InputMaybe<Array<KeyValueInput>>;
  noExpiration?: InputMaybe<Scalars["Boolean"]["input"]>;
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

export type RegistrationTokenOrder = {
  direction: OrderDirection;
  field: RegistrationTokenOrderField;
};

export enum RegistrationTokenOrderField {
  Created = "CREATED",
  Description = "DESCRIPTION",
  Mrn = "MRN",
}

export type RegistrationTokens = {
  __typename: "RegistrationTokens";
  spaceMrn: Scalars["ID"]["output"];
  tokens: RegistrationTokensConnection;
};

export type RegistrationTokensTokensArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<RegistrationTokenOrder>;
};

export type RegistrationTokensConnection = {
  __typename: "RegistrationTokensConnection";
  edges: Array<RegistrationTokenEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Related asset */
export type RelatedAsset = {
  __typename: "RelatedAsset";
  /** ID */
  id: Scalars["ID"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** Name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Score */
  score: Score;
  /** Type */
  type: Scalars["String"]["output"];
};

/** Related asset count */
export type RelatedAssetCount = {
  __typename: "RelatedAssetCount";
  /** Count */
  count: Scalars["Int"]["output"];
  /** Is parent */
  isParent: Scalars["Boolean"]["output"];
  /** Type */
  type: Scalars["String"]["output"];
};

/** Related asset edge */
export type RelatedAssetEdge = {
  __typename: "RelatedAssetEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<RelatedAsset>;
};

/** Related assets */
export type RelatedAssets = {
  __typename: "RelatedAssets";
  /** Count */
  count?: Maybe<Array<RelatedAssetCount>>;
  /** List related assets */
  list: RelatedAssetsConnection;
};

/** Related assets */
export type RelatedAssetsListArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  assetType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Related assets connection */
export type RelatedAssetsConnection = {
  __typename: "RelatedAssetsConnection";
  /** Edges */
  edges?: Maybe<Array<RelatedAssetEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total count */
  totalCount: Scalars["Int"]["output"];
};

/** Remediated by severity */
export type RemediatedBySeverity = {
  __typename: "RemediatedBySeverity";
  /** Fixed count */
  fixed: Scalars["Int"]["output"];
  /** mttrInMinutes */
  mttrInMinutes: Scalars["Int"]["output"];
  /** Severity */
  severity: Scalars["String"]["output"];
  /** Total count */
  total: Scalars["Int"]["output"];
};

/** Filter the remediation scripts */
export type RemediationFilter = {
  /** filter by package namespace, e.g., ubuntu:18.04 */
  namespace?: InputMaybe<Scalars["String"]["input"]>;
  /** filter by remediation script type */
  scriptTypes?: InputMaybe<Array<InputMaybe<RemediationScriptType>>>;
};

/** Input for the remediation script */
export type RemediationInput = {
  advisoryId?: InputMaybe<Scalars["ID"]["input"]>;
  filter?: InputMaybe<RemediationFilter>;
  packages?: InputMaybe<Array<RemediationPackageFields>>;
  scopeMrn: Scalars["String"]["input"];
};

/** We need to know the packages to generate the remediation script */
export type RemediationPackageFields = {
  /** Package version which fixes the vulnerability */
  fixedVersion?: InputMaybe<Scalars["String"]["input"]>;
  /** Package name */
  name: Scalars["String"]["input"];
};

/** Remediation responses */
export type RemediationQueryResponse =
  | VulnerabilityRemediationNotFound
  | VulnerabilityRemediationNotSupported
  | VulnerabilityRemediationResponse;

/** Remediation result */
export type RemediationResult = AuthenticationRequired | Remediations;

/** Remediation script */
export type RemediationScript = {
  __typename: "RemediationScript";
  /** Package manager: DPKG, YUM, DNF, ZYPPER, POWERSHELL */
  packageManager: PackageManager;
  /** Script platform name */
  platformName: Scalars["String"]["output"];
  /** Script platform version */
  platformVersion: Scalars["String"]["output"];
  /** Script */
  script: Scalars["String"]["output"];
  /** Type of the remediation script: SHELL, ANSIBLE, POWERSHELL */
  type: RemediationScriptType;
};

/** Remediation script type */
export enum RemediationScriptType {
  /** Ansible playbook */
  Ansible = "ANSIBLE",
  /** Powershell script */
  Powershell = "POWERSHELL",
  /** Shell script */
  Shell = "SHELL",
}

/** Remediations */
export type Remediations = {
  __typename: "Remediations";
  /** Entries */
  entries: Array<MqueryRemediation>;
};

export type RemoveOrganizationMembershipInput = {
  orgMrn: Scalars["String"]["input"];
  userMrn: Scalars["String"]["input"];
};

export type RemoveSpaceMembershipInput = {
  spaceMrn: Scalars["String"]["input"];
  userMrn: Scalars["String"]["input"];
};

/** Rendered asset query data */
export type RenderedAssetQueryData = {
  __typename: "RenderedAssetQueryData";
  /** The rendered assessment */
  assessment?: Maybe<Scalars["String"]["output"]>;
  /** The rendered data as JSON */
  data?: Maybe<Scalars["Any"]["output"]>;
};

/** Rendered asset query data input */
export type RenderedAssetQueryDataInput = {
  /** assetMrn is the MRN of the asset to query */
  assetMrn: Scalars["String"]["input"];
  /** queryMrn is the MRN of the query to get the data for */
  queryMrn: Scalars["String"]["input"];
};

export type RenderedAssetQueryDataResponse =
  | NotFoundError
  | RenderedAssetQueryData
  | RequestError;

/** Vulnerability Report */
export type Report = {
  __typename: "Report";
  /** Advisories affecting the space/asset */
  advisories?: Maybe<AdvisoriesConnection>;
  /** Cves affecting the space/asset */
  cves?: Maybe<CvesConnection>;
  /** Packages affected by advisories and cves */
  packages?: Maybe<PackagesConnection>;
  /** Platform */
  platform: Platform;
  /** published date */
  published?: Maybe<Scalars["String"]["output"]>;
  /** Statistics */
  stats?: Maybe<ReportStats>;
  /** Report status */
  status?: Maybe<ReportStatus>;
};

/** Vulnerability Report */
export type ReportAdvisoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdvisoryOrder>;
};

/** Vulnerability Report */
export type ReportCvesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CveOrder>;
};

/** Vulnerability Report */
export type ReportPackagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  isAffected?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderBy?: InputMaybe<PackagesOrder>;
};

/** Report found error */
export type ReportFound = Error & {
  __typename: "ReportFound";
  /** Code */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

/** Vulnerability Report Stats */
export type ReportStats = {
  __typename: "ReportStats";
  /** Advisories */
  advisories: ReportStatsAdvisories;
  /** Assets */
  assets: ReportStatsAsset;
  /** Cves */
  cves: ReportStatsCves;
  /** Exploits */
  exploits: ReportStatsExploits;
  /** Packages */
  packages: ReportStatsPackages;
  /** Scores */
  score: CvssScore;
};

/** Report statistics advisories */
export type ReportStatsAdvisories = {
  __typename: "ReportStatsAdvisories";
  /** Critical */
  critical: Scalars["Int"]["output"];
  /** High */
  high: Scalars["Int"]["output"];
  /** Low */
  low: Scalars["Int"]["output"];
  /** Medium */
  medium: Scalars["Int"]["output"];
  /** None */
  none: Scalars["Int"]["output"];
  /** Total */
  total: Scalars["Int"]["output"];
  /** Unknown */
  unknown: Scalars["Int"]["output"];
};

/** Vulnerability Report Stats for an asset */
export type ReportStatsAsset = {
  __typename: "ReportStatsAsset";
  /** Count of assets with score A */
  A: Scalars["Int"]["output"];
  /** Count of assets with score B */
  B: Scalars["Int"]["output"];
  /** Count of assets with score C */
  C: Scalars["Int"]["output"];
  /** Count of assets with score D */
  D: Scalars["Int"]["output"];
  /** Count of errored assets */
  Error: Scalars["Int"]["output"];
  /** Count of assets with score F */
  F: Scalars["Int"]["output"];
  /** Count of assets with score unknown */
  U: Scalars["Int"]["output"];
  /** Total count of assets */
  total: Scalars["Int"]["output"];
};

/** Report statistics CVEs */
export type ReportStatsCves = {
  __typename: "ReportStatsCves";
  /** The number of critical CVEs */
  critical: Scalars["Int"]["output"];
  /** The number of high CVEs */
  high: Scalars["Int"]["output"];
  /** The number of low CVEs */
  low: Scalars["Int"]["output"];
  /** The number of medium CVEs */
  medium: Scalars["Int"]["output"];
  /** The number of none CVEs */
  none: Scalars["Int"]["output"];
  /** The total number of CVEs */
  total: Scalars["Int"]["output"];
  /** The number of unknown CVEs */
  unknown: Scalars["Int"]["output"];
};

/** Report statistics exploits */
export type ReportStatsExploits = {
  __typename: "ReportStatsExploits";
  /** Total */
  total: Scalars["Int"]["output"];
};

/** Report statistics packages */
export type ReportStatsPackages = {
  __typename: "ReportStatsPackages";
  /** Affected */
  affected: Scalars["Int"]["output"];
  /** Critical */
  critical: Scalars["Int"]["output"];
  /** High */
  high: Scalars["Int"]["output"];
  /** Low */
  low: Scalars["Int"]["output"];
  /** Medium */
  medium: Scalars["Int"]["output"];
  /** None */
  none: Scalars["Int"]["output"];
  /** Total */
  total: Scalars["Int"]["output"];
  /** Unknown */
  unknown: Scalars["Int"]["output"];
};

/** Report status */
export type ReportStatus = NotFoundError | NotSupported | ReportFound;

/** Report viewed info. Only 1 page can be specified at a time */
export type ReportViewedInfo = {
  /** Asset overview page info */
  assetOverview?: InputMaybe<AssetOverviewPageInfo>;
  /** CI/CD job page info */
  cicdJob?: InputMaybe<CiCdJobPageInfo>;
  /** CI/CD project page info */
  cicdProject?: InputMaybe<CiCdProjectPageInfo>;
  /** Org view page info */
  orgView?: InputMaybe<OrgViewPageInfo>;
  /** Security dashboard page info */
  securityDashboard?: InputMaybe<SecurityDashboardPageInfo>;
  /** Vulnerability dashboard page info */
  vulnerabilityDashboard?: InputMaybe<VulnerabilityDashboardPageInfo>;
};

/** Report viewed input */
export type ReportViewedInput = {
  info: ReportViewedInfo;
  page: ReportViewedPage;
};

/** Report viewed page defines the pages that can be reported as viewed */
export enum ReportViewedPage {
  AssetOverview = "ASSET_OVERVIEW",
  CicdJob = "CICD_JOB",
  CicdProject = "CICD_PROJECT",
  OrgView = "ORG_VIEW",
  SecurityDashboard = "SECURITY_DASHBOARD",
  VulnerabilityDashboard = "VULNERABILITY_DASHBOARD",
}

/** Request error indicates that the request failed server-side */
export type RequestError = Error & {
  __typename: "RequestError";
  /** Code */
  code: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
};

export type Resource = {
  __typename: "Resource";
  assessmentField?: Maybe<Field>;
  fields?: Maybe<Array<Maybe<Field>>>;
  name: Scalars["String"]["output"];
  nestedContent: Scalars["Boolean"]["output"];
  numElements: Scalars["Int"]["output"];
  path: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

/** The review status for an exception group */
export enum ReviewStatus {
  Approved = "APPROVED",
  NotReviewed = "NOT_REVIEWED",
  Rejected = "REJECTED",
}

export type RevokeRegistrationTokenFailure = Error & {
  __typename: "RevokeRegistrationTokenFailure";
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type RevokeRegistrationTokenInput = {
  mrn: Scalars["String"]["input"];
};

export type RevokeRegistrationTokenResponse =
  | RevokeRegistrationTokenFailure
  | RevokeRegistrationTokenSuccess;

export type RevokeRegistrationTokenSuccess = {
  __typename: "RevokeRegistrationTokenSuccess";
  ok: Scalars["Boolean"]["output"];
};

/** Risk distribution */
export type RiskDistribution = {
  __typename: "RiskDistribution";
  /** A count */
  A: Scalars["Int"]["output"];
  /** B count */
  B: Scalars["Int"]["output"];
  /** C count */
  C: Scalars["Int"]["output"];
  /** D count */
  D: Scalars["Int"]["output"];
  /** Error count */
  Error: Scalars["Int"]["output"];
  /** F count */
  F: Scalars["Int"]["output"];
  /** U count */
  U: Scalars["Int"]["output"];
  /** Total count */
  total: Scalars["Int"]["output"];
};

/** Risk factor */
export type RiskFactor = {
  __typename: "RiskFactor";
  /** Risk factor enabled or disabled */
  action?: Maybe<RiskFactorAction>;
  /** Default risk magnitude */
  defaultMagnitude: RiskMagnitude;
  /** Docs */
  docs?: Maybe<RiskFactorDocs>;
  /** Risk factor magnitude */
  magnitude: RiskMagnitude;
  /** Mrn */
  mrn: Scalars["String"]["output"];
  /** Title */
  title: Scalars["String"]["output"];
};

export enum RiskFactorAction {
  /** Disable risk factor */
  Disable = "DISABLE",
  /** Enable risk factor */
  Enable = "ENABLE",
}

/** Risk factor docs */
export type RiskFactorDocs = {
  __typename: "RiskFactorDocs";
  /** Docs for when the risk factor is active (detected) */
  active?: Maybe<Scalars["String"]["output"]>;
  /** Docs for when the risk factor is inactive (not detected) */
  inactive?: Maybe<Scalars["String"]["output"]>;
};

/** Risk factor filter by mrn */
export type RiskFactorFilter = {
  /** Risk factor indicators to filter on. They are ORed together */
  indicators?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** MRN filter query */
  mrns?: InputMaybe<RiskFactorFilterQuery>;
};

/** Risk factor filter query */
export type RiskFactorFilterQuery = {
  /** Any terms here will be ANDed */
  and?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Any terms here will be ORed */
  or?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type RiskFactorMagnitudeModification = {
  /** Risk factor is toxic */
  isToxic: Scalars["Boolean"]["input"];
  /** Risk factor magnitude: -100 to 100 */
  value: Scalars["Int"]["input"];
};

export type RiskFactorStats = {
  __typename: "RiskFactorStats";
  /** Affected risk factors */
  affected: Scalars["Int"]["output"];
  /**
   * Risk factor indicator eg.:
   * - db-access
   * - admin
   * - asset-in-use
   * - rce
   * - tagged
   * - protected-data
   * - credentials
   * - containers
   * - exploitable
   * - eol
   */
  indicator: Scalars["String"]["output"];
  /** Risk Factor Mrn */
  mrn: Scalars["String"]["output"];
  /** Risk factor title */
  title: Scalars["String"]["output"];
  /** Total risk factors */
  total: Scalars["Int"]["output"];
};

export type RiskFactorsResponse = {
  __typename: "RiskFactorsResponse";
  /** Risk factor edges */
  edges?: Maybe<Array<RiskFactor>>;
};

export type RiskMagnitude = {
  __typename: "RiskMagnitude";
  /** Risk factor is toxic */
  isToxic: Scalars["Boolean"]["output"];
  /** Risk factor magnitude */
  value: Scalars["Int"]["output"];
};

/** Role represents the role of a user within a system or organization. */
export type Role = {
  __typename: "Role";
  /** The Mondoo Resource Name (MRN) uniquely identifies each role. */
  mrn: Scalars["String"]["output"];
  /** The title provides a human-readable name or designation for the role. */
  title: Scalars["String"]["output"];
};

export type RoleInput = {
  mrn: Scalars["String"]["input"];
};

/**
 * "
 * Instructions to run the policy or query pack from CLI
 */
export type RunCli = {
  __typename: "RunCli";
  /** The command to run */
  command: Scalars["String"]["output"];
  /** Url reference to the documentation for this command */
  installDocsURL: Scalars["String"]["output"];
};

/** S3 configuration options */
export type S3ConfigurationOptions = {
  __typename: "S3ConfigurationOptions";
  accessKey: Scalars["String"]["output"];
  bucket: Scalars["String"]["output"];
  endpoint: Scalars["String"]["output"];
  output: BucketOutputType;
  useLegacyList: Scalars["Boolean"]["output"];
  usePathStyle: Scalars["Boolean"]["output"];
};

/** S3 integration input */
export type S3ConfigurationOptionsInput = {
  accessKey: Scalars["String"]["input"];
  bucket: Scalars["String"]["input"];
  endpoint: Scalars["String"]["input"];
  output: BucketOutputType;
  secretAccessKey: Scalars["String"]["input"];
  useLegacyList: Scalars["Boolean"]["input"];
  usePathStyle: Scalars["Boolean"]["input"];
};

export type SshKey = {
  __typename: "SSHKey";
  content: Scalars["String"]["output"];
  fingerprint: Scalars["String"]["output"];
};

export type SsoProvider = {
  __typename: "SSOProvider";
  enabled: Scalars["Boolean"]["output"];
  idpEntityID: Scalars["String"]["output"];
  rpEntityID: Scalars["String"]["output"];
  ssoUrl: Scalars["String"]["output"];
  x509Fingerprints: Array<Scalars["String"]["output"]>;
};

export type SsoProviderInput = {
  enabled: Scalars["Boolean"]["input"];
  idpEntityID: Scalars["String"]["input"];
  rpEntityID: Scalars["String"]["input"];
  ssoUrl: Scalars["String"]["input"];
  x509Certificates?: InputMaybe<Array<Scalars["String"]["input"]>>;
  x509Fingerprints: Array<Scalars["String"]["input"]>;
};

export type SsoProviderResponse = {
  __typename: "SSOProviderResponse";
  organizationMrn?: Maybe<Scalars["String"]["output"]>;
  provider: SsoProvider;
};

/** ScanConfiguration for the AWS integration */
export type ScanConfiguration = {
  __typename: "ScanConfiguration";
  accountScan: Scalars["Boolean"]["output"];
  cronScaninHours: Scalars["Int"]["output"];
  ec2Scan: Scalars["Boolean"]["output"];
  ec2ScanOptions: Ec2ScanOptions;
  ecrScan: Scalars["Boolean"]["output"];
  ecsScan: Scalars["Boolean"]["output"];
  eventScanTriggers?: Maybe<Array<Maybe<AwsEventPattern>>>;
  vpcConfiguration?: Maybe<VpcConfiguration>;
};

/** ScanConfigurationInput is the input options for the AWS integration scan configuration */
export type ScanConfigurationInput = {
  accountScan?: InputMaybe<Scalars["Boolean"]["input"]>;
  cronScaninHours?: InputMaybe<Scalars["Int"]["input"]>;
  ec2Scan?: InputMaybe<Scalars["Boolean"]["input"]>;
  ec2ScanOptions?: InputMaybe<Ec2ScanOptionsInput>;
  ecrScan?: InputMaybe<Scalars["Boolean"]["input"]>;
  ecsScan?: InputMaybe<Scalars["Boolean"]["input"]>;
  eventScanTriggers?: InputMaybe<Array<InputMaybe<AwsEventPatternInput>>>;
  vpcConfiguration?: InputMaybe<VpcConfigurationInput>;
};

/** A mapping of SCIM group to organization or its spaces and an IAM role */
export type ScimGroupMapping = {
  /** IAM role to be assigned to all members of the SCIM group */
  iamRole: Scalars["String"]["input"];
  /** MRN of the organization to be mapped */
  orgMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** MRN of the space to be mapped */
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
};

/** Score */
export type Score = {
  __typename: "Score";
  /** Completion */
  completion: Scalars["Int"]["output"];
  /** Grade */
  grade: Scalars["String"]["output"];
  /** ID */
  id: Scalars["String"]["output"];
  /** Message */
  message: Scalars["String"]["output"];
  /** Type */
  type: Scalars["Int"]["output"];
  /** Value */
  value: Scalars["Int"]["output"];
  /** Weight */
  weight: Scalars["Int"]["output"];
};

/** Score range */
export type ScoreRange = {
  /** Begin */
  begin: Scalars["Int"]["input"];
  /** End */
  end: Scalars["Int"]["input"];
};

export enum ScoreResultType {
  /** Score rrepresents an error */
  Error = "ERROR",
  /** Score represents a result */
  Result = "RESULT",
  /** Score was not scored because query was skipped */
  Skipped = "SKIPPED",
  /** Was not scored for an unknown reason */
  Unknown = "UNKNOWN",
  /** The query has no scoring element */
  Unscored = "UNSCORED",
}

/** ScoreSeverity maps a score number to a severity level */
export enum ScoreSeverity {
  /** Severity is critical when score is 0 */
  Critical = "CRITICAL",
  /** Severity is high when score is between 1 - 40 */
  High = "HIGH",
  /** Severity is low when score is between 71 - 90 */
  Low = "LOW",
  /** Severity is medium when score is between 41 - 70 */
  Medium = "MEDIUM",
  /** Severity is none when score is between 91 - 100 */
  None = "NONE",
}

/** Score state */
export enum ScoreState {
  /** Not fixed/Failed */
  Closed = "CLOSED",
  /** Disabled */
  Disabled = "DISABLED",
  /** Fixed/Pass */
  Open = "OPEN",
  /** Snoozed */
  Snoozed = "SNOOZED",
}

/** Score state filter */
export enum ScoreStateFilter {
  /** All */
  All = "ALL",
  /** Only fixed/passed */
  Closed = "CLOSED",
  /** Only not fixed/failed */
  Open = "OPEN",
}

/** Score statistics */
export type ScoreStats = {
  __typename: "ScoreStats";
  /** Disabled */
  disabled: Scalars["Int"]["output"];
  /** Errors */
  errors: Scalars["Int"]["output"];
  /** Failed */
  failed: Scalars["Int"]["output"];
  /** Incomplete */
  incomplete: Scalars["Int"]["output"];
  /** Passed */
  passed: Scalars["Int"]["output"];
  /** Skipped */
  skipped: Scalars["Int"]["output"];
  /** Snoozed */
  snoozed: Scalars["Int"]["output"];
  /** Total */
  total: Scalars["Int"]["output"];
  /** Unknown */
  unknown: Scalars["Int"]["output"];
  /** Worst */
  worst: Scalars["Int"]["output"];
};

/** Score type */
export enum ScoreType {
  Disabled = "DISABLED",
  Error = "ERROR",
  OutOfScope = "OUT_OF_SCOPE",
  Result = "RESULT",
  Unknown = "UNKNOWN",
  Unscored = "UNSCORED",
}

/** Policy Scoring System */
export enum ScoringSystem {
  Average = "AVERAGE",
  Banded = "BANDED",
  Decayed = "DECAYED",
  HighestImpact = "HIGHEST_IMPACT",
  WeightedAverage = "WEIGHTED_AVERAGE",
}

export type SearchFilters = {
  aggregateScoreType?: InputMaybe<AggregateScoreType>;
};

/** The results of a search. */
export type SearchResultItem = Advisory | AggregateScore | Cve;

export type SearchResultItemConnection = {
  __typename: "SearchResultItemConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchResultItemEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The distribution of search results by score type. */
  scoreTypeDistribution: SearchResultScoreTypeDistribution;
};

/** An edge in a connection. */
export type SearchResultItemEdge = {
  __typename: "SearchResultItemEdge";
  /** The item at the end of the edge. */
  node?: Maybe<SearchResultItem>;
};

export type SearchResultScoreTypeDistribution = {
  __typename: "SearchResultScoreTypeDistribution";
  advisory: Scalars["Int"]["output"];
  asset: Scalars["Int"]["output"];
  check: Scalars["Int"]["output"];
  policy: Scalars["Int"]["output"];
  risk: Scalars["Int"]["output"];
  vulnerability: Scalars["Int"]["output"];
};

/** Possible search types for the search query. */
export enum SearchType {
  /** Returns matching aggregate scores */
  AggregateScore = "AGGREGATE_SCORE",
  /** Returns matching vulnerabilities and advisories in MVD. */
  Mvd = "MVD",
}

/** Security dashboard pagination info */
export type SecurityDashboardPageInfo = {
  /** organization mrn */
  orgMrn: Scalars["String"]["input"];
  /** space mrn */
  spaceMrn: Scalars["String"]["input"];
};

export type ServiceAccount = Identity & {
  __typename: "ServiceAccount";
  createdAt: Scalars["String"]["output"];
  creator?: Maybe<ServiceAccountCreator>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  labels: Array<KeyValue>;
  lastUsed?: Maybe<Scalars["String"]["output"]>;
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  /** @deprecated Use description */
  notes?: Maybe<Scalars["String"]["output"]>;
  roles: Array<Role>;
};

export type ServiceAccountConnection = {
  __typename: "ServiceAccountConnection";
  edges?: Maybe<Array<ServiceAccountEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ServiceAccountCreator = {
  __typename: "ServiceAccountCreator";
  email?: Maybe<Scalars["String"]["output"]>;
  mrn?: Maybe<Scalars["String"]["output"]>;
  service?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceAccountCredential = {
  __typename: "ServiceAccountCredential";
  apiEndpoint: Scalars["String"]["output"];
  certificate: Scalars["String"]["output"];
  mrn: Scalars["String"]["output"];
  privateKey: Scalars["String"]["output"];
  scopeMrn: Scalars["String"]["output"];
  spaceMrn?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceAccountEdge = {
  __typename: "ServiceAccountEdge";
  cursor: Scalars["ID"]["output"];
  node?: Maybe<ServiceAccount>;
};

export type ServiceAccountOrder = {
  direction: OrderDirection;
  field: ServiceAccountOrderField;
};

export enum ServiceAccountOrderField {
  CreatedAt = "CREATED_AT",
  Id = "ID",
  LastUsed = "LAST_USED",
  Name = "NAME",
}

export type SetAssetAnnotationsInput = {
  annotations: Array<KeyValueInput>;
  mrn: Scalars["String"]["input"];
  spaceMrn: Scalars["String"]["input"];
};

export type SetAssetAnnotationsPayload = {
  __typename: "SetAssetAnnotationsPayload";
  annotations: Array<KeyValue>;
};

/** Uploads policy content */
export type SetCustomPolicyInput = {
  /** RFC2397, base64 encoded policy bundle */
  dataurl: Scalars["String"]["input"];
  /** Overwrites the policy if it already exists */
  overwrite?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The mrn of the space */
  spaceMrn: Scalars["String"]["input"];
};

/** Result of adding a policy */
export type SetCustomPolicyPayload = {
  __typename: "SetCustomPolicyPayload";
  /** The mrn's of stored policies */
  policyMrns?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** Creates a new query pack with the provided content */
export type SetCustomQueryPackInput = {
  /** RFC2397, base64 encoded policy bundle */
  dataurl: Scalars["String"]["input"];
  /** Overwrites the query pack if it already exists */
  overwrite?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The mrn of the space */
  spaceMrn: Scalars["String"]["input"];
};

/** Result of setting query pack content */
export type SetCustomQueryPackPayload = {
  __typename: "SetCustomQueryPackPayload";
  /** The mrn's of stored query packs */
  queryPackMrns?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SetOrganizationMembershipInput = {
  orgMrn: Scalars["String"]["input"];
  roles: Array<RoleInput>;
  userMrn: Scalars["String"]["input"];
};

export type SetRoleInput = {
  entityMrn: Scalars["String"]["input"];
  roles: Array<RoleInput>;
};

export type SetRolesInput = {
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
  updates?: InputMaybe<Array<SetRoleInput>>;
};

export type SetRolesPayload = {
  __typename: "SetRolesPayload";
  errors: Scalars["Map"]["output"];
  mrns?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SetSsoProviderInput = {
  organizationMrn: Scalars["String"]["input"];
  provider?: InputMaybe<SsoProviderInput>;
};

/** Input type for setScimGroupMapping */
export type SetScimGroupMappingInput = {
  /** Group Identifier which is the SCIM displayName */
  group: Scalars["String"]["input"];
  /** Mappings of SCIM group to organization or its spaces with IAM role */
  mappings: Array<ScimGroupMapping>;
  /** Organization MRN for with the SCIM mapping is defined */
  orgMrn: Scalars["String"]["input"];
};

/** Return type for setScimGroupMapping */
export type SetScimGroupMappingPayload = {
  __typename: "SetScimGroupMappingPayload";
  group: Scalars["String"]["output"];
};

export type SetSpaceMembershipInput = {
  roles: Array<RoleInput>;
  spaceMrn: Scalars["String"]["input"];
  userMrn: Scalars["String"]["input"];
};

/** Possible severities */
export enum Severity {
  Critical = "CRITICAL",
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
  None = "NONE",
}

/** Slack configuration options */
export type SlackConfigurationOptions = {
  __typename: "SlackConfigurationOptions";
  /**
   * note: the slack integration currently returns nothing on its options,
   * this property is used as a placeholder to allow for a config options struct
   */
  placeholder: Scalars["String"]["output"];
};

/** Slack integration input */
export type SlackConfigurationOptionsInput = {
  slackToken?: InputMaybe<Scalars["String"]["input"]>;
};

/** Snowflake configuration options */
export type SnowflakeConfigurationOptions = {
  __typename: "SnowflakeConfigurationOptions";
  accountId: Scalars["String"]["output"];
  database: Scalars["String"]["output"];
  region: Scalars["String"]["output"];
  role?: Maybe<Scalars["String"]["output"]>;
  schema: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
  warehouse: Scalars["String"]["output"];
};

/** Snowflake integration input */
export type SnowflakeConfigurationOptionsInput = {
  accountId: Scalars["String"]["input"];
  database: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  region: Scalars["String"]["input"];
  role?: InputMaybe<Scalars["String"]["input"]>;
  schema: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
  warehouse: Scalars["String"]["input"];
};

/** Sources of CVEs, advisories, ... */
export type Source = {
  __typename: "Source";
  /** Source id */
  id: Scalars["String"]["output"];
  /** Source name */
  name: Scalars["String"]["output"];
  /** Source url */
  url: Scalars["String"]["output"];
};

/** Space */
export type Space = {
  __typename: "Space";
  /** Advisories */
  advisories?: Maybe<AdvisoriesConnection>;
  /** Agents */
  agents?: Maybe<AgentsConnection>;
  /** CVEs */
  cves?: Maybe<CvesConnection>;
  /** ID */
  id: Scalars["ID"]["output"];
  /** Members */
  members?: Maybe<MembersConnection>;
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** Organization */
  organization?: Maybe<Organization>;
  /** Settings */
  settings?: Maybe<SpaceSettings>;
  /** True if the space is shared with the user */
  shared: Scalars["Boolean"]["output"];
  /** Statistics */
  stats?: Maybe<SpaceStatistics>;
};

/** Space */
export type SpaceAdvisoriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdvisoryOrder>;
  platform?: InputMaybe<Array<AdvisoryPlatformFilter>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

/** Space */
export type SpaceAgentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AgentOrder>;
};

/** Space */
export type SpaceCvesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CveOrder>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Array<InputMaybe<CveState>>>;
};

/** Space */
export type SpaceMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MembershipOrder>;
};

/** An exception for a check that is defined on a space level */
export type SpaceCheckException = {
  __typename: "SpaceCheckException";
  /** Assets statistics */
  assetsStats: SpaceCheckExceptionAssetStats;
  /** Impact */
  impact: Impact;
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** The MRN of the policy the check is part of */
  policyMrn: Scalars["String"]["output"];
  /** The name of the policy the check is part of */
  policyName: Scalars["String"]["output"];
  /** Title */
  title: Scalars["String"]["output"];
};

/** Check stats for a space */
export type SpaceCheckExceptionAssetStats = {
  __typename: "SpaceCheckExceptionAssetStats";
  /** The number of assets that errored this check */
  errored: Scalars["Int"]["output"];
  /** The number of assets that failed this check */
  failed: Scalars["Int"]["output"];
  /** The number of assets that passed this check */
  passed: Scalars["Int"]["output"];
  /** The total number of assets that were checked */
  total: Scalars["Int"]["output"];
};

/** Space connection */
export type SpaceConnection = {
  __typename: "SpaceConnection";
  /** Edges */
  edges: Array<SpaceEdge>;
  /** Page info */
  pageInfo: PageInfo;
  /** Total amount of spaces */
  totalCount: Scalars["Int"]["output"];
};

/** Space coverage */
export type SpaceCoverage = {
  __typename: "SpaceCoverage";
  /** Monitored count */
  monitored: Scalars["Int"]["output"];
  /** Unhealthy count */
  unhealthy: Scalars["Int"]["output"];
  /** Unmonitored count */
  unmonitored: Scalars["Int"]["output"];
};

/** Space edge */
export type SpaceEdge = {
  __typename: "SpaceEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node: Space;
};

/** Space info */
export type SpaceInfo = {
  __typename: "SpaceInfo";
  /** Scores */
  scores: RiskDistribution;
  /** Space MRN */
  spaceMrn: Scalars["String"]["output"];
  /** Space name */
  spaceName: Scalars["String"]["output"];
  /** Space owners */
  spaceOwners: Array<Scalars["String"]["output"]>;
};

export type SpacePlanInfo = {
  __typename: "SpacePlanInfo";
  /** Active checks are currently being counted against the overall score. */
  active: PlanningGroup;
  /**
   * Exception checks are not counted against the score. These have no
   * end date.
   */
  exceptions: PlanningGroup;
  /**
   * Checks that are part of a milestone are not counted against the
   * score until the milestone's endDate has passed
   */
  milestones?: Maybe<Array<PlanningMilestone>>;
  /**
   * If the space plan has been previously applied, planApplied is
   * set to true. It is possible for this value to be false, but
   * for milestones and exceptions to be non-empty, for example if
   * the user ignores or disables queries outside the space plan
   * flow
   */
  planApplied: Scalars["Boolean"]["output"];
  /** A summary of the scores */
  scoringSummary: SpacePlanScoringSummary;
};

export type SpacePlanInput = {
  /**
   * The mrn to scope the planning information to. This can be either the space, or
   * an assigned policy
   */
  scopeMrn: Scalars["String"]["input"];
  /** The space mrn the plan is for */
  spaceMrn: Scalars["String"]["input"];
};

export type SpacePlanPayload = SpacePlanInfo;

export type SpacePlanQueryMutationDeltaInput = {
  /** The uid of the milestone to move the query to */
  milestoneUid?: InputMaybe<Scalars["String"]["input"]>;
  /** The queryMrn to move */
  queryMrn: Scalars["String"]["input"];
  /**
   * Where to move the query to. If the query is being moved to
   * a milestone, you must also set milestoneUid
   */
  to: SpacePlanQueryMutationDestinationType;
};

export enum SpacePlanQueryMutationDestinationType {
  /**
   * Move the query to active. These queries will be run
   * and scored
   */
  Active = "ACTIVE",
  /**
   * Move the query to exceptions. These queries will not be
   * run.
   */
  Exceptions = "EXCEPTIONS",
  /**
   * Move the query to a milestone. These queries will be run,
   * but their score will not be incorporated into the policy
   * score. If moving to a milestone, a milestone uid must also
   * be provided to indicate which milestone
   */
  Milestone = "MILESTONE",
}

export type SpacePlanScoringSummary = {
  __typename: "SpacePlanScoringSummary";
  /** The number of checks where all assets error */
  error: Scalars["Int"]["output"];
  /** The number of checks where all assets fail */
  fail: Scalars["Int"]["output"];
  /** The number of checks where all assets pass */
  pass: Scalars["Int"]["output"];
  /** The total number of checks */
  total: Scalars["Int"]["output"];
};

/** Space policy report */
export type SpacePolicyReport = {
  __typename: "SpacePolicyReport";
  /** Asset grades */
  assetGrades: AssetGradeDistribution;
  /** Asset summaries */
  assetSummaries?: Maybe<PolicyReportAssetSummariesConnection>;
  /** The mrn of the policy */
  policyMrn: Scalars["ID"]["output"];
  /** Query summaries */
  querySummaries?: Maybe<PolicyReportMquerySummariesConnection>;
  /** The mrn of the space */
  spaceMrn: Scalars["ID"]["output"];
};

/** Space policy report */
export type SpacePolicyReportAssetSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AssetSummaryOrder>;
};

/** Space policy report */
export type SpacePolicyReportQuerySummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PolicyReportMquerySummaryOrder>;
};

/** Input data for a space policy report */
export type SpacePolicyReportInput = {
  formatType?: InputMaybe<FormatType>;
  policyMrn: Scalars["ID"]["input"];
  spaceMrn: Scalars["ID"]["input"];
};

/** Possible responses for the space policy report */
export type SpacePolicyReportResponse =
  | CsvReport
  | NotFoundError
  | SpacePolicyReport;

/** SpacePolicySummaryInput contains the input parameters for the spacePolicySummary query */
export type SpacePolicySummaryInput = {
  /** policyMrn is the MRN of the policy to summarize */
  policyMrn: Scalars["String"]["input"];
  /**
   * spaceMrn is the MRN of the space. Statistics will be calculated
   * for the provided policy MRN in this space.
   */
  spaceMrn: Scalars["String"]["input"];
};

/** SpacePolicySummaryPayload contains the results of the spacePolicySummary query. */
export type SpacePolicySummaryPayload = {
  __typename: "SpacePolicySummaryPayload";
  /**
   * assetGrades is the distribution of grades for the assets in the space
   * for the requested policy
   */
  assetGrades: AssetGradeDistribution;
  /** policyMrn is the MRN of the policy */
  policyMrn: Scalars["ID"]["output"];
  /** querySummaries is the list of summaries for the queries in the policy */
  querySummaries?: Maybe<SpacePolicySummaryQuerySummariesConnection>;
  /** spaceMrn is the MRN of the space */
  spaceMrn: Scalars["ID"]["output"];
};

/** SpacePolicySummaryQuerySummariesInput is the list of summaries for the queries in a policy */
export type SpacePolicySummaryQuerySummariesConnection = {
  __typename: "SpacePolicySummaryQuerySummariesConnection";
  /** Edges */
  edges?: Maybe<Array<SpacePolicySummaryQuerySummaryEdge>>;
  /** Page info */
  pageInfo: PageInfo;
  /** totalCount is the total number of queries in the policy */
  totalCount: Scalars["Int"]["output"];
};

/** SpacePolicySummaryQuerySummary is the summary for a query in a policy */
export type SpacePolicySummaryQuerySummary = {
  __typename: "SpacePolicySummaryQuerySummary";
  /**
   * assetGrades is the distribution of grades for the assets in the space for
   * the query
   */
  assetGrades: AssetGradeDistribution;
  /** exception is an exception for the query if it exists */
  exception?: Maybe<MquerySummaryException>;
  /** ID */
  id: Scalars["ID"]["output"];
  /** mquery is the query */
  mquery: Mquery;
  /** mqueryState is the state of the query */
  mqueryState: MqueryState;
  /** mqueryType is the type of the query */
  mqueryType: MqueryType;
};

/** SpacePolicySummaryQuerySummaryEdge is the edge for a query summary */
export type SpacePolicySummaryQuerySummaryEdge = {
  __typename: "SpacePolicySummaryQuerySummaryEdge";
  /** Cursor */
  cursor: Scalars["ID"]["output"];
  /** Node */
  node?: Maybe<SpacePolicySummaryQuerySummary>;
};

/** Space report */
export type SpaceReport = {
  __typename: "SpaceReport";
  /** Policy report summaries */
  policyReportSummaries?: Maybe<PolicyReportSummariesConnection>;
  /** The mrn of the space */
  spaceMrn: Scalars["ID"]["output"];
};

/** Space report */
export type SpaceReportPolicyReportSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PolicyReportSummaryOrder>;
};

/** Input data for a space report */
export type SpaceReportInput = {
  formatType?: InputMaybe<FormatType>;
  spaceMrn: Scalars["ID"]["input"];
};

/** Possible responses for the space report */
export type SpaceReportResponse = CsvReport | NotFoundError | SpaceReport;

/** Space settings */
export type SpaceSettings = {
  __typename: "SpaceSettings";
  /** Cases configuration */
  casesConfiguration: CasesConfiguration;
  /** EOL platform configuration */
  eolAssetsConfiguration: EolAssetsConfiguration;
  /** Garbage collect assets configuration */
  garbageCollectAssetsConfiguration: GarbageCollectAssetsConfiguration;
  /** Platform vulnerability configuration */
  platformVulnerabilityConfiguration: PlatformVulnerabilityConfiguration;
  /** Terminated assets configuration */
  terminatedAssetsConfiguration: TerminatedAssetsConfiguration;
  /** Unused service accounts configuration */
  unusedServiceAccountsConfiguration: UnusedServiceAccountsConfiguration;
};

/** Space settings input */
export type SpaceSettingsInput = {
  /** Cases configuration */
  casesConfiguration?: InputMaybe<CasesConfigurationInput>;
  /** EOL platform configuration */
  eolAssetsConfiguration?: InputMaybe<EolAssetsConfigurationInput>;
  /** Garbage collect assets configuration */
  garbageCollectAssetsConfiguration?: InputMaybe<GarbageCollectAssetsConfigurationInput>;
  /** Platform vulnerability configuration */
  platformVulnerabilityConfiguration?: InputMaybe<PlatformVulnerabilityConfigurationInput>;
  /** Terminated assets configuration */
  terminatedAssetsConfiguration?: InputMaybe<TerminatedAssetsConfigurationInput>;
  /** Unused service accounts configuration */
  unusedServiceAccountsConfiguration?: InputMaybe<UnusedServiceAccountsConfigurationInput>;
};

/** Space statistics */
export type SpaceStatistics = {
  __typename: "SpaceStatistics";
  /** Agents */
  agents: AgentStatistics;
  /** Asset groups statistics */
  assetGroupStatistics: Array<AssetGroupStatistics>;
  /**
   * DEPRECATED
   * @deprecated Use integrationsStatistics instead
   */
  awsIntegrations: AgentStatistics;
  /** Coverage */
  coverage: SpaceCoverage;
  /**
   * DEPRECATED
   * @deprecated Use integrationsStatistics instead
   */
  integrations: AgentStatistics;
  /** Integrations statistics */
  integrationsStatistics: Array<IntegrationStatistics>;
  /** Platform distribution */
  platformdistribution?: Maybe<Array<Maybe<PlatformDistributionStats>>>;
  /** Risk distribution */
  riskdistribution: RiskDistribution;
};

/** Space Vulnerability inputs */
export type SpaceVulnerabilitiesInput = {
  /** first */
  count?: InputMaybe<Scalars["Int"]["input"]>;
  /** order type */
  orderType?: InputMaybe<VulnerabilityOrderType>;
  /** space mrn */
  spaceMrn: Scalars["String"]["input"];
};

/** Space vulnerability report */
export type SpaceVulnerabilityReport = {
  __typename: "SpaceVulnerabilityReport";
  /**
   * Advisories affecting the space
   * @deprecated Use aggregatedScores instead
   */
  advisorySummaries?: Maybe<AdvisorySummariesConnection>;
  /** Statistics */
  affectedAssetStats: AffectedAssetStats;
  /** Assets affected by advisories and cves in this space */
  assetSummaries?: Maybe<VulnerabilityReportAssetSummariesConnection>;
  /** Cves affecting the space */
  cveSummaries?: Maybe<CveSummariesConnection>;
  /** Space mrn */
  spaceMrn: Scalars["ID"]["output"];
};

/** Space vulnerability report */
export type SpaceVulnerabilityReportAdvisorySummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdvisorySummaryOrder>;
};

/** Space vulnerability report */
export type SpaceVulnerabilityReportAssetSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AssetSummaryOrder>;
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Space vulnerability report */
export type SpaceVulnerabilityReportCveSummariesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  onlyExploitable?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderBy?: InputMaybe<CveSummaryOrder>;
};

/** Input data for a space vulnerability report */
export type SpaceVulnerabilityReportInput = {
  formatType?: InputMaybe<FormatType>;
  spaceMrn: Scalars["ID"]["input"];
};

/** Possible responses for the space vulnerability report */
export type SpaceVulnerabilityReportResponse =
  | CsvReport
  | NotFoundError
  | SpaceVulnerabilityReport;

export type SubscriptionInfo = {
  __typename: "SubscriptionInfo";
  additions?: Maybe<Array<ProductInfo>>;
  basePlan: ProductInfo;
  isSelfService: Scalars["Boolean"]["output"];
  pendingCancel: Scalars["Boolean"]["output"];
  utilizationMetrics?: Maybe<Array<UtilizationMetric>>;
};

export type SubscriptionItem = {
  priceID: Scalars["String"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type SubscriptionPlan = {
  __typename: "SubscriptionPlan";
  additions?: Maybe<Array<Product>>;
  basePlan: Product;
  isCurrent: Scalars["Boolean"]["output"];
  isDowngrade: Scalars["Boolean"]["output"];
  isSelfService: Scalars["Boolean"]["output"];
  isUpgrade: Scalars["Boolean"]["output"];
};

export type Suggestion = {
  __typename: "Suggestion";
  value: Scalars["String"]["output"];
};

/** SuppressIntegrationMessageInput contains the inputs needed to suppress (remove) an integration message */
export type SuppressIntegrationMessageInput = {
  identifier?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  mrn: Scalars["String"]["input"];
};

/** SuppressIntegrationMessagePayload holds the mrn of the target integration */
export type SuppressIntegrationMessagePayload = {
  __typename: "SuppressIntegrationMessagePayload";
  mrn: Scalars["String"]["output"];
};

/** Tag */
export type Tag = {
  __typename: "Tag";
  /** The name of the tag */
  key: Scalars["String"]["output"];
  /** The value of the tag */
  value: Scalars["String"]["output"];
};

/** Terminated assets configuration */
export type TerminatedAssetsConfiguration = {
  __typename: "TerminatedAssetsConfiguration";
  /** After how many days to cleanup */
  after: Scalars["Int"]["output"];
  /** Whether to cleanup terminated assets */
  cleanup: Scalars["Boolean"]["output"];
};

/** Terminated assets configuration input */
export type TerminatedAssetsConfigurationInput = {
  /** Whether to cleanup terminated assets */
  cleanup?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Fetch Jira ticket context information. */
export type TicketContextInput = {
  /** Jira ticket context parameters. */
  jira?: InputMaybe<JiraTicketContextInput>;
  /** The MRN of the scope for which the context is fetched. */
  scopeMrn: Scalars["String"]["input"];
};

/** The payload for the ticket context. */
export type TicketContextPayload =
  | EmailTicketContextPayload
  | JiraTicketContextProjectsPayload
  | JiraTicketContextUsersPayload;

/** Ticket reference */
export type TicketRef = {
  __typename: "TicketRef";
  /** Integration MRN */
  integrationMrn: Scalars["String"]["output"];
  /** Ticket identifier. Only set if a ticket has been created. */
  ticketId?: Maybe<Scalars["String"]["output"]>;
  /** Type */
  type: TicketRefType;
  /** Ticket URL. Only set if a ticket has been created. */
  url?: Maybe<Scalars["String"]["output"]>;
};

/** Ticket reference type */
export enum TicketRefType {
  Email = "EMAIL",
  Github = "GITHUB",
  Gitlab = "GITLAB",
  Jira = "JIRA",
  Unknown = "UNKNOWN",
  Zendesk = "ZENDESK",
}

/** TicketingIntegrationsInput describes the input for listing ticketing integrations */
export type TicketingIntegrationsInput = {
  spaceMrn: Scalars["String"]["input"];
};

/** TicketingIntegrationsPayload is the response to a list of ticketing integrations */
export type TicketingIntegrationsPayload = {
  __typename: "TicketingIntegrationsPayload";
  integrations: Array<Maybe<ClientIntegration>>;
};

/** Timestamp filter */
export type TimestampFilter = {
  /** Comparison operator */
  operator: ComparisonOperator;
  /** RFC3339 timestamp */
  timestamp: Scalars["String"]["input"];
};

/** Top policy actions input */
export type TopPolicyActionsInput = {
  /** Count */
  count?: InputMaybe<Scalars["Int"]["input"]>;
  /** The mrn of the space */
  spaceMrn: Scalars["String"]["input"];
};

/** Top Vulnerability input */
export type TopVulnerabilityInput = {
  /** space mrn */
  spaceMrn: Scalars["String"]["input"];
};

/** Inputs used to trigger an action on a client integration */
export type TriggerActionInput = {
  actionOptions?: InputMaybe<TriggerActionOptionsInput>;
  mrn: Scalars["String"]["input"];
  type: ActionType;
};

/** Inputs options used to trigger an action on a client integration */
export type TriggerActionOptionsInput = {
  /** Optional - AWS scan options. */
  awsScanOptions?: InputMaybe<AwsScanOptions>;
  /** Optional source identifier, e.g. AWS account id. */
  identifiers?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Response when triggering action on client integration */
export type TriggerActionPayload = {
  __typename: "TriggerActionPayload";
  mrn: Scalars["String"]["output"];
};

/** Trust level of the object */
export enum TrustLevel {
  All = "ALL",
  Official = "OFFICIAL",
  Oss = "OSS",
  Private = "PRIVATE",
  VerifiedPublisher = "VERIFIED_PUBLISHER",
}

/** Unused service accounts configuration */
export type UnusedServiceAccountsConfiguration = {
  __typename: "UnusedServiceAccountsConfiguration";
  /** After how many days to cleanup */
  after: Scalars["Int"]["output"];
  /** Whether to cleanup unused service accounts */
  cleanup: Scalars["Boolean"]["output"];
};

/** Unused service accounts configuration input */
export type UnusedServiceAccountsConfigurationInput = {
  /** Whether to cleanup unused service accounts */
  cleanup?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Input used when updating an API token name or description */
export type UpdateApiTokenInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  mrn: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Inputs for updating agents */
export type UpdateAgentInput = {
  agentMrn: Scalars["String"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

/** Response when updating an agent */
export type UpdateAgentPayload = {
  __typename: "UpdateAgentPayload";
  agent: Agent;
};

/** Input for updating a client integration */
export type UpdateClientIntegrationConfigurationInput = {
  configurationOptions: ClientIntegrationConfigurationInput;
  mrn: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  type: ClientIntegrationType;
};

/** Response when updating client integration */
export type UpdateClientIntegrationConfigurationPayload = {
  __typename: "UpdateClientIntegrationConfigurationPayload";
  configurationOptions: ClientIntegrationConfigurationOptions;
  name: Scalars["String"]["output"];
};

/** Inputs to change the name of a client integration */
export type UpdateClientIntegrationNameInput = {
  mrn: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

/** Response when changing the name of a client integration */
export type UpdateClientIntegrationNamePayload = {
  __typename: "UpdateClientIntegrationNamePayload";
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** Update organization input */
export type UpdateOrganizationInput = {
  /** MRN */
  mrn: Scalars["String"]["input"];
  /** Name */
  name: Scalars["String"]["input"];
};

/** Update organization payload */
export type UpdateOrganizationPayload = {
  __typename: "UpdateOrganizationPayload";
  /** Organization */
  organization: Organization;
};

/** Input used when updating service account name, notes, and labels */
export type UpdateServiceAccountInput = {
  labels?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  mrn: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

/** Update space input */
export type UpdateSpaceInput = {
  /** MRN */
  mrn: Scalars["String"]["input"];
  /** Name */
  name: Scalars["String"]["input"];
  /** Settings */
  settings?: InputMaybe<SpaceSettingsInput>;
};

/** Update space payload */
export type UpdateSpacePayload = {
  __typename: "UpdateSpacePayload";
  /** Space */
  space: Space;
};

export type UpdateUserInput = {
  name: Scalars["String"]["input"];
};

export type UpdateUserPayload = {
  __typename: "UpdateUserPayload";
  user: User;
};

/** Upload framework input */
export type UploadFrameworkInput = {
  /** RFC2397, base64 encoded policy bundle */
  dataurl: Scalars["String"]["input"];
  /** The mrn of the space to which the framework belongs */
  spaceMrn: Scalars["String"]["input"];
};

export type User = Identity & {
  __typename: "User";
  email?: Maybe<Scalars["String"]["output"]>;
  firstSpace?: Maybe<Space>;
  mrn: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  organizations?: Maybe<Array<Organization>>;
  spaces?: Maybe<Array<Space>>;
  sshKeys?: Maybe<Array<SshKey>>;
  state?: Maybe<UserState>;
};

export type UserSetting = {
  __typename: "UserSetting";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type UserSharedSpaceEdge = {
  __typename: "UserSharedSpaceEdge";
  cursor: Scalars["ID"]["output"];
  node: Space;
};

export type UserSharedSpacesConnection = {
  __typename: "UserSharedSpacesConnection";
  edges?: Maybe<Array<UserSharedSpaceEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export enum UserState {
  Enabled = "ENABLED",
  Unknown = "UNKNOWN",
  Waitlisted = "WAITLISTED",
}

export type UtilizationMetric = {
  __typename: "UtilizationMetric";
  description: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  limit?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  units: Scalars["String"]["output"];
  value: Scalars["Int"]["output"];
};

/** VPCConfiguration describes the options for the VPCs used for the scanners */
export type VpcConfiguration = {
  __typename: "VPCConfiguration";
  CIDR?: Maybe<Scalars["String"]["output"]>;
  useDefaultVPC?: Maybe<Scalars["Boolean"]["output"]>;
  useMondooVPC?: Maybe<Scalars["Boolean"]["output"]>;
};

/**
 * VPCConfigurationInput describes the options for the VPCs used for the scanners. Setting useDefaultVPC to true will create the mondoo scanners in the
 * AWS account default VPC. Setting useDefaultVPC to false will lead to the lambda function creating the VPC for the scanners in each scanning region.
 */
export type VpcConfigurationInput = {
  CIDR?: InputMaybe<Scalars["String"]["input"]>;
  useDefaultVPC?: InputMaybe<Scalars["Boolean"]["input"]>;
  useMondooVPC?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Variant */
export type Variant = {
  __typename: "Variant";
  /** Icon */
  icon: Scalars["String"]["output"];
  /** Used for icons and filtering, variant id == code id */
  id: Scalars["ID"]["output"];
  /** The mrn is a unique identifier */
  mrn: Scalars["String"]["output"];
  /** The title */
  title: Scalars["String"]["output"];
};

/**
 * Describes a filter that can be used to filter a policies variants
 * by platform
 */
export type VariantPlatformFilter = {
  __typename: "VariantPlatformFilter";
  /** Icon */
  icon: Scalars["String"]["output"];
  /** Used for filtering */
  id: Scalars["ID"]["output"];
  /** The title */
  title: Scalars["String"]["output"];
};

/** Version distribution */
export type VersionDistribution = {
  __typename: "VersionDistribution";
  /** Total number of assets (all assets, noit only the onces with this software installed) */
  assets: Scalars["Int"]["output"];
  /** Version distribution */
  distribution?: Maybe<Array<VersionDistributionEntry>>;
  /** Total number of installs across all versions and independent of score */
  installations: Scalars["Int"]["output"];
  /** Total number of versions (not distinct) */
  total: Scalars["Int"]["output"];
};

/** Version distribution entry */
export type VersionDistributionEntry = {
  __typename: "VersionDistributionEntry";
  /** Number of assets with this version */
  count: Scalars["Int"]["output"];
  /** Risk score ranging between 0 - 100, calculated based on base score and risk factors */
  riskScore: Scalars["Int"]["output"];
  /** Version */
  version: Scalars["String"]["output"];
};

/** Vulnerability Dashboard stats */
export type VulnDashboardStats = {
  __typename: "VulnDashboardStats";
  /** remediated by severity */
  remediatedBySeverity?: Maybe<Array<Maybe<RemediatedBySeverity>>>;
  /** Statistics */
  stats?: Maybe<Array<Maybe<VulnerabilityStats>>>;
};

/** Vulnerability Dashboard stats input */
export type VulnDashboardStatsInput = {
  /** space mrn */
  spaceMrn: Scalars["String"]["input"];
};

/** Vulnerabilities */
export type Vulnerabilities = {
  __typename: "Vulnerabilities";
  /** number of critical vulnerabilities */
  critical: Scalars["Int"]["output"];
  /** date */
  date: Scalars["String"]["output"];
  /** number of high vulnerabilities */
  high: Scalars["Int"]["output"];
};

/** Vulnerabilities by space */
export type VulnerabilitiesPerSpace = {
  __typename: "VulnerabilitiesPerSpace";
  /** number of affected assets */
  amountAssets: Scalars["Int"]["output"];
  /** space mrn */
  spaceMrn: Scalars["String"]["output"];
  /** space name */
  spaceName: Scalars["String"]["output"];
  /** space owners */
  spaceOwners: Array<Scalars["String"]["output"]>;
};

/** Vulnerability count */
export type VulnerabilityCount = {
  __typename: "VulnerabilityCount";
  /** count */
  count: Scalars["Int"]["output"];
  /** severity */
  severity: Scalars["String"]["output"];
};

/** Vuln Dashboard pagination info */
export type VulnerabilityDashboardPageInfo = {
  /** organization mrn */
  orgMrn: Scalars["String"]["input"];
  /** space mrn */
  spaceMrn: Scalars["String"]["input"];
};

/** Vulnerability Details */
export type VulnerabilityInfo = {
  __typename: "VulnerabilityInfo";
  /** CVE */
  cve: Cve;
  /** space statistics */
  stats?: Maybe<Array<VulnerabilitiesPerSpace>>;
  /** number of affected assets */
  totalAffectedAssets: Scalars["Int"]["output"];
};

/** Vulnerability */
export type VulnerabilityItem = {
  __typename: "VulnerabilityItem";
  /** number of affected assets */
  assetsAffectedCount?: Maybe<Scalars["Int"]["output"]>;
  /** ID */
  id: Scalars["String"]["output"];
  /** published date */
  published: Scalars["String"]["output"];
  /** Score */
  score: Scalars["Int"]["output"];
  /** title */
  title: Scalars["String"]["output"];
};

/** possible vulnerability order types */
export enum VulnerabilityOrderType {
  Newest = "NEWEST",
  Severe = "SEVERE",
}

/** Remediation script not found, e.g., when the package is not found */
export type VulnerabilityRemediationNotFound = Error & {
  __typename: "VulnerabilityRemediationNotFound";
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

/** Remediation script not supported, e.g., wrong platform */
export type VulnerabilityRemediationNotSupported = Error & {
  __typename: "VulnerabilityRemediationNotSupported";
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

/** Remediation script(s) */
export type VulnerabilityRemediationResponse = {
  __typename: "VulnerabilityRemediationResponse";
  /** Name of the namespace with the highest version */
  highestVersionNamespace?: Maybe<Scalars["String"]["output"]>;
  /** List of available namespaces for an advisory */
  namespaces?: Maybe<Array<Scalars["String"]["output"]>>;
  /** List of packages which are covered by the scripts */
  packages?: Maybe<Array<Package>>;
  /** The generated scripts */
  scripts?: Maybe<Array<RemediationScript>>;
};

/** VulnerabilityReportAssetSummariesConnection */
export type VulnerabilityReportAssetSummariesConnection = {
  __typename: "VulnerabilityReportAssetSummariesConnection";
  /** Assets */
  edges?: Maybe<Array<VulnerabilityReportAssetSummaryEdge>>;
  /** Pagination info */
  pageInfo: PageInfo;
  /** Total count of assets */
  totalCount: Scalars["Int"]["output"];
};

/** Vulnerability Report asset summary */
export type VulnerabilityReportAssetSummary = {
  __typename: "VulnerabilityReportAssetSummary";
  /** number of unfixed advisories */
  activeAdvisoryCount: Scalars["Int"]["output"];
  /** number of unfixed cves */
  activeCveCount: Scalars["Int"]["output"];
  /** asset data */
  asset: Asset;
  /** number of fixed advisories */
  fixedAdvisoryCount: Scalars["Int"]["output"];
  /** number of fixed cves */
  fixedCveCount: Scalars["Int"]["output"];
  /** report id */
  id: Scalars["ID"]["output"];
  /** worst score */
  worstCvssScore?: Maybe<CvssScore>;
};

/** VulnerabilityReportAssetSummaryEdge */
export type VulnerabilityReportAssetSummaryEdge = {
  __typename: "VulnerabilityReportAssetSummaryEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** The real asset */
  node?: Maybe<VulnerabilityReportAssetSummary>;
};

/**
 * VulnerabilityRiskFactors
 *
 * Deprecated: Use `RiskFactorStats` instead
 */
export type VulnerabilityRiskFactors = {
  __typename: "VulnerabilityRiskFactors";
  /** Does a known exploit exist for this vulnerability */
  knownExploited: Scalars["Boolean"]["output"];
  /** Is the vulnerability remotely exploitable */
  remoteExploitable: Scalars["Boolean"]["output"];
};

/** Vulnerability score node object */
export type VulnerabilityScore = {
  __typename: "VulnerabilityScore";
  /** Asset information */
  asset?: Maybe<EntityInfoAsset>;
  /** CVSS information for this aggregate score (optional, only for vulns, and software) */
  cvss?: Maybe<CvssScore>;
  /** EPSS information for this aggregate score (optional, only for vulns, and software) */
  epss?: Maybe<EpssScore>;
  /** Timestamp, when the first failure was detected (RFC3339) */
  firstDetectedAt: Scalars["String"]["output"];
  /** Icon identifier for the score */
  iconId: Icon_Ids;
  /** Vulnerability ID */
  id: Scalars["String"]["output"];
  /** MRN */
  mrn: Scalars["String"]["output"];
  /** Timestamp, wheh the vulnerability has been published (RFC3339) */
  publishedAt?: Maybe<Scalars["String"]["output"]>;
  /** Risk factors */
  riskFactors?: Maybe<Array<Maybe<RiskFactorStats>>>;
  /** Risk score ranging between 0 - 100, calculated based on base score and risk factors */
  riskScore?: Maybe<Scalars["Int"]["output"]>;
  /** Vulnerability score type */
  scoreType: VulnerabilityScoreType;
  /** State */
  state: ScoreState;
  /** Risk score tags */
  tags?: Maybe<Array<Tag>>;
  /** Title */
  title: Scalars["String"]["output"];
  /** Timestamp, when the score was last updated */
  updatedAt: Scalars["String"]["output"];
};

/** A vulnerability score edge object */
export type VulnerabilityScoreEdge = {
  __typename: "VulnerabilityScoreEdge";
  /** A cursor used for pagination */
  cursor: Scalars["ID"]["output"];
  /** Vulnerability score node object */
  node?: Maybe<VulnerabilityScore>;
};

/** Vulnerability score filters */
export type VulnerabilityScoreFilter = {
  /**
   * Optional query terms. Will return only vulnerabilities containing
   * the term in their MRN
   */
  queryTerms?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** filter by risk factor mrn */
  risks?: InputMaybe<RiskFactorFilter>;
  /** Filter by vulnerability score type */
  scoreType?: InputMaybe<VulnerabilityScoreType>;
  /**
   * filter vulns by findingMrn of a versioned software package
   * e.g., finding versions of bash 5.0.1 by searching for //.../software/.../name/bash/5.0.1
   */
  softwareFindingMrn?: InputMaybe<Scalars["String"]["input"]>;
  /** Filter by state */
  state?: InputMaybe<ScoreStateFilter>;
};

/** Vulnerability score order object */
export type VulnerabilityScoreOrder = {
  /** Order direction */
  direction: OrderDirection;
  /** VulnerabilityScore field */
  field: VulnerabilityScoreOrderField;
};

/** Vulnerability score order field */
export enum VulnerabilityScoreOrderField {
  CvssScore = "CVSS_SCORE",
  LastUpdated = "LAST_UPDATED",
  Mrn = "MRN",
  RiskScore = "RISK_SCORE",
}

/** Vulnerability score type */
export enum VulnerabilityScoreType {
  Advisory = "ADVISORY",
  Cve = "CVE",
}

/** Vulnerability score connection object */
export type VulnerabilityScoresConnection = {
  __typename: "VulnerabilityScoresConnection";
  /** Risk score edges */
  edges?: Maybe<Array<VulnerabilityScoreEdge>>;
  /** Total count with filters applied */
  filteredTotalCount: Scalars["Int"]["output"];
  /** Pagination info */
  pageInfo: PageInfo;
  /** Total count of cves */
  totalCount: Scalars["Int"]["output"];
};

export type VulnerabilityScoresResponse =
  | NotFoundError
  | RequestError
  | VulnerabilityScoresConnection;

/** Vulnerability stats */
export type VulnerabilityStats = {
  __typename: "VulnerabilityStats";
  /** number of vulnerabilities */
  counts?: Maybe<Array<Maybe<VulnerabilityCount>>>;
  /** type */
  statType: Scalars["String"]["output"];
  /** total */
  total?: Maybe<Scalars["Int"]["output"]>;
};

/** Zendesk configuration options */
export type ZendeskConfigurationOptions = {
  __typename: "ZendeskConfigurationOptions";
  autoCloseTickets: Scalars["Boolean"]["output"];
  autoCreateTickets: Scalars["Boolean"]["output"];
  email: Scalars["String"]["output"];
  subdomain: Scalars["String"]["output"];
};

/** Zendesk integration input */
export type ZendeskConfigurationOptionsInput = {
  apiToken: Scalars["String"]["input"];
  autoCloseTickets: Scalars["Boolean"]["input"];
  autoCreateTickets: Scalars["Boolean"]["input"];
  email: Scalars["String"]["input"];
  subdomain: Scalars["String"]["input"];
};

export type AdvisoryFieldsFragment = {
  __typename: "AdvisoriesConnection";
  totalCount: number;
  edges?: Array<{
    __typename: "AdvisoryEdge";
    cursor: string;
    node?: {
      __typename: "Advisory";
      id: string;
      title?: string | null;
      publishedAt?: string | null;
      cvssScore: {
        __typename: "CvssScore";
        id: string;
        source: string;
        type: number;
        value: number;
        vector: string;
      };
      vulnerabilityRiskFactors: {
        __typename: "VulnerabilityRiskFactors";
        knownExploited: boolean;
        remoteExploitable: boolean;
      };
      cves?: Array<{
        __typename: "Cve";
        id: string;
        vulnerabilityRiskFactors: {
          __typename: "VulnerabilityRiskFactors";
          knownExploited: boolean;
          remoteExploitable: boolean;
        };
      }> | null;
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type AdvisoryNodeFieldsFragment = {
  __typename: "Advisory";
  id: string;
  title?: string | null;
  publishedAt?: string | null;
  cvssScore: {
    __typename: "CvssScore";
    id: string;
    source: string;
    type: number;
    value: number;
    vector: string;
  };
  vulnerabilityRiskFactors: {
    __typename: "VulnerabilityRiskFactors";
    knownExploited: boolean;
    remoteExploitable: boolean;
  };
  cves?: Array<{
    __typename: "Cve";
    id: string;
    vulnerabilityRiskFactors: {
      __typename: "VulnerabilityRiskFactors";
      knownExploited: boolean;
      remoteExploitable: boolean;
    };
  }> | null;
};

export type AdvisorySummariesFieldsFragment = {
  __typename: "AdvisorySummariesConnection";
  totalCount: number;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  edges?: Array<{
    __typename: "AdvisorySummaryEdge";
    cursor: string;
    node?: {
      __typename: "AdvisorySummary";
      id: string;
      spaceMrn: string;
      discoveredAt?: string | null;
      advisory: {
        __typename: "Advisory";
        id: string;
        mrn: string;
        title?: string | null;
        description?: string | null;
        vendorscore?: number | null;
        publishedAt?: string | null;
        modifiedAt?: string | null;
        source: { __typename: "Source"; id: string; name: string; url: string };
        cves?: Array<{
          __typename: "Cve";
          id: string;
          mrn: string;
          title?: string | null;
          description?: string | null;
          summary?: string | null;
          publishedAt?: string | null;
        }> | null;
        cvssScore: {
          __typename: "CvssScore";
          id: string;
          value: number;
          type: number;
          vector: string;
          source: string;
        };
      };
      cveSummaries?: Array<{
        __typename: "CveSummary";
        id: string;
        discoveredAt?: string | null;
        spaceMrn: string;
        cve: {
          __typename: "Cve";
          id: string;
          mrn: string;
          title?: string | null;
          description?: string | null;
          cvssScore: {
            __typename: "CvssScore";
            id: string;
            value: number;
            type: number;
            vector: string;
            source: string;
          };
        };
        affectedAssetStats: {
          __typename: "AffectedAssetStats";
          affectedCount: number;
          fixedCount: number;
          totalCount: number;
        };
      }> | null;
      assetSummaries?: {
        __typename: "VulnerabilityReportAssetSummariesConnection";
        totalCount: number;
        edges?: Array<{
          __typename: "VulnerabilityReportAssetSummaryEdge";
          cursor: string;
          node?: {
            __typename: "VulnerabilityReportAssetSummary";
            id: string;
            activeAdvisoryCount: number;
            fixedAdvisoryCount: number;
            activeCveCount: number;
            fixedCveCount: number;
            worstCvssScore?: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            } | null;
            asset: {
              __typename: "Asset";
              id: string;
              mrn: string;
              state: AssetState;
              name?: string | null;
              updatedAt?: string | null;
              labels: Array<{
                __typename: "KeyValue";
                key: string;
                value?: string | null;
              }>;
              platform?: {
                __typename: "Platform";
                name: string;
                release: string;
                arch: string;
                kind: PlatformKind;
                runtime: string;
              } | null;
            };
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      } | null;
      affectedAssetStats: {
        __typename: "AffectedAssetStats";
        affectedCount: number;
        fixedCount: number;
        totalCount: number;
      };
    } | null;
  }> | null;
};

export type AdvisorySummaryFieldsFragment = {
  __typename: "AdvisorySummariesConnection";
  totalCount: number;
  stats?: {
    __typename: "AdvisorySummariesConnectionStats";
    totalDatabaseAdvisories: number;
    totalAssetAdvisories: number;
  } | null;
  edges?: Array<{
    __typename: "AdvisorySummaryEdge";
    cursor: string;
    node?: {
      __typename: "AdvisorySummary";
      advisory: {
        __typename: "Advisory";
        id: string;
        title?: string | null;
        publishedAt?: string | null;
        cvssScore: {
          __typename: "CvssScore";
          id: string;
          source: string;
          type: number;
          value: number;
          vector: string;
        };
        vulnerabilityRiskFactors: {
          __typename: "VulnerabilityRiskFactors";
          knownExploited: boolean;
          remoteExploitable: boolean;
        };
        cves?: Array<{
          __typename: "Cve";
          id: string;
          vulnerabilityRiskFactors: {
            __typename: "VulnerabilityRiskFactors";
            knownExploited: boolean;
            remoteExploitable: boolean;
          };
        }> | null;
      };
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type AffectedAssetStatsFieldsFragment = {
  __typename: "AffectedAssetStats";
  affectedCount: number;
  fixedCount: number;
  totalCount: number;
};

export type AgentFieldsFragment = {
  __typename: "AgentsConnection";
  totalCount: number;
  edges?: Array<{
    __typename: "AgentEdge";
    cursor: string;
    node?: {
      __typename: "Agent";
      id: string;
      mrn: string;
      name: string;
      hostname: string;
      ipaddress: string;
      createdAt: string;
      status?: {
        __typename: "AgentStatus";
        version?: string | null;
        state?: AgentState | null;
        lastCheckin?: string | null;
      } | null;
      labels: Array<{
        __typename: "KeyValue";
        key: string;
        value?: string | null;
      }>;
      platform?: {
        __typename: "Platform";
        name: string;
        release: string;
        arch: string;
      } | null;
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type AssetFieldsFragment = {
  __typename: "AssetsConnection";
  totalCount: number;
  edges?: Array<{
    __typename: "AssetEdge";
    cursor: string;
    node?: {
      __typename: "Asset";
      id: string;
      mrn: string;
      name?: string | null;
      state: AssetState;
      updatedAt?: string | null;
      score: {
        __typename: "Score";
        type: number;
        value: number;
        completion: number;
      };
      labels: Array<{
        __typename: "KeyValue";
        key: string;
        value?: string | null;
      }>;
      platform?: {
        __typename: "Platform";
        name: string;
        title: string;
        release: string;
        arch: string;
        kind: PlatformKind;
        runtime: string;
      } | null;
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type AssetGradeDistributionFieldsFragment = {
  __typename: "AssetGradeDistribution";
  total: number;
  A: number;
  B: number;
  C: number;
  D: number;
  F: number;
  U: number;
  X: number;
};

export type AssetPackageDetailsFieldsFragment = {
  __typename: "AssetPackageDetails";
  assetMrn: string;
  name: string;
  version: string;
  createdAt?: string | null;
  updatedAt: string;
  description?: string | null;
  available?: string | null;
  references?: Array<string | null> | null;
  iconIDEnum?: Icon_Ids | null;
  asset?: { __typename: "EntityInfoAsset"; name: string } | null;
  vulnerabilityRiskFactors: {
    __typename: "VulnerabilityRiskFactors";
    knownExploited: boolean;
    remoteExploitable: boolean;
  };
  cvssScore: {
    __typename: "CvssScore";
    id: string;
    value: number;
    type: number;
    vector: string;
    source: string;
  };
  epssScore?: {
    __typename: "EpssScore";
    percentile: number;
    probability: number;
  } | null;
  cveSummaries?: {
    __typename: "CveSummariesConnection";
    totalCount: number;
    stats?: {
      __typename: "CveSummariesConnectionStats";
      totalDatabaseCves: number;
      totalAssetCves: number;
    } | null;
    edges?: Array<{
      __typename: "CveSummaryEdge";
      cursor: string;
      node?: {
        __typename: "CveSummary";
        cve: {
          __typename: "Cve";
          id: string;
          state?: CveState | null;
          published?: string | null;
          title?: string | null;
          vulnerabilityRiskFactors: {
            __typename: "VulnerabilityRiskFactors";
            knownExploited: boolean;
            remoteExploitable: boolean;
          };
          epssScore?: { __typename: "EpssScore"; probability: number } | null;
          cvssScore: {
            __typename: "CvssScore";
            type: number;
            value: number;
            vector: string;
          };
          score: { __typename: "CvssScore"; value: number; type: number };
        };
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
  } | null;
  advisorySummaries?: {
    __typename: "AdvisorySummariesConnection";
    totalCount: number;
    stats?: {
      __typename: "AdvisorySummariesConnectionStats";
      totalDatabaseAdvisories: number;
      totalAssetAdvisories: number;
    } | null;
    edges?: Array<{
      __typename: "AdvisorySummaryEdge";
      cursor: string;
      node?: {
        __typename: "AdvisorySummary";
        advisory: {
          __typename: "Advisory";
          id: string;
          title?: string | null;
          publishedAt?: string | null;
          cvssScore: {
            __typename: "CvssScore";
            id: string;
            source: string;
            type: number;
            value: number;
            vector: string;
          };
          vulnerabilityRiskFactors: {
            __typename: "VulnerabilityRiskFactors";
            knownExploited: boolean;
            remoteExploitable: boolean;
          };
          cves?: Array<{
            __typename: "Cve";
            id: string;
            vulnerabilityRiskFactors: {
              __typename: "VulnerabilityRiskFactors";
              knownExploited: boolean;
              remoteExploitable: boolean;
            };
          }> | null;
        };
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
  } | null;
};

export type AssetReportChecksFieldsFragment = {
  __typename: "AssetReportQueryConnection";
  totalCount: number;
  edges: Array<{
    __typename: "AssetReportQueryEdge";
    cursor: string;
    node: {
      __typename: "AssetReportQuery";
      id: string;
      mqueryState: MqueryState;
      mqueryType: MqueryType;
      data?: any | null;
      action: PolicyReportScoreEntryAction;
      policies?: Array<string> | null;
      policyId: string;
      policyName: string;
      mquery: {
        __typename: "Mquery";
        uid: string;
        mrn: string;
        title: string;
        mql: string;
        parent?: string | null;
        action: PolicyReportScoreEntryAction;
        docs?: {
          __typename: "MqueryDocs";
          desc: string;
          audit: string;
          remediations?:
            | {
                __typename: "AuthenticationRequired";
                message: string;
                code: string;
              }
            | {
                __typename: "Remediations";
                entries: Array<{
                  __typename: "MqueryRemediation";
                  desc: string;
                  id: string;
                }>;
              }
            | null;
          references?: Array<{
            __typename: "MqueryRef";
            title: string;
            url: string;
          }> | null;
        } | null;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
        tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
        properties?: Array<{
          __typename: "MProperty";
          uid: string;
          mrn: string;
          title: string;
          mql: string;
          setByPolicyMrn?: string | null;
        }> | null;
        variants?: Array<{
          __typename: "Variant";
          id: string;
          mrn: string;
          title: string;
          icon: string;
        }> | null;
        variantQueries?: Array<{
          __typename: "MqueryVariant";
          uid: string;
          mrn: string;
          title: string;
          mql: string;
          filter: string;
          filterID: string;
        } | null> | null;
      };
      score: {
        __typename: "Score";
        id: string;
        value: number;
        type: number;
        completion: number;
        weight: number;
        message: string;
        grade: string;
      };
      assessment?: {
        __typename: "PolicyQueryAssessment";
        state: PolicyReportEntryState;
        rendered: string;
        needsUpgrade: boolean;
        results?: Array<{
          __typename: "PolicyQueryAssessmentItem";
          actual: string;
          expected: string;
          error: string;
        }> | null;
        extendedResults?: Array<{
          __typename: "PolicyQueryAssessmentResources";
          resourceName: string;
          resourceTitle: string;
          numResources: number;
          actual?: Array<{
            __typename: "Resource";
            name: string;
            title: string;
            type: string;
            path: string;
            numElements: number;
            nestedContent: boolean;
            fields?: Array<{
              __typename: "Field";
              name: string;
              description?: string | null;
              path?: string | null;
              type: string;
              value?: any | null;
              updatedAt: string;
              numElements: number;
              nestedContent: boolean;
            } | null> | null;
            assessmentField?: {
              __typename: "Field";
              name: string;
              description?: string | null;
              path?: string | null;
              type: string;
              value?: any | null;
              updatedAt: string;
              numElements: number;
              nestedContent: boolean;
            } | null;
          }> | null;
        }> | null;
      } | null;
      exception?: {
        __typename: "AssetReportException";
        id: string;
        reviewStatus: ReviewStatus;
        action: ExceptionMutationAction;
        justification: string;
      } | null;
    };
  }>;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

export type AssetReportDataQueriesFieldsFragment = {
  __typename: "AssetReportQueryConnection";
  totalCount: number;
  edges: Array<{
    __typename: "AssetReportQueryEdge";
    cursor: string;
    node: {
      __typename: "AssetReportQuery";
      id: string;
      mqueryState: MqueryState;
      mqueryType: MqueryType;
      data?: any | null;
      action: PolicyReportScoreEntryAction;
      policies?: Array<string> | null;
      policyId: string;
      policyName: string;
      mquery: {
        __typename: "Mquery";
        uid: string;
        mrn: string;
        title: string;
        mql: string;
        parent?: string | null;
        action: PolicyReportScoreEntryAction;
        docs?: {
          __typename: "MqueryDocs";
          desc: string;
          audit: string;
          remediation: string;
          remediations?:
            | {
                __typename: "AuthenticationRequired";
                message: string;
                code: string;
              }
            | {
                __typename: "Remediations";
                entries: Array<{
                  __typename: "MqueryRemediation";
                  id: string;
                  desc: string;
                }>;
              }
            | null;
          references?: Array<{
            __typename: "MqueryRef";
            title: string;
            url: string;
          }> | null;
        } | null;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
        tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
        properties?: Array<{
          __typename: "MProperty";
          uid: string;
          mrn: string;
          title: string;
          mql: string;
          setByPolicyMrn?: string | null;
        }> | null;
        variants?: Array<{
          __typename: "Variant";
          id: string;
          title: string;
          icon: string;
        }> | null;
        variantQueries?: Array<{
          __typename: "MqueryVariant";
          uid: string;
          mrn: string;
          title: string;
          mql: string;
          filter: string;
          filterID: string;
        } | null> | null;
      };
      score: {
        __typename: "Score";
        id: string;
        value: number;
        type: number;
        completion: number;
        weight: number;
        message: string;
        grade: string;
      };
      assessment?: {
        __typename: "PolicyQueryAssessment";
        rendered: string;
        state: PolicyReportEntryState;
        needsUpgrade: boolean;
        results?: Array<{
          __typename: "PolicyQueryAssessmentItem";
          actual: string;
          expected: string;
          error: string;
        }> | null;
        extendedResults?: Array<{
          __typename: "PolicyQueryAssessmentResources";
          resourceName: string;
          resourceTitle: string;
          numResources: number;
          actual?: Array<{
            __typename: "Resource";
            name: string;
            title: string;
            type: string;
            path: string;
            numElements: number;
            nestedContent: boolean;
            fields?: Array<{
              __typename: "Field";
              name: string;
              description?: string | null;
              path?: string | null;
              type: string;
              value?: any | null;
              updatedAt: string;
              numElements: number;
              nestedContent: boolean;
            } | null> | null;
            assessmentField?: {
              __typename: "Field";
              name: string;
              description?: string | null;
              path?: string | null;
              type: string;
              value?: any | null;
              updatedAt: string;
              numElements: number;
              nestedContent: boolean;
            } | null;
          }> | null;
        }> | null;
      } | null;
    };
  }>;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

export type AssetReportListPoliciesFieldsFragment = {
  __typename: "AssetReportPolicyListConnection";
  totalCount: number;
  edges: Array<{
    __typename: "AssetReportPolicyListEdge";
    cursor: string;
    node: {
      __typename: "AssetReportPolicy";
      mrn: string;
      name: string;
      version: string;
      category: string;
      action: PolicyAction;
      scoreStats: {
        __typename: "ScoreStats";
        total: number;
        passed: number;
        failed: number;
        incomplete: number;
        errors: number;
        skipped: number;
        worst: number;
        disabled: number;
        snoozed: number;
        unknown: number;
      };
      score: {
        __typename: "Score";
        id: string;
        value: number;
        type: number;
        completion: number;
        weight: number;
        message: string;
        grade: string;
      };
    };
  }>;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

export type AssetSummariesEdgesFieldsFragment = {
  __typename: "VulnerabilityReportAssetSummariesConnection";
  totalCount: number;
  edges?: Array<{
    __typename: "VulnerabilityReportAssetSummaryEdge";
    cursor: string;
    node?: {
      __typename: "VulnerabilityReportAssetSummary";
      id: string;
      activeAdvisoryCount: number;
      fixedAdvisoryCount: number;
      activeCveCount: number;
      fixedCveCount: number;
      worstCvssScore?: {
        __typename: "CvssScore";
        id: string;
        value: number;
        type: number;
        vector: string;
        source: string;
      } | null;
      asset: {
        __typename: "Asset";
        id: string;
        mrn: string;
        state: AssetState;
        name?: string | null;
        updatedAt?: string | null;
        labels: Array<{
          __typename: "KeyValue";
          key: string;
          value?: string | null;
        }>;
        platform?: {
          __typename: "Platform";
          name: string;
          release: string;
          arch: string;
          kind: PlatformKind;
          runtime: string;
        } | null;
      };
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

export type BlastRadiusFieldsFragment = {
  __typename: "BlastRadius";
  indicator: string;
  assets: number;
  affected: number;
  critical: number;
  high: number;
  medium: number;
  low: number;
  none: number;
  snoozed: number;
  disabled: number;
};

export type CveFieldsFragment = {
  __typename: "CvesConnection";
  totalCount: number;
  edges?: Array<{
    __typename: "CveEdge";
    cursor: string;
    node?: {
      __typename: "Cve";
      id: string;
      state?: CveState | null;
      published?: string | null;
      title?: string | null;
      vulnerabilityRiskFactors: {
        __typename: "VulnerabilityRiskFactors";
        knownExploited: boolean;
        remoteExploitable: boolean;
      };
      epssScore?: { __typename: "EpssScore"; probability: number } | null;
      cvssScore: {
        __typename: "CvssScore";
        type: number;
        value: number;
        vector: string;
      };
      score: { __typename: "CvssScore"; value: number; type: number };
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type CveNodeFieldsFragment = {
  __typename: "Cve";
  id: string;
  state?: CveState | null;
  published?: string | null;
  title?: string | null;
  vulnerabilityRiskFactors: {
    __typename: "VulnerabilityRiskFactors";
    knownExploited: boolean;
    remoteExploitable: boolean;
  };
  epssScore?: { __typename: "EpssScore"; probability: number } | null;
  cvssScore: {
    __typename: "CvssScore";
    type: number;
    value: number;
    vector: string;
  };
  score: { __typename: "CvssScore"; value: number; type: number };
};

export type CveSummariesEdgesFieldsFragment = {
  __typename: "CveSummariesConnection";
  totalCount: number;
  edges?: Array<{
    __typename: "CveSummaryEdge";
    cursor: string;
    node?: {
      __typename: "CveSummary";
      id: string;
      discoveredAt?: string | null;
      spaceMrn: string;
      cve: {
        __typename: "Cve";
        id: string;
        mrn: string;
        title?: string | null;
        description?: string | null;
        summary?: string | null;
        publishedAt?: string | null;
        modifiedAt?: string | null;
        url?: string | null;
        state?: CveState | null;
        source: { __typename: "Source"; id: string; name: string; url: string };
        cvssScore: {
          __typename: "CvssScore";
          id: string;
          value: number;
          type: number;
          vector: string;
          source: string;
        };
      };
      assetSummaries?: {
        __typename: "VulnerabilityReportAssetSummariesConnection";
        totalCount: number;
        edges?: Array<{
          __typename: "VulnerabilityReportAssetSummaryEdge";
          cursor: string;
          node?: {
            __typename: "VulnerabilityReportAssetSummary";
            id: string;
            activeAdvisoryCount: number;
            fixedAdvisoryCount: number;
            activeCveCount: number;
            fixedCveCount: number;
            worstCvssScore?: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            } | null;
            asset: {
              __typename: "Asset";
              id: string;
              mrn: string;
              state: AssetState;
              name?: string | null;
              updatedAt?: string | null;
              labels: Array<{
                __typename: "KeyValue";
                key: string;
                value?: string | null;
              }>;
              platform?: {
                __typename: "Platform";
                name: string;
                release: string;
                arch: string;
                kind: PlatformKind;
                runtime: string;
              } | null;
            };
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      } | null;
      affectedAssetStats: {
        __typename: "AffectedAssetStats";
        affectedCount: number;
        fixedCount: number;
        totalCount: number;
      };
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

export type CveSummaryFieldsFragment = {
  __typename: "CveSummariesConnection";
  totalCount: number;
  stats?: {
    __typename: "CveSummariesConnectionStats";
    totalDatabaseCves: number;
    totalAssetCves: number;
  } | null;
  edges?: Array<{
    __typename: "CveSummaryEdge";
    cursor: string;
    node?: {
      __typename: "CveSummary";
      cve: {
        __typename: "Cve";
        id: string;
        state?: CveState | null;
        published?: string | null;
        title?: string | null;
        vulnerabilityRiskFactors: {
          __typename: "VulnerabilityRiskFactors";
          knownExploited: boolean;
          remoteExploitable: boolean;
        };
        epssScore?: { __typename: "EpssScore"; probability: number } | null;
        cvssScore: {
          __typename: "CvssScore";
          type: number;
          value: number;
          vector: string;
        };
        score: { __typename: "CvssScore"; value: number; type: number };
      };
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type CvssScoreFieldsFragment = {
  __typename: "CvssScore";
  id: string;
  value: number;
  type: number;
  vector: string;
  source: string;
};

export type MqueryDocsFieldsFragment = {
  __typename: "MqueryDocs";
  audit: string;
  desc: string;
  references?: Array<{
    __typename: "MqueryRef";
    title: string;
    url: string;
  }> | null;
  remediations?:
    | { __typename: "AuthenticationRequired"; message: string; code: string }
    | {
        __typename: "Remediations";
        entries: Array<{
          __typename: "MqueryRemediation";
          id: string;
          desc: string;
        }>;
      }
    | null;
};

export type PackageFieldsFragment = {
  __typename: "Package";
  id: string;
  name: string;
  version?: string | null;
  arch?: string | null;
  available?: string | null;
  status?: string | null;
  vulnDiscoveryTime?: string | null;
  iconIDEnum?: Icon_Ids | null;
  vulnerabilityRiskFactors: {
    __typename: "VulnerabilityRiskFactors";
    knownExploited: boolean;
    remoteExploitable: boolean;
  };
  cvssScore: {
    __typename: "CvssScore";
    type: number;
    value: number;
    vector: string;
  };
  epssScore?: {
    __typename: "EpssScore";
    percentile: number;
    probability: number;
  } | null;
};

export type PageInfoFieldsFragment = {
  __typename: "PageInfo";
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export type PlatformFieldsFragment = {
  __typename: "Platform";
  name: string;
  release: string;
  arch: string;
  kind: PlatformKind;
  runtime: string;
};

export type RemediationPackageFieldsFragment = {
  __typename: "Package";
  id: string;
  name: string;
  version?: string | null;
  namespace?: string | null;
  arch?: string | null;
};

export type RemediationScriptFieldsFragment = {
  __typename: "RemediationScript";
  type: RemediationScriptType;
  script: string;
  packageManager: PackageManager;
  platformName: string;
  platformVersion: string;
};

export type RiskDistributionFragment = {
  __typename: "SpaceStatistics";
  riskdistribution: {
    __typename: "RiskDistribution";
    total: number;
    U: number;
    A: number;
    B: number;
    C: number;
    D: number;
    F: number;
    Error: number;
  };
};

export type ServiceAccountFieldsFragment = {
  __typename: "ServiceAccountConnection";
  totalCount: number;
  edges?: Array<{
    __typename: "ServiceAccountEdge";
    cursor: string;
    node?: {
      __typename: "ServiceAccount";
      id: string;
      mrn: string;
      name: string;
      description?: string | null;
      createdAt: string;
      lastUsed?: string | null;
      notes?: string | null;
      roles: Array<{ __typename: "Role"; mrn: string; title: string }>;
      labels: Array<{
        __typename: "KeyValue";
        key: string;
        value?: string | null;
      }>;
      creator?: {
        __typename: "ServiceAccountCreator";
        mrn?: string | null;
        email?: string | null;
        service?: string | null;
      } | null;
    } | null;
  }> | null;
  pageInfo: {
    __typename: "PageInfo";
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type VulnerabilityRiskFactorsFieldsFragment = {
  __typename: "VulnerabilityRiskFactors";
  knownExploited: boolean;
  remoteExploitable: boolean;
};

export type AcceptInvitationMutationVariables = Exact<{
  invitationMrn: Scalars["ID"]["input"];
}>;

export type AcceptInvitationMutation = {
  __typename: "Mutation";
  acceptInvitation: boolean;
};

export type AcceptLegalPolicyMutationVariables = Exact<{
  input: AcceptLegalPolicy;
}>;

export type AcceptLegalPolicyMutation = {
  __typename: "Mutation";
  acceptLegalPolicy: boolean;
};

export type ActivateUserInRegionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ActivateUserInRegionMutation = {
  __typename: "Mutation";
  activateUserInRegion: {
    __typename: "ActivateUserInRegionPayload";
    user: { __typename: "User"; mrn: string };
  };
};

export type AddPolicyMutationVariables = Exact<{
  input: Array<PolicyAddInput> | PolicyAddInput;
}>;

export type AddPolicyMutation = { __typename: "Mutation"; addPolicy: boolean };

export type AddQueryPackMutationVariables = Exact<{
  input: Array<QueryPackAddInput> | QueryPackAddInput;
}>;

export type AddQueryPackMutation = {
  __typename: "Mutation";
  addQueryPack: boolean;
};

export type ApplyExceptionMutationVariables = Exact<{
  input: ExceptionMutationInput;
}>;

export type ApplyExceptionMutation = {
  __typename: "Mutation";
  applyException: boolean;
};

export type ApplyExceptionForScopesMutationVariables = Exact<{
  input: ApplyExceptionForScopesInput;
}>;

export type ApplyExceptionForScopesMutation = {
  __typename: "Mutation";
  applyExceptionForScopes: boolean;
};

export type ApplyExceptionReviewMutationVariables = Exact<{
  input: ExceptionReviewInput;
}>;

export type ApplyExceptionReviewMutation = {
  __typename: "Mutation";
  applyExceptionReview: boolean;
};

export type ApplyFrameworkMutationVariables = Exact<{
  input: ComplianceFrameworkMutationInput;
}>;

export type ApplyFrameworkMutation = {
  __typename: "Mutation";
  applyFrameworkMutation: boolean;
};

export type ApplySpacePlanMutationVariables = Exact<{
  input: ApplySpacePlanInput;
}>;

export type ApplySpacePlanMutation = {
  __typename: "Mutation";
  applySpacePlan:
    | { __typename: "ApplySpacePlanError"; code: string; message: string }
    | { __typename: "ApplySpacePlanSuccess"; success: boolean };
};

export type AssignPolicyMutationVariables = Exact<{
  input: PolicyAssignmentInput;
}>;

export type AssignPolicyMutation = {
  __typename: "Mutation";
  assignPolicy: boolean;
};

export type CancelInvitationMutationVariables = Exact<{
  invitationMrn: Scalars["ID"]["input"];
}>;

export type CancelInvitationMutation = {
  __typename: "Mutation";
  cancelInvitation: boolean;
};

export type ChangeSubscriptionPlanMutationVariables = Exact<{
  input: ChangeSubscriptionPlanInput;
}>;

export type ChangeSubscriptionPlanMutation = {
  __typename: "Mutation";
  changeSubscriptionPlan:
    | { __typename: "BillingSession"; url: string }
    | { __typename: "ChangeSubscriptionSuccess" }
    | {
        __typename: "PaymentPreview";
        currency: string;
        recurringCost: number;
        dueToday: number;
        prorationDate: number;
        effectiveDate: string;
      };
};

export type CreateBillingSessionMutationVariables = Exact<{
  input: BillingSessionInput;
}>;

export type CreateBillingSessionMutation = {
  __typename: "Mutation";
  createBillingSession: { __typename: "BillingSession"; url: string };
};

export type CreateCaseMutationVariables = Exact<{
  input: CreateCaseInput;
}>;

export type CreateCaseMutation = {
  __typename: "Mutation";
  createCase: {
    __typename: "Case";
    mrn: string;
    ticketRefs?: Array<{
      __typename: "TicketRef";
      ticketId?: string | null;
    }> | null;
  };
};

export type CreateClientIntegrationMutationVariables = Exact<{
  input: CreateClientIntegrationInput;
}>;

export type CreateClientIntegrationMutation = {
  __typename: "Mutation";
  createClientIntegration: {
    __typename: "CreateClientIntegrationPayload";
    integration: {
      __typename: "ClientIntegration";
      mrn: string;
      token?: string | null;
      name: string;
      status: string;
      created: string;
      lastCheckin?: string | null;
      type: ClientIntegrationType;
      messages?: Array<{
        __typename: "IntegrationMessage";
        message: string;
        status: IntegrationMessageStatus;
      }> | null;
      configurationOptions?:
        | {
            __typename: "AWSConfigurationOptions";
            region: string;
            isOrganization?: boolean | null;
            snsEndpoint: string;
            originAWSAccountId: string;
            cloudFormationTemplateUrl: string;
            scanConfiguration: {
              __typename: "ScanConfiguration";
              accountScan: boolean;
              ec2Scan: boolean;
              ecrScan: boolean;
              ecsScan: boolean;
              cronScaninHours: number;
              eventScanTriggers?: Array<{
                __typename: "AWSEventPattern";
                scanType: string;
                eventSource: string;
                eventDetailType: string;
              } | null> | null;
              ec2ScanOptions: {
                __typename: "Ec2ScanOptions";
                ebsVolumeScan: boolean;
                instanceConnect: boolean;
                instanceIdsFilter?: Array<string> | null;
                regionsFilter?: Array<string> | null;
                ssm: boolean;
                tagsFilter: any;
              };
              vpcConfiguration?: {
                __typename: "VPCConfiguration";
                CIDR?: string | null;
                useDefaultVPC?: boolean | null;
                useMondooVPC?: boolean | null;
              } | null;
            };
          }
        | {
            __typename: "AgentOptions";
            id: string;
            version?: string | null;
            notes: string;
            hostname: string;
            ipaddress: string;
            platform?: {
              __typename: "Platform";
              name: string;
              release: string;
              arch: string;
              kind: PlatformKind;
              runtime: string;
            } | null;
          }
        | { __typename: "AwsS3ConfigurationOptions" }
        | { __typename: "AzureBlobConfigurationOptions" }
        | {
            __typename: "AzureConfigurationOptions";
            tenantId: string;
            clientId: string;
            subscriptionsWhitelist?: Array<string> | null;
            subscriptionsBlacklist?: Array<string> | null;
            scanVms?: boolean | null;
          }
        | { __typename: "BigqueryConfigurationOptions" }
        | {
            __typename: "EmailConfigurationOptions";
            autoCreateTickets: boolean;
            recipients: Array<{
              __typename: "EmailRecipient";
              name: string;
              email: string;
              isDefault: boolean;
              referenceUrl?: string | null;
            }>;
          }
        | {
            __typename: "GcpConfigurationOptions";
            projectId?: string | null;
            organizationId?: string | null;
            discoverAll?: boolean | null;
          }
        | {
            __typename: "GcsBucketConfigurationOptions";
            output: BucketOutputType;
            bucket: string;
          }
        | {
            __typename: "GithubConfigurationOptions";
            owner?: string | null;
            repository?: string | null;
            reposAllowList?: Array<string> | null;
            reposDenyList?: Array<string> | null;
            enterpriseUrl?: string | null;
            githubOrg?: string | null;
            githubType: GithubIntegrationType;
            githubDiscoverK8sManifests: boolean;
            githubDiscoverTerraform: boolean;
          }
        | { __typename: "GithubTicketingConfigurationOptions" }
        | {
            __typename: "GitlabConfigurationOptions";
            group: string;
            type: GitlabIntegrationType;
            discoverGroups?: boolean | null;
            discoverProjects?: boolean | null;
            discoverTerraform?: boolean | null;
            discoverK8sManifests: boolean;
            baseUrl: string;
          }
        | { __typename: "GitlabTicketingConfigurationOptions" }
        | {
            __typename: "GoogleWorkspaceConfigurationOptions";
            customerId: string;
            impersonatedUserEmail: string;
          }
        | { __typename: "HostConfigurationOptions" }
        | {
            __typename: "HostedAwsConfigurationOptions";
            accessKeyId?: string | null;
            role?: string | null;
          }
        | { __typename: "JiraConfigurationOptions" }
        | {
            __typename: "K8sConfigurationOptions";
            scanNodes?: boolean | null;
            scanNodesStyle: K8sScanNodesStyle;
            scanWorkloads?: boolean | null;
            scanDeploys?: boolean | null;
            certificateManager: string;
            scanPublicImages?: boolean | null;
            namespaceAllowList?: Array<string> | null;
            namespaceDenyList?: Array<string> | null;
          }
        | {
            __typename: "MicrosoftDefenderConfigurationOptions";
            tenantId: string;
            clientId: string;
            subscriptionsAllowlist?: Array<string> | null;
            subscriptionsDenylist?: Array<string> | null;
            secretType?: string | null;
          }
        | {
            __typename: "Ms365ConfigurationOptions";
            tenantId: string;
            clientId: string;
          }
        | {
            __typename: "OciConfigurationOptions";
            tenancyOcid: string;
            userOcid: string;
            region: string;
            fingerprint: string;
          }
        | { __typename: "OktaConfigurationOptions"; organization: string }
        | {
            __typename: "PostgresConfigurationOptions";
            host: string;
            port: string;
            database: string;
            user: string;
          }
        | { __typename: "S3ConfigurationOptions" }
        | { __typename: "SlackConfigurationOptions"; placeholder: string }
        | { __typename: "SnowflakeConfigurationOptions" }
        | {
            __typename: "ZendeskConfigurationOptions";
            subdomain: string;
            email: string;
            autoCloseTickets: boolean;
            autoCreateTickets: boolean;
          }
        | null;
    };
  };
};

export type CreateInvitationMutationVariables = Exact<{
  input: CreateInvitationInput;
}>;

export type CreateInvitationMutation = {
  __typename: "Mutation";
  createInvitation: { __typename: "Invitation"; mrn: string };
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename: "Mutation";
  createOrganization?: {
    __typename: "Organization";
    id: string;
    mrn: string;
  } | null;
};

export type CreateServiceAccountMutationVariables = Exact<{
  input?: InputMaybe<CreateServiceAccountInput>;
}>;

export type CreateServiceAccountMutation = {
  __typename: "Mutation";
  createServiceAccount?: {
    __typename: "ServiceAccountCredential";
    mrn: string;
    certificate: string;
    privateKey: string;
    scopeMrn: string;
    apiEndpoint: string;
    spaceMrn?: string | null;
  } | null;
};

export type CreateSpaceMutationVariables = Exact<{
  input: CreateSpaceInput;
}>;

export type CreateSpaceMutation = {
  __typename: "Mutation";
  createSpace?: { __typename: "Space"; id: string; mrn: string } | null;
};

export type DeclineInvitationMutationVariables = Exact<{
  invitationMrn: Scalars["ID"]["input"];
}>;

export type DeclineInvitationMutation = {
  __typename: "Mutation";
  declineInvitation: boolean;
};

export type DeleteAgentsMutationVariables = Exact<{
  input: DeleteAgentsInput;
}>;

export type DeleteAgentsMutation = {
  __typename: "Mutation";
  deleteAgents: {
    __typename: "DeleteAgentsPayload";
    agentMrns: Array<string>;
    errors: any;
  };
};

export type DeleteApiTokenMutationVariables = Exact<{
  input: DeleteApiTokenInput;
}>;

export type DeleteApiTokenMutation = {
  __typename: "Mutation";
  deleteAPIToken?: { __typename: "DeleteAPITokenResponse"; ok: boolean } | null;
};

export type DeleteAssetsMutationVariables = Exact<{
  input: DeleteAssetsInput;
}>;

export type DeleteAssetsMutation = {
  __typename: "Mutation";
  deleteAssets?: {
    __typename: "DeleteAssetsPayload";
    assetMrns?: Array<string> | null;
    errors: any;
  } | null;
};

export type DeleteCicdProjectsMutationVariables = Exact<{
  input: DeleteProjectsInput;
}>;

export type DeleteCicdProjectsMutation = {
  __typename: "Mutation";
  deleteCicdProjects: {
    __typename: "DeleteProjectsPayload";
    mrns: Array<string>;
  };
};

export type DeleteClientIntegrationMutationVariables = Exact<{
  input: DeleteClientIntegrationInput;
}>;

export type DeleteClientIntegrationMutation = {
  __typename: "Mutation";
  deleteClientIntegration: {
    __typename: "DeleteClientIntegrationPayload";
    mrn: string;
  };
};

export type DeleteDocumentMutationVariables = Exact<{
  documentMrn: Scalars["String"]["input"];
}>;

export type DeleteDocumentMutation = {
  __typename: "Mutation";
  deleteDocument: boolean;
};

export type DeleteFrameworkMutationVariables = Exact<{
  input: DeleteFrameworkInput;
}>;

export type DeleteFrameworkMutation = {
  __typename: "Mutation";
  deleteFramework: boolean;
};

export type DeleteOrganizationMutationVariables = Exact<{
  orgMrn: Scalars["ID"]["input"];
}>;

export type DeleteOrganizationMutation = {
  __typename: "Mutation";
  deleteOrganization?: string | null;
};

export type DeletePolicyMutationVariables = Exact<{
  input: PolicyDeleteInput;
}>;

export type DeletePolicyMutation = {
  __typename: "Mutation";
  deletePolicy: boolean;
};

export type DeleteServiceAccountsMutationVariables = Exact<{
  input: DeleteServiceAccountsInput;
}>;

export type DeleteServiceAccountsMutation = {
  __typename: "Mutation";
  deleteServiceAccounts: {
    __typename: "DeleteServiceAccountsPayload";
    mrns: Array<string>;
    errors: any;
  };
};

export type DeleteSpaceMutationVariables = Exact<{
  spaceMrn: Scalars["ID"]["input"];
}>;

export type DeleteSpaceMutation = {
  __typename: "Mutation";
  deleteSpace?: string | null;
};

export type EditPropertiesMutationVariables = Exact<{
  input: PropertyQueryMutationInput;
}>;

export type EditPropertiesMutation = {
  __typename: "Mutation";
  applyPropertyQueryMutation:
    | {
        __typename: "PropertyQueryMutationInvalidProperty";
        message: string;
        code: string;
      }
    | {
        __typename: "PropertyQueryMutationInvalidTarget";
        message: string;
        code: string;
      }
    | { __typename: "PropertyQueryMutationSuccess"; success?: boolean | null };
};

export type EnableSsoProviderMutationVariables = Exact<{
  input: EnableSsoProviderInput;
}>;

export type EnableSsoProviderMutation = {
  __typename: "Mutation";
  enableSSOProvider: boolean;
};

export type GenerateApiTokenMutationVariables = Exact<{
  input: GenerateApiTokenInput;
}>;

export type GenerateApiTokenMutation = {
  __typename: "Mutation";
  generateAPIToken: {
    __typename: "GenerateAPITokenResponse";
    token: string;
    spaceMrn?: string | null;
    scopeMrn: string;
    name: string;
    mrn: string;
    description?: string | null;
    createdBy: string;
    createdAt: string;
  };
};

export type GenerateDocumentMutationVariables = Exact<{
  input: GenerateDocumentInput;
}>;

export type GenerateDocumentMutation = {
  __typename: "Mutation";
  generateDocument: { __typename: "GenerateDocumentPayload"; mrn: string };
};

export type GenerateRegistrationTokenMutationVariables = Exact<{
  input: RegistrationTokenInput;
}>;

export type GenerateRegistrationTokenMutation = {
  __typename: "Mutation";
  generateRegistrationToken: {
    __typename: "RegistrationToken";
    id: string;
    mrn: string;
    description: string;
    token: string;
    revoked: boolean;
    createdAt: string;
    createdBy: string;
    expiresAt: string;
    labels: Array<{
      __typename: "KeyValue";
      key: string;
      value?: string | null;
    }>;
  };
};

export type ModifySpaceRiskFactorsMutationVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  riskFactors: Array<ModifyRiskFactorInput> | ModifyRiskFactorInput;
}>;

export type ModifySpaceRiskFactorsMutation = {
  __typename: "Mutation";
  modifyRiskFactors: boolean;
};

export type ProcessCaseEventsMutationVariables = Exact<{
  input: ProcessCaseEventsInput;
}>;

export type ProcessCaseEventsMutation = {
  __typename: "Mutation";
  processCaseEvents: boolean;
};

export type RefreshAggregateScoresMutationVariables = Exact<{
  groupMrn: Scalars["String"]["input"];
}>;

export type RefreshAggregateScoresMutation = {
  __typename: "Mutation";
  refreshAggregateScores: boolean;
};

export type RemoveOrganizationMembershipMutationVariables = Exact<{
  input: RemoveOrganizationMembershipInput;
}>;

export type RemoveOrganizationMembershipMutation = {
  __typename: "Mutation";
  removeOrganizationMembership: boolean;
};

export type RemoveSpaceMembershipMutationVariables = Exact<{
  input: RemoveSpaceMembershipInput;
}>;

export type RemoveSpaceMembershipMutation = {
  __typename: "Mutation";
  removeSpaceMembership: boolean;
};

export type ReportViewedMutationVariables = Exact<{
  input: ReportViewedInput;
}>;

export type ReportViewedMutation = {
  __typename: "Mutation";
  reportViewed: boolean;
};

export type RequestDocumentDownloadUrlMutationVariables = Exact<{
  documentMrn: Scalars["String"]["input"];
}>;

export type RequestDocumentDownloadUrlMutation = {
  __typename: "Mutation";
  requestDocumentDownloadUrl: {
    __typename: "DocumentDownloadPayload";
    downloadUrl: string;
    expiresAt: string;
  };
};

export type ResendInvitationMutationVariables = Exact<{
  invitationMrn: Scalars["ID"]["input"];
}>;

export type ResendInvitationMutation = {
  __typename: "Mutation";
  resendInvitation: boolean;
};

export type RevokeRegistrationTokenMutationVariables = Exact<{
  input: RevokeRegistrationTokenInput;
}>;

export type RevokeRegistrationTokenMutation = {
  __typename: "Mutation";
  revokeRegistrationToken?:
    | {
        __typename: "RevokeRegistrationTokenFailure";
        message: string;
        code: string;
      }
    | { __typename: "RevokeRegistrationTokenSuccess"; ok: boolean }
    | null;
};

export type SetAssetAnnotationsMutationVariables = Exact<{
  input: SetAssetAnnotationsInput;
}>;

export type SetAssetAnnotationsMutation = {
  __typename: "Mutation";
  setAssetAnnotations?: {
    __typename: "SetAssetAnnotationsPayload";
    annotations: Array<{
      __typename: "KeyValue";
      key: string;
      value?: string | null;
    }>;
  } | null;
};

export type SetEmailPreferenceMutationVariables = Exact<{
  input: Array<EmailPreferenceInput> | EmailPreferenceInput;
}>;

export type SetEmailPreferenceMutation = {
  __typename: "Mutation";
  setEmailPreference: boolean;
};

export type SetIntegrationMutationVariables = Exact<{
  input: IntegrationInput;
}>;

export type SetIntegrationMutation = {
  __typename: "Mutation";
  setIntegration: boolean;
};

export type SetOrganizationMembershipMutationVariables = Exact<{
  input: SetOrganizationMembershipInput;
}>;

export type SetOrganizationMembershipMutation = {
  __typename: "Mutation";
  setOrganizationMembership: boolean;
};

export type SetRolesMutationVariables = Exact<{
  input: SetRolesInput;
}>;

export type SetRolesMutation = {
  __typename: "Mutation";
  setRoles?: {
    __typename: "SetRolesPayload";
    mrns?: Array<string> | null;
    errors: any;
  } | null;
};

export type SetSsoProviderMutationVariables = Exact<{
  input: SetSsoProviderInput;
}>;

export type SetSsoProviderMutation = {
  __typename: "Mutation";
  setSSOProvider: boolean;
};

export type SetSpaceMembershipMutationVariables = Exact<{
  input: SetSpaceMembershipInput;
}>;

export type SetSpaceMembershipMutation = {
  __typename: "Mutation";
  setSpaceMembership: boolean;
};

export type SetViewerSettingMutationVariables = Exact<{
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}>;

export type SetViewerSettingMutation = {
  __typename: "Mutation";
  changeViewerSetting: boolean;
};

export type SuppressIntegrationMessageMutationVariables = Exact<{
  input: SuppressIntegrationMessageInput;
}>;

export type SuppressIntegrationMessageMutation = {
  __typename: "Mutation";
  suppressIntegrationMessage: {
    __typename: "SuppressIntegrationMessagePayload";
    mrn: string;
  };
};

export type UnassignPolicyMutationVariables = Exact<{
  input: PolicyAssignmentInput;
}>;

export type UnassignPolicyMutation = {
  __typename: "Mutation";
  unassignPolicy: boolean;
};

export type UpdateApiTokenMutationVariables = Exact<{
  input: UpdateApiTokenInput;
}>;

export type UpdateApiTokenMutation = {
  __typename: "Mutation";
  updateAPIToken?: {
    __typename: "APIToken";
    mrn: string;
    spaceMrn: string;
    name: string;
    description?: string | null;
    createdAt: string;
    lastUsed?: string | null;
    roles: Array<{ __typename: "Role"; mrn: string; title: string }>;
    creator?: {
      __typename: "APITokenCreator";
      mrn?: string | null;
      email?: string | null;
    } | null;
  } | null;
};

export type CloseCaseMutationVariables = Exact<{
  input: CloseCaseInput;
}>;

export type CloseCaseMutation = { __typename: "Mutation"; closeCase: boolean };

export type UpdateClientIntegrationConfigurationMutationVariables = Exact<{
  input: UpdateClientIntegrationConfigurationInput;
}>;

export type UpdateClientIntegrationConfigurationMutation = {
  __typename: "Mutation";
  updateClientIntegrationConfiguration: {
    __typename: "UpdateClientIntegrationConfigurationPayload";
    configurationOptions:
      | {
          __typename: "AWSConfigurationOptions";
          region: string;
          isOrganization?: boolean | null;
          snsEndpoint: string;
          scanConfiguration: {
            __typename: "ScanConfiguration";
            accountScan: boolean;
            ec2Scan: boolean;
            ecrScan: boolean;
            ecsScan: boolean;
            cronScaninHours: number;
            eventScanTriggers?: Array<{
              __typename: "AWSEventPattern";
              eventDetailType: string;
              eventSource: string;
              scanType: string;
            } | null> | null;
            ec2ScanOptions: {
              __typename: "Ec2ScanOptions";
              ssm: boolean;
              instanceConnect: boolean;
              instanceIdsFilter?: Array<string> | null;
              regionsFilter?: Array<string> | null;
              tagsFilter: any;
              ebsVolumeScan: boolean;
            };
            vpcConfiguration?: {
              __typename: "VPCConfiguration";
              CIDR?: string | null;
              useDefaultVPC?: boolean | null;
              useMondooVPC?: boolean | null;
            } | null;
          };
        }
      | {
          __typename: "AgentOptions";
          id: string;
          version?: string | null;
          notes: string;
          hostname: string;
          ipaddress: string;
          platform?: {
            __typename: "Platform";
            name: string;
            release: string;
            arch: string;
            kind: PlatformKind;
            runtime: string;
          } | null;
        }
      | { __typename: "AwsS3ConfigurationOptions" }
      | { __typename: "AzureBlobConfigurationOptions" }
      | {
          __typename: "AzureConfigurationOptions";
          tenantId: string;
          clientId: string;
          subscriptionsWhitelist?: Array<string> | null;
          subscriptionsBlacklist?: Array<string> | null;
          scanVms?: boolean | null;
        }
      | { __typename: "BigqueryConfigurationOptions" }
      | { __typename: "EmailConfigurationOptions" }
      | {
          __typename: "GcpConfigurationOptions";
          projectId?: string | null;
          organizationId?: string | null;
          discoverAll?: boolean | null;
        }
      | {
          __typename: "GcsBucketConfigurationOptions";
          output: BucketOutputType;
          bucket: string;
        }
      | {
          __typename: "GithubConfigurationOptions";
          owner?: string | null;
          repository?: string | null;
          reposAllowList?: Array<string> | null;
          reposDenyList?: Array<string> | null;
          enterpriseUrl?: string | null;
          githubOrg?: string | null;
          githubType: GithubIntegrationType;
          githubDiscoverK8sManifests: boolean;
          githubDiscoverTerraform: boolean;
        }
      | { __typename: "GithubTicketingConfigurationOptions" }
      | {
          __typename: "GitlabConfigurationOptions";
          group: string;
          type: GitlabIntegrationType;
          discoverGroups?: boolean | null;
          discoverProjects?: boolean | null;
          discoverTerraform?: boolean | null;
          discoverK8sManifests: boolean;
          baseUrl: string;
        }
      | { __typename: "GitlabTicketingConfigurationOptions" }
      | {
          __typename: "GoogleWorkspaceConfigurationOptions";
          customerId: string;
          impersonatedUserEmail: string;
        }
      | {
          __typename: "HostConfigurationOptions";
          host: string;
          https?: boolean | null;
          http?: boolean | null;
        }
      | {
          __typename: "HostedAwsConfigurationOptions";
          accessKeyId?: string | null;
          role?: string | null;
        }
      | { __typename: "JiraConfigurationOptions" }
      | {
          __typename: "K8sConfigurationOptions";
          scanNodes?: boolean | null;
          scanNodesStyle: K8sScanNodesStyle;
          scanWorkloads?: boolean | null;
          scanDeploys?: boolean | null;
          certificateManager: string;
          scanPublicImages?: boolean | null;
          namespaceAllowList?: Array<string> | null;
          namespaceDenyList?: Array<string> | null;
        }
      | {
          __typename: "MicrosoftDefenderConfigurationOptions";
          tenantId: string;
          clientId: string;
          subscriptionsAllowlist?: Array<string> | null;
          subscriptionsDenylist?: Array<string> | null;
          secretType?: string | null;
        }
      | {
          __typename: "Ms365ConfigurationOptions";
          tenantId: string;
          clientId: string;
        }
      | {
          __typename: "OciConfigurationOptions";
          tenancyOcid: string;
          userOcid: string;
          region: string;
          fingerprint: string;
        }
      | { __typename: "OktaConfigurationOptions"; organization: string }
      | { __typename: "PostgresConfigurationOptions" }
      | { __typename: "S3ConfigurationOptions" }
      | { __typename: "SlackConfigurationOptions" }
      | { __typename: "SnowflakeConfigurationOptions" }
      | { __typename: "ZendeskConfigurationOptions" };
  };
};

export type UpdateOrganizationNameMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationNameMutation = {
  __typename: "Mutation";
  updateOrganization: {
    __typename: "UpdateOrganizationPayload";
    organization: { __typename: "Organization"; name: string; mrn: string };
  };
};

export type UpdateSpaceMutationVariables = Exact<{
  input: UpdateSpaceInput;
}>;

export type UpdateSpaceMutation = {
  __typename: "Mutation";
  updateSpace: {
    __typename: "UpdateSpacePayload";
    space: {
      __typename: "Space";
      name: string;
      mrn: string;
      settings?: {
        __typename: "SpaceSettings";
        eolAssetsConfiguration: {
          __typename: "EolAssetsConfiguration";
          enable: boolean;
          monthsInAdvance: number;
        };
        garbageCollectAssetsConfiguration: {
          __typename: "GarbageCollectAssetsConfiguration";
          enable: boolean;
          afterDays: number;
        };
        terminatedAssetsConfiguration: {
          __typename: "TerminatedAssetsConfiguration";
          cleanup: boolean;
          after: number;
        };
        unusedServiceAccountsConfiguration: {
          __typename: "UnusedServiceAccountsConfiguration";
          cleanup: boolean;
          after: number;
        };
        platformVulnerabilityConfiguration: {
          __typename: "PlatformVulnerabilityConfiguration";
          enable: boolean;
        };
        casesConfiguration: {
          __typename: "CasesConfiguration";
          autoCreate: boolean;
          aggregationWindow: number;
        };
      } | null;
    };
  };
};

export type UpdateUserNameMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserNameMutation = {
  __typename: "Mutation";
  updateUser: {
    __typename: "UpdateUserPayload";
    user: { __typename: "User"; name: string; mrn: string };
  };
};

export type UploadFrameworkMutationVariables = Exact<{
  input: UploadFrameworkInput;
}>;

export type UploadFrameworkMutation = {
  __typename: "Mutation";
  uploadFramework: boolean;
};

export type AdvisoryForwardPaginationQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AdvisoryForwardPaginationQuery = {
  __typename: "Query";
  advisories?: {
    __typename: "AdvisoriesConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "AdvisoryEdge";
      cursor: string;
      node?: {
        __typename: "Advisory";
        id: string;
        title?: string | null;
        publishedAt?: string | null;
        cvssScore: {
          __typename: "CvssScore";
          id: string;
          source: string;
          type: number;
          value: number;
          vector: string;
        };
        vulnerabilityRiskFactors: {
          __typename: "VulnerabilityRiskFactors";
          knownExploited: boolean;
          remoteExploitable: boolean;
        };
        cves?: Array<{
          __typename: "Cve";
          id: string;
          vulnerabilityRiskFactors: {
            __typename: "VulnerabilityRiskFactors";
            knownExploited: boolean;
            remoteExploitable: boolean;
          };
        }> | null;
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
  } | null;
};

export type AgentForwardPaginationQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  queryTerms?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  version?: InputMaybe<Array<AgentVersionFilter> | AgentVersionFilter>;
  state?: InputMaybe<Array<AgentState> | AgentState>;
  orderBy?: InputMaybe<AgentOrder>;
}>;

export type AgentForwardPaginationQuery = {
  __typename: "Query";
  agents?: {
    __typename: "AgentsConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "AgentEdge";
      cursor: string;
      node?: {
        __typename: "Agent";
        id: string;
        mrn: string;
        name: string;
        hostname: string;
        ipaddress: string;
        createdAt: string;
        status?: {
          __typename: "AgentStatus";
          version?: string | null;
          state?: AgentState | null;
          lastCheckin?: string | null;
        } | null;
        labels: Array<{
          __typename: "KeyValue";
          key: string;
          value?: string | null;
        }>;
        platform?: {
          __typename: "Platform";
          name: string;
          release: string;
          arch: string;
        } | null;
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
  } | null;
};

export type AssetForwardPaginationQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  queryTerms?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  platformTitle?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  platformName?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  platformKind?: InputMaybe<Array<PlatformKind> | PlatformKind>;
  platformRuntime?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  scoreRange?: InputMaybe<Array<ScoreRange> | ScoreRange>;
  scoreType: ScoreType;
  labels?: InputMaybe<Array<KeyValueInput> | KeyValueInput>;
  updated?: InputMaybe<AssetUpdateFilter>;
  eol?: InputMaybe<AssetEolFilter>;
  reboot?: InputMaybe<AssetOsRebootFilter>;
  exploitable?: InputMaybe<AssetExploitableFilter>;
  orderBy?: InputMaybe<AssetOrder>;
  groups?: InputMaybe<Array<GroupFilter> | GroupFilter>;
  assetTypes?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  relatedToAssetMrn?: InputMaybe<Scalars["String"]["input"]>;
  assetUrlFilter?: InputMaybe<
    Array<AssetUrlSegmentInput> | AssetUrlSegmentInput
  >;
}>;

export type AssetForwardPaginationQuery = {
  __typename: "Query";
  assets?: {
    __typename: "AssetsConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "AssetEdge";
      cursor: string;
      node?: {
        __typename: "Asset";
        id: string;
        mrn: string;
        name?: string | null;
        state: AssetState;
        updatedAt?: string | null;
        score: {
          __typename: "Score";
          type: number;
          value: number;
          completion: number;
        };
        labels: Array<{
          __typename: "KeyValue";
          key: string;
          value?: string | null;
        }>;
        platform?: {
          __typename: "Platform";
          name: string;
          title: string;
          release: string;
          arch: string;
          kind: PlatformKind;
          runtime: string;
        } | null;
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
  } | null;
};

export type AssetResourcesQueryVariables = Exact<{
  input: AssetResourceInput;
}>;

export type AssetResourcesQuery = {
  __typename: "Query";
  assetResources: {
    __typename: "AssetResourceResponse";
    selectedNames?: Array<string> | null;
    selectedPaths?: Array<string> | null;
    resources: Array<{
      __typename: "Resource";
      name: string;
      type: string;
      path: string;
      numElements: number;
      nestedContent: boolean;
      fields?: Array<{
        __typename: "Field";
        name: string;
        description?: string | null;
        path?: string | null;
        type: string;
        value?: any | null;
        updatedAt: string;
        numElements: number;
        nestedContent: boolean;
      } | null> | null;
    }>;
  };
};

export type AssetSearchSuggestionsQueryVariables = Exact<{
  input: AssetSearchSuggestionsInput;
}>;

export type AssetSearchSuggestionsQuery = {
  __typename: "Query";
  assetSearchSuggestions: {
    __typename: "AssetSearchSuggestions";
    suggestions: Array<{ __typename: "Suggestion"; value: string }>;
  };
};

export type AssetSoftwareDetailsQueryVariables = Exact<{
  input: AssetPackageDetailsInput;
}>;

export type AssetSoftwareDetailsQuery = {
  __typename: "Query";
  assetPackageDetails?:
    | {
        __typename: "AssetPackageDetails";
        assetMrn: string;
        name: string;
        version: string;
        createdAt?: string | null;
        updatedAt: string;
        description?: string | null;
        available?: string | null;
        references?: Array<string | null> | null;
        iconIDEnum?: Icon_Ids | null;
        asset?: { __typename: "EntityInfoAsset"; name: string } | null;
        vulnerabilityRiskFactors: {
          __typename: "VulnerabilityRiskFactors";
          knownExploited: boolean;
          remoteExploitable: boolean;
        };
        cvssScore: {
          __typename: "CvssScore";
          id: string;
          value: number;
          type: number;
          vector: string;
          source: string;
        };
        epssScore?: {
          __typename: "EpssScore";
          percentile: number;
          probability: number;
        } | null;
        cveSummaries?: {
          __typename: "CveSummariesConnection";
          totalCount: number;
          stats?: {
            __typename: "CveSummariesConnectionStats";
            totalDatabaseCves: number;
            totalAssetCves: number;
          } | null;
          edges?: Array<{
            __typename: "CveSummaryEdge";
            cursor: string;
            node?: {
              __typename: "CveSummary";
              cve: {
                __typename: "Cve";
                id: string;
                state?: CveState | null;
                published?: string | null;
                title?: string | null;
                vulnerabilityRiskFactors: {
                  __typename: "VulnerabilityRiskFactors";
                  knownExploited: boolean;
                  remoteExploitable: boolean;
                };
                epssScore?: {
                  __typename: "EpssScore";
                  probability: number;
                } | null;
                cvssScore: {
                  __typename: "CvssScore";
                  type: number;
                  value: number;
                  vector: string;
                };
                score: { __typename: "CvssScore"; value: number; type: number };
              };
            } | null;
          }> | null;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
          };
        } | null;
        advisorySummaries?: {
          __typename: "AdvisorySummariesConnection";
          totalCount: number;
          stats?: {
            __typename: "AdvisorySummariesConnectionStats";
            totalDatabaseAdvisories: number;
            totalAssetAdvisories: number;
          } | null;
          edges?: Array<{
            __typename: "AdvisorySummaryEdge";
            cursor: string;
            node?: {
              __typename: "AdvisorySummary";
              advisory: {
                __typename: "Advisory";
                id: string;
                title?: string | null;
                publishedAt?: string | null;
                cvssScore: {
                  __typename: "CvssScore";
                  id: string;
                  source: string;
                  type: number;
                  value: number;
                  vector: string;
                };
                vulnerabilityRiskFactors: {
                  __typename: "VulnerabilityRiskFactors";
                  knownExploited: boolean;
                  remoteExploitable: boolean;
                };
                cves?: Array<{
                  __typename: "Cve";
                  id: string;
                  vulnerabilityRiskFactors: {
                    __typename: "VulnerabilityRiskFactors";
                    knownExploited: boolean;
                    remoteExploitable: boolean;
                  };
                }> | null;
              };
            } | null;
          }> | null;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
          };
        } | null;
      }
    | { __typename: "NotFoundError"; code: string; message: string }
    | null;
};

export type AuditLogForwardPaginationQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<AuditLogOrder>;
  resourceMrn: Scalars["String"]["input"];
}>;

export type AuditLogForwardPaginationQuery = {
  __typename: "Query";
  auditlog?: {
    __typename: "AuditLogConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "AuditLogEdge";
      cursor: string;
      node?: {
        __typename: "AuditLogEntry";
        resource: string;
        action: string;
        timestamp: string;
        msg?: string | null;
        identity:
          | { __typename: "ServiceAccount"; name: string; mrn: string }
          | { __typename: "User"; name: string; mrn: string };
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
  } | null;
};

export type DownloadBundleQueryVariables = Exact<{
  input: DownloadBundleInput;
}>;

export type DownloadBundleQuery = {
  __typename: "Query";
  downloadBundle: { __typename: "PolicyBundleYaml"; yaml: string };
};

export type DownloadFrameworkQueryVariables = Exact<{
  input: DownloadFrameworkInput;
}>;

export type DownloadFrameworkQuery = {
  __typename: "Query";
  downloadFramework: { __typename: "FrameworkBundle"; yaml: string };
};

export type FindNewAwsAssetQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
}>;

export type FindNewAwsAssetQuery = {
  __typename: "Query";
  assets?: {
    __typename: "AssetsConnection";
    edges?: Array<{
      __typename: "AssetEdge";
      cursor: string;
      node?: {
        __typename: "Asset";
        mrn: string;
        platform?: { __typename: "Platform"; name: string } | null;
      } | null;
    }> | null;
  } | null;
};

export type GetActiveComplianceFrameworksQueryVariables = Exact<{
  input: ComplianceFrameworksInput;
}>;

export type GetActiveComplianceFrameworksQuery = {
  __typename: "Query";
  complianceFrameworks?: Array<{
    __typename: "ComplianceFramework";
    mrn: string;
    name: string;
    version: string;
    description: string;
    summary: string;
    state: ComplianceFrameworkState;
    policiesMrns: Array<string>;
    scopeMrn: string;
    completion?: number | null;
    createdAt: string;
    modifiedAt: string;
    authors?: Array<{
      __typename: "Author";
      name: string;
      email: string;
    }> | null;
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
    stats: {
      __typename: "ComplianceFrameworkStats";
      checks: number;
      assets: number;
      exceptions: number;
      controls: {
        __typename: "ControlsStats";
        activeControls: number;
        snoozedControls: number;
        disabledControls: number;
        outOfScopeControls: number;
        totalControls: number;
        averageCompletion: number;
        previousAverageCompletion: number;
      };
    };
    previousCompletionScores?: {
      __typename: "ComplianceScoresHistory";
      entries: Array<{
        __typename: "ComplianceScoreEntry";
        timestamp: string;
        score: number;
      }>;
    } | null;
    assetGroupStatistics: Array<{
      __typename: "AssetGroupStatistics";
      groupType: string;
      count: number;
      listsAssetTypes: Array<{
        __typename: "AssetTypeInfo";
        assetType: string;
        aliases?: Array<string> | null;
        displayName: string;
        shortName: string;
      }>;
      statistics?: Array<{
        __typename: "AssetTypeStatistics";
        count: number;
        type: {
          __typename: "AssetTypeInfo";
          assetType: string;
          aliases?: Array<string> | null;
          displayName: string;
          shortName: string;
        };
      }> | null;
    }>;
  }> | null;
};

export type GetActivePoliciesQueryVariables = Exact<{
  input: ActivePoliciesInput;
}>;

export type GetActivePoliciesQuery = {
  __typename: "Query";
  activePolicies?: {
    __typename: "ActivePoliciesConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "ActivePolicyEdge";
      cursor: string;
      node?: {
        __typename: "ActivePolicy";
        mrn: string;
        name: string;
        icon: Icon_Ids;
        updatedAt: string;
        action: PolicyAction;
        blastRadius: {
          __typename: "BlastRadius";
          indicator: string;
          assets: number;
          affected: number;
          critical: number;
          high: number;
          medium: number;
          low: number;
          none: number;
          error: number;
          pass: number;
        };
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type GetActivePoliciesCountQueryVariables = Exact<{
  input: ActivePoliciesInput;
}>;

export type GetActivePoliciesCountQuery = {
  __typename: "Query";
  activePolicies?: {
    __typename: "ActivePoliciesConnection";
    totalCount: number;
  } | null;
};

export type GetAggregateScoresQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<AggregateScoreFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<AggregateScoreOrder>;
}>;

export type GetAggregateScoresQuery = {
  __typename: "Query";
  aggregateScores?:
    | {
        __typename: "AggregateScoresConnection";
        totalCount: number;
        edges?: Array<{
          __typename: "AggregateScoreEdge";
          cursor: string;
          node?: {
            __typename: "AggregateScore";
            id: string;
            iconId: Icon_Ids;
            state: AggregateScoreState;
            findingMrn: string;
            rank: number;
            baseScore?: number | null;
            riskScore?: number | null;
            scoreType: AggregateScoreType;
            title: string;
            description: string;
            lastScannedAt?: string | null;
            firstDetectedAt: string;
            remediatedAt: string;
            spaceId: string;
            entity?:
              | {
                  __typename: "EntityInfoAsset";
                  id: string;
                  mrn: string;
                  name: string;
                }
              | {
                  __typename: "EntityInfoSpace";
                  id: string;
                  mrn: string;
                  name: string;
                }
              | null;
            blastRadius?: {
              __typename: "BlastRadius";
              indicator: string;
              assets: number;
              affected: number;
              critical: number;
              high: number;
              medium: number;
              low: number;
              none: number;
              snoozed: number;
              disabled: number;
            } | null;
            epss?: {
              __typename: "EpssScore";
              probability: number;
              percentile: number;
            } | null;
            cvss?: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            } | null;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              total: number;
              affected: number;
            } | null> | null;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string }
    | null;
};

export type GetAggregateScoresRefreshInfoQueryVariables = Exact<{
  groupMrn: Scalars["String"]["input"];
}>;

export type GetAggregateScoresRefreshInfoQuery = {
  __typename: "Query";
  aggregateScoresRefreshInfo: {
    __typename: "AggregateScoresRefreshInfo";
    refreshInProgress: boolean;
    lastUpdatedAt?: string | null;
  };
};

export type GetApiTokensQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  queryTerms?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  orderBy?: InputMaybe<ApiTokenOrder>;
}>;

export type GetApiTokensQuery = {
  __typename: "Query";
  apiTokens?: {
    __typename: "APITokenConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "APITokenEdge";
      cursor: string;
      node?: {
        __typename: "APIToken";
        mrn: string;
        spaceMrn: string;
        scopeMrn: string;
        name: string;
        description?: string | null;
        createdAt: string;
        lastUsed?: string | null;
        roles: Array<{ __typename: "Role"; mrn: string; title: string }>;
        creator?: {
          __typename: "APITokenCreator";
          mrn?: string | null;
          email?: string | null;
        } | null;
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type GetAssetPackagesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<PackageScoresOrder>;
  input?: InputMaybe<PackageScoresInput>;
}>;

export type GetAssetPackagesQuery = {
  __typename: "Query";
  packageScores?:
    | { __typename: "NotFoundError"; message: string; code: string }
    | {
        __typename: "PackageScores";
        totalCount: number;
        totalApps: number;
        totalOsPackages: number;
        stats?: {
          __typename: "PackageScoresStats";
          vulnerablePackages: number;
          totalPackages: number;
        } | null;
        edges?: Array<{
          __typename: "PackageScoreEdge";
          cursor: string;
          node?: {
            __typename: "PackageScore";
            id: string;
            riskScore: number;
            assetName?: string | null;
            lastUpdated: string;
            assetMrn?: string | null;
            packageName?: string | null;
            installedVersion?: string | null;
            availableVersion?: string | null;
            firstFound: string;
            packageType: PackageType;
            iconIDEnum?: Icon_Ids | null;
            platform?: string | null;
            cvssScore: {
              __typename: "CvssScore";
              value: number;
              id: string;
              source: string;
              type: number;
              vector: string;
            };
            epssScore?: {
              __typename: "EpssScore";
              probability: number;
              percentile: number;
            } | null;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              total: number;
              affected: number;
            } | null> | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | null;
};

export type GetAssetReportDataQueriesQueryVariables = Exact<{
  input: AssetReportInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  packsFilter?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetAssetReportDataQueriesQuery = {
  __typename: "Query";
  assetReport?:
    | {
        __typename: "AssetReport";
        dataQueries?: {
          __typename: "AssetReportQueryConnection";
          totalCount: number;
          edges: Array<{
            __typename: "AssetReportQueryEdge";
            cursor: string;
            node: {
              __typename: "AssetReportQuery";
              id: string;
              mqueryState: MqueryState;
              mqueryType: MqueryType;
              data?: any | null;
              action: PolicyReportScoreEntryAction;
              policies?: Array<string> | null;
              policyId: string;
              policyName: string;
              mquery: {
                __typename: "Mquery";
                uid: string;
                mrn: string;
                title: string;
                mql: string;
                parent?: string | null;
                action: PolicyReportScoreEntryAction;
                docs?: {
                  __typename: "MqueryDocs";
                  desc: string;
                  audit: string;
                  remediation: string;
                  remediations?:
                    | {
                        __typename: "AuthenticationRequired";
                        message: string;
                        code: string;
                      }
                    | {
                        __typename: "Remediations";
                        entries: Array<{
                          __typename: "MqueryRemediation";
                          id: string;
                          desc: string;
                        }>;
                      }
                    | null;
                  references?: Array<{
                    __typename: "MqueryRef";
                    title: string;
                    url: string;
                  }> | null;
                } | null;
                impact?: {
                  __typename: "Impact";
                  value: number;
                  rating: string;
                } | null;
                tags?: Array<{
                  __typename: "Tag";
                  key: string;
                  value: string;
                }> | null;
                properties?: Array<{
                  __typename: "MProperty";
                  uid: string;
                  mrn: string;
                  title: string;
                  mql: string;
                  setByPolicyMrn?: string | null;
                }> | null;
                variants?: Array<{
                  __typename: "Variant";
                  id: string;
                  title: string;
                  icon: string;
                }> | null;
                variantQueries?: Array<{
                  __typename: "MqueryVariant";
                  uid: string;
                  mrn: string;
                  title: string;
                  mql: string;
                  filter: string;
                  filterID: string;
                } | null> | null;
              };
              score: {
                __typename: "Score";
                id: string;
                value: number;
                type: number;
                completion: number;
                weight: number;
                message: string;
                grade: string;
              };
              assessment?: {
                __typename: "PolicyQueryAssessment";
                state: PolicyReportEntryState;
                rendered: string;
                needsUpgrade: boolean;
                results?: Array<{
                  __typename: "PolicyQueryAssessmentItem";
                  actual: string;
                  expected: string;
                  error: string;
                }> | null;
                extendedResults?: Array<{
                  __typename: "PolicyQueryAssessmentResources";
                  resourceName: string;
                  resourceTitle: string;
                  numResources: number;
                  actual?: Array<{
                    __typename: "Resource";
                    name: string;
                    title: string;
                    type: string;
                    path: string;
                    numElements: number;
                    nestedContent: boolean;
                    fields?: Array<{
                      __typename: "Field";
                      name: string;
                      description?: string | null;
                      path?: string | null;
                      type: string;
                      value?: any | null;
                      updatedAt: string;
                      numElements: number;
                      nestedContent: boolean;
                    } | null> | null;
                    assessmentField?: {
                      __typename: "Field";
                      name: string;
                      description?: string | null;
                      path?: string | null;
                      type: string;
                      value?: any | null;
                      updatedAt: string;
                      numElements: number;
                      nestedContent: boolean;
                    } | null;
                  }> | null;
                }> | null;
              } | null;
            };
          }>;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
        } | null;
      }
    | { __typename: "CsvReport" }
    | { __typename: "InvalidDataError" }
    | { __typename: "NotFoundError" }
    | null;
};

export type GetAssetSupportVulnerabilitiesQueryVariables = Exact<{
  assetMrn: Scalars["String"]["input"];
}>;

export type GetAssetSupportVulnerabilitiesQuery = {
  __typename: "Query";
  assetSupportVulnerabilities: boolean;
};

export type GetAssetSupportsVulnerabilitiesQueryVariables = Exact<{
  assetMrn: Scalars["String"]["input"];
}>;

export type GetAssetSupportsVulnerabilitiesQuery = {
  __typename: "Query";
  assetSupportVulnerabilities: boolean;
};

export type GetAssetUrlStatsQueryVariables = Exact<{
  input: AssetUrlStatsInput;
}>;

export type GetAssetUrlStatsQuery = {
  __typename: "Query";
  assetUrlStats?: {
    __typename: "AssetUrlStatsPayload";
    stats?: Array<{
      __typename: "AssetUrlStats";
      assetUrl: Array<{
        __typename: "AssetUrlSegment";
        key: string;
        value: string;
      }>;
      scoreDistribution: {
        __typename: "AssetUrlScoreDistribution";
        Unscored: number;
        Ok: number;
        Low: number;
        Medium: number;
        High: number;
        Critical: number;
      };
    }> | null;
  } | null;
};

export type GetAvailableComplianceFrameworksQueryVariables = Exact<{
  input: ComplianceFrameworksInput;
}>;

export type GetAvailableComplianceFrameworksQuery = {
  __typename: "Query";
  complianceFrameworks?: Array<{
    __typename: "ComplianceFramework";
    description: string;
    mrn: string;
    scopeMrn: string;
    name: string;
    summary: string;
    state: ComplianceFrameworkState;
    authors?: Array<{
      __typename: "Author";
      name: string;
      email: string;
    }> | null;
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
  }> | null;
};

export type GetCaseContentQueryVariables = Exact<{
  input: CaseContentInput;
}>;

export type GetCaseContentQuery = {
  __typename: "Query";
  caseContent: {
    __typename: "CaseContent";
    title: string;
    description: string;
  };
};

export type GetCaseDetailsQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
  affectedAssetsFirst?: InputMaybe<Scalars["Int"]["input"]>;
  affectedAssetsAfter?: InputMaybe<Scalars["String"]["input"]>;
  mitigatedAssetsFirst?: InputMaybe<Scalars["Int"]["input"]>;
  mitigatedAssetsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCaseDetailsQuery = {
  __typename: "Query";
  case:
    | {
        __typename: "Case";
        status: CaseStatus;
        mrn: string;
        title: string;
        createdAt: string;
        modifiedAt: string;
        assetsCount: number;
        totalRefsCount: number;
        closedRefsCount: number;
        tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
        ticketRefs?: Array<{
          __typename: "TicketRef";
          url?: string | null;
          type: TicketRefType;
          ticketId?: string | null;
        }> | null;
        vulnerabilityRefs?: Array<{
          __typename: "CaseRef";
          findingMrn: string;
          scopeMrn: string;
        } | null> | null;
        queryRefs?: Array<{
          __typename: "CaseRef";
          findingMrn: string;
          scopeMrn: string;
        } | null> | null;
        affectedAssets?: {
          __typename: "CaseAssetsConnection";
          totalCount: number;
          pageInfo: {
            __typename: "PageInfo";
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string;
          };
          edges?: Array<{
            __typename: "CaseAssetEdge";
            cursor: string;
            node: {
              __typename: "EntityInfoAsset";
              id: string;
              mrn: string;
              name: string;
              icon?: Icon_Ids | null;
              lastUpdated: string;
              tags?: Array<{
                __typename: "Tag";
                key: string;
                value: string;
              } | null> | null;
            };
          }> | null;
        } | null;
        mitigatedAssets?: {
          __typename: "CaseAssetsConnection";
          totalCount: number;
          pageInfo: {
            __typename: "PageInfo";
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string;
          };
          edges?: Array<{
            __typename: "CaseAssetEdge";
            cursor: string;
            node: {
              __typename: "EntityInfoAsset";
              id: string;
              mrn: string;
              name: string;
              icon?: Icon_Ids | null;
              lastUpdated: string;
              tags?: Array<{
                __typename: "Tag";
                key: string;
                value: string;
              } | null> | null;
            };
          }> | null;
        } | null;
      }
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string };
};

export type GetCasesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  input: CasesInput;
}>;

export type GetCasesQuery = {
  __typename: "Query";
  cases: {
    __typename: "CasesConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges?: Array<{
      __typename: "CaseEdge";
      cursor: string;
      node: {
        __typename: "Case";
        mrn: string;
        title: string;
        createdAt: string;
        modifiedAt: string;
        assetsCount: number;
        totalRefsCount: number;
        closedRefsCount: number;
        tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
        ticketRefs?: Array<{
          __typename: "TicketRef";
          url?: string | null;
          ticketId?: string | null;
        }> | null;
      };
    }> | null;
  };
};

export type GetCasesCountQueryVariables = Exact<{
  input: CasesInput;
}>;

export type GetCasesCountQuery = {
  __typename: "Query";
  cases: { __typename: "CasesConnection"; totalCount: number };
};

export type GetCheckScoresQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<CheckScoreOrder>;
  filter?: InputMaybe<CheckScoreFilter>;
}>;

export type GetCheckScoresQuery = {
  __typename: "Query";
  checkScores?:
    | {
        __typename: "CheckScoresConnection";
        totalCount: number;
        filteredTotalCount: number;
        edges?: Array<{
          __typename: "CheckScoresEdge";
          cursor: string;
          node?: {
            __typename: "CheckScore";
            mrn: string;
            id: string;
            title: string;
            state: ScoreState;
            iconId: Icon_Ids;
            baseScore?: number | null;
            riskScore?: number | null;
            lastUpdated: string;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              affected: number;
              total: number;
            } | null> | null;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
            asset?: {
              __typename: "EntityInfoAsset";
              id: string;
              mrn: string;
              name: string;
              icon?: Icon_Ids | null;
            } | null;
            platform?: {
              __typename: "Platform";
              name: string;
              title: string;
              arch: string;
              version: string;
              kind: PlatformKind;
              runtime: string;
              release: string;
            } | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string }
    | null;
};

export type GetClientIntegrationQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type GetClientIntegrationQuery = {
  __typename: "Query";
  clientIntegration: {
    __typename: "ClientIntegrationPayload";
    integration: {
      __typename: "ClientIntegration";
      mrn: string;
      name: string;
      status: string;
      created: string;
      type: ClientIntegrationType;
      lastCheckin?: string | null;
      lastStateInfo?: {
        __typename: "K8sIntegrationLastState";
        namespace?: string | null;
        k8sVersion?: string | null;
        operatorVersion?: string | null;
      } | null;
      evaluatedConfigurations?: Array<{
        __typename: "EvaluatedConfiguration";
        option?: string | null;
        configured?: boolean | null;
        applied?: boolean | null;
        msg?: string | null;
        msgStatus?: IntegrationMessageStatus | null;
        operatorMsg?: string | null;
        operatorMsgStatus?: IntegrationMessageStatus | null;
      }> | null;
      messages?: Array<{
        __typename: "IntegrationMessage";
        message: string;
        status: IntegrationMessageStatus;
        identifier?: string | null;
        timestamp?: string | null;
        suppressedByUser?: boolean | null;
      }> | null;
      labels?: Array<{
        __typename: "KeyValue";
        key: string;
        value?: string | null;
      }> | null;
      createdBy?: { __typename: "Author"; name: string; email: string } | null;
      configurationOptions?:
        | {
            __typename: "AWSConfigurationOptions";
            accountIds?: Array<string> | null;
            region: string;
            isOrganization?: boolean | null;
            snsEndpoint: string;
            originAWSAccountId: string;
            cloudFormationTemplateUrl: string;
            scanConfiguration: {
              __typename: "ScanConfiguration";
              ecrScan: boolean;
              ecsScan: boolean;
              cronScaninHours: number;
              accountScan: boolean;
              ec2Scan: boolean;
              ec2ScanOptions: {
                __typename: "Ec2ScanOptions";
                ebsVolumeScan: boolean;
                vaultType?: string | null;
                tagsFilter: any;
                regionsFilter?: Array<string> | null;
                instanceIdsFilter?: Array<string> | null;
                secretsMetadataQuery?: string | null;
                awsSecretsManagerVault?: boolean | null;
                ssm: boolean;
                instanceConnect: boolean;
                ebsScanOptions?: {
                  __typename: "EbsScanOptions";
                  maxAsgInstances?: number | null;
                  targetInstancesPerScanner?: number | null;
                } | null;
              };
              eventScanTriggers?: Array<{
                __typename: "AWSEventPattern";
                eventDetailType: string;
                eventSource: string;
                scanType: string;
              } | null> | null;
              vpcConfiguration?: {
                __typename: "VPCConfiguration";
                CIDR?: string | null;
                useDefaultVPC?: boolean | null;
                useMondooVPC?: boolean | null;
              } | null;
            };
          }
        | { __typename: "AgentOptions" }
        | {
            __typename: "AwsS3ConfigurationOptions";
            output: BucketOutputType;
            bucket: string;
            region: string;
            accessKey: string;
          }
        | {
            __typename: "AzureBlobConfigurationOptions";
            output: BucketOutputType;
          }
        | {
            __typename: "AzureConfigurationOptions";
            tenantId: string;
            clientId: string;
            subscriptionsWhitelist?: Array<string> | null;
            subscriptionsBlacklist?: Array<string> | null;
            scanVms?: boolean | null;
          }
        | { __typename: "BigqueryConfigurationOptions"; datasetId: string }
        | {
            __typename: "EmailConfigurationOptions";
            autoCreateTickets: boolean;
            recipients: Array<{
              __typename: "EmailRecipient";
              name: string;
              email: string;
              isDefault: boolean;
              referenceUrl?: string | null;
            }>;
          }
        | {
            __typename: "GcpConfigurationOptions";
            projectId?: string | null;
            organizationId?: string | null;
            discoverAll?: boolean | null;
          }
        | {
            __typename: "GcsBucketConfigurationOptions";
            output: BucketOutputType;
            bucket: string;
          }
        | {
            __typename: "GithubConfigurationOptions";
            owner?: string | null;
            repository?: string | null;
            reposAllowList?: Array<string> | null;
            reposDenyList?: Array<string> | null;
            enterpriseUrl?: string | null;
            githubOrg?: string | null;
            githubType: GithubIntegrationType;
            githubDiscoverK8sManifests: boolean;
            githubDiscoverTerraform: boolean;
          }
        | {
            __typename: "GithubTicketingConfigurationOptions";
            enterpriseUrl?: string | null;
            defaultRepoOwner?: string | null;
            defaultRepoName?: string | null;
            autoCloseTickets: boolean;
            autoCreateTickets: boolean;
          }
        | {
            __typename: "GitlabConfigurationOptions";
            group: string;
            type: GitlabIntegrationType;
            discoverGroups?: boolean | null;
            discoverProjects?: boolean | null;
            discoverTerraform?: boolean | null;
            discoverK8sManifests: boolean;
            baseUrl: string;
          }
        | {
            __typename: "GitlabTicketingConfigurationOptions";
            autoCloseTickets: boolean;
            autoCreateTickets: boolean;
            gitlabBaseUrl?: string | null;
            gitlabDefaultProject?: string | null;
          }
        | {
            __typename: "GoogleWorkspaceConfigurationOptions";
            impersonatedUserEmail: string;
            customerId: string;
          }
        | {
            __typename: "HostConfigurationOptions";
            host: string;
            https?: boolean | null;
            http?: boolean | null;
          }
        | {
            __typename: "HostedAwsConfigurationOptions";
            accessKeyId?: string | null;
            role?: string | null;
          }
        | {
            __typename: "JiraConfigurationOptions";
            host: string;
            email: string;
            autoCloseTickets: boolean;
            autoCreateCases: boolean;
            defaultProject: string;
          }
        | {
            __typename: "K8sConfigurationOptions";
            scanNodes?: boolean | null;
            scanNodesStyle: K8sScanNodesStyle;
            scanWorkloads?: boolean | null;
            scanDeploys?: boolean | null;
            certificateManager: string;
            scanPublicImages?: boolean | null;
            namespaceAllowList?: Array<string> | null;
            namespaceDenyList?: Array<string> | null;
          }
        | {
            __typename: "MicrosoftDefenderConfigurationOptions";
            tenantId: string;
            clientId: string;
            subscriptionsAllowlist?: Array<string> | null;
            subscriptionsDenylist?: Array<string> | null;
            secretType?: string | null;
          }
        | {
            __typename: "Ms365ConfigurationOptions";
            tenantId: string;
            clientId: string;
          }
        | {
            __typename: "OciConfigurationOptions";
            tenancyOcid: string;
            userOcid: string;
            region: string;
            fingerprint: string;
          }
        | { __typename: "OktaConfigurationOptions"; organization: string }
        | {
            __typename: "PostgresConfigurationOptions";
            host: string;
            port: string;
            database: string;
            user: string;
          }
        | {
            __typename: "S3ConfigurationOptions";
            output: BucketOutputType;
            endpoint: string;
            bucket: string;
            useLegacyList: boolean;
            usePathStyle: boolean;
            accessKey: string;
          }
        | { __typename: "SlackConfigurationOptions" }
        | {
            __typename: "SnowflakeConfigurationOptions";
            accountId: string;
            database: string;
            schema: string;
            region: string;
            warehouse: string;
            role?: string | null;
            username: string;
          }
        | {
            __typename: "ZendeskConfigurationOptions";
            subdomain: string;
            email: string;
            autoCloseTickets: boolean;
            autoCreateTickets: boolean;
          }
        | null;
      assetCounts?: Array<{
        __typename: "IntegrationAssetCount";
        assetType: string;
        total: number;
      }> | null;
    };
  };
};

export type GetClientIntegrationDeleteCommandQueryVariables = Exact<{
  input: GetClientIntegrationDeleteCommandInput;
}>;

export type GetClientIntegrationDeleteCommandQuery = {
  __typename: "Query";
  getClientIntegrationDeleteCommand: {
    __typename: "GetClientIntegrationDeleteCommandPayload";
    message: string;
  };
};

export type GetClientIntegrationTokenQueryVariables = Exact<{
  input: GetClientIntegrationTokenInput;
}>;

export type GetClientIntegrationTokenQuery = {
  __typename: "Query";
  getClientIntegrationToken: {
    __typename: "GetClientIntegrationTokenPayload";
    token: string;
  };
};

export type GetComplianceControlQueryVariables = Exact<{
  input: ComplianceControlInput;
}>;

export type GetComplianceControlQuery = {
  __typename: "Query";
  complianceControl: {
    __typename: "ComplianceControl";
    mrn: string;
    description: string;
    title: string;
    manual: boolean;
    completion: number;
    state: ControlState;
    framework?: {
      __typename: "ComplianceFramework";
      name: string;
      version: string;
    } | null;
    tags: Array<{ __typename: "Tag"; key: string; value: string } | null>;
    assetGroupStatistics: Array<{
      __typename: "AssetGroupStatistics";
      count: number;
      groupType: string;
      listsAssetTypes: Array<{
        __typename: "AssetTypeInfo";
        assetType: string;
        aliases?: Array<string> | null;
        displayName: string;
        shortName: string;
      }>;
      statistics?: Array<{
        __typename: "AssetTypeStatistics";
        count: number;
        type: {
          __typename: "AssetTypeInfo";
          assetType: string;
          aliases?: Array<string> | null;
          displayName: string;
          shortName: string;
        };
      }> | null;
    }>;
    assetsStats: {
      __typename: "ControlAssetsStats";
      compliantAssets: number;
      failedAssets: number;
      totalAssets: number;
    };
    checksStats: {
      __typename: "ControlChecksStats";
      activeChecks: number;
      snoozedChecks: number;
      disabledChecks: number;
      totalChecks: number;
    };
    exceptions: Array<{
      __typename: "ControlException";
      id: string;
      createdAt: string;
      modifiedAt?: string | null;
      title: string;
      justification?: string | null;
      reviewStatus: ReviewStatus;
      action: ExceptionMutationAction;
      author: { __typename: "Author"; name: string; email: string };
      reviewer?: { __typename: "Author"; name: string; email: string } | null;
    }>;
  };
};

export type GetComplianceControlAssetsQueryVariables = Exact<{
  input: ComplianceControlInput;
  assetsInput: ComplianceAssetsSearchInput;
}>;

export type GetComplianceControlAssetsQuery = {
  __typename: "Query";
  complianceControl: {
    __typename: "ComplianceControl";
    mrn: string;
    description: string;
    title: string;
    assets?: {
      __typename: "ComplianceAssetsConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "ComplianceAssetEdge";
        cursor: string;
        node?: {
          __typename: "ComplianceAsset";
          compliancePercentage?: number | null;
          relatedAssets?: number | null;
          asset: {
            __typename: "Asset";
            id: string;
            mrn: string;
            state: AssetState;
            name?: string | null;
            updatedAt?: string | null;
            referenceIDs: Array<string>;
            asset_type: string;
            labels: Array<{
              __typename: "KeyValue";
              key: string;
              value?: string | null;
            }>;
            platform?: {
              __typename: "Platform";
              name: string;
              title: string;
              arch: string;
              version: string;
              kind: PlatformKind;
              runtime: string;
              release: string;
            } | null;
            score: {
              __typename: "Score";
              id: string;
              value: number;
              type: number;
              completion: number;
              weight: number;
              message: string;
              grade: string;
            };
            annotations: Array<{
              __typename: "KeyValue";
              key: string;
              value?: string | null;
            }>;
          };
          exception?: {
            __typename: "ControlException";
            createdAt: string;
            action: ExceptionMutationAction;
            id: string;
            reviewStatus: ReviewStatus;
            title: string;
            justification?: string | null;
            author: { __typename: "Author"; name: string; email: string };
          } | null;
        } | null;
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    } | null;
  };
};

export type GetComplianceControlChecksQueryVariables = Exact<{
  input: ComplianceControlInput;
  checksInput: ChecksSearchInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetComplianceControlChecksQuery = {
  __typename: "Query";
  complianceControl: {
    __typename: "ComplianceControl";
    mrn: string;
    description: string;
    title: string;
    checks?: {
      __typename: "ChecksConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "ChecksEdge";
        cursor: string;
        node?:
          | {
              __typename: "PolicyCheck";
              state: CheckState;
              policyName: string;
              policyMrn: string;
              completion: number;
              assets: number;
            }
          | {
              __typename: "QueryCheck";
              state: CheckState;
              completion: number;
              assets: number;
              exception?: {
                __typename: "QueryCheckException";
                id: string;
                action: ExceptionMutationAction;
                justification: string;
                reviewStatus: ReviewStatus;
              } | null;
              refs: Array<
                | {
                    __typename: "PolicyCheckRef";
                    name: string;
                    mrn: string;
                    group: string;
                  }
                | {
                    __typename: "QueryPackCheckRef";
                    name: string;
                    mrn: string;
                    group: string;
                  }
              >;
              mquery: {
                __typename: "Mquery";
                action: PolicyReportScoreEntryAction;
                uid: string;
                title: string;
                mrn: string;
                impact?: {
                  __typename: "Impact";
                  rating: string;
                  value: number;
                } | null;
              };
            }
          | null;
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    } | null;
  };
};

export type GetComplianceControlDataQueriesQueryVariables = Exact<{
  input: ComplianceControlInput;
  dataQueriesInput: ChecksSearchInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetComplianceControlDataQueriesQuery = {
  __typename: "Query";
  complianceControl: {
    __typename: "ComplianceControl";
    mrn: string;
    dataQueries?: {
      __typename: "QueriesConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "QueryEdge";
        cursor: string;
        node?: {
          __typename: "QueryCheck";
          assets: number;
          completion: number;
          state: CheckState;
          mquery: {
            __typename: "Mquery";
            action: PolicyReportScoreEntryAction;
            mql: string;
            mrn: string;
            parent?: string | null;
            title: string;
            uid: string;
            docs?: {
              __typename: "MqueryDocs";
              desc: string;
              remediations?:
                | {
                    __typename: "AuthenticationRequired";
                    message: string;
                    code: string;
                  }
                | {
                    __typename: "Remediations";
                    entries: Array<{
                      __typename: "MqueryRemediation";
                      id: string;
                      desc: string;
                    }>;
                  }
                | null;
              references?: Array<{
                __typename: "MqueryRef";
                title: string;
                url: string;
              }> | null;
            } | null;
            impact?: {
              __typename: "Impact";
              value: number;
              rating: string;
            } | null;
            properties?: Array<{
              __typename: "MProperty";
              uid: string;
              title: string;
              setByPolicyMrn?: string | null;
              mrn: string;
              mql: string;
            }> | null;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
            variantQueries?: Array<{
              __typename: "MqueryVariant";
              filter: string;
              filterID: string;
              mql: string;
              mrn: string;
              title: string;
              uid: string;
              impact?: {
                __typename: "Impact";
                value: number;
                rating: string;
              } | null;
              properties?: Array<{
                __typename: "MProperty";
                mql: string;
                mrn: string;
                setByPolicyMrn?: string | null;
                title: string;
                uid: string;
              } | null> | null;
              tags?: Array<{
                __typename: "Tag";
                key: string;
                value: string;
              }> | null;
              docs?: {
                __typename: "MqueryDocs";
                desc: string;
                references?: Array<{
                  __typename: "MqueryRef";
                  title: string;
                  url: string;
                }> | null;
                remediations?:
                  | {
                      __typename: "AuthenticationRequired";
                      message: string;
                      code: string;
                    }
                  | {
                      __typename: "Remediations";
                      entries: Array<{
                        __typename: "MqueryRemediation";
                        id: string;
                        desc: string;
                      }>;
                    }
                  | null;
              } | null;
            } | null> | null;
            variants?: Array<{
              __typename: "Variant";
              id: string;
              icon: string;
            }> | null;
          };
          queryStats?: {
            __typename: "ScoreStats";
            total: number;
            passed: number;
            failed: number;
            incomplete: number;
            errors: number;
            skipped: number;
            worst: number;
            unknown: number;
          } | null;
          refs: Array<
            | {
                __typename: "PolicyCheckRef";
                name: string;
                mrn: string;
                group: string;
              }
            | {
                __typename: "QueryPackCheckRef";
                name: string;
                mrn: string;
                group: string;
              }
          >;
        } | null;
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    } | null;
  };
};

export type GetComplianceFrameworkQueryVariables = Exact<{
  input: ComplianceFrameworkInput;
}>;

export type GetComplianceFrameworkQuery = {
  __typename: "Query";
  complianceFramework: {
    __typename: "ComplianceFramework";
    mrn: string;
    name: string;
    version: string;
    createdAt: string;
    modifiedAt: string;
    description: string;
    summary: string;
    state: ComplianceFrameworkState;
    policiesMrns: Array<string>;
    scopeMrn: string;
    completion?: number | null;
    authors?: Array<{
      __typename: "Author";
      name: string;
      email: string;
    }> | null;
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
    stats: {
      __typename: "ComplianceFrameworkStats";
      checks: number;
      assets: number;
      exceptions: number;
      controls: {
        __typename: "ControlsStats";
        activeControls: number;
        snoozedControls: number;
        outOfScopeControls: number;
        disabledControls: number;
        totalControls: number;
        averageCompletion: number;
        previousAverageCompletion: number;
      };
    };
    assetGroupStatistics: Array<{
      __typename: "AssetGroupStatistics";
      groupType: string;
      count: number;
      listsAssetTypes: Array<{
        __typename: "AssetTypeInfo";
        assetType: string;
        aliases?: Array<string> | null;
        displayName: string;
        shortName: string;
      }>;
      statistics?: Array<{
        __typename: "AssetTypeStatistics";
        count: number;
        type: {
          __typename: "AssetTypeInfo";
          assetType: string;
          aliases?: Array<string> | null;
          displayName: string;
          shortName: string;
        };
      }> | null;
    }>;
    previousCompletionScores?: {
      __typename: "ComplianceScoresHistory";
      entries: Array<{
        __typename: "ComplianceScoreEntry";
        score: number;
        timestamp: string;
      }>;
    } | null;
  };
};

export type GetComplianceFrameworkAssetsQueryVariables = Exact<{
  input: ComplianceFrameworkInput;
  assetsInput: ComplianceAssetsSearchInput;
}>;

export type GetComplianceFrameworkAssetsQuery = {
  __typename: "Query";
  complianceFramework: {
    __typename: "ComplianceFramework";
    mrn: string;
    name: string;
    assetGroupStatistics: Array<{
      __typename: "AssetGroupStatistics";
      groupType: string;
      count: number;
      listsAssetTypes: Array<{
        __typename: "AssetTypeInfo";
        assetType: string;
        aliases?: Array<string> | null;
        displayName: string;
        shortName: string;
      }>;
      statistics?: Array<{
        __typename: "AssetTypeStatistics";
        count: number;
        type: {
          __typename: "AssetTypeInfo";
          assetType: string;
          aliases?: Array<string> | null;
          displayName: string;
          shortName: string;
        };
      }> | null;
    }>;
    assets?: {
      __typename: "ComplianceAssetsConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "ComplianceAssetEdge";
        cursor: string;
        node?: {
          __typename: "ComplianceAsset";
          compliancePercentage?: number | null;
          asset: {
            __typename: "Asset";
            id: string;
            mrn: string;
            state: AssetState;
            name?: string | null;
            updatedAt?: string | null;
            referenceIDs: Array<string>;
            asset_type: string;
            labels: Array<{
              __typename: "KeyValue";
              key: string;
              value?: string | null;
            }>;
            platform?: {
              __typename: "Platform";
              name: string;
              title: string;
              arch: string;
              version: string;
              kind: PlatformKind;
              runtime: string;
              release: string;
            } | null;
            score: {
              __typename: "Score";
              completion: number;
              grade: string;
              id: string;
              message: string;
              type: number;
              value: number;
              weight: number;
            };
            annotations: Array<{
              __typename: "KeyValue";
              key: string;
              value?: string | null;
            }>;
            relatedAssets?: {
              __typename: "RelatedAssets";
              count?: Array<{
                __typename: "RelatedAssetCount";
                count: number;
                isParent: boolean;
                type: string;
              }> | null;
              list: {
                __typename: "RelatedAssetsConnection";
                totalCount: number;
              };
            } | null;
          };
        } | null;
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    } | null;
  };
};

export type GetComplianceFrameworkControlsQueryVariables = Exact<{
  input: ComplianceFrameworkInput;
  controlsInput: ControlsSearchInput;
}>;

export type GetComplianceFrameworkControlsQuery = {
  __typename: "Query";
  complianceFramework: {
    __typename: "ComplianceFramework";
    mrn: string;
    name: string;
    controls?: {
      __typename: "ControlsConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "ControlsEdge";
        cursor: string;
        node?: {
          __typename: "ComplianceControl";
          mrn: string;
          description: string;
          title: string;
          manual: boolean;
          completion: number;
          state: ControlState;
          tags: Array<{ __typename: "Tag"; key: string; value: string } | null>;
          assetsStats: {
            __typename: "ControlAssetsStats";
            compliantAssets: number;
            failedAssets: number;
            totalAssets: number;
          };
          checksStats: {
            __typename: "ControlChecksStats";
            activeChecks: number;
            snoozedChecks: number;
            disabledChecks: number;
            totalChecks: number;
            totalQueries: number;
          };
          exception?: {
            __typename: "ControlException";
            createdAt: string;
            action: ExceptionMutationAction;
            id: string;
            reviewStatus: ReviewStatus;
            title: string;
            justification?: string | null;
            author: { __typename: "Author"; name: string; email: string };
          } | null;
        } | null;
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    } | null;
  };
};

export type GetComplianceFrameworksQueryVariables = Exact<{
  input: ComplianceFrameworksInput;
}>;

export type GetComplianceFrameworksQuery = {
  __typename: "Query";
  complianceFrameworks?: Array<{
    __typename: "ComplianceFramework";
    createdAt: string;
    modifiedAt: string;
    completion?: number | null;
    description: string;
    mrn: string;
    name: string;
    scopeMrn: string;
    state: ComplianceFrameworkState;
    summary: string;
    version: string;
    authors?: Array<{
      __typename: "Author";
      name: string;
      email: string;
    }> | null;
    previousCompletionScores?: {
      __typename: "ComplianceScoresHistory";
      entries: Array<{
        __typename: "ComplianceScoreEntry";
        score: number;
        timestamp: string;
      }>;
    } | null;
    stats: {
      __typename: "ComplianceFrameworkStats";
      checks: number;
      assets: number;
      exceptions: number;
      controls: {
        __typename: "ControlsStats";
        activeControls: number;
        snoozedControls: number;
        outOfScopeControls: number;
        disabledControls: number;
        totalControls: number;
        averageCompletion: number;
        previousAverageCompletion: number;
      };
    };
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
  }> | null;
};

export type GetContentQueryVariables = Exact<{
  input: ContentSearchInput;
}>;

export type GetContentQuery = {
  __typename: "Query";
  content?: {
    __typename: "ContentSearchResultItemConnection";
    totalCount: number;
    edges: Array<{
      __typename: "ContentSearchResultItemEdge";
      cursor: string;
      node: {
        __typename: "Policy";
        access: Access;
        action?: PolicyAction | null;
        assigned: boolean;
        category: string;
        createdAt: string;
        featured: boolean;
        trustLevel: TrustLevel;
        mrn: string;
        name: string;
        uid: string;
        updatedAt: string;
        version: string;
        authors?: Array<{
          __typename: "Author";
          email: string;
          name: string;
        }> | null;
        certifiedBy?: { __typename: "Certifier"; name: string } | null;
        statistics: {
          __typename: "PolicyStatistics";
          checks: number;
          policies: number;
          queries: number;
        };
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type GetCveAdvisoriesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  spaceMrn: Scalars["String"]["input"];
  advisoriesFirst?: InputMaybe<Scalars["Int"]["input"]>;
  advisoriesAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCveAdvisoriesQuery = {
  __typename: "Query";
  cve?: {
    __typename: "Cve";
    id: string;
    mrn: string;
    advisoryAggregateScores?:
      | {
          __typename: "AggregateScoresConnection";
          totalCount: number;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
          edges?: Array<{
            __typename: "AggregateScoreEdge";
            cursor: string;
            node?: {
              __typename: "AggregateScore";
              id: string;
              iconId: Icon_Ids;
              findingMrn: string;
              scoreType: AggregateScoreType;
              state: AggregateScoreState;
              description: string;
              remediatedAt: string;
              spaceId: string;
              title: string;
              firstDetectedAt: string;
              riskScore?: number | null;
              rank: number;
              blastRadius?: {
                __typename: "BlastRadius";
                indicator: string;
                assets: number;
                affected: number;
                critical: number;
                high: number;
                medium: number;
                low: number;
                none: number;
                snoozed: number;
                disabled: number;
              } | null;
              riskFactors?: Array<{
                __typename: "RiskFactorStats";
                mrn: string;
                indicator: string;
                title: string;
                total: number;
                affected: number;
              } | null> | null;
            } | null;
          }> | null;
        }
      | { __typename: "NotFoundError" }
      | { __typename: "RequestError" }
      | null;
  } | null;
};

export type GetDataQueriesListQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<DataQueryOrder>;
  filter?: InputMaybe<DataQueryFilter>;
}>;

export type GetDataQueriesListQuery = {
  __typename: "Query";
  dataQueries?:
    | {
        __typename: "DataQueriesConnection";
        totalCount: number;
        filteredTotalCount: number;
        edges?: Array<{
          __typename: "DataQueriesEdge";
          cursor: string;
          node?: {
            __typename: "DataQuery";
            mrn: string;
            id: string;
            title: string;
            iconId: Icon_Ids;
            lastUpdated: string;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
            asset?: {
              __typename: "EntityInfoAsset";
              id: string;
              mrn: string;
              name: string;
              icon?: Icon_Ids | null;
            } | null;
            platform?: {
              __typename: "Platform";
              name: string;
              title: string;
              arch: string;
              version: string;
              kind: PlatformKind;
              runtime: string;
              family?: Array<string> | null;
              labels?: Array<{
                __typename: "KeyValue";
                key: string;
                value?: string | null;
              }> | null;
            } | null;
            mquery?: {
              __typename: "Mquery";
              uid: string;
              mrn: string;
              title: string;
              mql: string;
              action: PolicyReportScoreEntryAction;
              code: string;
              codeId: string;
            } | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string }
    | null;
};

export type GetDataQueryQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<DataQueryOrder>;
  filter?: InputMaybe<DataQueryFilter>;
}>;

export type GetDataQueryQuery = {
  __typename: "Query";
  dataQueries?:
    | {
        __typename: "DataQueriesConnection";
        totalCount: number;
        filteredTotalCount: number;
        edges?: Array<{
          __typename: "DataQueriesEdge";
          cursor: string;
          node?: {
            __typename: "DataQuery";
            mrn: string;
            id: string;
            iconId: Icon_Ids;
            lastUpdated: string;
            value?: any | null;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
            asset?: {
              __typename: "EntityInfoAsset";
              id: string;
              mrn: string;
              name: string;
              icon?: Icon_Ids | null;
            } | null;
            platform?: {
              __typename: "Platform";
              name: string;
              title: string;
              arch: string;
              version: string;
              kind: PlatformKind;
              runtime: string;
              family?: Array<string> | null;
              labels?: Array<{
                __typename: "KeyValue";
                key: string;
                value?: string | null;
              }> | null;
            } | null;
            mquery?: {
              __typename: "Mquery";
              uid: string;
              mrn: string;
              title: string;
              mql: string;
              parent?: string | null;
              action: PolicyReportScoreEntryAction;
              code: string;
              codeId: string;
              severity?: number | null;
              docs?: {
                __typename: "MqueryDocs";
                desc: string;
                audit: string;
                remediation: string;
                remediations?:
                  | {
                      __typename: "AuthenticationRequired";
                      message: string;
                      code: string;
                    }
                  | {
                      __typename: "Remediations";
                      entries: Array<{
                        __typename: "MqueryRemediation";
                        id: string;
                        desc: string;
                      }>;
                    }
                  | null;
                references?: Array<{
                  __typename: "MqueryRef";
                  title: string;
                  url: string;
                }> | null;
              } | null;
              impact?: {
                __typename: "Impact";
                value: number;
                rating: string;
              } | null;
              tags?: Array<{
                __typename: "Tag";
                key: string;
                value: string;
              }> | null;
              properties?: Array<{
                __typename: "MProperty";
                uid: string;
                mrn: string;
                title: string;
                mql: string;
                setByPolicyMrn?: string | null;
              }> | null;
              variants?: Array<{
                __typename: "Variant";
                id: string;
                mrn: string;
                title: string;
                icon: string;
              }> | null;
              variantQueries?: Array<{
                __typename: "MqueryVariant";
                uid: string;
                mrn: string;
                title: string;
                mql: string;
                filter: string;
                filterID: string;
              } | null> | null;
            } | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string }
    | null;
};

export type GetEndOfLifeQueryVariables = Exact<{
  input: AssetReportInput;
}>;

export type GetEndOfLifeQuery = {
  __typename: "Query";
  assetReport?:
    | {
        __typename: "AssetReport";
        analysis?: {
          __typename: "AssetReportAnalysis";
          eol?: {
            __typename: "EOLAnalysis";
            date: string;
            status: EolStatus;
          } | null;
        } | null;
      }
    | { __typename: "CsvReport" }
    | { __typename: "InvalidDataError" }
    | { __typename: "NotFoundError" }
    | null;
};

export type GetFindingRiskFactorsQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<AggregateScoreFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<AggregateScoreOrder>;
}>;

export type GetFindingRiskFactorsQuery = {
  __typename: "Query";
  aggregateScores?:
    | {
        __typename: "AggregateScoresConnection";
        totalCount: number;
        edges?: Array<{
          __typename: "AggregateScoreEdge";
          cursor: string;
          node?: {
            __typename: "AggregateScore";
            id: string;
            findingMrn: string;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              total: number;
              affected: number;
            } | null> | null;
          } | null;
        }> | null;
      }
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string }
    | null;
};

export type GetIntegrationsSummaryQueryVariables = Exact<{
  input: IntegrationsSummaryInput;
}>;

export type GetIntegrationsSummaryQuery = {
  __typename: "Query";
  integrationsSummary: {
    __typename: "IntegrationsSummaryPayload";
    total: number;
    integrationSummaries?: Array<{
      __typename: "IntegrationTypeSummary";
      totalCount: number;
      activeCount: number;
      failedCount: number;
      warningCount: number;
      assetsCount: number;
      policiesCount: number;
      checksCount: number;
      type: IntegrationType;
    }> | null;
  };
};

export type GetMetricsQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  metricMrns?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type GetMetricsQuery = {
  __typename: "Query";
  metrics?: {
    __typename: "MetricsResponse";
    entries?: Array<{
      __typename: "MetricEntry";
      metricMrn: string;
      title: string;
      arrowResult?: string | null;
    }> | null;
  } | null;
};

export type GetMondooVulnerabilityDatabaseQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<MvdOrder>;
  queryTerms?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type: MvdEntryType;
  severity?: InputMaybe<Array<Severity> | Severity>;
}>;

export type GetMondooVulnerabilityDatabaseQuery = {
  __typename: "Query";
  mvd?: {
    __typename: "MvdEntryConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "MvdEntryEdge";
      cursor: string;
      node?:
        | {
            __typename: "Advisory";
            id: string;
            iconIDEnum?: Icon_Ids | null;
            mrn: string;
            title?: string | null;
            publishedAt?: string | null;
            cvssScore: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            };
          }
        | {
            __typename: "Cve";
            id: string;
            mrn: string;
            title?: string | null;
            publishedAt?: string | null;
            cvssScore: {
              __typename: "CvssScore";
              id: string;
              source: string;
              type: number;
              value: number;
              vector: string;
            };
          }
        | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type GetOrganizationOverviewQueryVariables = Exact<{
  input: OrganizationOverviewInput;
}>;

export type GetOrganizationOverviewQuery = {
  __typename: "Query";
  organizationOverview?: {
    __typename: "OrganizationOverviewResponse";
    organizationMrn: string;
    eolAssets: number;
    reportDate?: string | null;
    assetScores?: Array<{
      __typename: "AssetScoresDistribution";
      date: string;
      scores: {
        __typename: "RiskDistribution";
        total: number;
        A: number;
        B: number;
        C: number;
        D: number;
        F: number;
        U: number;
        Error: number;
      };
    }> | null;
    assets: {
      __typename: "AssetsInfo";
      improved: number;
      regressed: number;
      unchanged: number;
      scores: {
        __typename: "RiskDistribution";
        total: number;
        A: number;
        B: number;
        C: number;
        D: number;
        F: number;
        U: number;
        Error: number;
      };
    };
    vulnerabilities?: Array<{
      __typename: "Vulnerabilities";
      date: string;
      critical: number;
      high: number;
    }> | null;
    topVulnerabilities?: Array<{
      __typename: "VulnerabilityInfo";
      totalAffectedAssets: number;
      stats?: Array<{
        __typename: "VulnerabilitiesPerSpace";
        amountAssets: number;
        spaceMrn: string;
        spaceName: string;
        spaceOwners: Array<string>;
      }> | null;
      cve: {
        __typename: "Cve";
        id: string;
        title?: string | null;
        description?: string | null;
        cvssScore: {
          __typename: "CvssScore";
          id: string;
          type: number;
          value: number;
        };
      };
    }> | null;
    newVulnerabilities?: Array<{
      __typename: "NewVulnerabilityInfo";
      date: string;
      assetsAffected: number;
      cve: {
        __typename: "Cve";
        id: string;
        title?: string | null;
        description?: string | null;
        cvssScore: { __typename: "CvssScore"; type: number; value: number };
      };
    }> | null;
    spacesOverview?: Array<{
      __typename: "SpaceInfo";
      spaceName: string;
      spaceMrn: string;
      scores: {
        __typename: "RiskDistribution";
        total: number;
        A: number;
        B: number;
        C: number;
        D: number;
        F: number;
        U: number;
        Error: number;
      };
    }> | null;
    integrationSummaries?: Array<{
      __typename: "IntegrationTypeSummary";
      type: IntegrationType;
      totalCount: number;
    }> | null;
  } | null;
};

export type GetPolicyPropsQueryVariables = Exact<{
  input: PolicyInput;
}>;

export type GetPolicyPropsQuery = {
  __typename: "Query";
  policy?: {
    __typename: "Policy";
    mrn: string;
    properties?: Array<{
      __typename: "MProperty";
      uid: string;
      mrn: string;
      title: string;
      mql: string;
    }> | null;
  } | null;
};

export type GetRemediationQueryVariables = Exact<{
  input: RemediationInput;
}>;

export type GetRemediationQuery = {
  __typename: "Query";
  remediation?:
    | { __typename: "VulnerabilityRemediationNotFound" }
    | { __typename: "VulnerabilityRemediationNotSupported" }
    | {
        __typename: "VulnerabilityRemediationResponse";
        namespaces?: Array<string> | null;
        highestVersionNamespace?: string | null;
        scripts?: Array<{
          __typename: "RemediationScript";
          type: RemediationScriptType;
          script: string;
          packageManager: PackageManager;
          platformName: string;
          platformVersion: string;
        }> | null;
        packages?: Array<{
          __typename: "Package";
          id: string;
          name: string;
          version?: string | null;
          namespace?: string | null;
          arch?: string | null;
        }> | null;
      }
    | null;
};

export type GetRenderedAssetDataQueryQueryVariables = Exact<{
  input: RenderedAssetQueryDataInput;
}>;

export type GetRenderedAssetDataQueryQuery = {
  __typename: "Query";
  renderedAssetQueryData?:
    | { __typename: "NotFoundError"; message: string; code: string }
    | {
        __typename: "RenderedAssetQueryData";
        assessment?: string | null;
        data?: any | null;
      }
    | { __typename: "RequestError"; message: string; code: string }
    | null;
};

export type GetRiskFactorsQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  mrns?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type GetRiskFactorsQuery = {
  __typename: "Query";
  riskFactors: {
    __typename: "RiskFactorsResponse";
    edges?: Array<{
      __typename: "RiskFactor";
      mrn: string;
      title: string;
      docs?: { __typename: "RiskFactorDocs"; active?: string | null } | null;
    }> | null;
  };
};

export type GetRiskFactorsDocsQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  mrns?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type GetRiskFactorsDocsQuery = {
  __typename: "Query";
  riskFactors: {
    __typename: "RiskFactorsResponse";
    edges?: Array<{
      __typename: "RiskFactor";
      mrn: string;
      title: string;
      docs?: {
        __typename: "RiskFactorDocs";
        active?: string | null;
        inactive?: string | null;
      } | null;
    }> | null;
  };
};

export type GetSearchResultsQueryVariables = Exact<{
  input: AssetSearchInput;
  orderBy?: InputMaybe<AssetOrder>;
  first: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetSearchResultsQuery = {
  __typename: "Query";
  assetSearch?: {
    __typename: "AssetsConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
    };
    edges?: Array<{
      __typename: "AssetEdge";
      cursor: string;
      node?: {
        __typename: "Asset";
        asset_type: string;
        id: string;
        mrn: string;
        name?: string | null;
        referenceIDs: Array<string>;
        state: AssetState;
        updatedAt?: string | null;
        platform?: {
          __typename: "Platform";
          arch: string;
          kind: PlatformKind;
          name: string;
          runtime: string;
          title: string;
          version: string;
        } | null;
        score: {
          __typename: "Score";
          completion: number;
          grade: string;
          id: string;
          message: string;
          type: number;
          value: number;
          weight: number;
        };
      } | null;
    }> | null;
  } | null;
};

export type GetSharedSpacesCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSharedSpacesCountQuery = {
  __typename: "Query";
  sharedSpaces?: {
    __typename: "UserSharedSpacesConnection";
    totalCount: number;
  } | null;
};

export type GetSpaceRiskFactorsQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
}>;

export type GetSpaceRiskFactorsQuery = {
  __typename: "Query";
  riskFactors: {
    __typename: "RiskFactorsResponse";
    edges?: Array<{
      __typename: "RiskFactor";
      mrn: string;
      title: string;
      action?: RiskFactorAction | null;
      docs?: {
        __typename: "RiskFactorDocs";
        active?: string | null;
        inactive?: string | null;
      } | null;
      magnitude: {
        __typename: "RiskMagnitude";
        value: number;
        isToxic: boolean;
      };
      defaultMagnitude: {
        __typename: "RiskMagnitude";
        value: number;
        isToxic: boolean;
      };
    }> | null;
  };
};

export type GetSpaceVulnerabilityCountQueryVariables = Exact<{
  input: SpaceVulnerabilityReportInput;
}>;

export type GetSpaceVulnerabilityCountQuery = {
  __typename: "Query";
  spaceVulnerabilityReport?:
    | { __typename: "CsvReport" }
    | { __typename: "NotFoundError" }
    | {
        __typename: "SpaceVulnerabilityReport";
        spaceMrn: string;
        cveSummaries?: {
          __typename: "CveSummariesConnection";
          totalCount: number;
          stats?: {
            __typename: "CveSummariesConnectionStats";
            totalAssetCves: number;
            totalDatabaseCves: number;
          } | null;
        } | null;
      }
    | null;
};

export type GetTicketContextQueryVariables = Exact<{
  input: TicketContextInput;
}>;

export type GetTicketContextQuery = {
  __typename: "Query";
  ticketContext:
    | {
        __typename: "EmailTicketContextPayload";
        recipients: Array<{
          __typename: "EmailRecipient";
          name: string;
          email: string;
          isDefault: boolean;
          referenceUrl?: string | null;
        }>;
      }
    | {
        __typename: "JiraTicketContextProjectsPayload";
        projects?: Array<{
          __typename: "JiraProject";
          key: string;
          name: string;
        }> | null;
      }
    | { __typename: "JiraTicketContextUsersPayload" };
};

export type GetTicketingIntegrationsQueryVariables = Exact<{
  input: TicketingIntegrationsInput;
}>;

export type GetTicketingIntegrationsQuery = {
  __typename: "Query";
  ticketingIntegrations: {
    __typename: "TicketingIntegrationsPayload";
    integrations: Array<{
      __typename: "ClientIntegration";
      mrn: string;
      name: string;
      type: ClientIntegrationType;
      configurationOptions?:
        | { __typename: "AWSConfigurationOptions" }
        | { __typename: "AgentOptions" }
        | { __typename: "AwsS3ConfigurationOptions" }
        | { __typename: "AzureBlobConfigurationOptions" }
        | { __typename: "AzureConfigurationOptions" }
        | { __typename: "BigqueryConfigurationOptions" }
        | {
            __typename: "EmailConfigurationOptions";
            autoCreateTickets: boolean;
            recipients: Array<{
              __typename: "EmailRecipient";
              name: string;
              email: string;
              isDefault: boolean;
              referenceUrl?: string | null;
            }>;
          }
        | { __typename: "GcpConfigurationOptions" }
        | { __typename: "GcsBucketConfigurationOptions" }
        | { __typename: "GithubConfigurationOptions" }
        | {
            __typename: "GithubTicketingConfigurationOptions";
            enterpriseUrl?: string | null;
            defaultRepoOwner?: string | null;
            defaultRepoName?: string | null;
            autoCloseTickets: boolean;
            autoCreateTickets: boolean;
          }
        | { __typename: "GitlabConfigurationOptions" }
        | {
            __typename: "GitlabTicketingConfigurationOptions";
            autoCloseTickets: boolean;
            autoCreateTickets: boolean;
            gitlabBaseUrl?: string | null;
            gitlabDefaultProject?: string | null;
          }
        | { __typename: "GoogleWorkspaceConfigurationOptions" }
        | { __typename: "HostConfigurationOptions" }
        | { __typename: "HostedAwsConfigurationOptions" }
        | {
            __typename: "JiraConfigurationOptions";
            host: string;
            email: string;
            defaultProject: string;
            autoCloseTickets: boolean;
            autoCreateCases: boolean;
          }
        | { __typename: "K8sConfigurationOptions" }
        | { __typename: "MicrosoftDefenderConfigurationOptions" }
        | { __typename: "Ms365ConfigurationOptions" }
        | { __typename: "OciConfigurationOptions" }
        | { __typename: "OktaConfigurationOptions" }
        | { __typename: "PostgresConfigurationOptions" }
        | { __typename: "S3ConfigurationOptions" }
        | { __typename: "SlackConfigurationOptions" }
        | { __typename: "SnowflakeConfigurationOptions" }
        | {
            __typename: "ZendeskConfigurationOptions";
            subdomain: string;
            email: string;
            autoCloseTickets: boolean;
            autoCreateTickets: boolean;
          }
        | null;
    } | null>;
  };
};

export type GetVulnerabilityScoresQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<VulnerabilityScoreOrder>;
  filter?: InputMaybe<VulnerabilityScoreFilter>;
}>;

export type GetVulnerabilityScoresQuery = {
  __typename: "Query";
  vulnerabilityScores?:
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string }
    | {
        __typename: "VulnerabilityScoresConnection";
        totalCount: number;
        filteredTotalCount: number;
        edges?: Array<{
          __typename: "VulnerabilityScoreEdge";
          cursor: string;
          node?: {
            __typename: "VulnerabilityScore";
            mrn: string;
            id: string;
            title: string;
            state: ScoreState;
            iconId: Icon_Ids;
            riskScore?: number | null;
            scoreType: VulnerabilityScoreType;
            firstDetectedAt: string;
            publishedAt?: string | null;
            updatedAt: string;
            asset?: {
              __typename: "EntityInfoAsset";
              icon?: Icon_Ids | null;
              id: string;
              mrn: string;
              name: string;
              tags?: Array<{
                __typename: "Tag";
                key: string;
                value: string;
              } | null> | null;
            } | null;
            epss?: {
              __typename: "EpssScore";
              probability: number;
              percentile: number;
            } | null;
            cvss?: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            } | null;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              affected: number;
              total: number;
            } | null> | null;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | null;
};

export type IsActivePolicyQueryVariables = Exact<{
  input: PolicyInput;
}>;

export type IsActivePolicyQuery = {
  __typename: "Query";
  policy?: {
    __typename: "Policy";
    action?: PolicyAction | null;
    mrn: string;
  } | null;
};

export type ListClientIntegrationsQueryVariables = Exact<{
  input: ListClientIntegrationsInput;
}>;

export type ListClientIntegrationsQuery = {
  __typename: "Query";
  listClientIntegrations: {
    __typename: "ListClientIntegrationsPayload";
    integrations: Array<{
      __typename: "ClientIntegration";
      mrn: string;
      name: string;
      status: string;
      created: string;
      type: ClientIntegrationType;
      lastCheckin?: string | null;
      messages?: Array<{
        __typename: "IntegrationMessage";
        message: string;
        status: IntegrationMessageStatus;
      }> | null;
      configurationOptions?:
        | {
            __typename: "AWSConfigurationOptions";
            accountIds?: Array<string> | null;
            region: string;
            isOrganization?: boolean | null;
            snsEndpoint: string;
            originAWSAccountId: string;
            scanConfiguration: {
              __typename: "ScanConfiguration";
              ecrScan: boolean;
              ecsScan: boolean;
              cronScaninHours: number;
              accountScan: boolean;
              ec2Scan: boolean;
              ec2ScanOptions: {
                __typename: "Ec2ScanOptions";
                ebsVolumeScan: boolean;
                vaultType?: string | null;
                tagsFilter: any;
                regionsFilter?: Array<string> | null;
                instanceIdsFilter?: Array<string> | null;
                secretsMetadataQuery?: string | null;
                awsSecretsManagerVault?: boolean | null;
                ssm: boolean;
                instanceConnect: boolean;
                ebsScanOptions?: {
                  __typename: "EbsScanOptions";
                  maxAsgInstances?: number | null;
                  targetInstancesPerScanner?: number | null;
                } | null;
              };
              eventScanTriggers?: Array<{
                __typename: "AWSEventPattern";
                eventDetailType: string;
                eventSource: string;
                scanType: string;
              } | null> | null;
            };
          }
        | { __typename: "AgentOptions" }
        | { __typename: "AwsS3ConfigurationOptions" }
        | { __typename: "AzureBlobConfigurationOptions" }
        | { __typename: "AzureConfigurationOptions" }
        | { __typename: "BigqueryConfigurationOptions" }
        | { __typename: "EmailConfigurationOptions" }
        | { __typename: "GcpConfigurationOptions" }
        | { __typename: "GcsBucketConfigurationOptions" }
        | {
            __typename: "GithubConfigurationOptions";
            owner?: string | null;
            repository?: string | null;
            organization?: string | null;
            type: GithubIntegrationType;
            reposAllowList?: Array<string> | null;
            reposDenyList?: Array<string> | null;
          }
        | { __typename: "GithubTicketingConfigurationOptions" }
        | { __typename: "GitlabConfigurationOptions" }
        | { __typename: "GitlabTicketingConfigurationOptions" }
        | { __typename: "GoogleWorkspaceConfigurationOptions" }
        | { __typename: "HostConfigurationOptions" }
        | {
            __typename: "HostedAwsConfigurationOptions";
            accessKeyId?: string | null;
            role?: string | null;
          }
        | { __typename: "JiraConfigurationOptions" }
        | {
            __typename: "K8sConfigurationOptions";
            scanDeploys?: boolean | null;
            scanNodes?: boolean | null;
            scanWorkloads?: boolean | null;
            scanPublicImages?: boolean | null;
            certificateManager: string;
          }
        | {
            __typename: "MicrosoftDefenderConfigurationOptions";
            tenantId: string;
            clientId: string;
            subscriptionsAllowlist?: Array<string> | null;
            subscriptionsDenylist?: Array<string> | null;
            secretType?: string | null;
          }
        | { __typename: "Ms365ConfigurationOptions" }
        | { __typename: "OciConfigurationOptions" }
        | { __typename: "OktaConfigurationOptions" }
        | { __typename: "PostgresConfigurationOptions" }
        | { __typename: "S3ConfigurationOptions" }
        | { __typename: "SlackConfigurationOptions" }
        | { __typename: "SnowflakeConfigurationOptions" }
        | { __typename: "ZendeskConfigurationOptions" }
        | null;
      lastStateInfo?: {
        __typename: "K8sIntegrationLastState";
        namespace?: string | null;
        k8sVersion?: string | null;
        operatorVersion?: string | null;
      } | null;
    } | null>;
  };
};

export type ListDiscoveryResultsQueryVariables = Exact<{
  input: ListDiscoveryResultsInput;
}>;

export type ListDiscoveryResultsQuery = {
  __typename: "Query";
  listDiscoveryResults: {
    __typename: "ListDiscoveryResultsPayload";
    stats?: Array<{
      __typename: "DiscoveryStat";
      identifier?: string | null;
      query: string;
      count: number;
      title: string;
    }> | null;
  };
};

export type ListDocumentsQueryVariables = Exact<{
  scopeMrn: Scalars["String"]["input"];
  first: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ListDocumentsQuery = {
  __typename: "Query";
  listDocuments: {
    __typename: "DocumentMetadataConnection";
    totalCount: number;
    edges: Array<{
      __typename: "DocumentMetadataEdge";
      cursor: string;
      node: {
        __typename: "DocumentMetadata";
        mrn: string;
        name: string;
        type: DocumentType;
        format: DocumentFormat;
        status: DocumentStatus;
        statusMessage?: string | null;
        requestedAt: string;
        generatedAt?: string | null;
        createdBy?: {
          __typename: "Author";
          name: string;
          email: string;
        } | null;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type ListExceptionGroupsQueryVariables = Exact<{
  input: ExceptionGroupsInput;
}>;

export type ListExceptionGroupsQuery = {
  __typename: "Query";
  exceptionGroups: Array<{
    __typename: "ExceptionGroup";
    action: ExceptionMutationAction;
    createdAt: string;
    id: string;
    justification?: string | null;
    modifiedAt?: string | null;
    reviewStatus: ReviewStatus;
    scopeMrn: string;
    title: string;
    author?: { __typename: "Author"; email: string; name: string } | null;
    exceptions: Array<
      | {
          __typename: "AssetAdvisoryException";
          mrn: string;
          id: string;
          title: string;
          iconId: Icon_Ids;
          riskScore?: number | null;
          cvss?: {
            __typename: "CvssScore";
            id: string;
            value: number;
            type: number;
            vector: string;
            source: string;
          } | null;
        }
      | {
          __typename: "AssetCheckException";
          mrn: string;
          policyMrn: string;
          policyName: string;
          title: string;
          impact: { __typename: "Impact"; value: number; rating: string };
          score?: {
            __typename: "Score";
            id: string;
            value: number;
            type: number;
            completion: number;
            weight: number;
            message: string;
          } | null;
        }
      | { __typename: "AssetCveException"; mrn: string }
      | {
          __typename: "ControlsEdge";
          node?: {
            __typename: "ComplianceControl";
            mrn: string;
            description: string;
            title: string;
            manual: boolean;
            completion: number;
            state: ControlState;
            framework?: {
              __typename: "ComplianceFramework";
              name: string;
              version: string;
            } | null;
            tags: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            } | null>;
            assetsStats: {
              __typename: "ControlAssetsStats";
              compliantAssets: number;
              failedAssets: number;
              totalAssets: number;
            };
            checksStats: {
              __typename: "ControlChecksStats";
              activeChecks: number;
              snoozedChecks: number;
              disabledChecks: number;
              totalChecks: number;
            };
          } | null;
        }
      | {
          __typename: "SpaceCheckException";
          mrn: string;
          title: string;
          policyMrn: string;
          policyName: string;
          impact: { __typename: "Impact"; value: number; rating: string };
          assetsStats: {
            __typename: "SpaceCheckExceptionAssetStats";
            passed: number;
            failed: number;
            errored: number;
            total: number;
          };
        }
      | null
    >;
    reviewer?: { __typename: "Author"; email: string; name: string } | null;
  } | null>;
};

export type ListSharedSpacesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ListSharedSpacesQuery = {
  __typename: "Query";
  sharedSpaces?: {
    __typename: "UserSharedSpacesConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "UserSharedSpaceEdge";
      cursor: string;
      node: {
        __typename: "Space";
        id: string;
        mrn: string;
        name: string;
        stats?: {
          __typename: "SpaceStatistics";
          riskdistribution: {
            __typename: "RiskDistribution";
            total: number;
            U: number;
            A: number;
            B: number;
            C: number;
            D: number;
            F: number;
            Error: number;
          };
        } | null;
        organization?: {
          __typename: "Organization";
          id: string;
          name: string;
          mrn: string;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type LoadAdvisoryQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LoadAdvisoryQuery = {
  __typename: "Query";
  advisory?: {
    __typename: "Advisory";
    vendorscore?: number | null;
    title?: string | null;
    publishedAt?: string | null;
    mrn: string;
    modifiedAt?: string | null;
    id: string;
    description?: string | null;
    vulnerabilityRiskFactors: {
      __typename: "VulnerabilityRiskFactors";
      knownExploited: boolean;
      remoteExploitable: boolean;
    };
    externalUrls?: Array<{
      __typename: "Reference";
      iconIDEnum?: Icon_Ids | null;
      url: string;
      title?: string | null;
    } | null> | null;
    assets?: Array<{
      __typename: "AssetItem";
      id: string;
      mrn: string;
      name: string;
      platformName?: string | null;
      platformVersion?: string | null;
      advisoryInfo?: {
        __typename: "AssetAdvisoryInfo";
        activeAdvisoryCount?: number | null;
        worstCvssScore?: {
          __typename: "CvssScore";
          id: string;
          source: string;
          type: number;
          value: number;
          vector: string;
        } | null;
      } | null;
      score: { __typename: "Score"; type: number; value: number };
    }> | null;
    fixedByPackages?: Array<{
      __typename: "Package";
      id: string;
      name: string;
      version?: string | null;
      arch?: string | null;
      format?: string | null;
      namespace?: string | null;
      description?: string | null;
      status?: string | null;
      available?: string | null;
      origin?: string | null;
      score: {
        __typename: "CvssScore";
        id: string;
        value: number;
        type: number;
        vector: string;
        source: string;
      };
    }> | null;
    cvssScore: {
      __typename: "CvssScore";
      id: string;
      value: number;
      type: number;
      vector: string;
      source: string;
    };
  } | null;
};

export type LoadAdvisoryCvesQueryVariables = Exact<{
  advisoryId: Scalars["ID"]["input"];
  assetMrn?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LoadAdvisoryCvesQuery = {
  __typename: "Query";
  advisory?: {
    __typename: "Advisory";
    cves?: Array<{
      __typename: "Cve";
      id: string;
      mrn: string;
      riskScore?: number | null;
      title?: string | null;
      description?: string | null;
      summary?: string | null;
      publishedAt?: string | null;
      modifiedAt?: string | null;
      url?: string | null;
      cwe?: string | null;
      state?: CveState | null;
      source: { __typename: "Source"; id: string; name: string; url: string };
      cvssScore: {
        __typename: "CvssScore";
        id: string;
        value: number;
        type: number;
        vector: string;
        source: string;
      };
      assets?: Array<{
        __typename: "AssetItem";
        id: string;
        mrn: string;
        name: string;
        platformName?: string | null;
        platformVersion?: string | null;
        score: {
          __typename: "Score";
          id: string;
          value: number;
          type: number;
          completion: number;
          weight: number;
          message: string;
          grade: string;
        };
        advisoryInfo?: {
          __typename: "AssetAdvisoryInfo";
          activeAdvisoryCount?: number | null;
          worstCvssScore?: {
            __typename: "CvssScore";
            id: string;
            value: number;
            type: number;
            vector: string;
            source: string;
          } | null;
        } | null;
      }> | null;
      vulnerabilityRiskFactors: {
        __typename: "VulnerabilityRiskFactors";
        knownExploited: boolean;
        remoteExploitable: boolean;
      };
    }> | null;
  } | null;
};

export type LoadAgentQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadAgentQuery = {
  __typename: "Query";
  agent?: {
    __typename: "Agent";
    id: string;
    mrn: string;
    name: string;
    hostname: string;
    ipaddress: string;
    createdAt: string;
    status?: {
      __typename: "AgentStatus";
      version?: string | null;
      state?: AgentState | null;
      lastCheckin?: string | null;
    } | null;
    labels: Array<{
      __typename: "KeyValue";
      key: string;
      value?: string | null;
    }>;
    platform?: {
      __typename: "Platform";
      name: string;
      release: string;
      arch: string;
    } | null;
    errors?: Array<{
      __typename: "AgentError";
      timestamp?: string | null;
      message?: string | null;
    }> | null;
  } | null;
};

export type LoadAssetListPoliciesQueryVariables = Exact<{
  input: AssetReportInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<AssetReportPolicyOrder>;
  filter?: InputMaybe<AssetReportListPoliciesFilter>;
}>;

export type LoadAssetListPoliciesQuery = {
  __typename: "Query";
  assetReport?:
    | {
        __typename: "AssetReport";
        listPolicies?: {
          __typename: "AssetReportPolicyListConnection";
          totalCount: number;
          edges: Array<{
            __typename: "AssetReportPolicyListEdge";
            cursor: string;
            node: {
              __typename: "AssetReportPolicy";
              mrn: string;
              name: string;
              version: string;
              category: string;
              action: PolicyAction;
              scoreStats: {
                __typename: "ScoreStats";
                total: number;
                passed: number;
                failed: number;
                incomplete: number;
                errors: number;
                skipped: number;
                worst: number;
                disabled: number;
                snoozed: number;
                unknown: number;
              };
              score: {
                __typename: "Score";
                id: string;
                value: number;
                type: number;
                completion: number;
                weight: number;
                message: string;
                grade: string;
              };
            };
          }>;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
        } | null;
      }
    | { __typename: "CsvReport" }
    | { __typename: "InvalidDataError" }
    | { __typename: "NotFoundError" }
    | null;
};

export type LoadAssetReportQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
  assetLinkInput: AssetLinkInfoInput;
  assetReportInput: AssetReportInput;
  listPoliciesOrderBy?: InputMaybe<AssetReportPolicyOrder>;
  listPoliciesFilter?: InputMaybe<AssetReportListPoliciesFilter>;
  listPoliciesAfter?: InputMaybe<Scalars["String"]["input"]>;
  listPoliciesFirst?: InputMaybe<Scalars["Int"]["input"]>;
  policiesOrderBy?: InputMaybe<AssetReportPolicyOrder>;
  policiesPolicyFilter?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  policiesAfter?: InputMaybe<Scalars["String"]["input"]>;
  policiesFirst?: InputMaybe<Scalars["Int"]["input"]>;
  recommendedFirst?: InputMaybe<Scalars["Int"]["input"]>;
  recommendedAfter?: InputMaybe<Scalars["String"]["input"]>;
  checksFirst?: InputMaybe<Scalars["Int"]["input"]>;
  checksAfter?: InputMaybe<Scalars["String"]["input"]>;
  checksPolicyFilter?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  checksOrderBy?: InputMaybe<AssetReportQueryOrder>;
  checksFilter?: InputMaybe<Scalars["String"]["input"]>;
  checksImpact?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  checksPolicyMrns?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type LoadAssetReportQuery = {
  __typename: "Query";
  getAssetLinkInfo?:
    | {
        __typename: "CiAssetLinkInfo";
        mrn: string;
        assetLinkType: AssetLinkType;
        projectID: string;
      }
    | {
        __typename: "FleetAssetLinkInfo";
        mrn: string;
        assetLinkType: AssetLinkType;
      }
    | null;
  asset?: {
    __typename: "Asset";
    id: string;
    mrn: string;
    name?: string | null;
    state: AssetState;
    updatedAt?: string | null;
    referenceIDs: Array<string>;
    asset_type: string;
    labels: Array<{
      __typename: "KeyValue";
      key: string;
      value?: string | null;
    }>;
    annotations: Array<{
      __typename: "KeyValue";
      key: string;
      value?: string | null;
    }>;
    score: {
      __typename: "Score";
      grade: string;
      value: number;
      type: number;
      completion: number;
      message: string;
      weight: number;
    };
    relatedAssets?: {
      __typename: "RelatedAssets";
      count?: Array<{
        __typename: "RelatedAssetCount";
        isParent: boolean;
        count: number;
        type: string;
      }> | null;
    } | null;
    report?: {
      __typename: "Report";
      stats?: {
        __typename: "ReportStats";
        packages: { __typename: "ReportStatsPackages"; total: number };
      } | null;
    } | null;
    platform?: {
      __typename: "Platform";
      name: string;
      release: string;
      arch: string;
      kind: PlatformKind;
      runtime: string;
      title: string;
    } | null;
  } | null;
  assetReport?:
    | {
        __typename: "AssetReport";
        lastCompletedScoreTime?: string | null;
        overviewGroups: Array<{
          __typename: "AssetOverviewGroup";
          key: string;
          title: string;
          items: Array<{
            __typename: "AssetOverviewItem";
            key: string;
            name: string;
            sources?: Array<
              | { __typename: "KeyValueSource"; key: string; value: string }
              | { __typename: "QuerySource"; mrn: string }
            > | null;
            values: Array<
              | {
                  __typename: "AssetOverviewArrayValue";
                  arrayValue?: Array<
                    | { __typename: "AssetOverviewArrayValue" }
                    | {
                        __typename: "AssetOverviewBoolValue";
                        boolValue: boolean;
                      }
                    | {
                        __typename: "AssetOverviewDictValue";
                        dictValue?: Array<{
                          __typename: "KeyValue";
                          key: string;
                          value?: string | null;
                        }> | null;
                      }
                    | {
                        __typename: "AssetOverviewError";
                        value:
                          | {
                              __typename: "AssetOverviewErrorExecutionFailed";
                              msg: string;
                              queryMrn?: string | null;
                            }
                          | {
                              __typename: "AssetOverviewGenericError";
                              code: string;
                              msg: string;
                            };
                      }
                    | {
                        __typename: "AssetOverviewNullValue";
                        nullValue?: string | null;
                      }
                    | {
                        __typename: "AssetOverviewReferenceValue";
                        displayValue: string;
                        mrn: string;
                        referenceType: AssetOverviewReferenceTypeEnum;
                      }
                    | {
                        __typename: "AssetOverviewStringValue";
                        stringValue: string;
                      }
                    | {
                        __typename: "AssetOverviewTimeValue";
                        timeValue: string;
                      }
                    | {
                        __typename: "AssetOverviewURLValue";
                        displayValue: string;
                        url: string;
                      }
                  > | null;
                }
              | { __typename: "AssetOverviewBoolValue"; boolValue: boolean }
              | {
                  __typename: "AssetOverviewDictValue";
                  dictValue?: Array<{
                    __typename: "KeyValue";
                    key: string;
                    value?: string | null;
                  }> | null;
                }
              | {
                  __typename: "AssetOverviewError";
                  value:
                    | {
                        __typename: "AssetOverviewErrorExecutionFailed";
                        msg: string;
                        queryMrn?: string | null;
                      }
                    | {
                        __typename: "AssetOverviewGenericError";
                        code: string;
                        msg: string;
                      };
                }
              | {
                  __typename: "AssetOverviewNullValue";
                  nullValue?: string | null;
                }
              | {
                  __typename: "AssetOverviewReferenceValue";
                  displayValue: string;
                  mrn: string;
                  referenceType: AssetOverviewReferenceTypeEnum;
                }
              | { __typename: "AssetOverviewStringValue"; stringValue: string }
              | { __typename: "AssetOverviewTimeValue"; timeValue: string }
              | {
                  __typename: "AssetOverviewURLValue";
                  displayValue: string;
                  url: string;
                }
            >;
          }>;
        }>;
        analysis?: {
          __typename: "AssetReportAnalysis";
          eol?: {
            __typename: "EOLAnalysis";
            status: EolStatus;
            date: string;
          } | null;
        } | null;
        score: {
          __typename: "Score";
          grade: string;
          value: number;
          type: number;
          completion: number;
          message: string;
          weight: number;
        };
        listPolicies?: {
          __typename: "AssetReportPolicyListConnection";
          totalCount: number;
          edges: Array<{
            __typename: "AssetReportPolicyListEdge";
            cursor: string;
            node: {
              __typename: "AssetReportPolicy";
              mrn: string;
              name: string;
              version: string;
              category: string;
              action: PolicyAction;
              scoreStats: {
                __typename: "ScoreStats";
                total: number;
                passed: number;
                failed: number;
                incomplete: number;
                errors: number;
                skipped: number;
                worst: number;
                disabled: number;
                snoozed: number;
                unknown: number;
              };
              score: {
                __typename: "Score";
                id: string;
                value: number;
                type: number;
                completion: number;
                weight: number;
                message: string;
                grade: string;
              };
            };
          }>;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
        } | null;
        policies?: {
          __typename: "AssetReportPolicyConnection";
          totalCount: number;
          edges: Array<{
            __typename: "AssetReportPolicyEdge";
            cursor: string;
            node: {
              __typename: "AssetReportPolicyDetails";
              policy: {
                __typename: "Policy";
                name: string;
                mrn: string;
                action?: PolicyAction | null;
                assigned: boolean;
              };
              score: {
                __typename: "Score";
                grade: string;
                value: number;
                type: number;
                completion: number;
                message: string;
                weight: number;
              };
              queries?: Array<{
                __typename: "AssetReportQuery";
                id: string;
                mqueryState: MqueryState;
                mqueryType: MqueryType;
                action: PolicyReportScoreEntryAction;
                data?: any | null;
                policies?: Array<string> | null;
                mquery: {
                  __typename: "Mquery";
                  uid: string;
                  mrn: string;
                  title: string;
                  severity?: number | null;
                  action: PolicyReportScoreEntryAction;
                  code: string;
                  mql: string;
                  docs?: {
                    __typename: "MqueryDocs";
                    desc: string;
                    audit: string;
                    remediation: string;
                  } | null;
                };
                assessment?: {
                  __typename: "PolicyQueryAssessment";
                  state: PolicyReportEntryState;
                  rendered: string;
                  needsUpgrade: boolean;
                  results?: Array<{
                    __typename: "PolicyQueryAssessmentItem";
                    actual: string;
                    expected: string;
                    error: string;
                  }> | null;
                } | null;
                score: {
                  __typename: "Score";
                  value: number;
                  grade: string;
                  type: number;
                  completion: number;
                  message: string;
                  weight: number;
                };
              }> | null;
              scoreStats: {
                __typename: "ScoreStats";
                total: number;
                passed: number;
                failed: number;
                incomplete: number;
                errors: number;
                skipped: number;
                unknown: number;
                worst: number;
                snoozed: number;
                disabled: number;
              };
            };
          }>;
          pageInfo: {
            __typename: "PageInfo";
            hasPreviousPage: boolean;
            hasNextPage: boolean;
            endCursor: string;
            startCursor: string;
          };
        } | null;
        checks?: {
          __typename: "AssetReportQueryConnection";
          totalCount: number;
          edges: Array<{
            __typename: "AssetReportQueryEdge";
            cursor: string;
            node: {
              __typename: "AssetReportQuery";
              id: string;
              mqueryState: MqueryState;
              mqueryType: MqueryType;
              data?: any | null;
              action: PolicyReportScoreEntryAction;
              policies?: Array<string> | null;
              policyId: string;
              policyName: string;
              mquery: {
                __typename: "Mquery";
                uid: string;
                mrn: string;
                title: string;
                mql: string;
                parent?: string | null;
                action: PolicyReportScoreEntryAction;
                docs?: {
                  __typename: "MqueryDocs";
                  desc: string;
                  audit: string;
                  remediations?:
                    | {
                        __typename: "AuthenticationRequired";
                        message: string;
                        code: string;
                      }
                    | {
                        __typename: "Remediations";
                        entries: Array<{
                          __typename: "MqueryRemediation";
                          desc: string;
                          id: string;
                        }>;
                      }
                    | null;
                  references?: Array<{
                    __typename: "MqueryRef";
                    title: string;
                    url: string;
                  }> | null;
                } | null;
                impact?: {
                  __typename: "Impact";
                  value: number;
                  rating: string;
                } | null;
                tags?: Array<{
                  __typename: "Tag";
                  key: string;
                  value: string;
                }> | null;
                properties?: Array<{
                  __typename: "MProperty";
                  uid: string;
                  mrn: string;
                  title: string;
                  mql: string;
                  setByPolicyMrn?: string | null;
                }> | null;
                variants?: Array<{
                  __typename: "Variant";
                  id: string;
                  mrn: string;
                  title: string;
                  icon: string;
                }> | null;
                variantQueries?: Array<{
                  __typename: "MqueryVariant";
                  uid: string;
                  mrn: string;
                  title: string;
                  mql: string;
                  filter: string;
                  filterID: string;
                } | null> | null;
              };
              score: {
                __typename: "Score";
                id: string;
                value: number;
                type: number;
                completion: number;
                weight: number;
                message: string;
                grade: string;
              };
              assessment?: {
                __typename: "PolicyQueryAssessment";
                state: PolicyReportEntryState;
                rendered: string;
                needsUpgrade: boolean;
                results?: Array<{
                  __typename: "PolicyQueryAssessmentItem";
                  actual: string;
                  expected: string;
                  error: string;
                }> | null;
                extendedResults?: Array<{
                  __typename: "PolicyQueryAssessmentResources";
                  resourceName: string;
                  resourceTitle: string;
                  numResources: number;
                  actual?: Array<{
                    __typename: "Resource";
                    name: string;
                    title: string;
                    type: string;
                    path: string;
                    numElements: number;
                    nestedContent: boolean;
                    fields?: Array<{
                      __typename: "Field";
                      name: string;
                      description?: string | null;
                      path?: string | null;
                      type: string;
                      value?: any | null;
                      updatedAt: string;
                      numElements: number;
                      nestedContent: boolean;
                    } | null> | null;
                    assessmentField?: {
                      __typename: "Field";
                      name: string;
                      description?: string | null;
                      path?: string | null;
                      type: string;
                      value?: any | null;
                      updatedAt: string;
                      numElements: number;
                      nestedContent: boolean;
                    } | null;
                  }> | null;
                }> | null;
              } | null;
              exception?: {
                __typename: "AssetReportException";
                id: string;
                reviewStatus: ReviewStatus;
                action: ExceptionMutationAction;
                justification: string;
              } | null;
            };
          }>;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
        } | null;
        recommendedActions?: {
          __typename: "AssetReportRecommendedActionsConnection";
          totalCount: number;
          edges: Array<{
            __typename: "AssetReportRecommendedActionsEdge";
            cursor: string;
            node:
              | {
                  __typename: "RecommendedActionItemAdvisory";
                  id: string;
                  message: string;
                  severity: number;
                }
              | {
                  __typename: "RecommendedActionItemControl";
                  mrn: string;
                  codeId: string;
                  message: string;
                  severity: number;
                  score: {
                    __typename: "Score";
                    grade: string;
                    value: number;
                    type: number;
                    completion: number;
                    message: string;
                    weight: number;
                  };
                };
          }>;
        } | null;
        insights?: {
          __typename: "AssetInsights";
          security: {
            __typename: "AssetInsightsSecurity";
            policyCount: number;
            score: {
              __typename: "Score";
              id: string;
              value: number;
              type: number;
              completion: number;
              weight: number;
              message: string;
              grade: string;
            };
          };
          vulnerability?: {
            __typename: "AssetInsightsVulnerability";
            score: {
              __typename: "Score";
              id: string;
              value: number;
              type: number;
              completion: number;
              weight: number;
              message: string;
              grade: string;
            };
          } | null;
        } | null;
      }
    | { __typename: "CsvReport" }
    | { __typename: "InvalidDataError" }
    | { __typename: "NotFoundError"; code: string; message: string }
    | null;
};

export type LoadAssetReportChecksQueryVariables = Exact<{
  input: AssetReportInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  policyFilter?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  orderBy?: InputMaybe<AssetReportQueryOrder>;
  filter?: InputMaybe<Scalars["String"]["input"]>;
  impact?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  policyMrns?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type LoadAssetReportChecksQuery = {
  __typename: "Query";
  assetReport?:
    | {
        __typename: "AssetReport";
        checks?: {
          __typename: "AssetReportQueryConnection";
          totalCount: number;
          edges: Array<{
            __typename: "AssetReportQueryEdge";
            cursor: string;
            node: {
              __typename: "AssetReportQuery";
              id: string;
              mqueryState: MqueryState;
              mqueryType: MqueryType;
              data?: any | null;
              action: PolicyReportScoreEntryAction;
              policies?: Array<string> | null;
              policyId: string;
              policyName: string;
              mquery: {
                __typename: "Mquery";
                uid: string;
                mrn: string;
                title: string;
                mql: string;
                parent?: string | null;
                action: PolicyReportScoreEntryAction;
                docs?: {
                  __typename: "MqueryDocs";
                  desc: string;
                  audit: string;
                  remediations?:
                    | {
                        __typename: "AuthenticationRequired";
                        message: string;
                        code: string;
                      }
                    | {
                        __typename: "Remediations";
                        entries: Array<{
                          __typename: "MqueryRemediation";
                          desc: string;
                          id: string;
                        }>;
                      }
                    | null;
                  references?: Array<{
                    __typename: "MqueryRef";
                    title: string;
                    url: string;
                  }> | null;
                } | null;
                impact?: {
                  __typename: "Impact";
                  value: number;
                  rating: string;
                } | null;
                tags?: Array<{
                  __typename: "Tag";
                  key: string;
                  value: string;
                }> | null;
                properties?: Array<{
                  __typename: "MProperty";
                  uid: string;
                  mrn: string;
                  title: string;
                  mql: string;
                  setByPolicyMrn?: string | null;
                }> | null;
                variants?: Array<{
                  __typename: "Variant";
                  id: string;
                  mrn: string;
                  title: string;
                  icon: string;
                }> | null;
                variantQueries?: Array<{
                  __typename: "MqueryVariant";
                  uid: string;
                  mrn: string;
                  title: string;
                  mql: string;
                  filter: string;
                  filterID: string;
                } | null> | null;
              };
              score: {
                __typename: "Score";
                id: string;
                value: number;
                type: number;
                completion: number;
                weight: number;
                message: string;
                grade: string;
              };
              assessment?: {
                __typename: "PolicyQueryAssessment";
                state: PolicyReportEntryState;
                rendered: string;
                needsUpgrade: boolean;
                results?: Array<{
                  __typename: "PolicyQueryAssessmentItem";
                  actual: string;
                  expected: string;
                  error: string;
                }> | null;
                extendedResults?: Array<{
                  __typename: "PolicyQueryAssessmentResources";
                  resourceName: string;
                  resourceTitle: string;
                  numResources: number;
                  actual?: Array<{
                    __typename: "Resource";
                    name: string;
                    title: string;
                    type: string;
                    path: string;
                    numElements: number;
                    nestedContent: boolean;
                    fields?: Array<{
                      __typename: "Field";
                      name: string;
                      description?: string | null;
                      path?: string | null;
                      type: string;
                      value?: any | null;
                      updatedAt: string;
                      numElements: number;
                      nestedContent: boolean;
                    } | null> | null;
                    assessmentField?: {
                      __typename: "Field";
                      name: string;
                      description?: string | null;
                      path?: string | null;
                      type: string;
                      value?: any | null;
                      updatedAt: string;
                      numElements: number;
                      nestedContent: boolean;
                    } | null;
                  }> | null;
                }> | null;
              } | null;
              exception?: {
                __typename: "AssetReportException";
                id: string;
                reviewStatus: ReviewStatus;
                action: ExceptionMutationAction;
                justification: string;
              } | null;
            };
          }>;
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string;
            endCursor: string;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
        } | null;
      }
    | { __typename: "CsvReport" }
    | { __typename: "InvalidDataError" }
    | { __typename: "NotFoundError" }
    | null;
};

export type LoadBestPoliciesQueryVariables = Exact<{
  input: RatedPoliciesInput;
}>;

export type LoadBestPoliciesQuery = {
  __typename: "Query";
  bestPolicies?: Array<{
    __typename: "PolicyInfo";
    mrn?: string | null;
    name?: string | null;
    score?: number | null;
  } | null> | null;
};

export type LoadCategoriesQueryVariables = Exact<{
  input: CategoriesListInput;
}>;

export type LoadCategoriesQuery = {
  __typename: "Query";
  categories?: Array<{
    __typename: "Category";
    name: string;
    packs: number;
    policies: number;
    queries: number;
  } | null> | null;
};

export type LoadCicdProjectJobsQueryVariables = Exact<{
  input: CicdProjectJobsInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  labelFilter?: InputMaybe<Array<KeyValueInput> | KeyValueInput>;
}>;

export type LoadCicdProjectJobsQuery = {
  __typename: "Query";
  cicdProjectJobs?: {
    __typename: "CicdProjectJobs";
    id: string;
    spaceMrn: string;
    project: {
      __typename: "CicdProject";
      id: string;
      mrn: string;
      type: string;
      name: string;
      projectURL: string;
      projectID?: string | null;
    };
    jobs?: {
      __typename: "JobsConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "JobEdge";
        cursor: string;
        node:
          | {
              __typename: "AzureDevopsJob";
              id: string;
              mrn: string;
              name: string;
              target?: string | null;
              projectUrl: string;
              job: string;
              jobId: string;
              buildUrl: string;
              userName: string;
              commitSha: string;
              repositoryUrl: string;
              branch: string;
              grade: Grade;
              pipelineKind: GitPipelineKind;
              updatedAt: string;
            }
          | {
              __typename: "CircleCIJob";
              id: string;
              mrn: string;
              name: string;
              target?: string | null;
              projectUrl: string;
              mergeRequestUrl: string;
              job: string;
              jobId: string;
              buildUrl: string;
              userName: string;
              commitSha: string;
              repositoryUrl: string;
              branch: string;
              associatedPullRequests: string;
              workflowId: string;
              workflowJobId: string;
              workflowWorkspaceId: string;
              grade: Grade;
              pipelineKind: GitPipelineKind;
              updatedAt: string;
            }
          | {
              __typename: "GithubJob";
              id: string;
              mrn: string;
              name: string;
              target?: string | null;
              action: string;
              workflow: string;
              job: string;
              runId: string;
              runNumber: string;
              actor: string;
              repository: string;
              eventName: string;
              eventPath: string;
              workspace: string;
              sha: string;
              ref: string;
              headRef: string;
              baseRef: string;
              serverUrl: string;
              grade: Grade;
              pipelineKind: GitPipelineKind;
              updatedAt: string;
            }
          | {
              __typename: "GitlabJob";
              id: string;
              mrn: string;
              name: string;
              target?: string | null;
              projectUrl: string;
              projectId: string;
              projectTitle: string;
              pipelineUrl: string;
              jobId: string;
              jobName: string;
              jobURL: string;
              jobStage: string;
              commitSha: string;
              commitDescription: string;
              commitRefName: string;
              mergeRequestId: string;
              mergeRequestProjectUrl: string;
              userName: string;
              userId: string;
              userEmail: string;
              defaultBranch: string;
              grade: Grade;
              pipelineKind: GitPipelineKind;
              updatedAt: string;
            }
          | {
              __typename: "JenkinsJob";
              id: string;
              mrn: string;
              name: string;
              target?: string | null;
              projectUrl: string;
              job: string;
              jobId: string;
              buildUrl: string;
              userName: string;
              commitSha: string;
              repositoryUrl: string;
              branch: string;
              grade: Grade;
              pipelineKind: GitPipelineKind;
              updatedAt: string;
            }
          | {
              __typename: "KubernetesJob";
              id: string;
              mrn: string;
              grade: Grade;
              updatedAt: string;
              clusterId: string;
              namespace: string;
              uid: string;
              name: string;
              target?: string | null;
              operation: string;
              kind: string;
              author: string;
              ownerName: string;
              ownerUid: string;
              ownerKind: string;
              resourceVersion: string;
            };
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    } | null;
  } | null;
};

export type LoadCicdProjectsQueryVariables = Exact<{
  input: CicdProjectsInput;
}>;

export type LoadCicdProjectsQuery = {
  __typename: "Query";
  cicdProjects?: {
    __typename: "CicdProjects";
    spaceMrn: string;
    projects: {
      __typename: "CicdProjectsConnection";
      totalCount: number;
      edges: Array<{
        __typename: "CicdProjectEdge";
        cursor: string;
        node: {
          __typename: "CicdProject";
          id: string;
          mrn: string;
          type: string;
          name: string;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    };
  } | null;
};

export type LoadCveQueryVariables = Exact<{
  cveId: Scalars["ID"]["input"];
}>;

export type LoadCveQuery = {
  __typename: "Query";
  cve?: {
    __typename: "Cve";
    title?: string | null;
    summary?: string | null;
    state?: CveState | null;
    url?: string | null;
    references?: Array<string | null> | null;
    publishedAt?: string | null;
    modifiedAt?: string | null;
    mrn: string;
    id: string;
    description?: string | null;
    cwe?: string | null;
    source: { __typename: "Source"; id: string; name: string; url: string };
    cvssScore: {
      __typename: "CvssScore";
      vector: string;
      id: string;
      value: number;
      type: number;
      source: string;
    };
    epssScore?: {
      __typename: "EpssScore";
      percentile: number;
      probability: number;
    } | null;
    vulnerabilityRiskFactors: {
      __typename: "VulnerabilityRiskFactors";
      knownExploited: boolean;
      remoteExploitable: boolean;
    };
    exploits?: Array<{
      __typename: "Exploit";
      description?: string | null;
      id: string;
      modified?: string | null;
      mrn: string;
      published?: string | null;
      title?: string | null;
    } | null> | null;
  } | null;
};

export type LoadCveSpaceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  spaceMrn: Scalars["String"]["input"];
}>;

export type LoadCveSpaceQuery = {
  __typename: "Query";
  cve?: {
    __typename: "Cve";
    cwe?: string | null;
    description?: string | null;
    id: string;
    modifiedAt?: string | null;
    mrn: string;
    publishedAt?: string | null;
    url?: string | null;
    references?: Array<string | null> | null;
    state?: CveState | null;
    summary?: string | null;
    title?: string | null;
    cvssScore: {
      __typename: "CvssScore";
      id: string;
      source: string;
      type: number;
      value: number;
      vector: string;
    };
    epssScore?: {
      __typename: "EpssScore";
      percentile: number;
      probability: number;
    } | null;
    source: { __typename: "Source"; id: string; name: string; url: string };
    vulnerabilityRiskFactors: {
      __typename: "VulnerabilityRiskFactors";
      knownExploited: boolean;
      remoteExploitable: boolean;
    };
    exploits?: Array<{
      __typename: "Exploit";
      description?: string | null;
      id: string;
      modified?: string | null;
      mrn: string;
      published?: string | null;
      title?: string | null;
    } | null> | null;
  } | null;
};

export type LoadEmailPreferencesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoadEmailPreferencesQuery = {
  __typename: "Query";
  emailPreferences: Array<{
    __typename: "EmailPreference";
    listID: EmailPreferenceList;
    subscribed: boolean;
    subscribedAt: string;
  } | null>;
};

export type LoadIntegrationsQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadIntegrationsQuery = {
  __typename: "Query";
  integrations: Array<{
    __typename: "Integration";
    mrn: string;
    name: string;
    enabled: boolean;
    options?: {
      __typename: "IntegrationOptions";
      url?: string | null;
      chatid?: string | null;
      token?: string | null;
    } | null;
  } | null>;
};

export type LoadLegalPolicyConsentQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadLegalPolicyConsentQuery = {
  __typename: "Query";
  legalPolicies: Array<{
    __typename: "LegalPolicy";
    name: string;
    id: string;
    consent?: {
      __typename: "Consent";
      mrn: string;
      email: string;
      signatureAt: string;
    } | null;
  } | null>;
};

export type LoadMqueryQueryVariables = Exact<{
  input: MqueryInput;
}>;

export type LoadMqueryQuery = {
  __typename: "Query";
  mquery?: {
    __typename: "Mquery";
    uid: string;
    mrn: string;
    title: string;
    mql: string;
    parent?: string | null;
    action: PolicyReportScoreEntryAction;
    docs?: {
      __typename: "MqueryDocs";
      audit: string;
      desc: string;
      references?: Array<{
        __typename: "MqueryRef";
        title: string;
        url: string;
      }> | null;
      remediations?:
        | {
            __typename: "AuthenticationRequired";
            message: string;
            code: string;
          }
        | {
            __typename: "Remediations";
            entries: Array<{
              __typename: "MqueryRemediation";
              id: string;
              desc: string;
            }>;
          }
        | null;
    } | null;
    impact?: { __typename: "Impact"; value: number; rating: string } | null;
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
    properties?: Array<{
      __typename: "MProperty";
      uid: string;
      mrn: string;
      title: string;
      mql: string;
      setByPolicyMrn?: string | null;
    }> | null;
    variants?: Array<{
      __typename: "Variant";
      id: string;
      mrn: string;
      title: string;
      icon: string;
    }> | null;
    variantQueries?: Array<{
      __typename: "MqueryVariant";
      uid: string;
      mrn: string;
      title: string;
      mql: string;
      filter: string;
      filterID: string;
      docs?: {
        __typename: "MqueryDocs";
        audit: string;
        desc: string;
        references?: Array<{
          __typename: "MqueryRef";
          title: string;
          url: string;
        }> | null;
        remediations?:
          | {
              __typename: "AuthenticationRequired";
              message: string;
              code: string;
            }
          | {
              __typename: "Remediations";
              entries: Array<{
                __typename: "MqueryRemediation";
                id: string;
                desc: string;
              }>;
            }
          | null;
      } | null;
      impact?: { __typename: "Impact"; value: number; rating: string } | null;
      tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
      properties?: Array<{
        __typename: "MProperty";
        uid: string;
        mrn: string;
        title: string;
        mql: string;
        setByPolicyMrn?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type LoadOrganizationQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadOrganizationQuery = {
  __typename: "Query";
  organization?: {
    __typename: "Organization";
    id: string;
    mrn: string;
    name: string;
    capabilities: Array<string>;
  } | null;
};

export type LoadOrganizationForSpaceQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
}>;

export type LoadOrganizationForSpaceQuery = {
  __typename: "Query";
  space?: {
    __typename: "Space";
    id: string;
    mrn: string;
    organization?: {
      __typename: "Organization";
      id: string;
      mrn: string;
      name: string;
      capabilities: Array<string>;
    } | null;
  } | null;
};

export type LoadOrganizationMembersQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadOrganizationMembersQuery = {
  __typename: "Query";
  organization?: {
    __typename: "Organization";
    id: string;
    mrn: string;
    members?: {
      __typename: "MembersConnection";
      edges?: Array<{
        __typename: "MemberEdge";
        cursor: string;
        node?: {
          __typename: "Member";
          user: {
            __typename: "User";
            mrn: string;
            email?: string | null;
            name: string;
          };
          roles: Array<{ __typename: "Role"; title: string; mrn: string }>;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type LoadOrganizationSsoProviderQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadOrganizationSsoProviderQuery = {
  __typename: "Query";
  ssoProvider: {
    __typename: "SSOProviderResponse";
    provider: {
      __typename: "SSOProvider";
      idpEntityID: string;
      ssoUrl: string;
      rpEntityID: string;
      x509Fingerprints: Array<string>;
      enabled: boolean;
    };
  };
};

export type LoadOrganizationStatsQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LoadOrganizationStatsQuery = {
  __typename: "Query";
  organization?: {
    __typename: "Organization";
    id: string;
    mrn: string;
    name: string;
    spacesCount: number;
    spacesList: {
      __typename: "SpaceConnection";
      totalCount: number;
      edges: Array<{
        __typename: "SpaceEdge";
        cursor: string;
        node: {
          __typename: "Space";
          id: string;
          mrn: string;
          name: string;
          stats?: {
            __typename: "SpaceStatistics";
            riskdistribution: {
              __typename: "RiskDistribution";
              total: number;
              U: number;
              A: number;
              B: number;
              C: number;
              D: number;
              F: number;
              Error: number;
            };
          } | null;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    };
  } | null;
};

export type LoadPlatformsQueryVariables = Exact<{
  input: PlatformsListInput;
}>;

export type LoadPlatformsQuery = {
  __typename: "Query";
  platforms?: Array<{
    __typename: "ContentPlatform";
    id: string;
    name: string;
    version: string;
  }> | null;
};

export type LoadPolicyQueryVariables = Exact<{
  input: PolicyInput;
}>;

export type LoadPolicyQuery = {
  __typename: "Query";
  policy?: {
    __typename: "Policy";
    mrn: string;
    assigned: boolean;
    action?: PolicyAction | null;
    access: Access;
    category: string;
    createdAt: string;
    docs: string;
    featured: boolean;
    githubUrl?: string | null;
    name: string;
    scoringSystem?: ScoringSystem | null;
    defaultScoringSystem?: ScoringSystem | null;
    summary: string;
    trustLevel: TrustLevel;
    uid: string;
    updatedAt: string;
    version: string;
    authors?: Array<{
      __typename: "Author";
      email: string;
      name: string;
    }> | null;
    certifiedBy?: { __typename: "Certifier"; name: string } | null;
    groups?: Array<{
      __typename: "PolicyGroup";
      title: string;
      summary: string;
      checks?: Array<{
        __typename: "QueryRef";
        action: PolicyReportScoreEntryAction;
        mrn: string;
        impact?: { __typename: "Impact"; rating: string; value: number } | null;
      }> | null;
      queries?: Array<{
        __typename: "QueryRef";
        action: PolicyReportScoreEntryAction;
        mrn: string;
        impact?: { __typename: "Impact"; rating: string; value: number } | null;
      }> | null;
      policies?: Array<{
        __typename: "PolicyRef";
        action: PolicyReportScoreEntryAction;
        mrn: string;
        impact?: { __typename: "Impact"; rating: string; value: number } | null;
      }> | null;
    }> | null;
    mutationStatistics?: {
      __typename: "PolicyMutationStatistics";
      checks: {
        __typename: "PolicyQueryStatistics";
        active: number;
        ignored: number;
        removed: number;
      };
      queries: {
        __typename: "PolicyQueryStatistics";
        removed: number;
        ignored: number;
        active: number;
      };
    } | null;
    platforms?: Array<{
      __typename: "ContentPlatform";
      id: string;
      name: string;
      version: string;
    }> | null;
    properties?: Array<{
      __typename: "MProperty";
      uid: string;
      mrn: string;
      title: string;
      mql: string;
      setByPolicyMrn?: string | null;
    }> | null;
    queries?: Array<{ __typename: "Mquery"; mrn: string }> | null;
    runCli?: {
      __typename: "RunCli";
      command: string;
      installDocsURL: string;
    } | null;
    statistics: {
      __typename: "PolicyStatistics";
      checks: number;
      policies: number;
      queries: number;
    };
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
    variants?: Array<{
      __typename: "Variant";
      icon: string;
      id: string;
      title: string;
    }> | null;
  } | null;
};

export type LoadRegistrationTokensQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  orderBy?: InputMaybe<RegistrationTokenOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LoadRegistrationTokensQuery = {
  __typename: "Query";
  registrationTokens?: {
    __typename: "RegistrationTokensConnection";
    totalCount: number;
    edges: Array<{
      __typename: "RegistrationTokenEdge";
      cursor: string;
      node: {
        __typename: "RegistrationToken";
        id: string;
        mrn: string;
        description: string;
        token: string;
        createdBy: string;
        createdAt: string;
        expiresAt: string;
        revoked: boolean;
        labels: Array<{
          __typename: "KeyValue";
          key: string;
          value?: string | null;
        }>;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      endCursor: string;
      startCursor: string;
    };
  } | null;
};

export type LoadResourceInvitationsQueryVariables = Exact<{
  resourceMrn?: InputMaybe<Scalars["String"]["input"]>;
  inviteeMrn?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<InvitationOrder>;
}>;

export type LoadResourceInvitationsQuery = {
  __typename: "Query";
  invitations?: {
    __typename: "InvitationsConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "InvitationEdge";
      cursor: string;
      node?: {
        __typename: "Invitation";
        mrn: string;
        resourceMrn: string;
        senderEmail: string;
        inviteeEmail: string;
        roleMrn: string;
        expiresAt: string;
        message: string;
        state: InvitationState;
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type LoadServiceAccountQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadServiceAccountQuery = {
  __typename: "Query";
  serviceAccount?: {
    __typename: "ServiceAccount";
    id: string;
    mrn: string;
    name: string;
    createdAt: string;
    lastUsed?: string | null;
    roles: Array<{ __typename: "Role"; mrn: string; title: string }>;
  } | null;
};

export type LoadSpaceQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
}>;

export type LoadSpaceQuery = {
  __typename: "Query";
  space?: {
    __typename: "Space";
    id: string;
    mrn: string;
    name: string;
    organization?: {
      __typename: "Organization";
      id: string;
      mrn: string;
      name: string;
    } | null;
    settings?: {
      __typename: "SpaceSettings";
      garbageCollectAssetsConfiguration: {
        __typename: "GarbageCollectAssetsConfiguration";
        enable: boolean;
        afterDays: number;
      };
      terminatedAssetsConfiguration: {
        __typename: "TerminatedAssetsConfiguration";
        cleanup: boolean;
        after: number;
      };
      unusedServiceAccountsConfiguration: {
        __typename: "UnusedServiceAccountsConfiguration";
        cleanup: boolean;
        after: number;
      };
      platformVulnerabilityConfiguration: {
        __typename: "PlatformVulnerabilityConfiguration";
        enable: boolean;
      };
      eolAssetsConfiguration: {
        __typename: "EolAssetsConfiguration";
        enable: boolean;
        monthsInAdvance: number;
      };
      casesConfiguration: {
        __typename: "CasesConfiguration";
        autoCreate: boolean;
        aggregationWindow: number;
      };
    } | null;
    stats?: {
      __typename: "SpaceStatistics";
      riskdistribution: {
        __typename: "RiskDistribution";
        total: number;
        U: number;
        A: number;
        B: number;
        C: number;
        D: number;
        F: number;
        Error: number;
      };
    } | null;
  } | null;
};

export type LoadSpaceMembersQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadSpaceMembersQuery = {
  __typename: "Query";
  space?: {
    __typename: "Space";
    id: string;
    mrn: string;
    members?: {
      __typename: "MembersConnection";
      edges?: Array<{
        __typename: "MemberEdge";
        cursor: string;
        node?: {
          __typename: "Member";
          user: {
            __typename: "User";
            mrn: string;
            email?: string | null;
            name: string;
          };
          roles: Array<{ __typename: "Role"; title: string; mrn: string }>;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type LoadSpacePlanQueryVariables = Exact<{
  input: SpacePlanInput;
}>;

export type LoadSpacePlanQuery = {
  __typename: "Query";
  spacePlan?: {
    __typename: "SpacePlanInfo";
    planApplied: boolean;
    scoringSummary: {
      __typename: "SpacePlanScoringSummary";
      total: number;
      pass: number;
      fail: number;
      error: number;
    };
    active: {
      __typename: "PlanningGroup";
      title: string;
      queries?: Array<{
        __typename: "PlanningCheck";
        policies?: Array<string> | null;
        mquery: {
          __typename: "Mquery";
          uid: string;
          mrn: string;
          title: string;
          action: PolicyReportScoreEntryAction;
          impact?: {
            __typename: "Impact";
            rating: string;
            value: number;
          } | null;
        };
        scoreDistribution?: {
          __typename: "MqueryResultsDistribution";
          pass: number;
          fail: number;
          error: number;
          unknown: number;
        } | null;
      }> | null;
    };
    milestones?: Array<{
      __typename: "PlanningMilestone";
      uid: string;
      title: string;
      justification?: string | null;
      endDate?: string | null;
      queries?: Array<{
        __typename: "PlanningCheck";
        policies?: Array<string> | null;
        mquery: {
          __typename: "Mquery";
          uid: string;
          mrn: string;
          title: string;
          action: PolicyReportScoreEntryAction;
          impact?: {
            __typename: "Impact";
            rating: string;
            value: number;
          } | null;
        };
        scoreDistribution?: {
          __typename: "MqueryResultsDistribution";
          pass: number;
          fail: number;
          error: number;
          unknown: number;
        } | null;
      }> | null;
    }> | null;
    exceptions: {
      __typename: "PlanningGroup";
      title: string;
      queries?: Array<{
        __typename: "PlanningCheck";
        policies?: Array<string> | null;
        mquery: {
          __typename: "Mquery";
          uid: string;
          mrn: string;
          title: string;
          action: PolicyReportScoreEntryAction;
          impact?: {
            __typename: "Impact";
            rating: string;
            value: number;
          } | null;
        };
        scoreDistribution?: {
          __typename: "MqueryResultsDistribution";
          pass: number;
          fail: number;
          error: number;
          unknown: number;
        } | null;
      }> | null;
    };
  } | null;
};

export type LoadSpaceStatsQueryVariables = Exact<{
  mrn: Scalars["String"]["input"];
}>;

export type LoadSpaceStatsQuery = {
  __typename: "Query";
  space?: {
    __typename: "Space";
    id: string;
    mrn: string;
    stats?: {
      __typename: "SpaceStatistics";
      assetGroupStatistics: Array<{
        __typename: "AssetGroupStatistics";
        groupType: string;
        count: number;
        listsAssetTypes: Array<{
          __typename: "AssetTypeInfo";
          assetType: string;
          displayName: string;
          shortName: string;
          aliases?: Array<string> | null;
        }>;
        statistics?: Array<{
          __typename: "AssetTypeStatistics";
          count: number;
          type: {
            __typename: "AssetTypeInfo";
            assetType: string;
            displayName: string;
            shortName: string;
          };
        }> | null;
      }>;
      integrationsStatistics: Array<{
        __typename: "IntegrationStatistics";
        active: number;
        pending: number;
        errored: number;
        total: number;
        type: IntegrationType;
      }>;
      riskdistribution: {
        __typename: "RiskDistribution";
        total: number;
        U: number;
        A: number;
        B: number;
        C: number;
        D: number;
        F: number;
        Error: number;
      };
    } | null;
  } | null;
};

export type LoadTopMisconfigsQueryVariables = Exact<{
  input: SpacePolicySummaryInput;
}>;

export type LoadTopMisconfigsQuery = {
  __typename: "Query";
  spacePolicySummary?: {
    __typename: "SpacePolicySummaryPayload";
    spaceMrn: string;
    querySummaries?: {
      __typename: "SpacePolicySummaryQuerySummariesConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "SpacePolicySummaryQuerySummaryEdge";
        cursor: string;
        node?: {
          __typename: "SpacePolicySummaryQuerySummary";
          id: string;
          mqueryState: MqueryState;
          mqueryType: MqueryType;
          mquery: {
            __typename: "Mquery";
            mrn: string;
            parent?: string | null;
            title: string;
            uid: string;
            action: PolicyReportScoreEntryAction;
            impact?: {
              __typename: "Impact";
              rating: string;
              value: number;
            } | null;
          };
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type LoadTopPolicyActionsQueryVariables = Exact<{
  input: TopPolicyActionsInput;
}>;

export type LoadTopPolicyActionsQuery = {
  __typename: "Query";
  topPolicyActions?: Array<{
    __typename: "QueryInfo";
    name?: string | null;
    severity: number;
    codeId?: string | null;
    score?: { __typename: "AssetGradeDistribution"; total: number } | null;
  } | null> | null;
};

export type LoadTopVulnerabilityQueryVariables = Exact<{
  input: TopVulnerabilityInput;
}>;

export type LoadTopVulnerabilityQuery = {
  __typename: "Query";
  topVulnerability?: {
    __typename: "VulnerabilityItem";
    id: string;
    title: string;
    score: number;
    published: string;
    assetsAffectedCount?: number | null;
  } | null;
};

export type LoadUserInvitationsQueryVariables = Exact<{
  resourceMrn?: InputMaybe<Scalars["String"]["input"]>;
  inviteeMrn?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<InvitationOrder>;
}>;

export type LoadUserInvitationsQuery = {
  __typename: "Query";
  invitations?: {
    __typename: "InvitationsConnection";
    edges?: Array<{
      __typename: "InvitationEdge";
      cursor: string;
      node?: {
        __typename: "Invitation";
        mrn: string;
        resourceMrn: string;
        senderEmail: string;
        inviteeEmail: string;
        roleMrn: string;
        expiresAt: string;
        message: string;
        state: InvitationState;
      } | null;
    }> | null;
  } | null;
};

export type LoadViewerQueryVariables = Exact<{ [key: string]: never }>;

export type LoadViewerQuery = {
  __typename: "Query";
  viewer?: {
    __typename: "User";
    mrn: string;
    email?: string | null;
    name: string;
    state?: UserState | null;
    organizations?: Array<{
      __typename: "Organization";
      id: string;
      mrn: string;
      name: string;
      capabilities: Array<string>;
      spacesCount: number;
      subscriptionInfo?: {
        __typename: "SubscriptionInfo";
        basePlan: { __typename: "ProductInfo"; name: string; id: string };
      } | null;
      members?: { __typename: "MembersConnection"; totalCount: number } | null;
      owners?: {
        __typename: "MembersConnection";
        totalCount: number;
        edges?: Array<{
          __typename: "MemberEdge";
          cursor: string;
          node?: {
            __typename: "Member";
            user: {
              __typename: "User";
              mrn: string;
              name: string;
              email?: string | null;
            };
          } | null;
        }> | null;
      } | null;
    }> | null;
    firstSpace?: {
      __typename: "Space";
      id: string;
      mrn: string;
      name: string;
      organization?: {
        __typename: "Organization";
        id: string;
        mrn: string;
        name: string;
      } | null;
      settings?: {
        __typename: "SpaceSettings";
        eolAssetsConfiguration: {
          __typename: "EolAssetsConfiguration";
          enable: boolean;
          monthsInAdvance: number;
        };
        garbageCollectAssetsConfiguration: {
          __typename: "GarbageCollectAssetsConfiguration";
          enable: boolean;
          afterDays: number;
        };
        terminatedAssetsConfiguration: {
          __typename: "TerminatedAssetsConfiguration";
          cleanup: boolean;
          after: number;
        };
        unusedServiceAccountsConfiguration: {
          __typename: "UnusedServiceAccountsConfiguration";
          cleanup: boolean;
          after: number;
        };
        platformVulnerabilityConfiguration: {
          __typename: "PlatformVulnerabilityConfiguration";
          enable: boolean;
        };
        casesConfiguration: {
          __typename: "CasesConfiguration";
          autoCreate: boolean;
          aggregationWindow: number;
        };
      } | null;
      stats?: {
        __typename: "SpaceStatistics";
        riskdistribution: {
          __typename: "RiskDistribution";
          total: number;
          U: number;
          A: number;
          B: number;
          C: number;
          D: number;
          F: number;
          Error: number;
        };
      } | null;
    } | null;
  } | null;
  viewerSettings: Array<{
    __typename: "UserSetting";
    key: string;
    value: string;
  }>;
};

export type LoadWorstPoliciesQueryVariables = Exact<{
  input: RatedPoliciesInput;
}>;

export type LoadWorstPoliciesQuery = {
  __typename: "Query";
  worstPolicies?: Array<{
    __typename: "PolicyInfo";
    mrn?: string | null;
    name?: string | null;
    score?: number | null;
  } | null> | null;
};

export type MqueryAssetScoresQueryVariables = Exact<{
  input: MqueryAssetScoresInput;
  first: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<AssetOrder>;
}>;

export type MqueryAssetScoresQuery = {
  __typename: "Query";
  mqueryAssetScores?: {
    __typename: "MqueryAssetScoresConnection";
    title: string;
    description: string;
    totalCount: number;
    mql: string;
    scoreDistribution: {
      __typename: "MqueryResultsDistribution";
      pass: number;
      fail: number;
      error: number;
      unknown: number;
    };
    edges?: Array<{
      __typename: "MqueryAssetScoresEdge";
      cursor: string;
      node?: {
        __typename: "QueryAssetScore";
        assetMrn?: string | null;
        assetName?: string | null;
        assetPlatform?: {
          __typename: "Platform";
          name: string;
          title: string;
          arch: string;
          version: string;
          kind: PlatformKind;
          runtime: string;
          release: string;
        } | null;
        score?: {
          __typename: "Score";
          id: string;
          value: number;
          type: number;
          completion: number;
          weight: number;
          message: string;
          grade: string;
        } | null;
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    docs?: {
      __typename: "MqueryDocs";
      desc: string;
      remediations?:
        | {
            __typename: "AuthenticationRequired";
            message: string;
            code: string;
          }
        | {
            __typename: "Remediations";
            entries: Array<{
              __typename: "MqueryRemediation";
              id: string;
              desc: string;
            }>;
          }
        | null;
      references?: Array<{
        __typename: "MqueryRef";
        title: string;
        url: string;
      }> | null;
    } | null;
  } | null;
};

export type OrganizationIdSuggestionQueryVariables = Exact<{
  input: IdSuggestionInput;
}>;

export type OrganizationIdSuggestionQuery = {
  __typename: "Query";
  organizationIDSuggestion?: {
    __typename: "IDSuggestionResponse";
    id: string;
    available: boolean;
  } | null;
};

export type PingIntegrationQueryVariables = Exact<{
  input: PingIntegrationInput;
}>;

export type PingIntegrationQuery = {
  __typename: "Query";
  pingIntegration: { __typename: "PingIntegrationPayload"; mrn: string };
};

export type QueryPackQueryVariables = Exact<{
  input: PolicyInput;
}>;

export type QueryPackQuery = {
  __typename: "Query";
  policy?: {
    __typename: "Policy";
    uid: string;
    mrn: string;
    name: string;
    version: string;
    summary: string;
    docs: string;
    category: string;
    trustLevel: TrustLevel;
    access: Access;
    featured: boolean;
    githubUrl?: string | null;
    assigned: boolean;
    updatedAt: string;
    createdAt: string;
    authors?: Array<{
      __typename: "Author";
      name: string;
      email: string;
    }> | null;
    platforms?: Array<{
      __typename: "ContentPlatform";
      id: string;
      name: string;
      version: string;
    }> | null;
    statistics: {
      __typename: "PolicyStatistics";
      checks: number;
      queries: number;
      policies: number;
    };
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
    certifiedBy?: { __typename: "Certifier"; name: string } | null;
    queries?: Array<{
      __typename: "Mquery";
      uid: string;
      mrn: string;
      title: string;
      mql: string;
      action: PolicyReportScoreEntryAction;
      docs?: {
        __typename: "MqueryDocs";
        desc: string;
        remediations?:
          | {
              __typename: "AuthenticationRequired";
              message: string;
              code: string;
            }
          | {
              __typename: "Remediations";
              entries: Array<{
                __typename: "MqueryRemediation";
                id: string;
                desc: string;
              }>;
            }
          | null;
        references?: Array<{
          __typename: "MqueryRef";
          title: string;
          url: string;
        }> | null;
      } | null;
      impact?: { __typename: "Impact"; value: number; rating: string } | null;
      tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
      properties?: Array<{
        __typename: "MProperty";
        uid: string;
        mrn: string;
        title: string;
        mql: string;
        setByPolicyMrn?: string | null;
      }> | null;
    }> | null;
    properties?: Array<{
      __typename: "MProperty";
      uid: string;
      mrn: string;
      title: string;
      mql: string;
      setByPolicyMrn?: string | null;
    }> | null;
    groups?: Array<{
      __typename: "PolicyGroup";
      title: string;
      summary: string;
      checks?: Array<{
        __typename: "QueryRef";
        mrn: string;
        action: PolicyReportScoreEntryAction;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
      }> | null;
      queries?: Array<{
        __typename: "QueryRef";
        mrn: string;
        action: PolicyReportScoreEntryAction;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
      }> | null;
      policies?: Array<{
        __typename: "PolicyRef";
        mrn: string;
        action: PolicyReportScoreEntryAction;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
      }> | null;
    }> | null;
    runCli?: {
      __typename: "RunCli";
      command: string;
      installDocsURL: string;
    } | null;
    mutationStatistics?: {
      __typename: "PolicyMutationStatistics";
      checks: {
        __typename: "PolicyQueryStatistics";
        active: number;
        ignored: number;
        removed: number;
      };
      queries: {
        __typename: "PolicyQueryStatistics";
        active: number;
        ignored: number;
        removed: number;
      };
    } | null;
  } | null;
};

export type RegistryPolicyQueryVariables = Exact<{
  input: PolicyInput;
}>;

export type RegistryPolicyQuery = {
  __typename: "Query";
  policy?: {
    __typename: "Policy";
    uid: string;
    mrn: string;
    name: string;
    version: string;
    summary: string;
    docs: string;
    action?: PolicyAction | null;
    category: string;
    trustLevel: TrustLevel;
    access: Access;
    featured: boolean;
    githubUrl?: string | null;
    assigned: boolean;
    updatedAt: string;
    createdAt: string;
    authors?: Array<{
      __typename: "Author";
      name: string;
      email: string;
    }> | null;
    platforms?: Array<{
      __typename: "ContentPlatform";
      id: string;
      name: string;
      version: string;
    }> | null;
    statistics: {
      __typename: "PolicyStatistics";
      checks: number;
      queries: number;
      policies: number;
    };
    tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
    certifiedBy?: { __typename: "Certifier"; name: string } | null;
    queries?: Array<{
      __typename: "Mquery";
      uid: string;
      mrn: string;
      title: string;
      parent?: string | null;
      mql: string;
      action: PolicyReportScoreEntryAction;
      docs?: {
        __typename: "MqueryDocs";
        desc: string;
        remediations?:
          | {
              __typename: "AuthenticationRequired";
              message: string;
              code: string;
            }
          | {
              __typename: "Remediations";
              entries: Array<{
                __typename: "MqueryRemediation";
                id: string;
                desc: string;
              }>;
            }
          | null;
        references?: Array<{
          __typename: "MqueryRef";
          title: string;
          url: string;
        }> | null;
      } | null;
      impact?: { __typename: "Impact"; value: number; rating: string } | null;
      tags?: Array<{ __typename: "Tag"; key: string; value: string }> | null;
      variants?: Array<{
        __typename: "Variant";
        id: string;
        title: string;
        icon: string;
      }> | null;
      properties?: Array<{
        __typename: "MProperty";
        uid: string;
        mrn: string;
        title: string;
        mql: string;
        setByPolicyMrn?: string | null;
      }> | null;
    }> | null;
    variants?: Array<{
      __typename: "Variant";
      id: string;
      title: string;
      icon: string;
    }> | null;
    variantPlatformFilters?: Array<{
      __typename: "VariantPlatformFilter";
      id: string;
      title: string;
      icon: string;
    }> | null;
    properties?: Array<{
      __typename: "MProperty";
      uid: string;
      mrn: string;
      title: string;
      mql: string;
      setByPolicyMrn?: string | null;
    }> | null;
    groups?: Array<{
      __typename: "PolicyGroup";
      title: string;
      summary: string;
      checks?: Array<{
        __typename: "QueryRef";
        mrn: string;
        action: PolicyReportScoreEntryAction;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
      }> | null;
      queries?: Array<{
        __typename: "QueryRef";
        mrn: string;
        action: PolicyReportScoreEntryAction;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
      }> | null;
      policies?: Array<{
        __typename: "PolicyRef";
        mrn: string;
        action: PolicyReportScoreEntryAction;
        impact?: { __typename: "Impact"; value: number; rating: string } | null;
      }> | null;
    }> | null;
    runCli?: {
      __typename: "RunCli";
      command: string;
      installDocsURL: string;
    } | null;
    mutationStatistics?: {
      __typename: "PolicyMutationStatistics";
      checks: {
        __typename: "PolicyQueryStatistics";
        active: number;
        ignored: number;
        removed: number;
      };
      queries: {
        __typename: "PolicyQueryStatistics";
        active: number;
        ignored: number;
        removed: number;
      };
    } | null;
  } | null;
};

export type SearchAggregateScoreQueryVariables = Exact<{
  query: Scalars["String"]["input"];
  type: SearchType;
  first: Scalars["Int"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<SearchFilters>;
}>;

export type SearchAggregateScoreQuery = {
  __typename: "Query";
  search: {
    __typename: "SearchResultItemConnection";
    scoreTypeDistribution: {
      __typename: "SearchResultScoreTypeDistribution";
      vulnerability: number;
      advisory: number;
      check: number;
      policy: number;
      risk: number;
      asset: number;
    };
    edges?: Array<{
      __typename: "SearchResultItemEdge";
      node?:
        | {
            __typename: "Advisory";
            id: string;
            iconID?: string | null;
            iconIDEnum?: Icon_Ids | null;
            mrn: string;
            title?: string | null;
            description?: string | null;
            vendorscore?: number | null;
            published?: string | null;
            modified?: string | null;
            publishedAt?: string | null;
            modifiedAt?: string | null;
            source: {
              __typename: "Source";
              id: string;
              name: string;
              url: string;
            };
            externalUrls?: Array<{
              __typename: "Reference";
              title?: string | null;
              url: string;
              iconIDEnum?: Icon_Ids | null;
            } | null> | null;
            cves?: Array<{
              __typename: "Cve";
              id: string;
              mrn: string;
              title?: string | null;
              description?: string | null;
              summary?: string | null;
              published?: string | null;
              modified?: string | null;
              publishedAt?: string | null;
              modifiedAt?: string | null;
              url?: string | null;
              cwe?: string | null;
              state?: CveState | null;
              exploitable?: boolean | null;
              references?: Array<string | null> | null;
            }> | null;
            score: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            };
            cvssScore: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            };
            affected?: Array<{
              __typename: "Package";
              id: string;
              iconID?: string | null;
              iconIDEnum?: Icon_Ids | null;
              name: string;
              version?: string | null;
              arch?: string | null;
              format?: string | null;
              namespace?: string | null;
              description?: string | null;
              status?: string | null;
              available?: string | null;
              origin?: string | null;
              exploitable?: boolean | null;
              vulnDiscoveryTime?: string | null;
              type: PackageType;
              advisories: Array<{
                __typename: "Advisory";
                id: string;
                iconID?: string | null;
                iconIDEnum?: Icon_Ids | null;
                mrn: string;
                title?: string | null;
                description?: string | null;
                vendorscore?: number | null;
                published?: string | null;
                modified?: string | null;
                publishedAt?: string | null;
                modifiedAt?: string | null;
              }>;
            }> | null;
            fixed?: Array<{
              __typename: "Package";
              id: string;
              iconID?: string | null;
              iconIDEnum?: Icon_Ids | null;
              name: string;
              version?: string | null;
              arch?: string | null;
              format?: string | null;
              namespace?: string | null;
              description?: string | null;
              status?: string | null;
              available?: string | null;
              origin?: string | null;
              exploitable?: boolean | null;
              vulnDiscoveryTime?: string | null;
              type: PackageType;
            }> | null;
            affectedPackages?: Array<{
              __typename: "Package";
              id: string;
              iconID?: string | null;
              iconIDEnum?: Icon_Ids | null;
              name: string;
              version?: string | null;
              arch?: string | null;
              format?: string | null;
              namespace?: string | null;
              description?: string | null;
              status?: string | null;
              available?: string | null;
              origin?: string | null;
              exploitable?: boolean | null;
              vulnDiscoveryTime?: string | null;
              type: PackageType;
            }> | null;
            fixedByPackages?: Array<{
              __typename: "Package";
              id: string;
              iconID?: string | null;
              iconIDEnum?: Icon_Ids | null;
              name: string;
              version?: string | null;
              arch?: string | null;
              format?: string | null;
              namespace?: string | null;
              description?: string | null;
              status?: string | null;
              available?: string | null;
              origin?: string | null;
              exploitable?: boolean | null;
              vulnDiscoveryTime?: string | null;
              type: PackageType;
            }> | null;
            vulnerabilityRiskFactors: {
              __typename: "VulnerabilityRiskFactors";
              knownExploited: boolean;
              remoteExploitable: boolean;
            };
            assets?: Array<{
              __typename: "AssetItem";
              id: string;
              mrn: string;
              name: string;
              platformName?: string | null;
              platformVersion?: string | null;
              score: {
                __typename: "Score";
                id: string;
                value: number;
                type: number;
                completion: number;
                weight: number;
                message: string;
                grade: string;
              };
              advisoryInfo?: {
                __typename: "AssetAdvisoryInfo";
                activeAdvisoryCount?: number | null;
                worstCvssScore?: {
                  __typename: "CvssScore";
                  id: string;
                  value: number;
                  type: number;
                  vector: string;
                  source: string;
                } | null;
              } | null;
            }> | null;
          }
        | {
            __typename: "AggregateScore";
            iconId: Icon_Ids;
            findingMrn: string;
            rank: number;
            baseScore?: number | null;
            riskScore?: number | null;
            scoreType: AggregateScoreType;
            lastScannedAt?: string | null;
            firstDetectedAt: string;
            remediatedAt: string;
            spaceId: string;
            nodeId: string;
            nodeTitle: string;
            nodeDescription: string;
            entity?:
              | {
                  __typename: "EntityInfoAsset";
                  id: string;
                  mrn: string;
                  name: string;
                }
              | {
                  __typename: "EntityInfoSpace";
                  id: string;
                  mrn: string;
                  name: string;
                }
              | null;
            blastRadius?: {
              __typename: "BlastRadius";
              indicator: string;
              assets: number;
              affected: number;
              critical: number;
              high: number;
              medium: number;
              low: number;
              none: number;
            } | null;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              affected: number;
              total: number;
            } | null> | null;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
            epss?: {
              __typename: "EpssScore";
              probability: number;
              percentile: number;
            } | null;
            cvss?: {
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            } | null;
          }
        | {
            __typename: "Cve";
            id: string;
            mrn: string;
            description?: string | null;
            publishedAt?: string | null;
            modifiedAt?: string | null;
            cwe?: string | null;
            state?: CveState | null;
            references?: Array<string | null> | null;
            cveTitle?: string | null;
            cvssScores: Array<{
              __typename: "CvssScore";
              id: string;
              value: number;
              type: number;
              vector: string;
              source: string;
            } | null>;
            exploits?: Array<{
              __typename: "Exploit";
              id: string;
              title?: string | null;
              description?: string | null;
              published?: string | null;
              modified?: string | null;
            } | null> | null;
            epssScore?: {
              __typename: "EpssScore";
              probability: number;
              percentile: number;
            } | null;
          }
        | null;
    } | null> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type SearchPolicyQueryVariables = Exact<{
  input: ContentSearchInput;
}>;

export type SearchPolicyQuery = {
  __typename: "Query";
  content?: {
    __typename: "ContentSearchResultItemConnection";
    totalCount: number;
    edges: Array<{
      __typename: "ContentSearchResultItemEdge";
      cursor: string;
      node: {
        __typename: "Policy";
        uid: string;
        mrn: string;
        name: string;
        action?: PolicyAction | null;
        version: string;
        summary: string;
        docs: string;
        category: string;
        trustLevel: TrustLevel;
        access: Access;
        featured: boolean;
        assigned: boolean;
        authors?: Array<{
          __typename: "Author";
          name: string;
          email: string;
        }> | null;
        statistics: {
          __typename: "PolicyStatistics";
          checks: number;
          queries: number;
          policies: number;
        };
        certifiedBy?: { __typename: "Certifier"; name: string } | null;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type SearchQueryPackQueryVariables = Exact<{
  input: ContentSearchInput;
}>;

export type SearchQueryPackQuery = {
  __typename: "Query";
  content?: {
    __typename: "ContentSearchResultItemConnection";
    totalCount: number;
    edges: Array<{
      __typename: "ContentSearchResultItemEdge";
      cursor: string;
      node: {
        __typename: "Policy";
        uid: string;
        mrn: string;
        name: string;
        version: string;
        summary: string;
        docs: string;
        category: string;
        trustLevel: TrustLevel;
        access: Access;
        featured: boolean;
        assigned: boolean;
        authors?: Array<{
          __typename: "Author";
          name: string;
          email: string;
        }> | null;
        statistics: {
          __typename: "PolicyStatistics";
          checks: number;
          queries: number;
          policies: number;
        };
        certifiedBy?: { __typename: "Certifier"; name: string } | null;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type ServiceAccountForwardPaginationQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  scopeMrn?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  queryTerms?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  orderBy?: InputMaybe<ServiceAccountOrder>;
}>;

export type ServiceAccountForwardPaginationQuery = {
  __typename: "Query";
  serviceAccounts?: {
    __typename: "ServiceAccountConnection";
    totalCount: number;
    edges?: Array<{
      __typename: "ServiceAccountEdge";
      cursor: string;
      node?: {
        __typename: "ServiceAccount";
        id: string;
        mrn: string;
        name: string;
        description?: string | null;
        createdAt: string;
        lastUsed?: string | null;
        notes?: string | null;
        roles: Array<{ __typename: "Role"; mrn: string; title: string }>;
        labels: Array<{
          __typename: "KeyValue";
          key: string;
          value?: string | null;
        }>;
        creator?: {
          __typename: "ServiceAccountCreator";
          mrn?: string | null;
          email?: string | null;
          service?: string | null;
        } | null;
      } | null;
    }> | null;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
  } | null;
};

export type SpaceAdvisoryForwardPaginationQueryVariables = Exact<{
  spaceMrn: Scalars["String"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SpaceAdvisoryForwardPaginationQuery = {
  __typename: "Query";
  space?: {
    __typename: "Space";
    id: string;
    advisories?: {
      __typename: "AdvisoriesConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "AdvisoryEdge";
        cursor: string;
        node?: {
          __typename: "Advisory";
          id: string;
          title?: string | null;
          publishedAt?: string | null;
          cvssScore: {
            __typename: "CvssScore";
            id: string;
            source: string;
            type: number;
            value: number;
            vector: string;
          };
          vulnerabilityRiskFactors: {
            __typename: "VulnerabilityRiskFactors";
            knownExploited: boolean;
            remoteExploitable: boolean;
          };
          cves?: Array<{
            __typename: "Cve";
            id: string;
            vulnerabilityRiskFactors: {
              __typename: "VulnerabilityRiskFactors";
              knownExploited: boolean;
              remoteExploitable: boolean;
            };
          }> | null;
        } | null;
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
      };
    } | null;
  } | null;
};

export type SpaceCveForwardPaginationQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  spaceMrn: Scalars["String"]["input"];
}>;

export type SpaceCveForwardPaginationQuery = {
  __typename: "Query";
  space?: {
    __typename: "Space";
    id: string;
    mrn: string;
    cves?: {
      __typename: "CvesConnection";
      totalCount: number;
      edges?: Array<{
        __typename: "CveEdge";
        cursor: string;
        node?: {
          __typename: "Cve";
          id: string;
          state?: CveState | null;
          published?: string | null;
          title?: string | null;
          vulnerabilityRiskFactors: {
            __typename: "VulnerabilityRiskFactors";
            knownExploited: boolean;
            remoteExploitable: boolean;
          };
          epssScore?: { __typename: "EpssScore"; probability: number } | null;
          cvssScore: {
            __typename: "CvssScore";
            type: number;
            value: number;
            vector: string;
          };
          score: { __typename: "CvssScore"; value: number; type: number };
        } | null;
      }> | null;
      pageInfo: {
        __typename: "PageInfo";
        startCursor: string;
        endCursor: string;
        hasNextPage: boolean;
      };
    } | null;
  } | null;
};

export type SpaceIdSuggestionQueryVariables = Exact<{
  input: IdSuggestionInput;
}>;

export type SpaceIdSuggestionQuery = {
  __typename: "Query";
  spaceIDSuggestion?: {
    __typename: "IDSuggestionResponse";
    id: string;
    available: boolean;
  } | null;
};

export type SubscriptionInfoQueryVariables = Exact<{
  orgMrn: Scalars["ID"]["input"];
}>;

export type SubscriptionInfoQuery = {
  __typename: "Query";
  subscriptionInfo?: {
    __typename: "SubscriptionInfo";
    isSelfService: boolean;
    pendingCancel: boolean;
    basePlan: {
      __typename: "ProductInfo";
      id: string;
      key: string;
      name: string;
    };
    additions?: Array<{
      __typename: "ProductInfo";
      id: string;
      key: string;
      name: string;
      quantity: number;
    }> | null;
    utilizationMetrics?: Array<{
      __typename: "UtilizationMetric";
      name: string;
      description: string;
      units: string;
      value: number;
      limit?: number | null;
    }> | null;
  } | null;
  subscriptionPlans?: Array<{
    __typename: "SubscriptionPlan";
    isSelfService: boolean;
    isCurrent: boolean;
    isDowngrade: boolean;
    isUpgrade: boolean;
    basePlan: {
      __typename: "Product";
      id: string;
      key: string;
      name: string;
      description: string;
      price: {
        __typename: "Price";
        id: string;
        currency: string;
        cost: number;
      };
      entitlements?: Array<{
        __typename: "Entitlement";
        name: string;
        key: string;
        description: string;
        units: string;
        value: number;
      }> | null;
    };
    additions?: Array<{
      __typename: "Product";
      id: string;
      key: string;
      name: string;
      description: string;
      maxQuantity: number;
      price: {
        __typename: "Price";
        id: string;
        currency: string;
        cost: number;
      };
      entitlements?: Array<{
        __typename: "Entitlement";
        name: string;
        key: string;
        description: string;
        units: string;
        value: number;
      }> | null;
    }> | null;
  }> | null;
};

export type TestIamActionsQueryVariables = Exact<{
  resourceMrn: Scalars["String"]["input"];
  actions: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type TestIamActionsQuery = {
  __typename: "Query";
  testIamActions: Array<string>;
};

export type TriggerActionQueryVariables = Exact<{
  input: TriggerActionInput;
}>;

export type TriggerActionQuery = {
  __typename: "Query";
  triggerAction: { __typename: "TriggerActionPayload"; mrn: string };
};

export type GetPackageScoresQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<PackageScoresOrder>;
  input?: InputMaybe<PackageScoresInput>;
}>;

export type GetPackageScoresQuery = {
  __typename: "Query";
  packageScores?:
    | { __typename: "NotFoundError"; message: string; code: string }
    | {
        __typename: "PackageScores";
        totalCount: number;
        edges?: Array<{
          __typename: "PackageScoreEdge";
          cursor: string;
          node?: {
            __typename: "PackageScore";
            id: string;
            score: number;
            assetName?: string | null;
            lastUpdated: string;
            assetMrn?: string | null;
            platform?: string | null;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              total: number;
              affected: number;
            } | null> | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | null;
};

export type GetSpaceSoftwareQueryVariables = Exact<{
  entityMrn: Scalars["String"]["input"];
  filter?: InputMaybe<AggregateScoreFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  orderBy?: InputMaybe<AggregateScoreOrder>;
}>;

export type GetSpaceSoftwareQuery = {
  __typename: "Query";
  aggregateScores?:
    | {
        __typename: "AggregateScoresConnection";
        totalCount: number;
        edges?: Array<{
          __typename: "AggregateScoreEdge";
          cursor: string;
          node?: {
            __typename: "AggregateScore";
            id: string;
            iconId: Icon_Ids;
            state: AggregateScoreState;
            findingMrn: string;
            rank: number;
            baseScore?: number | null;
            riskScore?: number | null;
            scoreType: AggregateScoreType;
            title: string;
            description: string;
            lastScannedAt?: string | null;
            firstDetectedAt: string;
            remediatedAt: string;
            spaceId: string;
            versionDistribution?: {
              __typename: "VersionDistribution";
              total: number;
              assets: number;
              installations: number;
              distribution?: Array<{
                __typename: "VersionDistributionEntry";
                version: string;
                count: number;
                riskScore: number;
              }> | null;
            } | null;
            entity?:
              | {
                  __typename: "EntityInfoAsset";
                  id: string;
                  mrn: string;
                  name: string;
                }
              | {
                  __typename: "EntityInfoSpace";
                  id: string;
                  mrn: string;
                  name: string;
                }
              | null;
            blastRadius?: {
              __typename: "BlastRadius";
              indicator: string;
              assets: number;
              affected: number;
              critical: number;
              high: number;
              medium: number;
              low: number;
              none: number;
              snoozed: number;
              disabled: number;
            } | null;
            riskFactors?: Array<{
              __typename: "RiskFactorStats";
              mrn: string;
              indicator: string;
              title: string;
              total: number;
              affected: number;
            } | null> | null;
            tags?: Array<{
              __typename: "Tag";
              key: string;
              value: string;
            }> | null;
            epss?: {
              __typename: "EpssScore";
              percentile: number;
              probability: number;
            } | null;
            cvss?: {
              __typename: "CvssScore";
              value: number;
              id: string;
              type: number;
              vector: string;
              source: string;
            } | null;
          } | null;
        }> | null;
        pageInfo: {
          __typename: "PageInfo";
          startCursor: string;
          endCursor: string;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
      }
    | { __typename: "NotFoundError"; message: string; code: string }
    | { __typename: "RequestError"; message: string; code: string }
    | null;
};

export const VulnerabilityRiskFactorsFieldsFragmentDoc = gql`
  fragment VulnerabilityRiskFactorsFields on VulnerabilityRiskFactors {
    knownExploited
    remoteExploitable
  }
`;
export const AdvisoryNodeFieldsFragmentDoc = gql`
  fragment AdvisoryNodeFields on Advisory {
    id
    title
    publishedAt
    cvssScore {
      id
      source
      type
      value
      vector
    }
    vulnerabilityRiskFactors {
      ...VulnerabilityRiskFactorsFields
    }
    cves {
      id
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
`;
export const AdvisoryFieldsFragmentDoc = gql`
  fragment AdvisoryFields on AdvisoriesConnection {
    totalCount
    edges {
      cursor
      node {
        ...AdvisoryNodeFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
  ${AdvisoryNodeFieldsFragmentDoc}
`;
export const PageInfoFieldsFragmentDoc = gql`
  fragment PageInfoFields on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;
export const CvssScoreFieldsFragmentDoc = gql`
  fragment CvssScoreFields on CvssScore {
    id
    value
    type
    vector
    source
  }
`;
export const AffectedAssetStatsFieldsFragmentDoc = gql`
  fragment AffectedAssetStatsFields on AffectedAssetStats {
    affectedCount
    fixedCount
    totalCount
  }
`;
export const PlatformFieldsFragmentDoc = gql`
  fragment PlatformFields on Platform {
    name
    release
    arch
    kind
    runtime
  }
`;
export const AssetSummariesEdgesFieldsFragmentDoc = gql`
  fragment AssetSummariesEdgesFields on VulnerabilityReportAssetSummariesConnection {
    totalCount
    edges {
      cursor
      node {
        id
        activeAdvisoryCount
        fixedAdvisoryCount
        activeCveCount
        fixedCveCount
        worstCvssScore {
          ...CvssScoreFields
        }
        asset {
          id
          mrn
          state
          name
          labels {
            key
            value
          }
          updatedAt
          platform {
            ...PlatformFields
          }
        }
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
  ${CvssScoreFieldsFragmentDoc}
  ${PlatformFieldsFragmentDoc}
  ${PageInfoFieldsFragmentDoc}
`;
export const AdvisorySummariesFieldsFragmentDoc = gql`
  fragment AdvisorySummariesFields on AdvisorySummariesConnection {
    totalCount
    pageInfo {
      ...PageInfoFields
    }
    edges {
      cursor
      node {
        id
        spaceMrn
        discoveredAt
        advisory {
          id
          mrn
          source {
            id
            name
            url
          }
          title
          description
          cves {
            id
            mrn
            title
            description
            summary
            publishedAt
          }
          cvssScore {
            ...CvssScoreFields
          }
          vendorscore
          publishedAt
          modifiedAt
        }
        cveSummaries {
          id
          cve {
            id
            mrn
            title
            description
            cvssScore {
              ...CvssScoreFields
            }
          }
          discoveredAt
          affectedAssetStats {
            ...AffectedAssetStatsFields
          }
          spaceMrn
        }
        assetSummaries {
          ...AssetSummariesEdgesFields
        }
        affectedAssetStats {
          ...AffectedAssetStatsFields
        }
      }
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${CvssScoreFieldsFragmentDoc}
  ${AffectedAssetStatsFieldsFragmentDoc}
  ${AssetSummariesEdgesFieldsFragmentDoc}
`;
export const AgentFieldsFragmentDoc = gql`
  fragment AgentFields on AgentsConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mrn
        name
        status {
          version
          state
          lastCheckin
        }
        labels {
          key
          value
        }
        platform {
          name
          release
          arch
        }
        hostname
        ipaddress
        createdAt
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
export const AssetFieldsFragmentDoc = gql`
  fragment AssetFields on AssetsConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mrn
        name
        state
        score {
          type
          value
          completion
        }
        updatedAt
        labels {
          key
          value
        }
        platform {
          name
          title
          release
          arch
          kind
          runtime
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
export const AssetGradeDistributionFieldsFragmentDoc = gql`
  fragment AssetGradeDistributionFields on AssetGradeDistribution {
    total
    A
    B
    C
    D
    F
    U
    X
  }
`;
export const CveNodeFieldsFragmentDoc = gql`
  fragment CveNodeFields on Cve {
    id
    state
    published
    title
    vulnerabilityRiskFactors {
      ...VulnerabilityRiskFactorsFields
    }
    epssScore {
      probability
    }
    cvssScore {
      type
      value
      vector
    }
    score {
      value
      type
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
`;
export const CveSummaryFieldsFragmentDoc = gql`
  fragment CveSummaryFields on CveSummariesConnection {
    totalCount
    stats {
      totalDatabaseCves
      totalAssetCves
    }
    edges {
      cursor
      node {
        cve {
          ...CveNodeFields
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
  ${CveNodeFieldsFragmentDoc}
`;
export const AdvisorySummaryFieldsFragmentDoc = gql`
  fragment AdvisorySummaryFields on AdvisorySummariesConnection {
    totalCount
    stats {
      totalDatabaseAdvisories
      totalAssetAdvisories
    }
    edges {
      cursor
      node {
        advisory {
          ...AdvisoryNodeFields
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
  ${AdvisoryNodeFieldsFragmentDoc}
`;
export const AssetPackageDetailsFieldsFragmentDoc = gql`
  fragment AssetPackageDetailsFields on AssetPackageDetails {
    assetMrn
    asset {
      name
    }
    name
    version
    createdAt
    updatedAt
    description
    available
    vulnerabilityRiskFactors {
      ...VulnerabilityRiskFactorsFields
    }
    references
    iconIDEnum
    cvssScore {
      id
      value
      type
      vector
      source
    }
    epssScore {
      percentile
      probability
    }
    cveSummaries(orderBy: { direction: DESC, field: SCORE }) {
      ...CveSummaryFields
    }
    advisorySummaries(orderBy: { direction: DESC, field: SCORE }) {
      ...AdvisorySummaryFields
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
  ${CveSummaryFieldsFragmentDoc}
  ${AdvisorySummaryFieldsFragmentDoc}
`;
export const AssetReportChecksFieldsFragmentDoc = gql`
  fragment AssetReportChecksFields on AssetReportQueryConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mquery {
          uid
          mrn
          title
          docs {
            desc
            audit
            remediations {
              ... on Remediations {
                entries {
                  desc
                  id
                }
              }
              ... on AuthenticationRequired {
                message
                code
              }
            }
            references {
              title
              url
            }
          }
          impact {
            value
            rating
          }
          mql
          tags {
            key
            value
          }
          properties {
            uid
            mrn
            title
            mql
            setByPolicyMrn
          }
          parent
          variants {
            id
            mrn
            title
            icon
          }
          variantQueries {
            uid
            mrn
            title
            mql
            filter
            filterID
          }
          action
        }
        score {
          id
          value
          type
          completion
          weight
          message
          grade
        }
        mqueryState
        mqueryType
        data
        assessment {
          state
          rendered
          results {
            actual
            expected
            error
          }
          extendedResults {
            resourceName
            resourceTitle
            numResources
            actual {
              name
              title
              fields {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
              type
              path
              numElements
              nestedContent
              assessmentField {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
            }
          }
          needsUpgrade
        }
        action
        policies
        policyId
        policyName
        exception {
          id
          reviewStatus
          action
          justification
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;
export const AssetReportDataQueriesFieldsFragmentDoc = gql`
  fragment AssetReportDataQueriesFields on AssetReportQueryConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mquery {
          uid
          mrn
          title
          docs {
            desc
            remediations {
              ... on Remediations {
                entries {
                  id
                  desc
                }
              }
              ... on AuthenticationRequired {
                message
                code
              }
            }
            references {
              title
              url
            }
            audit
            remediation
          }
          impact {
            value
            rating
          }
          mql
          tags {
            key
            value
          }
          properties {
            uid
            mrn
            title
            mql
            setByPolicyMrn
          }
          parent
          variants {
            id
            title
            icon
          }
          variantQueries {
            uid
            mrn
            title
            mql
            filter
            filterID
          }
          action
        }
        score {
          id
          value
          type
          completion
          weight
          message
          grade
        }
        mqueryState
        mqueryType
        data
        assessment {
          rendered
          state
          rendered
          results {
            actual
            expected
            error
          }
          extendedResults {
            resourceName
            resourceTitle
            numResources
            actual {
              name
              title
              fields {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
              type
              path
              numElements
              nestedContent
              assessmentField {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
            }
          }
          needsUpgrade
        }
        action
        policies
        policyId
        policyName
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;
export const AssetReportListPoliciesFieldsFragmentDoc = gql`
  fragment AssetReportListPoliciesFields on AssetReportPolicyListConnection {
    totalCount
    edges {
      cursor
      node {
        mrn
        name
        version
        category
        action
        scoreStats {
          total
          passed
          failed
          incomplete
          errors
          skipped
          worst
          disabled
          snoozed
          unknown
        }
        score {
          id
          value
          type
          completion
          weight
          message
          grade
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;
export const BlastRadiusFieldsFragmentDoc = gql`
  fragment BlastRadiusFields on BlastRadius {
    indicator
    assets
    affected
    critical
    high
    medium
    low
    none
    snoozed
    disabled
  }
`;
export const CveFieldsFragmentDoc = gql`
  fragment CveFields on CvesConnection {
    totalCount
    edges {
      cursor
      node {
        ...CveNodeFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
  ${CveNodeFieldsFragmentDoc}
`;
export const CveSummariesEdgesFieldsFragmentDoc = gql`
  fragment CveSummariesEdgesFields on CveSummariesConnection {
    totalCount
    edges {
      cursor
      node {
        id
        cve {
          id
          mrn
          title
          description
          summary
          publishedAt
          modifiedAt
          url
          source {
            id
            name
            url
          }
          cvssScore {
            ...CvssScoreFields
          }
          state
        }
        assetSummaries {
          ...AssetSummariesEdgesFields
        }
        discoveredAt
        affectedAssetStats {
          ...AffectedAssetStatsFields
        }
        spaceMrn
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
  ${CvssScoreFieldsFragmentDoc}
  ${AssetSummariesEdgesFieldsFragmentDoc}
  ${AffectedAssetStatsFieldsFragmentDoc}
  ${PageInfoFieldsFragmentDoc}
`;
export const MqueryDocsFieldsFragmentDoc = gql`
  fragment MqueryDocsFields on MqueryDocs {
    audit
    desc
    references {
      title
      url
    }
    remediations {
      ... on Remediations {
        entries {
          id
          desc
        }
      }
      ... on AuthenticationRequired {
        message
        code
      }
    }
  }
`;
export const PackageFieldsFragmentDoc = gql`
  fragment PackageFields on Package {
    id
    name
    version
    arch
    available
    status
    vulnerabilityRiskFactors {
      ...VulnerabilityRiskFactorsFields
    }
    vulnDiscoveryTime
    iconIDEnum
    cvssScore {
      type
      value
      vector
    }
    epssScore {
      percentile
      probability
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
`;
export const RemediationPackageFieldsFragmentDoc = gql`
  fragment RemediationPackageFields on Package {
    id
    name
    version
    namespace
    arch
  }
`;
export const RemediationScriptFieldsFragmentDoc = gql`
  fragment RemediationScriptFields on RemediationScript {
    type
    script
    packageManager
    platformName
    platformVersion
  }
`;
export const RiskDistributionFragmentDoc = gql`
  fragment RiskDistribution on SpaceStatistics {
    riskdistribution {
      total
      U
      A
      B
      C
      D
      F
      Error
    }
  }
`;
export const ServiceAccountFieldsFragmentDoc = gql`
  fragment ServiceAccountFields on ServiceAccountConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mrn
        name
        description
        roles {
          mrn
          title
        }
        createdAt
        lastUsed
        labels {
          key
          value
        }
        creator {
          mrn
          email
          service
        }
        notes
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;
export const AcceptInvitationDocument = gql`
  mutation AcceptInvitation($invitationMrn: ID!) {
    acceptInvitation(invitationMrn: $invitationMrn)
  }
`;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      invitationMrn: // value for 'invitationMrn'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >(AcceptInvitationDocument, options);
}
export type AcceptInvitationMutationHookResult = ReturnType<
  typeof useAcceptInvitationMutation
>;
export type AcceptInvitationMutationResult =
  Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>;
export const AcceptLegalPolicyDocument = gql`
  mutation AcceptLegalPolicy($input: AcceptLegalPolicy!) {
    acceptLegalPolicy(input: $input)
  }
`;
export type AcceptLegalPolicyMutationFn = Apollo.MutationFunction<
  AcceptLegalPolicyMutation,
  AcceptLegalPolicyMutationVariables
>;

/**
 * __useAcceptLegalPolicyMutation__
 *
 * To run a mutation, you first call `useAcceptLegalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptLegalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptLegalPolicyMutation, { data, loading, error }] = useAcceptLegalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptLegalPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptLegalPolicyMutation,
    AcceptLegalPolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptLegalPolicyMutation,
    AcceptLegalPolicyMutationVariables
  >(AcceptLegalPolicyDocument, options);
}
export type AcceptLegalPolicyMutationHookResult = ReturnType<
  typeof useAcceptLegalPolicyMutation
>;
export type AcceptLegalPolicyMutationResult =
  Apollo.MutationResult<AcceptLegalPolicyMutation>;
export type AcceptLegalPolicyMutationOptions = Apollo.BaseMutationOptions<
  AcceptLegalPolicyMutation,
  AcceptLegalPolicyMutationVariables
>;
export const ActivateUserInRegionDocument = gql`
  mutation ActivateUserInRegion {
    activateUserInRegion {
      user {
        mrn
      }
    }
  }
`;
export type ActivateUserInRegionMutationFn = Apollo.MutationFunction<
  ActivateUserInRegionMutation,
  ActivateUserInRegionMutationVariables
>;

/**
 * __useActivateUserInRegionMutation__
 *
 * To run a mutation, you first call `useActivateUserInRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserInRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserInRegionMutation, { data, loading, error }] = useActivateUserInRegionMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateUserInRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateUserInRegionMutation,
    ActivateUserInRegionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateUserInRegionMutation,
    ActivateUserInRegionMutationVariables
  >(ActivateUserInRegionDocument, options);
}
export type ActivateUserInRegionMutationHookResult = ReturnType<
  typeof useActivateUserInRegionMutation
>;
export type ActivateUserInRegionMutationResult =
  Apollo.MutationResult<ActivateUserInRegionMutation>;
export type ActivateUserInRegionMutationOptions = Apollo.BaseMutationOptions<
  ActivateUserInRegionMutation,
  ActivateUserInRegionMutationVariables
>;
export const AddPolicyDocument = gql`
  mutation AddPolicy($input: [PolicyAddInput!]!) {
    addPolicy(input: $input)
  }
`;
export type AddPolicyMutationFn = Apollo.MutationFunction<
  AddPolicyMutation,
  AddPolicyMutationVariables
>;

/**
 * __useAddPolicyMutation__
 *
 * To run a mutation, you first call `useAddPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPolicyMutation, { data, loading, error }] = useAddPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPolicyMutation,
    AddPolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPolicyMutation, AddPolicyMutationVariables>(
    AddPolicyDocument,
    options,
  );
}
export type AddPolicyMutationHookResult = ReturnType<
  typeof useAddPolicyMutation
>;
export type AddPolicyMutationResult = Apollo.MutationResult<AddPolicyMutation>;
export type AddPolicyMutationOptions = Apollo.BaseMutationOptions<
  AddPolicyMutation,
  AddPolicyMutationVariables
>;
export const AddQueryPackDocument = gql`
  mutation AddQueryPack($input: [QueryPackAddInput!]!) {
    addQueryPack(input: $input)
  }
`;
export type AddQueryPackMutationFn = Apollo.MutationFunction<
  AddQueryPackMutation,
  AddQueryPackMutationVariables
>;

/**
 * __useAddQueryPackMutation__
 *
 * To run a mutation, you first call `useAddQueryPackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQueryPackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQueryPackMutation, { data, loading, error }] = useAddQueryPackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddQueryPackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddQueryPackMutation,
    AddQueryPackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddQueryPackMutation,
    AddQueryPackMutationVariables
  >(AddQueryPackDocument, options);
}
export type AddQueryPackMutationHookResult = ReturnType<
  typeof useAddQueryPackMutation
>;
export type AddQueryPackMutationResult =
  Apollo.MutationResult<AddQueryPackMutation>;
export type AddQueryPackMutationOptions = Apollo.BaseMutationOptions<
  AddQueryPackMutation,
  AddQueryPackMutationVariables
>;
export const ApplyExceptionDocument = gql`
  mutation ApplyException($input: ExceptionMutationInput!) {
    applyException(input: $input)
  }
`;
export type ApplyExceptionMutationFn = Apollo.MutationFunction<
  ApplyExceptionMutation,
  ApplyExceptionMutationVariables
>;

/**
 * __useApplyExceptionMutation__
 *
 * To run a mutation, you first call `useApplyExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyExceptionMutation, { data, loading, error }] = useApplyExceptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyExceptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyExceptionMutation,
    ApplyExceptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyExceptionMutation,
    ApplyExceptionMutationVariables
  >(ApplyExceptionDocument, options);
}
export type ApplyExceptionMutationHookResult = ReturnType<
  typeof useApplyExceptionMutation
>;
export type ApplyExceptionMutationResult =
  Apollo.MutationResult<ApplyExceptionMutation>;
export type ApplyExceptionMutationOptions = Apollo.BaseMutationOptions<
  ApplyExceptionMutation,
  ApplyExceptionMutationVariables
>;
export const ApplyExceptionForScopesDocument = gql`
  mutation ApplyExceptionForScopes($input: ApplyExceptionForScopesInput!) {
    applyExceptionForScopes(input: $input)
  }
`;
export type ApplyExceptionForScopesMutationFn = Apollo.MutationFunction<
  ApplyExceptionForScopesMutation,
  ApplyExceptionForScopesMutationVariables
>;

/**
 * __useApplyExceptionForScopesMutation__
 *
 * To run a mutation, you first call `useApplyExceptionForScopesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyExceptionForScopesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyExceptionForScopesMutation, { data, loading, error }] = useApplyExceptionForScopesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyExceptionForScopesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyExceptionForScopesMutation,
    ApplyExceptionForScopesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyExceptionForScopesMutation,
    ApplyExceptionForScopesMutationVariables
  >(ApplyExceptionForScopesDocument, options);
}
export type ApplyExceptionForScopesMutationHookResult = ReturnType<
  typeof useApplyExceptionForScopesMutation
>;
export type ApplyExceptionForScopesMutationResult =
  Apollo.MutationResult<ApplyExceptionForScopesMutation>;
export type ApplyExceptionForScopesMutationOptions = Apollo.BaseMutationOptions<
  ApplyExceptionForScopesMutation,
  ApplyExceptionForScopesMutationVariables
>;
export const ApplyExceptionReviewDocument = gql`
  mutation ApplyExceptionReview($input: ExceptionReviewInput!) {
    applyExceptionReview(input: $input)
  }
`;
export type ApplyExceptionReviewMutationFn = Apollo.MutationFunction<
  ApplyExceptionReviewMutation,
  ApplyExceptionReviewMutationVariables
>;

/**
 * __useApplyExceptionReviewMutation__
 *
 * To run a mutation, you first call `useApplyExceptionReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyExceptionReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyExceptionReviewMutation, { data, loading, error }] = useApplyExceptionReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyExceptionReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyExceptionReviewMutation,
    ApplyExceptionReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyExceptionReviewMutation,
    ApplyExceptionReviewMutationVariables
  >(ApplyExceptionReviewDocument, options);
}
export type ApplyExceptionReviewMutationHookResult = ReturnType<
  typeof useApplyExceptionReviewMutation
>;
export type ApplyExceptionReviewMutationResult =
  Apollo.MutationResult<ApplyExceptionReviewMutation>;
export type ApplyExceptionReviewMutationOptions = Apollo.BaseMutationOptions<
  ApplyExceptionReviewMutation,
  ApplyExceptionReviewMutationVariables
>;
export const ApplyFrameworkDocument = gql`
  mutation ApplyFramework($input: ComplianceFrameworkMutationInput!) {
    applyFrameworkMutation(input: $input)
  }
`;
export type ApplyFrameworkMutationFn = Apollo.MutationFunction<
  ApplyFrameworkMutation,
  ApplyFrameworkMutationVariables
>;

/**
 * __useApplyFrameworkMutation__
 *
 * To run a mutation, you first call `useApplyFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyFrameworkMutation, { data, loading, error }] = useApplyFrameworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyFrameworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyFrameworkMutation,
    ApplyFrameworkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyFrameworkMutation,
    ApplyFrameworkMutationVariables
  >(ApplyFrameworkDocument, options);
}
export type ApplyFrameworkMutationHookResult = ReturnType<
  typeof useApplyFrameworkMutation
>;
export type ApplyFrameworkMutationResult =
  Apollo.MutationResult<ApplyFrameworkMutation>;
export type ApplyFrameworkMutationOptions = Apollo.BaseMutationOptions<
  ApplyFrameworkMutation,
  ApplyFrameworkMutationVariables
>;
export const ApplySpacePlanDocument = gql`
  mutation ApplySpacePlan($input: ApplySpacePlanInput!) {
    applySpacePlan(input: $input) {
      ... on ApplySpacePlanSuccess {
        success
      }
      ... on ApplySpacePlanError {
        code
        message
      }
    }
  }
`;
export type ApplySpacePlanMutationFn = Apollo.MutationFunction<
  ApplySpacePlanMutation,
  ApplySpacePlanMutationVariables
>;

/**
 * __useApplySpacePlanMutation__
 *
 * To run a mutation, you first call `useApplySpacePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplySpacePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applySpacePlanMutation, { data, loading, error }] = useApplySpacePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplySpacePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplySpacePlanMutation,
    ApplySpacePlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplySpacePlanMutation,
    ApplySpacePlanMutationVariables
  >(ApplySpacePlanDocument, options);
}
export type ApplySpacePlanMutationHookResult = ReturnType<
  typeof useApplySpacePlanMutation
>;
export type ApplySpacePlanMutationResult =
  Apollo.MutationResult<ApplySpacePlanMutation>;
export type ApplySpacePlanMutationOptions = Apollo.BaseMutationOptions<
  ApplySpacePlanMutation,
  ApplySpacePlanMutationVariables
>;
export const AssignPolicyDocument = gql`
  mutation AssignPolicy($input: PolicyAssignmentInput!) {
    assignPolicy(input: $input)
  }
`;
export type AssignPolicyMutationFn = Apollo.MutationFunction<
  AssignPolicyMutation,
  AssignPolicyMutationVariables
>;

/**
 * __useAssignPolicyMutation__
 *
 * To run a mutation, you first call `useAssignPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPolicyMutation, { data, loading, error }] = useAssignPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPolicyMutation,
    AssignPolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPolicyMutation,
    AssignPolicyMutationVariables
  >(AssignPolicyDocument, options);
}
export type AssignPolicyMutationHookResult = ReturnType<
  typeof useAssignPolicyMutation
>;
export type AssignPolicyMutationResult =
  Apollo.MutationResult<AssignPolicyMutation>;
export type AssignPolicyMutationOptions = Apollo.BaseMutationOptions<
  AssignPolicyMutation,
  AssignPolicyMutationVariables
>;
export const CancelInvitationDocument = gql`
  mutation CancelInvitation($invitationMrn: ID!) {
    cancelInvitation(invitationMrn: $invitationMrn)
  }
`;
export type CancelInvitationMutationFn = Apollo.MutationFunction<
  CancelInvitationMutation,
  CancelInvitationMutationVariables
>;

/**
 * __useCancelInvitationMutation__
 *
 * To run a mutation, you first call `useCancelInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvitationMutation, { data, loading, error }] = useCancelInvitationMutation({
 *   variables: {
 *      invitationMrn: // value for 'invitationMrn'
 *   },
 * });
 */
export function useCancelInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelInvitationMutation,
    CancelInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelInvitationMutation,
    CancelInvitationMutationVariables
  >(CancelInvitationDocument, options);
}
export type CancelInvitationMutationHookResult = ReturnType<
  typeof useCancelInvitationMutation
>;
export type CancelInvitationMutationResult =
  Apollo.MutationResult<CancelInvitationMutation>;
export type CancelInvitationMutationOptions = Apollo.BaseMutationOptions<
  CancelInvitationMutation,
  CancelInvitationMutationVariables
>;
export const ChangeSubscriptionPlanDocument = gql`
  mutation ChangeSubscriptionPlan($input: ChangeSubscriptionPlanInput!) {
    changeSubscriptionPlan(input: $input) {
      __typename
      ... on BillingSession {
        url
      }
      ... on PaymentPreview {
        currency
        recurringCost
        dueToday
        prorationDate
        effectiveDate
      }
    }
  }
`;
export type ChangeSubscriptionPlanMutationFn = Apollo.MutationFunction<
  ChangeSubscriptionPlanMutation,
  ChangeSubscriptionPlanMutationVariables
>;

/**
 * __useChangeSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionPlanMutation, { data, loading, error }] = useChangeSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeSubscriptionPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeSubscriptionPlanMutation,
    ChangeSubscriptionPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeSubscriptionPlanMutation,
    ChangeSubscriptionPlanMutationVariables
  >(ChangeSubscriptionPlanDocument, options);
}
export type ChangeSubscriptionPlanMutationHookResult = ReturnType<
  typeof useChangeSubscriptionPlanMutation
>;
export type ChangeSubscriptionPlanMutationResult =
  Apollo.MutationResult<ChangeSubscriptionPlanMutation>;
export type ChangeSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<
  ChangeSubscriptionPlanMutation,
  ChangeSubscriptionPlanMutationVariables
>;
export const CreateBillingSessionDocument = gql`
  mutation CreateBillingSession($input: BillingSessionInput!) {
    createBillingSession(input: $input) {
      url
    }
  }
`;
export type CreateBillingSessionMutationFn = Apollo.MutationFunction<
  CreateBillingSessionMutation,
  CreateBillingSessionMutationVariables
>;

/**
 * __useCreateBillingSessionMutation__
 *
 * To run a mutation, you first call `useCreateBillingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingSessionMutation, { data, loading, error }] = useCreateBillingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBillingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBillingSessionMutation,
    CreateBillingSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBillingSessionMutation,
    CreateBillingSessionMutationVariables
  >(CreateBillingSessionDocument, options);
}
export type CreateBillingSessionMutationHookResult = ReturnType<
  typeof useCreateBillingSessionMutation
>;
export type CreateBillingSessionMutationResult =
  Apollo.MutationResult<CreateBillingSessionMutation>;
export type CreateBillingSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateBillingSessionMutation,
  CreateBillingSessionMutationVariables
>;
export const CreateCaseDocument = gql`
  mutation CreateCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      mrn
      ticketRefs {
        ticketId
      }
    }
  }
`;
export type CreateCaseMutationFn = Apollo.MutationFunction<
  CreateCaseMutation,
  CreateCaseMutationVariables
>;

/**
 * __useCreateCaseMutation__
 *
 * To run a mutation, you first call `useCreateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseMutation, { data, loading, error }] = useCreateCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCaseMutation,
    CreateCaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCaseMutation, CreateCaseMutationVariables>(
    CreateCaseDocument,
    options,
  );
}
export type CreateCaseMutationHookResult = ReturnType<
  typeof useCreateCaseMutation
>;
export type CreateCaseMutationResult =
  Apollo.MutationResult<CreateCaseMutation>;
export type CreateCaseMutationOptions = Apollo.BaseMutationOptions<
  CreateCaseMutation,
  CreateCaseMutationVariables
>;
export const CreateClientIntegrationDocument = gql`
  mutation CreateClientIntegration($input: CreateClientIntegrationInput!) {
    createClientIntegration(input: $input) {
      integration {
        mrn
        token
        name
        status
        created
        lastCheckin
        type
        messages {
          message
          status
        }
        configurationOptions {
          ... on K8sConfigurationOptions {
            scanNodes
            scanNodesStyle
            scanWorkloads
            scanDeploys
            certificateManager
            scanPublicImages
            namespaceAllowList
            namespaceDenyList
          }
          ... on AWSConfigurationOptions {
            region
            isOrganization
            snsEndpoint
            originAWSAccountId
            cloudFormationTemplateUrl
            scanConfiguration {
              accountScan
              ec2Scan
              ecrScan
              ecsScan
              cronScaninHours
              eventScanTriggers {
                scanType
                eventSource
                eventDetailType
              }
              ec2ScanOptions {
                ebsVolumeScan
                instanceConnect
                instanceIdsFilter
                regionsFilter
                ssm
                tagsFilter
              }
              vpcConfiguration {
                CIDR
                useDefaultVPC
                useMondooVPC
              }
            }
          }
          ... on HostedAwsConfigurationOptions {
            accessKeyId
            role
          }
          ... on AgentOptions {
            id
            version
            notes
            platform {
              name
              release
              arch
              kind
              runtime
            }
            hostname
            ipaddress
          }
          ... on AzureConfigurationOptions {
            tenantId
            clientId
            subscriptionsWhitelist
            subscriptionsBlacklist
            scanVms
          }
          ... on Ms365ConfigurationOptions {
            tenantId
            clientId
          }
          ... on MicrosoftDefenderConfigurationOptions {
            tenantId
            clientId
            subscriptionsAllowlist
            subscriptionsDenylist
            secretType
          }
          ... on GcpConfigurationOptions {
            projectId
            organizationId
            discoverAll
          }
          ... on OktaConfigurationOptions {
            organization
          }
          ... on GcsBucketConfigurationOptions {
            output
            bucket
          }
          ... on GitlabConfigurationOptions {
            group
            type
            discoverGroups
            discoverProjects
            discoverTerraform
            discoverK8sManifests
            baseUrl
          }
          ... on GithubConfigurationOptions {
            owner
            repository
            githubOrg: organization
            githubType: type
            reposAllowList
            reposDenyList
            githubDiscoverK8sManifests: discoverK8sManifests
            githubDiscoverTerraform: discoverTerraform
            enterpriseUrl
          }
          ... on GoogleWorkspaceConfigurationOptions {
            customerId
            impersonatedUserEmail
          }
          ... on SlackConfigurationOptions {
            placeholder
          }
          ... on PostgresConfigurationOptions {
            host
            port
            database
            user
          }
          ... on OciConfigurationOptions {
            tenancyOcid
            userOcid
            region
            fingerprint
          }
          ... on EmailConfigurationOptions {
            autoCreateTickets
            recipients {
              name
              email
              isDefault
              referenceUrl
            }
          }
          ... on ZendeskConfigurationOptions {
            subdomain
            email
            autoCloseTickets
            autoCreateTickets
          }
        }
      }
    }
  }
`;
export type CreateClientIntegrationMutationFn = Apollo.MutationFunction<
  CreateClientIntegrationMutation,
  CreateClientIntegrationMutationVariables
>;

/**
 * __useCreateClientIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateClientIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientIntegrationMutation, { data, loading, error }] = useCreateClientIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientIntegrationMutation,
    CreateClientIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientIntegrationMutation,
    CreateClientIntegrationMutationVariables
  >(CreateClientIntegrationDocument, options);
}
export type CreateClientIntegrationMutationHookResult = ReturnType<
  typeof useCreateClientIntegrationMutation
>;
export type CreateClientIntegrationMutationResult =
  Apollo.MutationResult<CreateClientIntegrationMutation>;
export type CreateClientIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateClientIntegrationMutation,
  CreateClientIntegrationMutationVariables
>;
export const CreateInvitationDocument = gql`
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      mrn
    }
  }
`;
export type CreateInvitationMutationFn = Apollo.MutationFunction<
  CreateInvitationMutation,
  CreateInvitationMutationVariables
>;

/**
 * __useCreateInvitationMutation__
 *
 * To run a mutation, you first call `useCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationMutation, { data, loading, error }] = useCreateInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvitationMutation,
    CreateInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvitationMutation,
    CreateInvitationMutationVariables
  >(CreateInvitationDocument, options);
}
export type CreateInvitationMutationHookResult = ReturnType<
  typeof useCreateInvitationMutation
>;
export type CreateInvitationMutationResult =
  Apollo.MutationResult<CreateInvitationMutation>;
export type CreateInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateInvitationMutation,
  CreateInvitationMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      mrn
    }
  }
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const CreateServiceAccountDocument = gql`
  mutation CreateServiceAccount($input: CreateServiceAccountInput) {
    createServiceAccount(input: $input) {
      mrn
      certificate
      privateKey
      scopeMrn
      apiEndpoint
      spaceMrn
    }
  }
`;
export type CreateServiceAccountMutationFn = Apollo.MutationFunction<
  CreateServiceAccountMutation,
  CreateServiceAccountMutationVariables
>;

/**
 * __useCreateServiceAccountMutation__
 *
 * To run a mutation, you first call `useCreateServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceAccountMutation, { data, loading, error }] = useCreateServiceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceAccountMutation,
    CreateServiceAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceAccountMutation,
    CreateServiceAccountMutationVariables
  >(CreateServiceAccountDocument, options);
}
export type CreateServiceAccountMutationHookResult = ReturnType<
  typeof useCreateServiceAccountMutation
>;
export type CreateServiceAccountMutationResult =
  Apollo.MutationResult<CreateServiceAccountMutation>;
export type CreateServiceAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceAccountMutation,
  CreateServiceAccountMutationVariables
>;
export const CreateSpaceDocument = gql`
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      mrn
    }
  }
`;
export type CreateSpaceMutationFn = Apollo.MutationFunction<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>;

/**
 * __useCreateSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpaceMutation, { data, loading, error }] = useCreateSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSpaceMutation,
    CreateSpaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSpaceMutation, CreateSpaceMutationVariables>(
    CreateSpaceDocument,
    options,
  );
}
export type CreateSpaceMutationHookResult = ReturnType<
  typeof useCreateSpaceMutation
>;
export type CreateSpaceMutationResult =
  Apollo.MutationResult<CreateSpaceMutation>;
export type CreateSpaceMutationOptions = Apollo.BaseMutationOptions<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>;
export const DeclineInvitationDocument = gql`
  mutation DeclineInvitation($invitationMrn: ID!) {
    declineInvitation(invitationMrn: $invitationMrn)
  }
`;
export type DeclineInvitationMutationFn = Apollo.MutationFunction<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>;

/**
 * __useDeclineInvitationMutation__
 *
 * To run a mutation, you first call `useDeclineInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineInvitationMutation, { data, loading, error }] = useDeclineInvitationMutation({
 *   variables: {
 *      invitationMrn: // value for 'invitationMrn'
 *   },
 * });
 */
export function useDeclineInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineInvitationMutation,
    DeclineInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineInvitationMutation,
    DeclineInvitationMutationVariables
  >(DeclineInvitationDocument, options);
}
export type DeclineInvitationMutationHookResult = ReturnType<
  typeof useDeclineInvitationMutation
>;
export type DeclineInvitationMutationResult =
  Apollo.MutationResult<DeclineInvitationMutation>;
export type DeclineInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>;
export const DeleteAgentsDocument = gql`
  mutation DeleteAgents($input: DeleteAgentsInput!) {
    deleteAgents(input: $input) {
      agentMrns
      errors
    }
  }
`;
export type DeleteAgentsMutationFn = Apollo.MutationFunction<
  DeleteAgentsMutation,
  DeleteAgentsMutationVariables
>;

/**
 * __useDeleteAgentsMutation__
 *
 * To run a mutation, you first call `useDeleteAgentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgentsMutation, { data, loading, error }] = useDeleteAgentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAgentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAgentsMutation,
    DeleteAgentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAgentsMutation,
    DeleteAgentsMutationVariables
  >(DeleteAgentsDocument, options);
}
export type DeleteAgentsMutationHookResult = ReturnType<
  typeof useDeleteAgentsMutation
>;
export type DeleteAgentsMutationResult =
  Apollo.MutationResult<DeleteAgentsMutation>;
export type DeleteAgentsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAgentsMutation,
  DeleteAgentsMutationVariables
>;
export const DeleteApiTokenDocument = gql`
  mutation DeleteAPIToken($input: DeleteAPITokenInput!) {
    deleteAPIToken(input: $input) {
      ok
    }
  }
`;
export type DeleteApiTokenMutationFn = Apollo.MutationFunction<
  DeleteApiTokenMutation,
  DeleteApiTokenMutationVariables
>;

/**
 * __useDeleteApiTokenMutation__
 *
 * To run a mutation, you first call `useDeleteApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiTokenMutation, { data, loading, error }] = useDeleteApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApiTokenMutation,
    DeleteApiTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApiTokenMutation,
    DeleteApiTokenMutationVariables
  >(DeleteApiTokenDocument, options);
}
export type DeleteApiTokenMutationHookResult = ReturnType<
  typeof useDeleteApiTokenMutation
>;
export type DeleteApiTokenMutationResult =
  Apollo.MutationResult<DeleteApiTokenMutation>;
export type DeleteApiTokenMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiTokenMutation,
  DeleteApiTokenMutationVariables
>;
export const DeleteAssetsDocument = gql`
  mutation DeleteAssets($input: DeleteAssetsInput!) {
    deleteAssets(input: $input) {
      assetMrns
      errors
    }
  }
`;
export type DeleteAssetsMutationFn = Apollo.MutationFunction<
  DeleteAssetsMutation,
  DeleteAssetsMutationVariables
>;

/**
 * __useDeleteAssetsMutation__
 *
 * To run a mutation, you first call `useDeleteAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetsMutation, { data, loading, error }] = useDeleteAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetsMutation,
    DeleteAssetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetsMutation,
    DeleteAssetsMutationVariables
  >(DeleteAssetsDocument, options);
}
export type DeleteAssetsMutationHookResult = ReturnType<
  typeof useDeleteAssetsMutation
>;
export type DeleteAssetsMutationResult =
  Apollo.MutationResult<DeleteAssetsMutation>;
export type DeleteAssetsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetsMutation,
  DeleteAssetsMutationVariables
>;
export const DeleteCicdProjectsDocument = gql`
  mutation DeleteCicdProjects($input: DeleteProjectsInput!) {
    deleteCicdProjects(input: $input) {
      mrns
    }
  }
`;
export type DeleteCicdProjectsMutationFn = Apollo.MutationFunction<
  DeleteCicdProjectsMutation,
  DeleteCicdProjectsMutationVariables
>;

/**
 * __useDeleteCicdProjectsMutation__
 *
 * To run a mutation, you first call `useDeleteCicdProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCicdProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCicdProjectsMutation, { data, loading, error }] = useDeleteCicdProjectsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCicdProjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCicdProjectsMutation,
    DeleteCicdProjectsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCicdProjectsMutation,
    DeleteCicdProjectsMutationVariables
  >(DeleteCicdProjectsDocument, options);
}
export type DeleteCicdProjectsMutationHookResult = ReturnType<
  typeof useDeleteCicdProjectsMutation
>;
export type DeleteCicdProjectsMutationResult =
  Apollo.MutationResult<DeleteCicdProjectsMutation>;
export type DeleteCicdProjectsMutationOptions = Apollo.BaseMutationOptions<
  DeleteCicdProjectsMutation,
  DeleteCicdProjectsMutationVariables
>;
export const DeleteClientIntegrationDocument = gql`
  mutation DeleteClientIntegration($input: DeleteClientIntegrationInput!) {
    deleteClientIntegration(input: $input) {
      mrn
    }
  }
`;
export type DeleteClientIntegrationMutationFn = Apollo.MutationFunction<
  DeleteClientIntegrationMutation,
  DeleteClientIntegrationMutationVariables
>;

/**
 * __useDeleteClientIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteClientIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientIntegrationMutation, { data, loading, error }] = useDeleteClientIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientIntegrationMutation,
    DeleteClientIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientIntegrationMutation,
    DeleteClientIntegrationMutationVariables
  >(DeleteClientIntegrationDocument, options);
}
export type DeleteClientIntegrationMutationHookResult = ReturnType<
  typeof useDeleteClientIntegrationMutation
>;
export type DeleteClientIntegrationMutationResult =
  Apollo.MutationResult<DeleteClientIntegrationMutation>;
export type DeleteClientIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientIntegrationMutation,
  DeleteClientIntegrationMutationVariables
>;
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($documentMrn: String!) {
    deleteDocument(documentMRN: $documentMrn)
  }
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      documentMrn: // value for 'documentMrn'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>;
export type DeleteDocumentMutationResult =
  Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const DeleteFrameworkDocument = gql`
  mutation DeleteFramework($input: DeleteFrameworkInput!) {
    deleteFramework(input: $input)
  }
`;
export type DeleteFrameworkMutationFn = Apollo.MutationFunction<
  DeleteFrameworkMutation,
  DeleteFrameworkMutationVariables
>;

/**
 * __useDeleteFrameworkMutation__
 *
 * To run a mutation, you first call `useDeleteFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFrameworkMutation, { data, loading, error }] = useDeleteFrameworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFrameworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFrameworkMutation,
    DeleteFrameworkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFrameworkMutation,
    DeleteFrameworkMutationVariables
  >(DeleteFrameworkDocument, options);
}
export type DeleteFrameworkMutationHookResult = ReturnType<
  typeof useDeleteFrameworkMutation
>;
export type DeleteFrameworkMutationResult =
  Apollo.MutationResult<DeleteFrameworkMutation>;
export type DeleteFrameworkMutationOptions = Apollo.BaseMutationOptions<
  DeleteFrameworkMutation,
  DeleteFrameworkMutationVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($orgMrn: ID!) {
    deleteOrganization(orgMrn: $orgMrn)
  }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      orgMrn: // value for 'orgMrn'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationHookResult = ReturnType<
  typeof useDeleteOrganizationMutation
>;
export type DeleteOrganizationMutationResult =
  Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const DeletePolicyDocument = gql`
  mutation DeletePolicy($input: PolicyDeleteInput!) {
    deletePolicy(input: $input)
  }
`;
export type DeletePolicyMutationFn = Apollo.MutationFunction<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;

/**
 * __useDeletePolicyMutation__
 *
 * To run a mutation, you first call `useDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyMutation, { data, loading, error }] = useDeletePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyMutation,
    DeletePolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePolicyMutation,
    DeletePolicyMutationVariables
  >(DeletePolicyDocument, options);
}
export type DeletePolicyMutationHookResult = ReturnType<
  typeof useDeletePolicyMutation
>;
export type DeletePolicyMutationResult =
  Apollo.MutationResult<DeletePolicyMutation>;
export type DeletePolicyMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;
export const DeleteServiceAccountsDocument = gql`
  mutation DeleteServiceAccounts($input: DeleteServiceAccountsInput!) {
    deleteServiceAccounts(input: $input) {
      mrns
      errors
    }
  }
`;
export type DeleteServiceAccountsMutationFn = Apollo.MutationFunction<
  DeleteServiceAccountsMutation,
  DeleteServiceAccountsMutationVariables
>;

/**
 * __useDeleteServiceAccountsMutation__
 *
 * To run a mutation, you first call `useDeleteServiceAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceAccountsMutation, { data, loading, error }] = useDeleteServiceAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteServiceAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceAccountsMutation,
    DeleteServiceAccountsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceAccountsMutation,
    DeleteServiceAccountsMutationVariables
  >(DeleteServiceAccountsDocument, options);
}
export type DeleteServiceAccountsMutationHookResult = ReturnType<
  typeof useDeleteServiceAccountsMutation
>;
export type DeleteServiceAccountsMutationResult =
  Apollo.MutationResult<DeleteServiceAccountsMutation>;
export type DeleteServiceAccountsMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceAccountsMutation,
  DeleteServiceAccountsMutationVariables
>;
export const DeleteSpaceDocument = gql`
  mutation DeleteSpace($spaceMrn: ID!) {
    deleteSpace(spaceMrn: $spaceMrn)
  }
`;
export type DeleteSpaceMutationFn = Apollo.MutationFunction<
  DeleteSpaceMutation,
  DeleteSpaceMutationVariables
>;

/**
 * __useDeleteSpaceMutation__
 *
 * To run a mutation, you first call `useDeleteSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpaceMutation, { data, loading, error }] = useDeleteSpaceMutation({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useDeleteSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSpaceMutation,
    DeleteSpaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSpaceMutation, DeleteSpaceMutationVariables>(
    DeleteSpaceDocument,
    options,
  );
}
export type DeleteSpaceMutationHookResult = ReturnType<
  typeof useDeleteSpaceMutation
>;
export type DeleteSpaceMutationResult =
  Apollo.MutationResult<DeleteSpaceMutation>;
export type DeleteSpaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteSpaceMutation,
  DeleteSpaceMutationVariables
>;
export const EditPropertiesDocument = gql`
  mutation EditProperties($input: PropertyQueryMutationInput!) {
    applyPropertyQueryMutation(input: $input) {
      ... on PropertyQueryMutationInvalidTarget {
        message
        code
      }
      ... on PropertyQueryMutationInvalidProperty {
        message
        code
      }
      ... on PropertyQueryMutationSuccess {
        success
      }
    }
  }
`;
export type EditPropertiesMutationFn = Apollo.MutationFunction<
  EditPropertiesMutation,
  EditPropertiesMutationVariables
>;

/**
 * __useEditPropertiesMutation__
 *
 * To run a mutation, you first call `useEditPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPropertiesMutation, { data, loading, error }] = useEditPropertiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPropertiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPropertiesMutation,
    EditPropertiesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditPropertiesMutation,
    EditPropertiesMutationVariables
  >(EditPropertiesDocument, options);
}
export type EditPropertiesMutationHookResult = ReturnType<
  typeof useEditPropertiesMutation
>;
export type EditPropertiesMutationResult =
  Apollo.MutationResult<EditPropertiesMutation>;
export type EditPropertiesMutationOptions = Apollo.BaseMutationOptions<
  EditPropertiesMutation,
  EditPropertiesMutationVariables
>;
export const EnableSsoProviderDocument = gql`
  mutation EnableSSOProvider($input: EnableSSOProviderInput!) {
    enableSSOProvider(input: $input)
  }
`;
export type EnableSsoProviderMutationFn = Apollo.MutationFunction<
  EnableSsoProviderMutation,
  EnableSsoProviderMutationVariables
>;

/**
 * __useEnableSsoProviderMutation__
 *
 * To run a mutation, you first call `useEnableSsoProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableSsoProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableSsoProviderMutation, { data, loading, error }] = useEnableSsoProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableSsoProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableSsoProviderMutation,
    EnableSsoProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableSsoProviderMutation,
    EnableSsoProviderMutationVariables
  >(EnableSsoProviderDocument, options);
}
export type EnableSsoProviderMutationHookResult = ReturnType<
  typeof useEnableSsoProviderMutation
>;
export type EnableSsoProviderMutationResult =
  Apollo.MutationResult<EnableSsoProviderMutation>;
export type EnableSsoProviderMutationOptions = Apollo.BaseMutationOptions<
  EnableSsoProviderMutation,
  EnableSsoProviderMutationVariables
>;
export const GenerateApiTokenDocument = gql`
  mutation GenerateAPIToken($input: GenerateAPITokenInput!) {
    generateAPIToken(input: $input) {
      token
      spaceMrn
      scopeMrn
      name
      mrn
      description
      createdBy
      createdAt
    }
  }
`;
export type GenerateApiTokenMutationFn = Apollo.MutationFunction<
  GenerateApiTokenMutation,
  GenerateApiTokenMutationVariables
>;

/**
 * __useGenerateApiTokenMutation__
 *
 * To run a mutation, you first call `useGenerateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiTokenMutation, { data, loading, error }] = useGenerateApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateApiTokenMutation,
    GenerateApiTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateApiTokenMutation,
    GenerateApiTokenMutationVariables
  >(GenerateApiTokenDocument, options);
}
export type GenerateApiTokenMutationHookResult = ReturnType<
  typeof useGenerateApiTokenMutation
>;
export type GenerateApiTokenMutationResult =
  Apollo.MutationResult<GenerateApiTokenMutation>;
export type GenerateApiTokenMutationOptions = Apollo.BaseMutationOptions<
  GenerateApiTokenMutation,
  GenerateApiTokenMutationVariables
>;
export const GenerateDocumentDocument = gql`
  mutation GenerateDocument($input: GenerateDocumentInput!) {
    generateDocument(input: $input) {
      mrn
    }
  }
`;
export type GenerateDocumentMutationFn = Apollo.MutationFunction<
  GenerateDocumentMutation,
  GenerateDocumentMutationVariables
>;

/**
 * __useGenerateDocumentMutation__
 *
 * To run a mutation, you first call `useGenerateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocumentMutation, { data, loading, error }] = useGenerateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateDocumentMutation,
    GenerateDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateDocumentMutation,
    GenerateDocumentMutationVariables
  >(GenerateDocumentDocument, options);
}
export type GenerateDocumentMutationHookResult = ReturnType<
  typeof useGenerateDocumentMutation
>;
export type GenerateDocumentMutationResult =
  Apollo.MutationResult<GenerateDocumentMutation>;
export type GenerateDocumentMutationOptions = Apollo.BaseMutationOptions<
  GenerateDocumentMutation,
  GenerateDocumentMutationVariables
>;
export const GenerateRegistrationTokenDocument = gql`
  mutation GenerateRegistrationToken($input: RegistrationTokenInput!) {
    generateRegistrationToken(input: $input) {
      id
      mrn
      description
      labels {
        key
        value
      }
      token
      revoked
      createdAt
      createdBy
      expiresAt
    }
  }
`;
export type GenerateRegistrationTokenMutationFn = Apollo.MutationFunction<
  GenerateRegistrationTokenMutation,
  GenerateRegistrationTokenMutationVariables
>;

/**
 * __useGenerateRegistrationTokenMutation__
 *
 * To run a mutation, you first call `useGenerateRegistrationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRegistrationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRegistrationTokenMutation, { data, loading, error }] = useGenerateRegistrationTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateRegistrationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateRegistrationTokenMutation,
    GenerateRegistrationTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateRegistrationTokenMutation,
    GenerateRegistrationTokenMutationVariables
  >(GenerateRegistrationTokenDocument, options);
}
export type GenerateRegistrationTokenMutationHookResult = ReturnType<
  typeof useGenerateRegistrationTokenMutation
>;
export type GenerateRegistrationTokenMutationResult =
  Apollo.MutationResult<GenerateRegistrationTokenMutation>;
export type GenerateRegistrationTokenMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateRegistrationTokenMutation,
    GenerateRegistrationTokenMutationVariables
  >;
export const ModifySpaceRiskFactorsDocument = gql`
  mutation ModifySpaceRiskFactors(
    $spaceMrn: String!
    $riskFactors: [ModifyRiskFactorInput!]!
  ) {
    modifyRiskFactors(spaceMrn: $spaceMrn, riskFactors: $riskFactors)
  }
`;
export type ModifySpaceRiskFactorsMutationFn = Apollo.MutationFunction<
  ModifySpaceRiskFactorsMutation,
  ModifySpaceRiskFactorsMutationVariables
>;

/**
 * __useModifySpaceRiskFactorsMutation__
 *
 * To run a mutation, you first call `useModifySpaceRiskFactorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifySpaceRiskFactorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifySpaceRiskFactorsMutation, { data, loading, error }] = useModifySpaceRiskFactorsMutation({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      riskFactors: // value for 'riskFactors'
 *   },
 * });
 */
export function useModifySpaceRiskFactorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifySpaceRiskFactorsMutation,
    ModifySpaceRiskFactorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ModifySpaceRiskFactorsMutation,
    ModifySpaceRiskFactorsMutationVariables
  >(ModifySpaceRiskFactorsDocument, options);
}
export type ModifySpaceRiskFactorsMutationHookResult = ReturnType<
  typeof useModifySpaceRiskFactorsMutation
>;
export type ModifySpaceRiskFactorsMutationResult =
  Apollo.MutationResult<ModifySpaceRiskFactorsMutation>;
export type ModifySpaceRiskFactorsMutationOptions = Apollo.BaseMutationOptions<
  ModifySpaceRiskFactorsMutation,
  ModifySpaceRiskFactorsMutationVariables
>;
export const ProcessCaseEventsDocument = gql`
  mutation ProcessCaseEvents($input: ProcessCaseEventsInput!) {
    processCaseEvents(input: $input)
  }
`;
export type ProcessCaseEventsMutationFn = Apollo.MutationFunction<
  ProcessCaseEventsMutation,
  ProcessCaseEventsMutationVariables
>;

/**
 * __useProcessCaseEventsMutation__
 *
 * To run a mutation, you first call `useProcessCaseEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessCaseEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processCaseEventsMutation, { data, loading, error }] = useProcessCaseEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessCaseEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessCaseEventsMutation,
    ProcessCaseEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProcessCaseEventsMutation,
    ProcessCaseEventsMutationVariables
  >(ProcessCaseEventsDocument, options);
}
export type ProcessCaseEventsMutationHookResult = ReturnType<
  typeof useProcessCaseEventsMutation
>;
export type ProcessCaseEventsMutationResult =
  Apollo.MutationResult<ProcessCaseEventsMutation>;
export type ProcessCaseEventsMutationOptions = Apollo.BaseMutationOptions<
  ProcessCaseEventsMutation,
  ProcessCaseEventsMutationVariables
>;
export const RefreshAggregateScoresDocument = gql`
  mutation RefreshAggregateScores($groupMrn: String!) {
    refreshAggregateScores(groupMrn: $groupMrn)
  }
`;
export type RefreshAggregateScoresMutationFn = Apollo.MutationFunction<
  RefreshAggregateScoresMutation,
  RefreshAggregateScoresMutationVariables
>;

/**
 * __useRefreshAggregateScoresMutation__
 *
 * To run a mutation, you first call `useRefreshAggregateScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAggregateScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAggregateScoresMutation, { data, loading, error }] = useRefreshAggregateScoresMutation({
 *   variables: {
 *      groupMrn: // value for 'groupMrn'
 *   },
 * });
 */
export function useRefreshAggregateScoresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAggregateScoresMutation,
    RefreshAggregateScoresMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshAggregateScoresMutation,
    RefreshAggregateScoresMutationVariables
  >(RefreshAggregateScoresDocument, options);
}
export type RefreshAggregateScoresMutationHookResult = ReturnType<
  typeof useRefreshAggregateScoresMutation
>;
export type RefreshAggregateScoresMutationResult =
  Apollo.MutationResult<RefreshAggregateScoresMutation>;
export type RefreshAggregateScoresMutationOptions = Apollo.BaseMutationOptions<
  RefreshAggregateScoresMutation,
  RefreshAggregateScoresMutationVariables
>;
export const RemoveOrganizationMembershipDocument = gql`
  mutation RemoveOrganizationMembership(
    $input: RemoveOrganizationMembershipInput!
  ) {
    removeOrganizationMembership(input: $input)
  }
`;
export type RemoveOrganizationMembershipMutationFn = Apollo.MutationFunction<
  RemoveOrganizationMembershipMutation,
  RemoveOrganizationMembershipMutationVariables
>;

/**
 * __useRemoveOrganizationMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationMembershipMutation, { data, loading, error }] = useRemoveOrganizationMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrganizationMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrganizationMembershipMutation,
    RemoveOrganizationMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOrganizationMembershipMutation,
    RemoveOrganizationMembershipMutationVariables
  >(RemoveOrganizationMembershipDocument, options);
}
export type RemoveOrganizationMembershipMutationHookResult = ReturnType<
  typeof useRemoveOrganizationMembershipMutation
>;
export type RemoveOrganizationMembershipMutationResult =
  Apollo.MutationResult<RemoveOrganizationMembershipMutation>;
export type RemoveOrganizationMembershipMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveOrganizationMembershipMutation,
    RemoveOrganizationMembershipMutationVariables
  >;
export const RemoveSpaceMembershipDocument = gql`
  mutation RemoveSpaceMembership($input: RemoveSpaceMembershipInput!) {
    removeSpaceMembership(input: $input)
  }
`;
export type RemoveSpaceMembershipMutationFn = Apollo.MutationFunction<
  RemoveSpaceMembershipMutation,
  RemoveSpaceMembershipMutationVariables
>;

/**
 * __useRemoveSpaceMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveSpaceMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSpaceMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSpaceMembershipMutation, { data, loading, error }] = useRemoveSpaceMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSpaceMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSpaceMembershipMutation,
    RemoveSpaceMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSpaceMembershipMutation,
    RemoveSpaceMembershipMutationVariables
  >(RemoveSpaceMembershipDocument, options);
}
export type RemoveSpaceMembershipMutationHookResult = ReturnType<
  typeof useRemoveSpaceMembershipMutation
>;
export type RemoveSpaceMembershipMutationResult =
  Apollo.MutationResult<RemoveSpaceMembershipMutation>;
export type RemoveSpaceMembershipMutationOptions = Apollo.BaseMutationOptions<
  RemoveSpaceMembershipMutation,
  RemoveSpaceMembershipMutationVariables
>;
export const ReportViewedDocument = gql`
  mutation ReportViewed($input: ReportViewedInput!) {
    reportViewed(input: $input)
  }
`;
export type ReportViewedMutationFn = Apollo.MutationFunction<
  ReportViewedMutation,
  ReportViewedMutationVariables
>;

/**
 * __useReportViewedMutation__
 *
 * To run a mutation, you first call `useReportViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportViewedMutation, { data, loading, error }] = useReportViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportViewedMutation,
    ReportViewedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReportViewedMutation,
    ReportViewedMutationVariables
  >(ReportViewedDocument, options);
}
export type ReportViewedMutationHookResult = ReturnType<
  typeof useReportViewedMutation
>;
export type ReportViewedMutationResult =
  Apollo.MutationResult<ReportViewedMutation>;
export type ReportViewedMutationOptions = Apollo.BaseMutationOptions<
  ReportViewedMutation,
  ReportViewedMutationVariables
>;
export const RequestDocumentDownloadUrlDocument = gql`
  mutation RequestDocumentDownloadUrl($documentMrn: String!) {
    requestDocumentDownloadUrl(documentMRN: $documentMrn) {
      downloadUrl
      expiresAt
    }
  }
`;
export type RequestDocumentDownloadUrlMutationFn = Apollo.MutationFunction<
  RequestDocumentDownloadUrlMutation,
  RequestDocumentDownloadUrlMutationVariables
>;

/**
 * __useRequestDocumentDownloadUrlMutation__
 *
 * To run a mutation, you first call `useRequestDocumentDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDocumentDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDocumentDownloadUrlMutation, { data, loading, error }] = useRequestDocumentDownloadUrlMutation({
 *   variables: {
 *      documentMrn: // value for 'documentMrn'
 *   },
 * });
 */
export function useRequestDocumentDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestDocumentDownloadUrlMutation,
    RequestDocumentDownloadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestDocumentDownloadUrlMutation,
    RequestDocumentDownloadUrlMutationVariables
  >(RequestDocumentDownloadUrlDocument, options);
}
export type RequestDocumentDownloadUrlMutationHookResult = ReturnType<
  typeof useRequestDocumentDownloadUrlMutation
>;
export type RequestDocumentDownloadUrlMutationResult =
  Apollo.MutationResult<RequestDocumentDownloadUrlMutation>;
export type RequestDocumentDownloadUrlMutationOptions =
  Apollo.BaseMutationOptions<
    RequestDocumentDownloadUrlMutation,
    RequestDocumentDownloadUrlMutationVariables
  >;
export const ResendInvitationDocument = gql`
  mutation ResendInvitation($invitationMrn: ID!) {
    resendInvitation(invitationMrn: $invitationMrn)
  }
`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      invitationMrn: // value for 'invitationMrn'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >(ResendInvitationDocument, options);
}
export type ResendInvitationMutationHookResult = ReturnType<
  typeof useResendInvitationMutation
>;
export type ResendInvitationMutationResult =
  Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;
export const RevokeRegistrationTokenDocument = gql`
  mutation RevokeRegistrationToken($input: RevokeRegistrationTokenInput!) {
    revokeRegistrationToken(input: $input) {
      ... on RevokeRegistrationTokenSuccess {
        ok
      }
      ... on RevokeRegistrationTokenFailure {
        message
        code
      }
    }
  }
`;
export type RevokeRegistrationTokenMutationFn = Apollo.MutationFunction<
  RevokeRegistrationTokenMutation,
  RevokeRegistrationTokenMutationVariables
>;

/**
 * __useRevokeRegistrationTokenMutation__
 *
 * To run a mutation, you first call `useRevokeRegistrationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeRegistrationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeRegistrationTokenMutation, { data, loading, error }] = useRevokeRegistrationTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeRegistrationTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeRegistrationTokenMutation,
    RevokeRegistrationTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeRegistrationTokenMutation,
    RevokeRegistrationTokenMutationVariables
  >(RevokeRegistrationTokenDocument, options);
}
export type RevokeRegistrationTokenMutationHookResult = ReturnType<
  typeof useRevokeRegistrationTokenMutation
>;
export type RevokeRegistrationTokenMutationResult =
  Apollo.MutationResult<RevokeRegistrationTokenMutation>;
export type RevokeRegistrationTokenMutationOptions = Apollo.BaseMutationOptions<
  RevokeRegistrationTokenMutation,
  RevokeRegistrationTokenMutationVariables
>;
export const SetAssetAnnotationsDocument = gql`
  mutation SetAssetAnnotations($input: SetAssetAnnotationsInput!) {
    setAssetAnnotations(input: $input) {
      annotations {
        key
        value
      }
    }
  }
`;
export type SetAssetAnnotationsMutationFn = Apollo.MutationFunction<
  SetAssetAnnotationsMutation,
  SetAssetAnnotationsMutationVariables
>;

/**
 * __useSetAssetAnnotationsMutation__
 *
 * To run a mutation, you first call `useSetAssetAnnotationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAssetAnnotationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAssetAnnotationsMutation, { data, loading, error }] = useSetAssetAnnotationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAssetAnnotationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAssetAnnotationsMutation,
    SetAssetAnnotationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetAssetAnnotationsMutation,
    SetAssetAnnotationsMutationVariables
  >(SetAssetAnnotationsDocument, options);
}
export type SetAssetAnnotationsMutationHookResult = ReturnType<
  typeof useSetAssetAnnotationsMutation
>;
export type SetAssetAnnotationsMutationResult =
  Apollo.MutationResult<SetAssetAnnotationsMutation>;
export type SetAssetAnnotationsMutationOptions = Apollo.BaseMutationOptions<
  SetAssetAnnotationsMutation,
  SetAssetAnnotationsMutationVariables
>;
export const SetEmailPreferenceDocument = gql`
  mutation SetEmailPreference($input: [EmailPreferenceInput!]!) {
    setEmailPreference(input: $input)
  }
`;
export type SetEmailPreferenceMutationFn = Apollo.MutationFunction<
  SetEmailPreferenceMutation,
  SetEmailPreferenceMutationVariables
>;

/**
 * __useSetEmailPreferenceMutation__
 *
 * To run a mutation, you first call `useSetEmailPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmailPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmailPreferenceMutation, { data, loading, error }] = useSetEmailPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEmailPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEmailPreferenceMutation,
    SetEmailPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEmailPreferenceMutation,
    SetEmailPreferenceMutationVariables
  >(SetEmailPreferenceDocument, options);
}
export type SetEmailPreferenceMutationHookResult = ReturnType<
  typeof useSetEmailPreferenceMutation
>;
export type SetEmailPreferenceMutationResult =
  Apollo.MutationResult<SetEmailPreferenceMutation>;
export type SetEmailPreferenceMutationOptions = Apollo.BaseMutationOptions<
  SetEmailPreferenceMutation,
  SetEmailPreferenceMutationVariables
>;
export const SetIntegrationDocument = gql`
  mutation SetIntegration($input: IntegrationInput!) {
    setIntegration(input: $input)
  }
`;
export type SetIntegrationMutationFn = Apollo.MutationFunction<
  SetIntegrationMutation,
  SetIntegrationMutationVariables
>;

/**
 * __useSetIntegrationMutation__
 *
 * To run a mutation, you first call `useSetIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIntegrationMutation, { data, loading, error }] = useSetIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetIntegrationMutation,
    SetIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetIntegrationMutation,
    SetIntegrationMutationVariables
  >(SetIntegrationDocument, options);
}
export type SetIntegrationMutationHookResult = ReturnType<
  typeof useSetIntegrationMutation
>;
export type SetIntegrationMutationResult =
  Apollo.MutationResult<SetIntegrationMutation>;
export type SetIntegrationMutationOptions = Apollo.BaseMutationOptions<
  SetIntegrationMutation,
  SetIntegrationMutationVariables
>;
export const SetOrganizationMembershipDocument = gql`
  mutation SetOrganizationMembership($input: SetOrganizationMembershipInput!) {
    setOrganizationMembership(input: $input)
  }
`;
export type SetOrganizationMembershipMutationFn = Apollo.MutationFunction<
  SetOrganizationMembershipMutation,
  SetOrganizationMembershipMutationVariables
>;

/**
 * __useSetOrganizationMembershipMutation__
 *
 * To run a mutation, you first call `useSetOrganizationMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationMembershipMutation, { data, loading, error }] = useSetOrganizationMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganizationMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOrganizationMembershipMutation,
    SetOrganizationMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOrganizationMembershipMutation,
    SetOrganizationMembershipMutationVariables
  >(SetOrganizationMembershipDocument, options);
}
export type SetOrganizationMembershipMutationHookResult = ReturnType<
  typeof useSetOrganizationMembershipMutation
>;
export type SetOrganizationMembershipMutationResult =
  Apollo.MutationResult<SetOrganizationMembershipMutation>;
export type SetOrganizationMembershipMutationOptions =
  Apollo.BaseMutationOptions<
    SetOrganizationMembershipMutation,
    SetOrganizationMembershipMutationVariables
  >;
export const SetRolesDocument = gql`
  mutation SetRoles($input: SetRolesInput!) {
    setRoles(input: $input) {
      mrns
      errors
    }
  }
`;
export type SetRolesMutationFn = Apollo.MutationFunction<
  SetRolesMutation,
  SetRolesMutationVariables
>;

/**
 * __useSetRolesMutation__
 *
 * To run a mutation, you first call `useSetRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRolesMutation, { data, loading, error }] = useSetRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetRolesMutation,
    SetRolesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetRolesMutation, SetRolesMutationVariables>(
    SetRolesDocument,
    options,
  );
}
export type SetRolesMutationHookResult = ReturnType<typeof useSetRolesMutation>;
export type SetRolesMutationResult = Apollo.MutationResult<SetRolesMutation>;
export type SetRolesMutationOptions = Apollo.BaseMutationOptions<
  SetRolesMutation,
  SetRolesMutationVariables
>;
export const SetSsoProviderDocument = gql`
  mutation SetSSOProvider($input: SetSSOProviderInput!) {
    setSSOProvider(input: $input)
  }
`;
export type SetSsoProviderMutationFn = Apollo.MutationFunction<
  SetSsoProviderMutation,
  SetSsoProviderMutationVariables
>;

/**
 * __useSetSsoProviderMutation__
 *
 * To run a mutation, you first call `useSetSsoProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSsoProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSsoProviderMutation, { data, loading, error }] = useSetSsoProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSsoProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSsoProviderMutation,
    SetSsoProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSsoProviderMutation,
    SetSsoProviderMutationVariables
  >(SetSsoProviderDocument, options);
}
export type SetSsoProviderMutationHookResult = ReturnType<
  typeof useSetSsoProviderMutation
>;
export type SetSsoProviderMutationResult =
  Apollo.MutationResult<SetSsoProviderMutation>;
export type SetSsoProviderMutationOptions = Apollo.BaseMutationOptions<
  SetSsoProviderMutation,
  SetSsoProviderMutationVariables
>;
export const SetSpaceMembershipDocument = gql`
  mutation SetSpaceMembership($input: SetSpaceMembershipInput!) {
    setSpaceMembership(input: $input)
  }
`;
export type SetSpaceMembershipMutationFn = Apollo.MutationFunction<
  SetSpaceMembershipMutation,
  SetSpaceMembershipMutationVariables
>;

/**
 * __useSetSpaceMembershipMutation__
 *
 * To run a mutation, you first call `useSetSpaceMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSpaceMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSpaceMembershipMutation, { data, loading, error }] = useSetSpaceMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSpaceMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSpaceMembershipMutation,
    SetSpaceMembershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetSpaceMembershipMutation,
    SetSpaceMembershipMutationVariables
  >(SetSpaceMembershipDocument, options);
}
export type SetSpaceMembershipMutationHookResult = ReturnType<
  typeof useSetSpaceMembershipMutation
>;
export type SetSpaceMembershipMutationResult =
  Apollo.MutationResult<SetSpaceMembershipMutation>;
export type SetSpaceMembershipMutationOptions = Apollo.BaseMutationOptions<
  SetSpaceMembershipMutation,
  SetSpaceMembershipMutationVariables
>;
export const SetViewerSettingDocument = gql`
  mutation SetViewerSetting($key: String!, $value: String!) {
    changeViewerSetting(key: $key, value: $value)
  }
`;
export type SetViewerSettingMutationFn = Apollo.MutationFunction<
  SetViewerSettingMutation,
  SetViewerSettingMutationVariables
>;

/**
 * __useSetViewerSettingMutation__
 *
 * To run a mutation, you first call `useSetViewerSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetViewerSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setViewerSettingMutation, { data, loading, error }] = useSetViewerSettingMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetViewerSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetViewerSettingMutation,
    SetViewerSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetViewerSettingMutation,
    SetViewerSettingMutationVariables
  >(SetViewerSettingDocument, options);
}
export type SetViewerSettingMutationHookResult = ReturnType<
  typeof useSetViewerSettingMutation
>;
export type SetViewerSettingMutationResult =
  Apollo.MutationResult<SetViewerSettingMutation>;
export type SetViewerSettingMutationOptions = Apollo.BaseMutationOptions<
  SetViewerSettingMutation,
  SetViewerSettingMutationVariables
>;
export const SuppressIntegrationMessageDocument = gql`
  mutation SuppressIntegrationMessage(
    $input: SuppressIntegrationMessageInput!
  ) {
    suppressIntegrationMessage(input: $input) {
      mrn
    }
  }
`;
export type SuppressIntegrationMessageMutationFn = Apollo.MutationFunction<
  SuppressIntegrationMessageMutation,
  SuppressIntegrationMessageMutationVariables
>;

/**
 * __useSuppressIntegrationMessageMutation__
 *
 * To run a mutation, you first call `useSuppressIntegrationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuppressIntegrationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suppressIntegrationMessageMutation, { data, loading, error }] = useSuppressIntegrationMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuppressIntegrationMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SuppressIntegrationMessageMutation,
    SuppressIntegrationMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SuppressIntegrationMessageMutation,
    SuppressIntegrationMessageMutationVariables
  >(SuppressIntegrationMessageDocument, options);
}
export type SuppressIntegrationMessageMutationHookResult = ReturnType<
  typeof useSuppressIntegrationMessageMutation
>;
export type SuppressIntegrationMessageMutationResult =
  Apollo.MutationResult<SuppressIntegrationMessageMutation>;
export type SuppressIntegrationMessageMutationOptions =
  Apollo.BaseMutationOptions<
    SuppressIntegrationMessageMutation,
    SuppressIntegrationMessageMutationVariables
  >;
export const UnassignPolicyDocument = gql`
  mutation UnassignPolicy($input: PolicyAssignmentInput!) {
    unassignPolicy(input: $input)
  }
`;
export type UnassignPolicyMutationFn = Apollo.MutationFunction<
  UnassignPolicyMutation,
  UnassignPolicyMutationVariables
>;

/**
 * __useUnassignPolicyMutation__
 *
 * To run a mutation, you first call `useUnassignPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignPolicyMutation, { data, loading, error }] = useUnassignPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignPolicyMutation,
    UnassignPolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnassignPolicyMutation,
    UnassignPolicyMutationVariables
  >(UnassignPolicyDocument, options);
}
export type UnassignPolicyMutationHookResult = ReturnType<
  typeof useUnassignPolicyMutation
>;
export type UnassignPolicyMutationResult =
  Apollo.MutationResult<UnassignPolicyMutation>;
export type UnassignPolicyMutationOptions = Apollo.BaseMutationOptions<
  UnassignPolicyMutation,
  UnassignPolicyMutationVariables
>;
export const UpdateApiTokenDocument = gql`
  mutation UpdateAPIToken($input: UpdateAPITokenInput!) {
    updateAPIToken(input: $input) {
      mrn
      spaceMrn
      name
      description
      roles {
        mrn
        title
      }
      createdAt
      lastUsed
      creator {
        mrn
        email
      }
    }
  }
`;
export type UpdateApiTokenMutationFn = Apollo.MutationFunction<
  UpdateApiTokenMutation,
  UpdateApiTokenMutationVariables
>;

/**
 * __useUpdateApiTokenMutation__
 *
 * To run a mutation, you first call `useUpdateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiTokenMutation, { data, loading, error }] = useUpdateApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApiTokenMutation,
    UpdateApiTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApiTokenMutation,
    UpdateApiTokenMutationVariables
  >(UpdateApiTokenDocument, options);
}
export type UpdateApiTokenMutationHookResult = ReturnType<
  typeof useUpdateApiTokenMutation
>;
export type UpdateApiTokenMutationResult =
  Apollo.MutationResult<UpdateApiTokenMutation>;
export type UpdateApiTokenMutationOptions = Apollo.BaseMutationOptions<
  UpdateApiTokenMutation,
  UpdateApiTokenMutationVariables
>;
export const CloseCaseDocument = gql`
  mutation CloseCase($input: CloseCaseInput!) {
    closeCase(input: $input)
  }
`;
export type CloseCaseMutationFn = Apollo.MutationFunction<
  CloseCaseMutation,
  CloseCaseMutationVariables
>;

/**
 * __useCloseCaseMutation__
 *
 * To run a mutation, you first call `useCloseCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeCaseMutation, { data, loading, error }] = useCloseCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseCaseMutation,
    CloseCaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseCaseMutation, CloseCaseMutationVariables>(
    CloseCaseDocument,
    options,
  );
}
export type CloseCaseMutationHookResult = ReturnType<
  typeof useCloseCaseMutation
>;
export type CloseCaseMutationResult = Apollo.MutationResult<CloseCaseMutation>;
export type CloseCaseMutationOptions = Apollo.BaseMutationOptions<
  CloseCaseMutation,
  CloseCaseMutationVariables
>;
export const UpdateClientIntegrationConfigurationDocument = gql`
  mutation UpdateClientIntegrationConfiguration(
    $input: UpdateClientIntegrationConfigurationInput!
  ) {
    updateClientIntegrationConfiguration(input: $input) {
      configurationOptions {
        ... on K8sConfigurationOptions {
          scanNodes
          scanNodesStyle
          scanWorkloads
          scanDeploys
          certificateManager
          scanPublicImages
          namespaceAllowList
          namespaceDenyList
        }
        ... on AWSConfigurationOptions {
          region
          isOrganization
          snsEndpoint
          scanConfiguration {
            accountScan
            ec2Scan
            ecrScan
            ecsScan
            cronScaninHours
            eventScanTriggers {
              eventDetailType
              eventSource
              scanType
            }
            ec2ScanOptions {
              ssm
              instanceConnect
              instanceIdsFilter
              regionsFilter
              tagsFilter
              ebsVolumeScan
            }
            vpcConfiguration {
              CIDR
              useDefaultVPC
              useMondooVPC
            }
          }
        }
        ... on HostedAwsConfigurationOptions {
          accessKeyId
          role
        }
        ... on AzureConfigurationOptions {
          tenantId
          clientId
          subscriptionsWhitelist
          subscriptionsBlacklist
          scanVms
        }
        ... on Ms365ConfigurationOptions {
          tenantId
          clientId
        }
        ... on MicrosoftDefenderConfigurationOptions {
          tenantId
          clientId
          subscriptionsAllowlist
          subscriptionsDenylist
          secretType
        }
        ... on GcpConfigurationOptions {
          projectId
          organizationId
          discoverAll
        }
        ... on GcsBucketConfigurationOptions {
          output
          bucket
        }
        ... on GitlabConfigurationOptions {
          group
          type
          discoverGroups
          discoverProjects
          discoverTerraform
          discoverK8sManifests
          baseUrl
        }
        ... on GithubConfigurationOptions {
          owner
          repository
          githubOrg: organization
          githubType: type
          reposAllowList
          reposDenyList
          githubDiscoverK8sManifests: discoverK8sManifests
          githubDiscoverTerraform: discoverTerraform
          enterpriseUrl
        }
        ... on HostConfigurationOptions {
          host
          https
          http
        }
        ... on AgentOptions {
          id
          version
          notes
          platform {
            name
            release
            arch
            kind
            runtime
          }
          hostname
          ipaddress
        }
        ... on MicrosoftDefenderConfigurationOptions {
          tenantId
          clientId
          subscriptionsAllowlist
          subscriptionsDenylist
          secretType
        }
        ... on OktaConfigurationOptions {
          organization
        }
        ... on GoogleWorkspaceConfigurationOptions {
          customerId
          impersonatedUserEmail
        }
        ... on OciConfigurationOptions {
          tenancyOcid
          userOcid
          region
          fingerprint
        }
      }
    }
  }
`;
export type UpdateClientIntegrationConfigurationMutationFn =
  Apollo.MutationFunction<
    UpdateClientIntegrationConfigurationMutation,
    UpdateClientIntegrationConfigurationMutationVariables
  >;

/**
 * __useUpdateClientIntegrationConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateClientIntegrationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientIntegrationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientIntegrationConfigurationMutation, { data, loading, error }] = useUpdateClientIntegrationConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientIntegrationConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientIntegrationConfigurationMutation,
    UpdateClientIntegrationConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientIntegrationConfigurationMutation,
    UpdateClientIntegrationConfigurationMutationVariables
  >(UpdateClientIntegrationConfigurationDocument, options);
}
export type UpdateClientIntegrationConfigurationMutationHookResult = ReturnType<
  typeof useUpdateClientIntegrationConfigurationMutation
>;
export type UpdateClientIntegrationConfigurationMutationResult =
  Apollo.MutationResult<UpdateClientIntegrationConfigurationMutation>;
export type UpdateClientIntegrationConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientIntegrationConfigurationMutation,
    UpdateClientIntegrationConfigurationMutationVariables
  >;
export const UpdateOrganizationNameDocument = gql`
  mutation UpdateOrganizationName($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        name
        mrn
      }
    }
  }
`;
export type UpdateOrganizationNameMutationFn = Apollo.MutationFunction<
  UpdateOrganizationNameMutation,
  UpdateOrganizationNameMutationVariables
>;

/**
 * __useUpdateOrganizationNameMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationNameMutation, { data, loading, error }] = useUpdateOrganizationNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationNameMutation,
    UpdateOrganizationNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationNameMutation,
    UpdateOrganizationNameMutationVariables
  >(UpdateOrganizationNameDocument, options);
}
export type UpdateOrganizationNameMutationHookResult = ReturnType<
  typeof useUpdateOrganizationNameMutation
>;
export type UpdateOrganizationNameMutationResult =
  Apollo.MutationResult<UpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationNameMutation,
  UpdateOrganizationNameMutationVariables
>;
export const UpdateSpaceDocument = gql`
  mutation UpdateSpace($input: UpdateSpaceInput!) {
    updateSpace(input: $input) {
      space {
        name
        mrn
        settings {
          eolAssetsConfiguration {
            enable
            monthsInAdvance
          }
          garbageCollectAssetsConfiguration {
            enable
            afterDays
          }
          terminatedAssetsConfiguration {
            cleanup
            after
          }
          unusedServiceAccountsConfiguration {
            cleanup
            after
          }
          platformVulnerabilityConfiguration {
            enable
          }
          casesConfiguration {
            autoCreate
            aggregationWindow
          }
        }
      }
    }
  }
`;
export type UpdateSpaceMutationFn = Apollo.MutationFunction<
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables
>;

/**
 * __useUpdateSpaceMutation__
 *
 * To run a mutation, you first call `useUpdateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpaceMutation, { data, loading, error }] = useUpdateSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSpaceMutation,
    UpdateSpaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSpaceMutation, UpdateSpaceMutationVariables>(
    UpdateSpaceDocument,
    options,
  );
}
export type UpdateSpaceMutationHookResult = ReturnType<
  typeof useUpdateSpaceMutation
>;
export type UpdateSpaceMutationResult =
  Apollo.MutationResult<UpdateSpaceMutation>;
export type UpdateSpaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables
>;
export const UpdateUserNameDocument = gql`
  mutation UpdateUserName($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        name
        mrn
      }
    }
  }
`;
export type UpdateUserNameMutationFn = Apollo.MutationFunction<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>;

/**
 * __useUpdateUserNameMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameMutation, { data, loading, error }] = useUpdateUserNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserNameMutation,
    UpdateUserNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserNameMutation,
    UpdateUserNameMutationVariables
  >(UpdateUserNameDocument, options);
}
export type UpdateUserNameMutationHookResult = ReturnType<
  typeof useUpdateUserNameMutation
>;
export type UpdateUserNameMutationResult =
  Apollo.MutationResult<UpdateUserNameMutation>;
export type UpdateUserNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserNameMutation,
  UpdateUserNameMutationVariables
>;
export const UploadFrameworkDocument = gql`
  mutation UploadFramework($input: UploadFrameworkInput!) {
    uploadFramework(input: $input)
  }
`;
export type UploadFrameworkMutationFn = Apollo.MutationFunction<
  UploadFrameworkMutation,
  UploadFrameworkMutationVariables
>;

/**
 * __useUploadFrameworkMutation__
 *
 * To run a mutation, you first call `useUploadFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFrameworkMutation, { data, loading, error }] = useUploadFrameworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFrameworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFrameworkMutation,
    UploadFrameworkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadFrameworkMutation,
    UploadFrameworkMutationVariables
  >(UploadFrameworkDocument, options);
}
export type UploadFrameworkMutationHookResult = ReturnType<
  typeof useUploadFrameworkMutation
>;
export type UploadFrameworkMutationResult =
  Apollo.MutationResult<UploadFrameworkMutation>;
export type UploadFrameworkMutationOptions = Apollo.BaseMutationOptions<
  UploadFrameworkMutation,
  UploadFrameworkMutationVariables
>;
export const AdvisoryForwardPaginationDocument = gql`
  query AdvisoryForwardPagination($after: String, $first: Int, $query: String) {
    advisories(
      after: $after
      first: $first
      orderBy: { direction: DESC, field: PUBLISHED }
      query: $query
    ) {
      ...AdvisoryFields
    }
  }
  ${AdvisoryFieldsFragmentDoc}
`;

/**
 * __useAdvisoryForwardPaginationQuery__
 *
 * To run a query within a React component, call `useAdvisoryForwardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvisoryForwardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvisoryForwardPaginationQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAdvisoryForwardPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdvisoryForwardPaginationQuery,
    AdvisoryForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdvisoryForwardPaginationQuery,
    AdvisoryForwardPaginationQueryVariables
  >(AdvisoryForwardPaginationDocument, options);
}
export function useAdvisoryForwardPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdvisoryForwardPaginationQuery,
    AdvisoryForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdvisoryForwardPaginationQuery,
    AdvisoryForwardPaginationQueryVariables
  >(AdvisoryForwardPaginationDocument, options);
}
export type AdvisoryForwardPaginationQueryHookResult = ReturnType<
  typeof useAdvisoryForwardPaginationQuery
>;
export type AdvisoryForwardPaginationLazyQueryHookResult = ReturnType<
  typeof useAdvisoryForwardPaginationLazyQuery
>;
export type AdvisoryForwardPaginationQueryResult = Apollo.QueryResult<
  AdvisoryForwardPaginationQuery,
  AdvisoryForwardPaginationQueryVariables
>;
export const AgentForwardPaginationDocument = gql`
  query AgentForwardPagination(
    $spaceMrn: String!
    $after: String
    $first: Int
    $query: String
    $queryTerms: [String!]
    $version: [AgentVersionFilter!]
    $state: [AgentState!]
    $orderBy: AgentOrder
  ) {
    agents(
      spaceMrn: $spaceMrn
      first: $first
      after: $after
      query: $query
      queryTerms: $queryTerms
      version: $version
      state: $state
      orderBy: $orderBy
    ) {
      ...AgentFields
    }
  }
  ${AgentFieldsFragmentDoc}
`;

/**
 * __useAgentForwardPaginationQuery__
 *
 * To run a query within a React component, call `useAgentForwardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentForwardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentForwardPaginationQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *      queryTerms: // value for 'queryTerms'
 *      version: // value for 'version'
 *      state: // value for 'state'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAgentForwardPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgentForwardPaginationQuery,
    AgentForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgentForwardPaginationQuery,
    AgentForwardPaginationQueryVariables
  >(AgentForwardPaginationDocument, options);
}
export function useAgentForwardPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentForwardPaginationQuery,
    AgentForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgentForwardPaginationQuery,
    AgentForwardPaginationQueryVariables
  >(AgentForwardPaginationDocument, options);
}
export type AgentForwardPaginationQueryHookResult = ReturnType<
  typeof useAgentForwardPaginationQuery
>;
export type AgentForwardPaginationLazyQueryHookResult = ReturnType<
  typeof useAgentForwardPaginationLazyQuery
>;
export type AgentForwardPaginationQueryResult = Apollo.QueryResult<
  AgentForwardPaginationQuery,
  AgentForwardPaginationQueryVariables
>;
export const AssetForwardPaginationDocument = gql`
  query AssetForwardPagination(
    $spaceMrn: String!
    $after: String
    $first: Int
    $queryTerms: [String!]
    $platformTitle: [String!]
    $platformName: [String!]
    $platformKind: [PlatformKind!]
    $platformRuntime: [String!]
    $scoreRange: [ScoreRange!]
    $scoreType: ScoreType!
    $labels: [KeyValueInput!]
    $updated: AssetUpdateFilter
    $eol: AssetEolFilter
    $reboot: AssetOSRebootFilter
    $exploitable: AssetExploitableFilter
    $orderBy: AssetOrder
    $groups: [GroupFilter!]
    $assetTypes: [String!]
    $relatedToAssetMrn: String
    $assetUrlFilter: [AssetUrlSegmentInput!]
  ) {
    assets(
      spaceMrn: $spaceMrn
      after: $after
      first: $first
      orderBy: $orderBy
      queryTerms: $queryTerms
      platformTitle: $platformTitle
      platformName: $platformName
      platformKind: $platformKind
      platformRuntime: $platformRuntime
      scoreRange: $scoreRange
      scoreType: $scoreType
      labels: $labels
      updated: $updated
      eol: $eol
      reboot: $reboot
      exploitable: $exploitable
      groups: $groups
      assetTypes: $assetTypes
      relatedToAssetMrn: $relatedToAssetMrn
      assetUrlFilter: $assetUrlFilter
    ) {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;

/**
 * __useAssetForwardPaginationQuery__
 *
 * To run a query within a React component, call `useAssetForwardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetForwardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetForwardPaginationQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      queryTerms: // value for 'queryTerms'
 *      platformTitle: // value for 'platformTitle'
 *      platformName: // value for 'platformName'
 *      platformKind: // value for 'platformKind'
 *      platformRuntime: // value for 'platformRuntime'
 *      scoreRange: // value for 'scoreRange'
 *      scoreType: // value for 'scoreType'
 *      labels: // value for 'labels'
 *      updated: // value for 'updated'
 *      eol: // value for 'eol'
 *      reboot: // value for 'reboot'
 *      exploitable: // value for 'exploitable'
 *      orderBy: // value for 'orderBy'
 *      groups: // value for 'groups'
 *      assetTypes: // value for 'assetTypes'
 *      relatedToAssetMrn: // value for 'relatedToAssetMrn'
 *      assetUrlFilter: // value for 'assetUrlFilter'
 *   },
 * });
 */
export function useAssetForwardPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssetForwardPaginationQuery,
    AssetForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssetForwardPaginationQuery,
    AssetForwardPaginationQueryVariables
  >(AssetForwardPaginationDocument, options);
}
export function useAssetForwardPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetForwardPaginationQuery,
    AssetForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssetForwardPaginationQuery,
    AssetForwardPaginationQueryVariables
  >(AssetForwardPaginationDocument, options);
}
export type AssetForwardPaginationQueryHookResult = ReturnType<
  typeof useAssetForwardPaginationQuery
>;
export type AssetForwardPaginationLazyQueryHookResult = ReturnType<
  typeof useAssetForwardPaginationLazyQuery
>;
export type AssetForwardPaginationQueryResult = Apollo.QueryResult<
  AssetForwardPaginationQuery,
  AssetForwardPaginationQueryVariables
>;
export const AssetResourcesDocument = gql`
  query AssetResources($input: AssetResourceInput!) {
    assetResources(input: $input) {
      selectedNames
      selectedPaths
      resources {
        name
        fields {
          name
          description
          path
          type
          value
          updatedAt
          numElements
          nestedContent
        }
        type
        path
        numElements
        nestedContent
      }
    }
  }
`;

/**
 * __useAssetResourcesQuery__
 *
 * To run a query within a React component, call `useAssetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetResourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssetResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssetResourcesQuery,
    AssetResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetResourcesQuery, AssetResourcesQueryVariables>(
    AssetResourcesDocument,
    options,
  );
}
export function useAssetResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetResourcesQuery,
    AssetResourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetResourcesQuery, AssetResourcesQueryVariables>(
    AssetResourcesDocument,
    options,
  );
}
export type AssetResourcesQueryHookResult = ReturnType<
  typeof useAssetResourcesQuery
>;
export type AssetResourcesLazyQueryHookResult = ReturnType<
  typeof useAssetResourcesLazyQuery
>;
export type AssetResourcesQueryResult = Apollo.QueryResult<
  AssetResourcesQuery,
  AssetResourcesQueryVariables
>;
export const AssetSearchSuggestionsDocument = gql`
  query AssetSearchSuggestions($input: AssetSearchSuggestionsInput!) {
    assetSearchSuggestions(input: $input) {
      ... on AssetSearchSuggestions {
        suggestions {
          value
        }
      }
    }
  }
`;

/**
 * __useAssetSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useAssetSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetSearchSuggestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssetSearchSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssetSearchSuggestionsQuery,
    AssetSearchSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssetSearchSuggestionsQuery,
    AssetSearchSuggestionsQueryVariables
  >(AssetSearchSuggestionsDocument, options);
}
export function useAssetSearchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetSearchSuggestionsQuery,
    AssetSearchSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssetSearchSuggestionsQuery,
    AssetSearchSuggestionsQueryVariables
  >(AssetSearchSuggestionsDocument, options);
}
export type AssetSearchSuggestionsQueryHookResult = ReturnType<
  typeof useAssetSearchSuggestionsQuery
>;
export type AssetSearchSuggestionsLazyQueryHookResult = ReturnType<
  typeof useAssetSearchSuggestionsLazyQuery
>;
export type AssetSearchSuggestionsQueryResult = Apollo.QueryResult<
  AssetSearchSuggestionsQuery,
  AssetSearchSuggestionsQueryVariables
>;
export const AssetSoftwareDetailsDocument = gql`
  query AssetSoftwareDetails($input: AssetPackageDetailsInput!) {
    assetPackageDetails(input: $input) {
      ... on NotFoundError {
        code
        message
      }
      ...AssetPackageDetailsFields
    }
  }
  ${AssetPackageDetailsFieldsFragmentDoc}
`;

/**
 * __useAssetSoftwareDetailsQuery__
 *
 * To run a query within a React component, call `useAssetSoftwareDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetSoftwareDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetSoftwareDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssetSoftwareDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssetSoftwareDetailsQuery,
    AssetSoftwareDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssetSoftwareDetailsQuery,
    AssetSoftwareDetailsQueryVariables
  >(AssetSoftwareDetailsDocument, options);
}
export function useAssetSoftwareDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetSoftwareDetailsQuery,
    AssetSoftwareDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssetSoftwareDetailsQuery,
    AssetSoftwareDetailsQueryVariables
  >(AssetSoftwareDetailsDocument, options);
}
export type AssetSoftwareDetailsQueryHookResult = ReturnType<
  typeof useAssetSoftwareDetailsQuery
>;
export type AssetSoftwareDetailsLazyQueryHookResult = ReturnType<
  typeof useAssetSoftwareDetailsLazyQuery
>;
export type AssetSoftwareDetailsQueryResult = Apollo.QueryResult<
  AssetSoftwareDetailsQuery,
  AssetSoftwareDetailsQueryVariables
>;
export const AuditLogForwardPaginationDocument = gql`
  query AuditLogForwardPagination(
    $first: Int
    $after: String
    $orderBy: AuditLogOrder = { direction: DESC, field: TIMESTAMP }
    $resourceMrn: String!
  ) {
    auditlog(
      first: $first
      after: $after
      orderBy: $orderBy
      resourceMrn: $resourceMrn
    ) {
      totalCount
      edges {
        cursor
        node {
          identity {
            name
            mrn
          }
          resource
          action
          timestamp
          msg
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __useAuditLogForwardPaginationQuery__
 *
 * To run a query within a React component, call `useAuditLogForwardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogForwardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogForwardPaginationQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      resourceMrn: // value for 'resourceMrn'
 *   },
 * });
 */
export function useAuditLogForwardPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuditLogForwardPaginationQuery,
    AuditLogForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AuditLogForwardPaginationQuery,
    AuditLogForwardPaginationQueryVariables
  >(AuditLogForwardPaginationDocument, options);
}
export function useAuditLogForwardPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuditLogForwardPaginationQuery,
    AuditLogForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuditLogForwardPaginationQuery,
    AuditLogForwardPaginationQueryVariables
  >(AuditLogForwardPaginationDocument, options);
}
export type AuditLogForwardPaginationQueryHookResult = ReturnType<
  typeof useAuditLogForwardPaginationQuery
>;
export type AuditLogForwardPaginationLazyQueryHookResult = ReturnType<
  typeof useAuditLogForwardPaginationLazyQuery
>;
export type AuditLogForwardPaginationQueryResult = Apollo.QueryResult<
  AuditLogForwardPaginationQuery,
  AuditLogForwardPaginationQueryVariables
>;
export const DownloadBundleDocument = gql`
  query DownloadBundle($input: DownloadBundleInput!) {
    downloadBundle(input: $input) {
      ... on PolicyBundleYaml {
        yaml
      }
    }
  }
`;

/**
 * __useDownloadBundleQuery__
 *
 * To run a query within a React component, call `useDownloadBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadBundleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadBundleQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadBundleQuery,
    DownloadBundleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadBundleQuery, DownloadBundleQueryVariables>(
    DownloadBundleDocument,
    options,
  );
}
export function useDownloadBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadBundleQuery,
    DownloadBundleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadBundleQuery, DownloadBundleQueryVariables>(
    DownloadBundleDocument,
    options,
  );
}
export type DownloadBundleQueryHookResult = ReturnType<
  typeof useDownloadBundleQuery
>;
export type DownloadBundleLazyQueryHookResult = ReturnType<
  typeof useDownloadBundleLazyQuery
>;
export type DownloadBundleQueryResult = Apollo.QueryResult<
  DownloadBundleQuery,
  DownloadBundleQueryVariables
>;
export const DownloadFrameworkDocument = gql`
  query DownloadFramework($input: DownloadFrameworkInput!) {
    downloadFramework(input: $input) {
      ... on FrameworkBundle {
        yaml
      }
    }
  }
`;

/**
 * __useDownloadFrameworkQuery__
 *
 * To run a query within a React component, call `useDownloadFrameworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFrameworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFrameworkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadFrameworkQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadFrameworkQuery,
    DownloadFrameworkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadFrameworkQuery,
    DownloadFrameworkQueryVariables
  >(DownloadFrameworkDocument, options);
}
export function useDownloadFrameworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadFrameworkQuery,
    DownloadFrameworkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadFrameworkQuery,
    DownloadFrameworkQueryVariables
  >(DownloadFrameworkDocument, options);
}
export type DownloadFrameworkQueryHookResult = ReturnType<
  typeof useDownloadFrameworkQuery
>;
export type DownloadFrameworkLazyQueryHookResult = ReturnType<
  typeof useDownloadFrameworkLazyQuery
>;
export type DownloadFrameworkQueryResult = Apollo.QueryResult<
  DownloadFrameworkQuery,
  DownloadFrameworkQueryVariables
>;
export const FindNewAwsAssetDocument = gql`
  query FindNewAwsAsset($spaceMrn: String!) {
    assets(spaceMrn: $spaceMrn, platformName: "aws") {
      edges {
        cursor
        node {
          mrn
          platform {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useFindNewAwsAssetQuery__
 *
 * To run a query within a React component, call `useFindNewAwsAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindNewAwsAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindNewAwsAssetQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useFindNewAwsAssetQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindNewAwsAssetQuery,
    FindNewAwsAssetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindNewAwsAssetQuery, FindNewAwsAssetQueryVariables>(
    FindNewAwsAssetDocument,
    options,
  );
}
export function useFindNewAwsAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindNewAwsAssetQuery,
    FindNewAwsAssetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindNewAwsAssetQuery,
    FindNewAwsAssetQueryVariables
  >(FindNewAwsAssetDocument, options);
}
export type FindNewAwsAssetQueryHookResult = ReturnType<
  typeof useFindNewAwsAssetQuery
>;
export type FindNewAwsAssetLazyQueryHookResult = ReturnType<
  typeof useFindNewAwsAssetLazyQuery
>;
export type FindNewAwsAssetQueryResult = Apollo.QueryResult<
  FindNewAwsAssetQuery,
  FindNewAwsAssetQueryVariables
>;
export const GetActiveComplianceFrameworksDocument = gql`
  query GetActiveComplianceFrameworks($input: ComplianceFrameworksInput!) {
    complianceFrameworks(input: $input) {
      mrn
      name
      version
      authors {
        name
        email
      }
      tags {
        key
        value
      }
      description
      summary
      stats {
        controls {
          activeControls
          snoozedControls
          disabledControls
          outOfScopeControls
          totalControls
          averageCompletion
          previousAverageCompletion
        }
        checks
        assets
        exceptions
      }
      state
      policiesMrns
      scopeMrn
      completion
      previousCompletionScores {
        entries {
          timestamp
          score
        }
      }
      assetGroupStatistics {
        listsAssetTypes {
          assetType
          aliases
          displayName
          shortName
        }
        groupType
        count
        statistics {
          type {
            assetType
            aliases
            displayName
            shortName
          }
          count
        }
      }
      createdAt
      modifiedAt
    }
  }
`;

/**
 * __useGetActiveComplianceFrameworksQuery__
 *
 * To run a query within a React component, call `useGetActiveComplianceFrameworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveComplianceFrameworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveComplianceFrameworksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActiveComplianceFrameworksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveComplianceFrameworksQuery,
    GetActiveComplianceFrameworksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveComplianceFrameworksQuery,
    GetActiveComplianceFrameworksQueryVariables
  >(GetActiveComplianceFrameworksDocument, options);
}
export function useGetActiveComplianceFrameworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveComplianceFrameworksQuery,
    GetActiveComplianceFrameworksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveComplianceFrameworksQuery,
    GetActiveComplianceFrameworksQueryVariables
  >(GetActiveComplianceFrameworksDocument, options);
}
export type GetActiveComplianceFrameworksQueryHookResult = ReturnType<
  typeof useGetActiveComplianceFrameworksQuery
>;
export type GetActiveComplianceFrameworksLazyQueryHookResult = ReturnType<
  typeof useGetActiveComplianceFrameworksLazyQuery
>;
export type GetActiveComplianceFrameworksQueryResult = Apollo.QueryResult<
  GetActiveComplianceFrameworksQuery,
  GetActiveComplianceFrameworksQueryVariables
>;
export const GetActivePoliciesDocument = gql`
  query GetActivePolicies($input: ActivePoliciesInput!) {
    activePolicies(input: $input) {
      totalCount
      edges {
        cursor
        node {
          mrn
          name
          icon
          updatedAt
          action
          blastRadius {
            indicator
            assets
            affected
            critical
            high
            medium
            low
            none
            error
            pass
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGetActivePoliciesQuery__
 *
 * To run a query within a React component, call `useGetActivePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePoliciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivePoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivePoliciesQuery,
    GetActivePoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivePoliciesQuery,
    GetActivePoliciesQueryVariables
  >(GetActivePoliciesDocument, options);
}
export function useGetActivePoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivePoliciesQuery,
    GetActivePoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivePoliciesQuery,
    GetActivePoliciesQueryVariables
  >(GetActivePoliciesDocument, options);
}
export type GetActivePoliciesQueryHookResult = ReturnType<
  typeof useGetActivePoliciesQuery
>;
export type GetActivePoliciesLazyQueryHookResult = ReturnType<
  typeof useGetActivePoliciesLazyQuery
>;
export type GetActivePoliciesQueryResult = Apollo.QueryResult<
  GetActivePoliciesQuery,
  GetActivePoliciesQueryVariables
>;
export const GetActivePoliciesCountDocument = gql`
  query GetActivePoliciesCount($input: ActivePoliciesInput!) {
    activePolicies(input: $input) {
      totalCount
    }
  }
`;

/**
 * __useGetActivePoliciesCountQuery__
 *
 * To run a query within a React component, call `useGetActivePoliciesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePoliciesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePoliciesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivePoliciesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivePoliciesCountQuery,
    GetActivePoliciesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivePoliciesCountQuery,
    GetActivePoliciesCountQueryVariables
  >(GetActivePoliciesCountDocument, options);
}
export function useGetActivePoliciesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivePoliciesCountQuery,
    GetActivePoliciesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivePoliciesCountQuery,
    GetActivePoliciesCountQueryVariables
  >(GetActivePoliciesCountDocument, options);
}
export type GetActivePoliciesCountQueryHookResult = ReturnType<
  typeof useGetActivePoliciesCountQuery
>;
export type GetActivePoliciesCountLazyQueryHookResult = ReturnType<
  typeof useGetActivePoliciesCountLazyQuery
>;
export type GetActivePoliciesCountQueryResult = Apollo.QueryResult<
  GetActivePoliciesCountQuery,
  GetActivePoliciesCountQueryVariables
>;
export const GetAggregateScoresDocument = gql`
  query GetAggregateScores(
    $entityMrn: String!
    $filter: AggregateScoreFilter
    $first: Int
    $after: String
    $orderBy: AggregateScoreOrder
  ) {
    aggregateScores(
      entityMrn: $entityMrn
      filter: $filter
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      ... on AggregateScoresConnection {
        totalCount
        edges {
          cursor
          node {
            id
            iconId
            state
            entity {
              ... on EntityInfoAsset {
                id
                mrn
                name
              }
              ... on EntityInfoSpace {
                id
                mrn
                name
              }
            }
            findingMrn
            rank
            baseScore
            riskScore
            scoreType
            blastRadius {
              ...BlastRadiusFields
            }
            epss {
              probability
              percentile
            }
            cvss {
              id
              value
              type
              vector
              source
            }
            riskFactors {
              mrn
              indicator
              title
              total
              affected
            }
            title
            description
            tags {
              key
              value
            }
            lastScannedAt
            firstDetectedAt
            remediatedAt
            spaceId
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
  ${BlastRadiusFieldsFragmentDoc}
`;

/**
 * __useGetAggregateScoresQuery__
 *
 * To run a query within a React component, call `useGetAggregateScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateScoresQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAggregateScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAggregateScoresQuery,
    GetAggregateScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAggregateScoresQuery,
    GetAggregateScoresQueryVariables
  >(GetAggregateScoresDocument, options);
}
export function useGetAggregateScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAggregateScoresQuery,
    GetAggregateScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAggregateScoresQuery,
    GetAggregateScoresQueryVariables
  >(GetAggregateScoresDocument, options);
}
export type GetAggregateScoresQueryHookResult = ReturnType<
  typeof useGetAggregateScoresQuery
>;
export type GetAggregateScoresLazyQueryHookResult = ReturnType<
  typeof useGetAggregateScoresLazyQuery
>;
export type GetAggregateScoresQueryResult = Apollo.QueryResult<
  GetAggregateScoresQuery,
  GetAggregateScoresQueryVariables
>;
export const GetAggregateScoresRefreshInfoDocument = gql`
  query GetAggregateScoresRefreshInfo($groupMrn: String!) {
    aggregateScoresRefreshInfo(groupMrn: $groupMrn) {
      refreshInProgress
      lastUpdatedAt
    }
  }
`;

/**
 * __useGetAggregateScoresRefreshInfoQuery__
 *
 * To run a query within a React component, call `useGetAggregateScoresRefreshInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateScoresRefreshInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateScoresRefreshInfoQuery({
 *   variables: {
 *      groupMrn: // value for 'groupMrn'
 *   },
 * });
 */
export function useGetAggregateScoresRefreshInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAggregateScoresRefreshInfoQuery,
    GetAggregateScoresRefreshInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAggregateScoresRefreshInfoQuery,
    GetAggregateScoresRefreshInfoQueryVariables
  >(GetAggregateScoresRefreshInfoDocument, options);
}
export function useGetAggregateScoresRefreshInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAggregateScoresRefreshInfoQuery,
    GetAggregateScoresRefreshInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAggregateScoresRefreshInfoQuery,
    GetAggregateScoresRefreshInfoQueryVariables
  >(GetAggregateScoresRefreshInfoDocument, options);
}
export type GetAggregateScoresRefreshInfoQueryHookResult = ReturnType<
  typeof useGetAggregateScoresRefreshInfoQuery
>;
export type GetAggregateScoresRefreshInfoLazyQueryHookResult = ReturnType<
  typeof useGetAggregateScoresRefreshInfoLazyQuery
>;
export type GetAggregateScoresRefreshInfoQueryResult = Apollo.QueryResult<
  GetAggregateScoresRefreshInfoQuery,
  GetAggregateScoresRefreshInfoQueryVariables
>;
export const GetApiTokensDocument = gql`
  query GetApiTokens(
    $spaceMrn: String!
    $scopeMrn: String
    $first: Int
    $after: String
    $queryTerms: [String!]
    $orderBy: APITokenOrder
  ) {
    apiTokens(
      spaceMrn: $spaceMrn
      scopeMrn: $scopeMrn
      first: $first
      after: $after
      queryTerms: $queryTerms
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        cursor
        node {
          mrn
          spaceMrn
          scopeMrn
          name
          description
          roles {
            mrn
            title
          }
          createdAt
          lastUsed
          creator {
            mrn
            email
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGetApiTokensQuery__
 *
 * To run a query within a React component, call `useGetApiTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiTokensQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      scopeMrn: // value for 'scopeMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      queryTerms: // value for 'queryTerms'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetApiTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApiTokensQuery,
    GetApiTokensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiTokensQuery, GetApiTokensQueryVariables>(
    GetApiTokensDocument,
    options,
  );
}
export function useGetApiTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiTokensQuery,
    GetApiTokensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiTokensQuery, GetApiTokensQueryVariables>(
    GetApiTokensDocument,
    options,
  );
}
export type GetApiTokensQueryHookResult = ReturnType<
  typeof useGetApiTokensQuery
>;
export type GetApiTokensLazyQueryHookResult = ReturnType<
  typeof useGetApiTokensLazyQuery
>;
export type GetApiTokensQueryResult = Apollo.QueryResult<
  GetApiTokensQuery,
  GetApiTokensQueryVariables
>;
export const GetAssetPackagesDocument = gql`
  query GetAssetPackages(
    $first: Int
    $after: String
    $orderBy: PackageScoresOrder
    $input: PackageScoresInput
  ) {
    packageScores(
      first: $first
      after: $after
      orderBy: $orderBy
      input: $input
    ) {
      ... on PackageScores {
        totalCount
        totalApps
        totalOsPackages
        stats {
          vulnerablePackages
          totalPackages
        }
        edges {
          cursor
          node {
            id
            riskScore
            assetName
            lastUpdated
            assetMrn
            packageName
            installedVersion
            availableVersion
            firstFound
            packageType
            iconIDEnum
            cvssScore {
              value
              id
              source
              type
              vector
            }
            epssScore {
              probability
              percentile
            }
            riskFactors {
              mrn
              indicator
              title
              total
              affected
            }
            platform
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetAssetPackagesQuery__
 *
 * To run a query within a React component, call `useGetAssetPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetPackagesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssetPackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssetPackagesQuery,
    GetAssetPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetPackagesQuery, GetAssetPackagesQueryVariables>(
    GetAssetPackagesDocument,
    options,
  );
}
export function useGetAssetPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetPackagesQuery,
    GetAssetPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetPackagesQuery,
    GetAssetPackagesQueryVariables
  >(GetAssetPackagesDocument, options);
}
export type GetAssetPackagesQueryHookResult = ReturnType<
  typeof useGetAssetPackagesQuery
>;
export type GetAssetPackagesLazyQueryHookResult = ReturnType<
  typeof useGetAssetPackagesLazyQuery
>;
export type GetAssetPackagesQueryResult = Apollo.QueryResult<
  GetAssetPackagesQuery,
  GetAssetPackagesQueryVariables
>;
export const GetAssetReportDataQueriesDocument = gql`
  query GetAssetReportDataQueries(
    $input: AssetReportInput!
    $first: Int
    $after: String
    $packsFilter: [String]
  ) {
    assetReport(input: $input) {
      ... on AssetReport {
        dataQueries(first: $first, after: $after, packsFilter: $packsFilter) {
          totalCount
          edges {
            cursor
            node {
              id
              mquery {
                uid
                mrn
                title
                docs {
                  desc
                  remediations {
                    ... on Remediations {
                      entries {
                        id
                        desc
                      }
                    }
                    ... on AuthenticationRequired {
                      message
                      code
                    }
                  }
                  references {
                    title
                    url
                  }
                  audit
                  remediation
                }
                impact {
                  value
                  rating
                }
                mql
                tags {
                  key
                  value
                }
                properties {
                  uid
                  mrn
                  title
                  mql
                  setByPolicyMrn
                }
                parent
                variants {
                  id
                  title
                  icon
                }
                variantQueries {
                  uid
                  mrn
                  title
                  mql
                  filter
                  filterID
                }
                action
              }
              score {
                id
                value
                type
                completion
                weight
                message
                grade
              }
              mqueryState
              mqueryType
              data
              assessment {
                state
                rendered
                results {
                  actual
                  expected
                  error
                }
                extendedResults {
                  resourceName
                  resourceTitle
                  numResources
                  actual {
                    name
                    title
                    fields {
                      name
                      description
                      path
                      type
                      value
                      updatedAt
                      numElements
                      nestedContent
                    }
                    type
                    path
                    numElements
                    nestedContent
                    assessmentField {
                      name
                      description
                      path
                      type
                      value
                      updatedAt
                      numElements
                      nestedContent
                    }
                  }
                }
                needsUpgrade
              }
              action
              policies
              policyId
              policyName
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssetReportDataQueriesQuery__
 *
 * To run a query within a React component, call `useGetAssetReportDataQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetReportDataQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetReportDataQueriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      packsFilter: // value for 'packsFilter'
 *   },
 * });
 */
export function useGetAssetReportDataQueriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetReportDataQueriesQuery,
    GetAssetReportDataQueriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetReportDataQueriesQuery,
    GetAssetReportDataQueriesQueryVariables
  >(GetAssetReportDataQueriesDocument, options);
}
export function useGetAssetReportDataQueriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetReportDataQueriesQuery,
    GetAssetReportDataQueriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetReportDataQueriesQuery,
    GetAssetReportDataQueriesQueryVariables
  >(GetAssetReportDataQueriesDocument, options);
}
export type GetAssetReportDataQueriesQueryHookResult = ReturnType<
  typeof useGetAssetReportDataQueriesQuery
>;
export type GetAssetReportDataQueriesLazyQueryHookResult = ReturnType<
  typeof useGetAssetReportDataQueriesLazyQuery
>;
export type GetAssetReportDataQueriesQueryResult = Apollo.QueryResult<
  GetAssetReportDataQueriesQuery,
  GetAssetReportDataQueriesQueryVariables
>;
export const GetAssetSupportVulnerabilitiesDocument = gql`
  query GetAssetSupportVulnerabilities($assetMrn: String!) {
    assetSupportVulnerabilities(assetMrn: $assetMrn)
  }
`;

/**
 * __useGetAssetSupportVulnerabilitiesQuery__
 *
 * To run a query within a React component, call `useGetAssetSupportVulnerabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetSupportVulnerabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetSupportVulnerabilitiesQuery({
 *   variables: {
 *      assetMrn: // value for 'assetMrn'
 *   },
 * });
 */
export function useGetAssetSupportVulnerabilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetSupportVulnerabilitiesQuery,
    GetAssetSupportVulnerabilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetSupportVulnerabilitiesQuery,
    GetAssetSupportVulnerabilitiesQueryVariables
  >(GetAssetSupportVulnerabilitiesDocument, options);
}
export function useGetAssetSupportVulnerabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetSupportVulnerabilitiesQuery,
    GetAssetSupportVulnerabilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetSupportVulnerabilitiesQuery,
    GetAssetSupportVulnerabilitiesQueryVariables
  >(GetAssetSupportVulnerabilitiesDocument, options);
}
export type GetAssetSupportVulnerabilitiesQueryHookResult = ReturnType<
  typeof useGetAssetSupportVulnerabilitiesQuery
>;
export type GetAssetSupportVulnerabilitiesLazyQueryHookResult = ReturnType<
  typeof useGetAssetSupportVulnerabilitiesLazyQuery
>;
export type GetAssetSupportVulnerabilitiesQueryResult = Apollo.QueryResult<
  GetAssetSupportVulnerabilitiesQuery,
  GetAssetSupportVulnerabilitiesQueryVariables
>;
export const GetAssetSupportsVulnerabilitiesDocument = gql`
  query GetAssetSupportsVulnerabilities($assetMrn: String!) {
    assetSupportVulnerabilities(assetMrn: $assetMrn)
  }
`;

/**
 * __useGetAssetSupportsVulnerabilitiesQuery__
 *
 * To run a query within a React component, call `useGetAssetSupportsVulnerabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetSupportsVulnerabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetSupportsVulnerabilitiesQuery({
 *   variables: {
 *      assetMrn: // value for 'assetMrn'
 *   },
 * });
 */
export function useGetAssetSupportsVulnerabilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetSupportsVulnerabilitiesQuery,
    GetAssetSupportsVulnerabilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetSupportsVulnerabilitiesQuery,
    GetAssetSupportsVulnerabilitiesQueryVariables
  >(GetAssetSupportsVulnerabilitiesDocument, options);
}
export function useGetAssetSupportsVulnerabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetSupportsVulnerabilitiesQuery,
    GetAssetSupportsVulnerabilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetSupportsVulnerabilitiesQuery,
    GetAssetSupportsVulnerabilitiesQueryVariables
  >(GetAssetSupportsVulnerabilitiesDocument, options);
}
export type GetAssetSupportsVulnerabilitiesQueryHookResult = ReturnType<
  typeof useGetAssetSupportsVulnerabilitiesQuery
>;
export type GetAssetSupportsVulnerabilitiesLazyQueryHookResult = ReturnType<
  typeof useGetAssetSupportsVulnerabilitiesLazyQuery
>;
export type GetAssetSupportsVulnerabilitiesQueryResult = Apollo.QueryResult<
  GetAssetSupportsVulnerabilitiesQuery,
  GetAssetSupportsVulnerabilitiesQueryVariables
>;
export const GetAssetUrlStatsDocument = gql`
  query GetAssetUrlStats($input: AssetUrlStatsInput!) {
    assetUrlStats(input: $input) {
      stats {
        assetUrl {
          key
          value
        }
        scoreDistribution {
          Unscored
          Ok
          Low
          Medium
          High
          Critical
        }
      }
    }
  }
`;

/**
 * __useGetAssetUrlStatsQuery__
 *
 * To run a query within a React component, call `useGetAssetUrlStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetUrlStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetUrlStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssetUrlStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetUrlStatsQuery,
    GetAssetUrlStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetUrlStatsQuery, GetAssetUrlStatsQueryVariables>(
    GetAssetUrlStatsDocument,
    options,
  );
}
export function useGetAssetUrlStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetUrlStatsQuery,
    GetAssetUrlStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetUrlStatsQuery,
    GetAssetUrlStatsQueryVariables
  >(GetAssetUrlStatsDocument, options);
}
export type GetAssetUrlStatsQueryHookResult = ReturnType<
  typeof useGetAssetUrlStatsQuery
>;
export type GetAssetUrlStatsLazyQueryHookResult = ReturnType<
  typeof useGetAssetUrlStatsLazyQuery
>;
export type GetAssetUrlStatsQueryResult = Apollo.QueryResult<
  GetAssetUrlStatsQuery,
  GetAssetUrlStatsQueryVariables
>;
export const GetAvailableComplianceFrameworksDocument = gql`
  query GetAvailableComplianceFrameworks($input: ComplianceFrameworksInput!) {
    complianceFrameworks(input: $input) {
      description
      mrn
      scopeMrn
      name
      summary
      state
      authors {
        name
        email
      }
      tags {
        key
        value
      }
    }
  }
`;

/**
 * __useGetAvailableComplianceFrameworksQuery__
 *
 * To run a query within a React component, call `useGetAvailableComplianceFrameworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableComplianceFrameworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableComplianceFrameworksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAvailableComplianceFrameworksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableComplianceFrameworksQuery,
    GetAvailableComplianceFrameworksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableComplianceFrameworksQuery,
    GetAvailableComplianceFrameworksQueryVariables
  >(GetAvailableComplianceFrameworksDocument, options);
}
export function useGetAvailableComplianceFrameworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableComplianceFrameworksQuery,
    GetAvailableComplianceFrameworksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableComplianceFrameworksQuery,
    GetAvailableComplianceFrameworksQueryVariables
  >(GetAvailableComplianceFrameworksDocument, options);
}
export type GetAvailableComplianceFrameworksQueryHookResult = ReturnType<
  typeof useGetAvailableComplianceFrameworksQuery
>;
export type GetAvailableComplianceFrameworksLazyQueryHookResult = ReturnType<
  typeof useGetAvailableComplianceFrameworksLazyQuery
>;
export type GetAvailableComplianceFrameworksQueryResult = Apollo.QueryResult<
  GetAvailableComplianceFrameworksQuery,
  GetAvailableComplianceFrameworksQueryVariables
>;
export const GetCaseContentDocument = gql`
  query GetCaseContent($input: CaseContentInput!) {
    caseContent(input: $input) {
      title
      description
    }
  }
`;

/**
 * __useGetCaseContentQuery__
 *
 * To run a query within a React component, call `useGetCaseContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCaseContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseContentQuery,
    GetCaseContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseContentQuery, GetCaseContentQueryVariables>(
    GetCaseContentDocument,
    options,
  );
}
export function useGetCaseContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseContentQuery,
    GetCaseContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaseContentQuery, GetCaseContentQueryVariables>(
    GetCaseContentDocument,
    options,
  );
}
export type GetCaseContentQueryHookResult = ReturnType<
  typeof useGetCaseContentQuery
>;
export type GetCaseContentLazyQueryHookResult = ReturnType<
  typeof useGetCaseContentLazyQuery
>;
export type GetCaseContentQueryResult = Apollo.QueryResult<
  GetCaseContentQuery,
  GetCaseContentQueryVariables
>;
export const GetCaseDetailsDocument = gql`
  query GetCaseDetails(
    $mrn: String!
    $affectedAssetsFirst: Int
    $affectedAssetsAfter: String
    $mitigatedAssetsFirst: Int
    $mitigatedAssetsAfter: String
  ) {
    case(mrn: $mrn) {
      ... on NotFoundError {
        message
        code
      }
      ... on RequestError {
        message
        code
      }
      ... on Case {
        status
        mrn
        title
        createdAt
        modifiedAt
        tags {
          key
          value
        }
        assetsCount
        totalRefsCount
        closedRefsCount
        ticketRefs {
          url
          type
          ticketId
        }
        vulnerabilityRefs {
          findingMrn
          scopeMrn
        }
        queryRefs {
          findingMrn
          scopeMrn
        }
        affectedAssets(
          first: $affectedAssetsFirst
          after: $affectedAssetsAfter
        ) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            cursor
            node {
              id
              mrn
              name
              icon
              tags {
                key
                value
              }
              lastUpdated
            }
          }
        }
        mitigatedAssets(
          first: $mitigatedAssetsFirst
          after: $mitigatedAssetsAfter
        ) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            cursor
            node {
              id
              mrn
              name
              icon
              tags {
                key
                value
              }
              lastUpdated
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCaseDetailsQuery__
 *
 * To run a query within a React component, call `useGetCaseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseDetailsQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *      affectedAssetsFirst: // value for 'affectedAssetsFirst'
 *      affectedAssetsAfter: // value for 'affectedAssetsAfter'
 *      mitigatedAssetsFirst: // value for 'mitigatedAssetsFirst'
 *      mitigatedAssetsAfter: // value for 'mitigatedAssetsAfter'
 *   },
 * });
 */
export function useGetCaseDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseDetailsQuery,
    GetCaseDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseDetailsQuery, GetCaseDetailsQueryVariables>(
    GetCaseDetailsDocument,
    options,
  );
}
export function useGetCaseDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseDetailsQuery,
    GetCaseDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaseDetailsQuery, GetCaseDetailsQueryVariables>(
    GetCaseDetailsDocument,
    options,
  );
}
export type GetCaseDetailsQueryHookResult = ReturnType<
  typeof useGetCaseDetailsQuery
>;
export type GetCaseDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCaseDetailsLazyQuery
>;
export type GetCaseDetailsQueryResult = Apollo.QueryResult<
  GetCaseDetailsQuery,
  GetCaseDetailsQueryVariables
>;
export const GetCasesDocument = gql`
  query GetCases($first: Int, $after: String, $input: CasesInput!) {
    cases(first: $first, after: $after, input: $input) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        cursor
        node {
          mrn
          title
          createdAt
          modifiedAt
          tags {
            key
            value
          }
          assetsCount
          totalRefsCount
          closedRefsCount
          ticketRefs {
            url
            ticketId
          }
        }
      }
    }
  }
`;

/**
 * __useGetCasesQuery__
 *
 * To run a query within a React component, call `useGetCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCasesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCasesQuery, GetCasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCasesQuery, GetCasesQueryVariables>(
    GetCasesDocument,
    options,
  );
}
export function useGetCasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCasesQuery,
    GetCasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCasesQuery, GetCasesQueryVariables>(
    GetCasesDocument,
    options,
  );
}
export type GetCasesQueryHookResult = ReturnType<typeof useGetCasesQuery>;
export type GetCasesLazyQueryHookResult = ReturnType<
  typeof useGetCasesLazyQuery
>;
export type GetCasesQueryResult = Apollo.QueryResult<
  GetCasesQuery,
  GetCasesQueryVariables
>;
export const GetCasesCountDocument = gql`
  query GetCasesCount($input: CasesInput!) {
    cases(input: $input) {
      totalCount
    }
  }
`;

/**
 * __useGetCasesCountQuery__
 *
 * To run a query within a React component, call `useGetCasesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCasesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCasesCountQuery,
    GetCasesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCasesCountQuery, GetCasesCountQueryVariables>(
    GetCasesCountDocument,
    options,
  );
}
export function useGetCasesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCasesCountQuery,
    GetCasesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCasesCountQuery, GetCasesCountQueryVariables>(
    GetCasesCountDocument,
    options,
  );
}
export type GetCasesCountQueryHookResult = ReturnType<
  typeof useGetCasesCountQuery
>;
export type GetCasesCountLazyQueryHookResult = ReturnType<
  typeof useGetCasesCountLazyQuery
>;
export type GetCasesCountQueryResult = Apollo.QueryResult<
  GetCasesCountQuery,
  GetCasesCountQueryVariables
>;
export const GetCheckScoresDocument = gql`
  query GetCheckScores(
    $entityMrn: String!
    $first: Int
    $after: String
    $orderBy: CheckScoreOrder
    $filter: CheckScoreFilter
  ) {
    checkScores(
      entityMrn: $entityMrn
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      ... on CheckScoresConnection {
        totalCount
        filteredTotalCount
        edges {
          cursor
          node {
            mrn
            id
            title
            state
            iconId
            baseScore
            riskScore
            riskFactors {
              mrn
              indicator
              title
              affected
              total
            }
            lastUpdated
            tags {
              key
              value
            }
            asset {
              id
              mrn
              name
              icon
            }
            platform {
              name
              title
              arch
              version
              kind
              runtime
              release
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetCheckScoresQuery__
 *
 * To run a query within a React component, call `useGetCheckScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckScoresQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCheckScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCheckScoresQuery,
    GetCheckScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCheckScoresQuery, GetCheckScoresQueryVariables>(
    GetCheckScoresDocument,
    options,
  );
}
export function useGetCheckScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckScoresQuery,
    GetCheckScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCheckScoresQuery, GetCheckScoresQueryVariables>(
    GetCheckScoresDocument,
    options,
  );
}
export type GetCheckScoresQueryHookResult = ReturnType<
  typeof useGetCheckScoresQuery
>;
export type GetCheckScoresLazyQueryHookResult = ReturnType<
  typeof useGetCheckScoresLazyQuery
>;
export type GetCheckScoresQueryResult = Apollo.QueryResult<
  GetCheckScoresQuery,
  GetCheckScoresQueryVariables
>;
export const GetClientIntegrationDocument = gql`
  query GetClientIntegration($mrn: String!) {
    clientIntegration(input: { mrn: $mrn }) {
      integration {
        mrn
        name
        status
        created
        type
        lastCheckin
        lastStateInfo {
          ... on K8sIntegrationLastState {
            namespace
            k8sVersion
            operatorVersion
          }
        }
        evaluatedConfigurations {
          option
          configured
          applied
          msg
          msgStatus
          operatorMsg
          operatorMsgStatus
        }
        messages {
          message
          status
          identifier
          timestamp
          suppressedByUser
        }
        labels {
          key
          value
        }
        createdBy {
          name
          email
        }
        configurationOptions {
          ... on AWSConfigurationOptions {
            accountIds
            region
            isOrganization
            scanConfiguration {
              ec2ScanOptions {
                ebsScanOptions {
                  maxAsgInstances
                  targetInstancesPerScanner
                }
                ebsVolumeScan
                vaultType
                tagsFilter
                regionsFilter
                instanceIdsFilter
                secretsMetadataQuery
                awsSecretsManagerVault
                ssm
                instanceConnect
              }
              eventScanTriggers {
                eventDetailType
                eventSource
                scanType
              }
              ecrScan
              ecsScan
              cronScaninHours
              accountScan
              ec2Scan
              vpcConfiguration {
                CIDR
                useDefaultVPC
                useMondooVPC
              }
            }
            snsEndpoint
            originAWSAccountId
            cloudFormationTemplateUrl
          }
          ... on HostedAwsConfigurationOptions {
            accessKeyId
            role
          }
          ... on K8sConfigurationOptions {
            scanNodes
            scanNodesStyle
            scanWorkloads
            scanDeploys
            certificateManager
            scanPublicImages
            namespaceAllowList
            namespaceDenyList
          }
          ... on AzureConfigurationOptions {
            tenantId
            clientId
            subscriptionsWhitelist
            subscriptionsBlacklist
            scanVms
          }
          ... on BigqueryConfigurationOptions {
            datasetId
          }
          ... on SnowflakeConfigurationOptions {
            accountId
            database
            schema
            region
            warehouse
            role
            username
          }
          ... on AwsS3ConfigurationOptions {
            output
            bucket
            region
            accessKey
          }
          ... on S3ConfigurationOptions {
            output
            endpoint
            bucket
            useLegacyList
            usePathStyle
            accessKey
          }
          ... on Ms365ConfigurationOptions {
            tenantId
            clientId
          }
          ... on MicrosoftDefenderConfigurationOptions {
            tenantId
            clientId
            subscriptionsAllowlist
            subscriptionsDenylist
            secretType
          }
          ... on GcpConfigurationOptions {
            projectId
            organizationId
            discoverAll
          }
          ... on GithubConfigurationOptions {
            owner
            repository
            githubOrg: organization
            githubType: type
            reposAllowList
            reposDenyList
            githubDiscoverK8sManifests: discoverK8sManifests
            githubDiscoverTerraform: discoverTerraform
            enterpriseUrl
          }
          ... on OktaConfigurationOptions {
            organization
          }
          ... on GitlabConfigurationOptions {
            group
            type
            discoverGroups
            discoverProjects
            discoverTerraform
            discoverK8sManifests
            baseUrl
          }
          ... on GoogleWorkspaceConfigurationOptions {
            impersonatedUserEmail
            customerId
          }
          ... on GcsBucketConfigurationOptions {
            output
            bucket
          }
          ... on HostConfigurationOptions {
            host
            https
            http
          }
          ... on PostgresConfigurationOptions {
            host
            port
            database
            user
          }
          ... on JiraConfigurationOptions {
            host
            email
            autoCloseTickets
            autoCreateCases
            defaultProject
          }
          ... on EmailConfigurationOptions {
            autoCreateTickets
            recipients {
              name
              email
              isDefault
              referenceUrl
            }
          }
          ... on ZendeskConfigurationOptions {
            subdomain
            email
            autoCloseTickets
            autoCreateTickets
          }
          ... on GithubTicketingConfigurationOptions {
            enterpriseUrl
            defaultRepoOwner
            defaultRepoName
            autoCloseTickets
            autoCreateTickets
          }
          ... on GitlabTicketingConfigurationOptions {
            gitlabBaseUrl: baseUrl
            gitlabDefaultProject: defaultProject
            autoCloseTickets
            autoCreateTickets
          }
          ... on OciConfigurationOptions {
            tenancyOcid
            userOcid
            region
            fingerprint
          }
          ... on AzureBlobConfigurationOptions {
            output
          }
        }
        assetCounts {
          assetType
          total
        }
      }
    }
  }
`;

/**
 * __useGetClientIntegrationQuery__
 *
 * To run a query within a React component, call `useGetClientIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientIntegrationQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useGetClientIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientIntegrationQuery,
    GetClientIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientIntegrationQuery,
    GetClientIntegrationQueryVariables
  >(GetClientIntegrationDocument, options);
}
export function useGetClientIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientIntegrationQuery,
    GetClientIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientIntegrationQuery,
    GetClientIntegrationQueryVariables
  >(GetClientIntegrationDocument, options);
}
export type GetClientIntegrationQueryHookResult = ReturnType<
  typeof useGetClientIntegrationQuery
>;
export type GetClientIntegrationLazyQueryHookResult = ReturnType<
  typeof useGetClientIntegrationLazyQuery
>;
export type GetClientIntegrationQueryResult = Apollo.QueryResult<
  GetClientIntegrationQuery,
  GetClientIntegrationQueryVariables
>;
export const GetClientIntegrationDeleteCommandDocument = gql`
  query GetClientIntegrationDeleteCommand(
    $input: GetClientIntegrationDeleteCommandInput!
  ) {
    getClientIntegrationDeleteCommand(input: $input) {
      message
    }
  }
`;

/**
 * __useGetClientIntegrationDeleteCommandQuery__
 *
 * To run a query within a React component, call `useGetClientIntegrationDeleteCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientIntegrationDeleteCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientIntegrationDeleteCommandQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientIntegrationDeleteCommandQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientIntegrationDeleteCommandQuery,
    GetClientIntegrationDeleteCommandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientIntegrationDeleteCommandQuery,
    GetClientIntegrationDeleteCommandQueryVariables
  >(GetClientIntegrationDeleteCommandDocument, options);
}
export function useGetClientIntegrationDeleteCommandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientIntegrationDeleteCommandQuery,
    GetClientIntegrationDeleteCommandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientIntegrationDeleteCommandQuery,
    GetClientIntegrationDeleteCommandQueryVariables
  >(GetClientIntegrationDeleteCommandDocument, options);
}
export type GetClientIntegrationDeleteCommandQueryHookResult = ReturnType<
  typeof useGetClientIntegrationDeleteCommandQuery
>;
export type GetClientIntegrationDeleteCommandLazyQueryHookResult = ReturnType<
  typeof useGetClientIntegrationDeleteCommandLazyQuery
>;
export type GetClientIntegrationDeleteCommandQueryResult = Apollo.QueryResult<
  GetClientIntegrationDeleteCommandQuery,
  GetClientIntegrationDeleteCommandQueryVariables
>;
export const GetClientIntegrationTokenDocument = gql`
  query GetClientIntegrationToken($input: GetClientIntegrationTokenInput!) {
    getClientIntegrationToken(input: $input) {
      token
    }
  }
`;

/**
 * __useGetClientIntegrationTokenQuery__
 *
 * To run a query within a React component, call `useGetClientIntegrationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientIntegrationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientIntegrationTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientIntegrationTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientIntegrationTokenQuery,
    GetClientIntegrationTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientIntegrationTokenQuery,
    GetClientIntegrationTokenQueryVariables
  >(GetClientIntegrationTokenDocument, options);
}
export function useGetClientIntegrationTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientIntegrationTokenQuery,
    GetClientIntegrationTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientIntegrationTokenQuery,
    GetClientIntegrationTokenQueryVariables
  >(GetClientIntegrationTokenDocument, options);
}
export type GetClientIntegrationTokenQueryHookResult = ReturnType<
  typeof useGetClientIntegrationTokenQuery
>;
export type GetClientIntegrationTokenLazyQueryHookResult = ReturnType<
  typeof useGetClientIntegrationTokenLazyQuery
>;
export type GetClientIntegrationTokenQueryResult = Apollo.QueryResult<
  GetClientIntegrationTokenQuery,
  GetClientIntegrationTokenQueryVariables
>;
export const GetComplianceControlDocument = gql`
  query GetComplianceControl($input: ComplianceControlInput!) {
    complianceControl(input: $input) {
      framework {
        name
        version
      }
      mrn
      description
      title
      manual
      completion
      tags {
        key
        value
      }
      state
      assetGroupStatistics {
        count
        groupType
        listsAssetTypes {
          assetType
          aliases
          displayName
          shortName
        }
        statistics {
          count
          type {
            assetType
            aliases
            displayName
            shortName
          }
        }
      }
      assetsStats {
        compliantAssets
        failedAssets
        totalAssets
      }
      checksStats {
        activeChecks
        snoozedChecks
        disabledChecks
        totalChecks
      }
      exceptions {
        id
        author {
          name
          email
        }
        reviewer {
          name
          email
        }
        createdAt
        modifiedAt
        title
        justification
        reviewStatus
        action
      }
    }
  }
`;

/**
 * __useGetComplianceControlQuery__
 *
 * To run a query within a React component, call `useGetComplianceControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceControlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetComplianceControlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceControlQuery,
    GetComplianceControlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceControlQuery,
    GetComplianceControlQueryVariables
  >(GetComplianceControlDocument, options);
}
export function useGetComplianceControlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceControlQuery,
    GetComplianceControlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceControlQuery,
    GetComplianceControlQueryVariables
  >(GetComplianceControlDocument, options);
}
export type GetComplianceControlQueryHookResult = ReturnType<
  typeof useGetComplianceControlQuery
>;
export type GetComplianceControlLazyQueryHookResult = ReturnType<
  typeof useGetComplianceControlLazyQuery
>;
export type GetComplianceControlQueryResult = Apollo.QueryResult<
  GetComplianceControlQuery,
  GetComplianceControlQueryVariables
>;
export const GetComplianceControlAssetsDocument = gql`
  query GetComplianceControlAssets(
    $input: ComplianceControlInput!
    $assetsInput: ComplianceAssetsSearchInput!
  ) {
    complianceControl(input: $input) {
      mrn
      description
      title
      assets(input: $assetsInput) {
        totalCount
        edges {
          cursor
          node {
            asset {
              id
              mrn
              state
              name
              labels {
                key
                value
              }
              updatedAt
              platform {
                name
                title
                arch
                version
                kind
                runtime
                release
              }
              score {
                id
                value
                type
                completion
                weight
                message
                grade
              }
              referenceIDs
              annotations {
                key
                value
              }
              asset_type
            }
            compliancePercentage
            relatedAssets
            exception {
              author {
                name
                email
              }
              createdAt
              action
              id
              reviewStatus
              title
              justification
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

/**
 * __useGetComplianceControlAssetsQuery__
 *
 * To run a query within a React component, call `useGetComplianceControlAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceControlAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceControlAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      assetsInput: // value for 'assetsInput'
 *   },
 * });
 */
export function useGetComplianceControlAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceControlAssetsQuery,
    GetComplianceControlAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceControlAssetsQuery,
    GetComplianceControlAssetsQueryVariables
  >(GetComplianceControlAssetsDocument, options);
}
export function useGetComplianceControlAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceControlAssetsQuery,
    GetComplianceControlAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceControlAssetsQuery,
    GetComplianceControlAssetsQueryVariables
  >(GetComplianceControlAssetsDocument, options);
}
export type GetComplianceControlAssetsQueryHookResult = ReturnType<
  typeof useGetComplianceControlAssetsQuery
>;
export type GetComplianceControlAssetsLazyQueryHookResult = ReturnType<
  typeof useGetComplianceControlAssetsLazyQuery
>;
export type GetComplianceControlAssetsQueryResult = Apollo.QueryResult<
  GetComplianceControlAssetsQuery,
  GetComplianceControlAssetsQueryVariables
>;
export const GetComplianceControlChecksDocument = gql`
  query GetComplianceControlChecks(
    $input: ComplianceControlInput!
    $checksInput: ChecksSearchInput!
    $first: Int
    $after: String
  ) {
    complianceControl(input: $input) {
      mrn
      description
      title
      checks(input: $checksInput, first: $first, after: $after) {
        totalCount
        edges {
          cursor
          node {
            ... on PolicyCheck {
              state
              policyName
              policyMrn
              completion
              assets
            }
            ... on QueryCheck {
              exception {
                id
                action
                justification
                reviewStatus
              }
              state
              refs {
                ... on QueryPackCheckRef {
                  name
                  mrn
                  group
                }
                ... on PolicyCheckRef {
                  name
                  mrn
                  group
                }
              }
              mquery {
                action
                impact {
                  rating
                  value
                }
                uid
                title
                mrn
              }
              completion
              assets
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

/**
 * __useGetComplianceControlChecksQuery__
 *
 * To run a query within a React component, call `useGetComplianceControlChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceControlChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceControlChecksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      checksInput: // value for 'checksInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetComplianceControlChecksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceControlChecksQuery,
    GetComplianceControlChecksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceControlChecksQuery,
    GetComplianceControlChecksQueryVariables
  >(GetComplianceControlChecksDocument, options);
}
export function useGetComplianceControlChecksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceControlChecksQuery,
    GetComplianceControlChecksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceControlChecksQuery,
    GetComplianceControlChecksQueryVariables
  >(GetComplianceControlChecksDocument, options);
}
export type GetComplianceControlChecksQueryHookResult = ReturnType<
  typeof useGetComplianceControlChecksQuery
>;
export type GetComplianceControlChecksLazyQueryHookResult = ReturnType<
  typeof useGetComplianceControlChecksLazyQuery
>;
export type GetComplianceControlChecksQueryResult = Apollo.QueryResult<
  GetComplianceControlChecksQuery,
  GetComplianceControlChecksQueryVariables
>;
export const GetComplianceControlDataQueriesDocument = gql`
  query GetComplianceControlDataQueries(
    $input: ComplianceControlInput!
    $dataQueriesInput: ChecksSearchInput!
    $first: Int
    $after: String
  ) {
    complianceControl(input: $input) {
      mrn
      dataQueries(input: $dataQueriesInput, first: $first, after: $after) {
        totalCount
        edges {
          cursor
          node {
            assets
            completion
            mquery {
              action
              docs {
                desc
                remediations {
                  ... on Remediations {
                    entries {
                      id
                      desc
                    }
                  }
                  ... on AuthenticationRequired {
                    message
                    code
                  }
                }
                references {
                  title
                  url
                }
              }
              impact {
                value
                rating
              }
              mql
              mrn
              parent
              properties {
                uid
                title
                setByPolicyMrn
                mrn
                mql
              }
              tags {
                key
                value
              }
              title
              uid
              variantQueries {
                filter
                filterID
                impact {
                  value
                  rating
                }
                mql
                mrn
                properties {
                  mql
                  mrn
                  setByPolicyMrn
                  title
                  uid
                }
                tags {
                  key
                  value
                }
                title
                uid
                docs {
                  desc
                  references {
                    title
                    url
                  }
                  remediations {
                    ... on Remediations {
                      entries {
                        id
                        desc
                      }
                    }
                    ... on AuthenticationRequired {
                      message
                      code
                    }
                  }
                }
              }
              variants {
                id
                icon
              }
            }
            queryStats {
              total
              passed
              failed
              incomplete
              errors
              skipped
              worst
              unknown
            }
            refs {
              ... on QueryPackCheckRef {
                name
                mrn
                group
              }
              ... on PolicyCheckRef {
                name
                mrn
                group
              }
            }
            state
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

/**
 * __useGetComplianceControlDataQueriesQuery__
 *
 * To run a query within a React component, call `useGetComplianceControlDataQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceControlDataQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceControlDataQueriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      dataQueriesInput: // value for 'dataQueriesInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetComplianceControlDataQueriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceControlDataQueriesQuery,
    GetComplianceControlDataQueriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceControlDataQueriesQuery,
    GetComplianceControlDataQueriesQueryVariables
  >(GetComplianceControlDataQueriesDocument, options);
}
export function useGetComplianceControlDataQueriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceControlDataQueriesQuery,
    GetComplianceControlDataQueriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceControlDataQueriesQuery,
    GetComplianceControlDataQueriesQueryVariables
  >(GetComplianceControlDataQueriesDocument, options);
}
export type GetComplianceControlDataQueriesQueryHookResult = ReturnType<
  typeof useGetComplianceControlDataQueriesQuery
>;
export type GetComplianceControlDataQueriesLazyQueryHookResult = ReturnType<
  typeof useGetComplianceControlDataQueriesLazyQuery
>;
export type GetComplianceControlDataQueriesQueryResult = Apollo.QueryResult<
  GetComplianceControlDataQueriesQuery,
  GetComplianceControlDataQueriesQueryVariables
>;
export const GetComplianceFrameworkDocument = gql`
  query GetComplianceFramework($input: ComplianceFrameworkInput!) {
    complianceFramework(input: $input) {
      mrn
      name
      version
      createdAt
      modifiedAt
      authors {
        name
        email
      }
      tags {
        key
        value
      }
      description
      summary
      stats {
        controls {
          activeControls
          snoozedControls
          outOfScopeControls
          disabledControls
          totalControls
          averageCompletion
          previousAverageCompletion
        }
        checks
        assets
        exceptions
      }
      state
      policiesMrns
      scopeMrn
      completion
      assetGroupStatistics {
        listsAssetTypes {
          assetType
          aliases
          displayName
          shortName
        }
        groupType
        count
        statistics {
          type {
            assetType
            aliases
            displayName
            shortName
          }
          count
        }
      }
      previousCompletionScores {
        entries {
          score
          timestamp
        }
      }
    }
  }
`;

/**
 * __useGetComplianceFrameworkQuery__
 *
 * To run a query within a React component, call `useGetComplianceFrameworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceFrameworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceFrameworkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetComplianceFrameworkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceFrameworkQuery,
    GetComplianceFrameworkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceFrameworkQuery,
    GetComplianceFrameworkQueryVariables
  >(GetComplianceFrameworkDocument, options);
}
export function useGetComplianceFrameworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceFrameworkQuery,
    GetComplianceFrameworkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceFrameworkQuery,
    GetComplianceFrameworkQueryVariables
  >(GetComplianceFrameworkDocument, options);
}
export type GetComplianceFrameworkQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworkQuery
>;
export type GetComplianceFrameworkLazyQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworkLazyQuery
>;
export type GetComplianceFrameworkQueryResult = Apollo.QueryResult<
  GetComplianceFrameworkQuery,
  GetComplianceFrameworkQueryVariables
>;
export const GetComplianceFrameworkAssetsDocument = gql`
  query GetComplianceFrameworkAssets(
    $input: ComplianceFrameworkInput!
    $assetsInput: ComplianceAssetsSearchInput!
  ) {
    complianceFramework(input: $input) {
      mrn
      name
      assetGroupStatistics {
        listsAssetTypes {
          assetType
          aliases
          displayName
          shortName
        }
        groupType
        count
        statistics {
          type {
            assetType
            aliases
            displayName
            shortName
          }
          count
        }
      }
      assets(input: $assetsInput) {
        totalCount
        edges {
          cursor
          node {
            compliancePercentage
            asset {
              id
              mrn
              state
              name
              labels {
                key
                value
              }
              updatedAt
              platform {
                name
                title
                arch
                version
                kind
                runtime
                release
              }
              score {
                completion
                grade
                id
                message
                type
                value
                weight
              }
              referenceIDs
              annotations {
                key
                value
              }
              asset_type
              relatedAssets {
                count {
                  count
                  isParent
                  type
                }
                list {
                  totalCount
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

/**
 * __useGetComplianceFrameworkAssetsQuery__
 *
 * To run a query within a React component, call `useGetComplianceFrameworkAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceFrameworkAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceFrameworkAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      assetsInput: // value for 'assetsInput'
 *   },
 * });
 */
export function useGetComplianceFrameworkAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceFrameworkAssetsQuery,
    GetComplianceFrameworkAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceFrameworkAssetsQuery,
    GetComplianceFrameworkAssetsQueryVariables
  >(GetComplianceFrameworkAssetsDocument, options);
}
export function useGetComplianceFrameworkAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceFrameworkAssetsQuery,
    GetComplianceFrameworkAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceFrameworkAssetsQuery,
    GetComplianceFrameworkAssetsQueryVariables
  >(GetComplianceFrameworkAssetsDocument, options);
}
export type GetComplianceFrameworkAssetsQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworkAssetsQuery
>;
export type GetComplianceFrameworkAssetsLazyQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworkAssetsLazyQuery
>;
export type GetComplianceFrameworkAssetsQueryResult = Apollo.QueryResult<
  GetComplianceFrameworkAssetsQuery,
  GetComplianceFrameworkAssetsQueryVariables
>;
export const GetComplianceFrameworkControlsDocument = gql`
  query GetComplianceFrameworkControls(
    $input: ComplianceFrameworkInput!
    $controlsInput: ControlsSearchInput!
  ) {
    complianceFramework(input: $input) {
      mrn
      name
      controls(input: $controlsInput) {
        totalCount
        edges {
          cursor
          node {
            mrn
            description
            title
            manual
            completion
            tags {
              key
              value
            }
            state
            assetsStats {
              compliantAssets
              failedAssets
              totalAssets
            }
            checksStats {
              activeChecks
              snoozedChecks
              disabledChecks
              totalChecks
              totalQueries
            }
            exception {
              author {
                name
                email
              }
              createdAt
              action
              id
              reviewStatus
              title
              justification
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

/**
 * __useGetComplianceFrameworkControlsQuery__
 *
 * To run a query within a React component, call `useGetComplianceFrameworkControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceFrameworkControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceFrameworkControlsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      controlsInput: // value for 'controlsInput'
 *   },
 * });
 */
export function useGetComplianceFrameworkControlsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceFrameworkControlsQuery,
    GetComplianceFrameworkControlsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceFrameworkControlsQuery,
    GetComplianceFrameworkControlsQueryVariables
  >(GetComplianceFrameworkControlsDocument, options);
}
export function useGetComplianceFrameworkControlsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceFrameworkControlsQuery,
    GetComplianceFrameworkControlsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceFrameworkControlsQuery,
    GetComplianceFrameworkControlsQueryVariables
  >(GetComplianceFrameworkControlsDocument, options);
}
export type GetComplianceFrameworkControlsQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworkControlsQuery
>;
export type GetComplianceFrameworkControlsLazyQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworkControlsLazyQuery
>;
export type GetComplianceFrameworkControlsQueryResult = Apollo.QueryResult<
  GetComplianceFrameworkControlsQuery,
  GetComplianceFrameworkControlsQueryVariables
>;
export const GetComplianceFrameworksDocument = gql`
  query GetComplianceFrameworks($input: ComplianceFrameworksInput!) {
    complianceFrameworks(input: $input) {
      authors {
        name
        email
      }
      createdAt
      modifiedAt
      completion
      description
      mrn
      name
      previousCompletionScores {
        entries {
          score
          timestamp
        }
      }
      scopeMrn
      state
      stats {
        controls {
          activeControls
          snoozedControls
          outOfScopeControls
          disabledControls
          totalControls
          averageCompletion
          previousAverageCompletion
        }
        checks
        assets
        exceptions
      }
      summary
      version
      tags {
        key
        value
      }
    }
  }
`;

/**
 * __useGetComplianceFrameworksQuery__
 *
 * To run a query within a React component, call `useGetComplianceFrameworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplianceFrameworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplianceFrameworksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetComplianceFrameworksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetComplianceFrameworksQuery,
    GetComplianceFrameworksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetComplianceFrameworksQuery,
    GetComplianceFrameworksQueryVariables
  >(GetComplianceFrameworksDocument, options);
}
export function useGetComplianceFrameworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplianceFrameworksQuery,
    GetComplianceFrameworksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetComplianceFrameworksQuery,
    GetComplianceFrameworksQueryVariables
  >(GetComplianceFrameworksDocument, options);
}
export type GetComplianceFrameworksQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworksQuery
>;
export type GetComplianceFrameworksLazyQueryHookResult = ReturnType<
  typeof useGetComplianceFrameworksLazyQuery
>;
export type GetComplianceFrameworksQueryResult = Apollo.QueryResult<
  GetComplianceFrameworksQuery,
  GetComplianceFrameworksQueryVariables
>;
export const GetContentDocument = gql`
  query GetContent($input: ContentSearchInput!) {
    content(input: $input) {
      totalCount
      edges {
        cursor
        node {
          ... on Policy {
            access
            action
            assigned
            authors {
              email
              name
            }
            category
            certifiedBy {
              name
            }
            createdAt
            featured
            trustLevel
            mrn
            name
            statistics {
              checks
              policies
              queries
            }
            uid
            updatedAt
            version
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentQuery,
    GetContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(
    GetContentDocument,
    options,
  );
}
export function useGetContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentQuery,
    GetContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(
    GetContentDocument,
    options,
  );
}
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<
  typeof useGetContentLazyQuery
>;
export type GetContentQueryResult = Apollo.QueryResult<
  GetContentQuery,
  GetContentQueryVariables
>;
export const GetCveAdvisoriesDocument = gql`
  query GetCveAdvisories(
    $id: ID!
    $spaceMrn: String!
    $advisoriesFirst: Int
    $advisoriesAfter: String
  ) {
    cve(id: $id, spaceMrn: $spaceMrn) {
      id
      mrn
      advisoryAggregateScores(
        first: $advisoriesFirst
        after: $advisoriesAfter
        spaceMrn: $spaceMrn
      ) {
        ... on AggregateScoresConnection {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              iconId
              findingMrn
              scoreType
              state
              description
              remediatedAt
              spaceId
              title
              firstDetectedAt
              riskScore
              rank
              blastRadius {
                ...BlastRadiusFields
              }
              riskFactors {
                mrn
                indicator
                title
                total
                affected
              }
            }
          }
        }
      }
    }
  }
  ${BlastRadiusFieldsFragmentDoc}
`;

/**
 * __useGetCveAdvisoriesQuery__
 *
 * To run a query within a React component, call `useGetCveAdvisoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCveAdvisoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCveAdvisoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      spaceMrn: // value for 'spaceMrn'
 *      advisoriesFirst: // value for 'advisoriesFirst'
 *      advisoriesAfter: // value for 'advisoriesAfter'
 *   },
 * });
 */
export function useGetCveAdvisoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCveAdvisoriesQuery,
    GetCveAdvisoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCveAdvisoriesQuery, GetCveAdvisoriesQueryVariables>(
    GetCveAdvisoriesDocument,
    options,
  );
}
export function useGetCveAdvisoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCveAdvisoriesQuery,
    GetCveAdvisoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCveAdvisoriesQuery,
    GetCveAdvisoriesQueryVariables
  >(GetCveAdvisoriesDocument, options);
}
export type GetCveAdvisoriesQueryHookResult = ReturnType<
  typeof useGetCveAdvisoriesQuery
>;
export type GetCveAdvisoriesLazyQueryHookResult = ReturnType<
  typeof useGetCveAdvisoriesLazyQuery
>;
export type GetCveAdvisoriesQueryResult = Apollo.QueryResult<
  GetCveAdvisoriesQuery,
  GetCveAdvisoriesQueryVariables
>;
export const GetDataQueriesListDocument = gql`
  query GetDataQueriesList(
    $entityMrn: String!
    $first: Int
    $after: String
    $orderBy: DataQueryOrder
    $filter: DataQueryFilter
  ) {
    dataQueries(
      entityMrn: $entityMrn
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      ... on DataQueriesConnection {
        totalCount
        edges {
          cursor
          node {
            mrn
            id
            title
            iconId
            lastUpdated
            tags {
              key
              value
            }
            asset {
              id
              mrn
              name
              icon
            }
            platform {
              name
              title
              arch
              version
              kind
              runtime
              family
              labels {
                key
                value
              }
            }
            mquery {
              uid
              mrn
              title
              mql
              action
              code
              codeId
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        filteredTotalCount
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetDataQueriesListQuery__
 *
 * To run a query within a React component, call `useGetDataQueriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataQueriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataQueriesListQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDataQueriesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataQueriesListQuery,
    GetDataQueriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDataQueriesListQuery,
    GetDataQueriesListQueryVariables
  >(GetDataQueriesListDocument, options);
}
export function useGetDataQueriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataQueriesListQuery,
    GetDataQueriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDataQueriesListQuery,
    GetDataQueriesListQueryVariables
  >(GetDataQueriesListDocument, options);
}
export type GetDataQueriesListQueryHookResult = ReturnType<
  typeof useGetDataQueriesListQuery
>;
export type GetDataQueriesListLazyQueryHookResult = ReturnType<
  typeof useGetDataQueriesListLazyQuery
>;
export type GetDataQueriesListQueryResult = Apollo.QueryResult<
  GetDataQueriesListQuery,
  GetDataQueriesListQueryVariables
>;
export const GetDataQueryDocument = gql`
  query GetDataQuery(
    $entityMrn: String!
    $first: Int
    $after: String
    $orderBy: DataQueryOrder
    $filter: DataQueryFilter
  ) {
    dataQueries(
      entityMrn: $entityMrn
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      ... on DataQueriesConnection {
        totalCount
        edges {
          cursor
          node {
            mrn
            id
            iconId
            lastUpdated
            tags {
              key
              value
            }
            asset {
              id
              mrn
              name
              icon
            }
            platform {
              name
              title
              arch
              version
              kind
              runtime
              family
              labels {
                key
                value
              }
            }
            value
            mquery {
              uid
              mrn
              title
              docs {
                desc
                remediations {
                  ... on Remediations {
                    entries {
                      id
                      desc
                    }
                  }
                  ... on AuthenticationRequired {
                    message
                    code
                  }
                }
                references {
                  title
                  url
                }
                audit
                remediation
              }
              impact {
                value
                rating
              }
              mql
              tags {
                key
                value
              }
              properties {
                uid
                mrn
                title
                mql
                setByPolicyMrn
              }
              parent
              variants {
                id
                mrn
                title
                icon
              }
              variantQueries {
                uid
                mrn
                title
                mql
                filter
                filterID
              }
              action
              code
              codeId
              severity
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        filteredTotalCount
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetDataQueryQuery__
 *
 * To run a query within a React component, call `useGetDataQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataQueryQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDataQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataQueryQuery,
    GetDataQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDataQueryQuery, GetDataQueryQueryVariables>(
    GetDataQueryDocument,
    options,
  );
}
export function useGetDataQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataQueryQuery,
    GetDataQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDataQueryQuery, GetDataQueryQueryVariables>(
    GetDataQueryDocument,
    options,
  );
}
export type GetDataQueryQueryHookResult = ReturnType<
  typeof useGetDataQueryQuery
>;
export type GetDataQueryLazyQueryHookResult = ReturnType<
  typeof useGetDataQueryLazyQuery
>;
export type GetDataQueryQueryResult = Apollo.QueryResult<
  GetDataQueryQuery,
  GetDataQueryQueryVariables
>;
export const GetEndOfLifeDocument = gql`
  query GetEndOfLife($input: AssetReportInput!) {
    assetReport(input: $input) {
      ... on AssetReport {
        analysis {
          eol {
            date
            status
          }
        }
      }
    }
  }
`;

/**
 * __useGetEndOfLifeQuery__
 *
 * To run a query within a React component, call `useGetEndOfLifeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndOfLifeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndOfLifeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEndOfLifeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEndOfLifeQuery,
    GetEndOfLifeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEndOfLifeQuery, GetEndOfLifeQueryVariables>(
    GetEndOfLifeDocument,
    options,
  );
}
export function useGetEndOfLifeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndOfLifeQuery,
    GetEndOfLifeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEndOfLifeQuery, GetEndOfLifeQueryVariables>(
    GetEndOfLifeDocument,
    options,
  );
}
export type GetEndOfLifeQueryHookResult = ReturnType<
  typeof useGetEndOfLifeQuery
>;
export type GetEndOfLifeLazyQueryHookResult = ReturnType<
  typeof useGetEndOfLifeLazyQuery
>;
export type GetEndOfLifeQueryResult = Apollo.QueryResult<
  GetEndOfLifeQuery,
  GetEndOfLifeQueryVariables
>;
export const GetFindingRiskFactorsDocument = gql`
  query GetFindingRiskFactors(
    $entityMrn: String!
    $filter: AggregateScoreFilter
    $first: Int
    $after: String
    $orderBy: AggregateScoreOrder
  ) {
    aggregateScores(
      entityMrn: $entityMrn
      filter: $filter
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      ... on AggregateScoresConnection {
        totalCount
        edges {
          cursor
          node {
            id
            findingMrn
            riskFactors {
              mrn
              indicator
              title
              total
              affected
            }
          }
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetFindingRiskFactorsQuery__
 *
 * To run a query within a React component, call `useGetFindingRiskFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFindingRiskFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFindingRiskFactorsQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetFindingRiskFactorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFindingRiskFactorsQuery,
    GetFindingRiskFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFindingRiskFactorsQuery,
    GetFindingRiskFactorsQueryVariables
  >(GetFindingRiskFactorsDocument, options);
}
export function useGetFindingRiskFactorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFindingRiskFactorsQuery,
    GetFindingRiskFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFindingRiskFactorsQuery,
    GetFindingRiskFactorsQueryVariables
  >(GetFindingRiskFactorsDocument, options);
}
export type GetFindingRiskFactorsQueryHookResult = ReturnType<
  typeof useGetFindingRiskFactorsQuery
>;
export type GetFindingRiskFactorsLazyQueryHookResult = ReturnType<
  typeof useGetFindingRiskFactorsLazyQuery
>;
export type GetFindingRiskFactorsQueryResult = Apollo.QueryResult<
  GetFindingRiskFactorsQuery,
  GetFindingRiskFactorsQueryVariables
>;
export const GetIntegrationsSummaryDocument = gql`
  query GetIntegrationsSummary($input: IntegrationsSummaryInput!) {
    integrationsSummary(input: $input) {
      integrationSummaries {
        totalCount
        activeCount
        failedCount
        warningCount
        assetsCount
        policiesCount
        checksCount
        type
      }
      total
    }
  }
`;

/**
 * __useGetIntegrationsSummaryQuery__
 *
 * To run a query within a React component, call `useGetIntegrationsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationsSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIntegrationsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationsSummaryQuery,
    GetIntegrationsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIntegrationsSummaryQuery,
    GetIntegrationsSummaryQueryVariables
  >(GetIntegrationsSummaryDocument, options);
}
export function useGetIntegrationsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationsSummaryQuery,
    GetIntegrationsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIntegrationsSummaryQuery,
    GetIntegrationsSummaryQueryVariables
  >(GetIntegrationsSummaryDocument, options);
}
export type GetIntegrationsSummaryQueryHookResult = ReturnType<
  typeof useGetIntegrationsSummaryQuery
>;
export type GetIntegrationsSummaryLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationsSummaryLazyQuery
>;
export type GetIntegrationsSummaryQueryResult = Apollo.QueryResult<
  GetIntegrationsSummaryQuery,
  GetIntegrationsSummaryQueryVariables
>;
export const GetMetricsDocument = gql`
  query GetMetrics($entityMrn: String!, $metricMrns: [String!]) {
    metrics(entityMrn: $entityMrn, metricMrns: $metricMrns) {
      ... on MetricsResponse {
        entries {
          metricMrn
          title
          arrowResult
        }
      }
    }
  }
`;

/**
 * __useGetMetricsQuery__
 *
 * To run a query within a React component, call `useGetMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricsQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      metricMrns: // value for 'metricMrns'
 *   },
 * });
 */
export function useGetMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMetricsQuery,
    GetMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetricsQuery, GetMetricsQueryVariables>(
    GetMetricsDocument,
    options,
  );
}
export function useGetMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetricsQuery,
    GetMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetricsQuery, GetMetricsQueryVariables>(
    GetMetricsDocument,
    options,
  );
}
export type GetMetricsQueryHookResult = ReturnType<typeof useGetMetricsQuery>;
export type GetMetricsLazyQueryHookResult = ReturnType<
  typeof useGetMetricsLazyQuery
>;
export type GetMetricsQueryResult = Apollo.QueryResult<
  GetMetricsQuery,
  GetMetricsQueryVariables
>;
export const GetMondooVulnerabilityDatabaseDocument = gql`
  query GetMondooVulnerabilityDatabase(
    $first: Int
    $after: String
    $orderBy: MvdOrder
    $queryTerms: [String!]
    $type: MvdEntryType!
    $severity: [Severity!]
  ) {
    mvd(
      first: $first
      after: $after
      orderBy: $orderBy
      queryTerms: $queryTerms
      type: $type
      severity: $severity
    ) {
      edges {
        cursor
        node {
          ... on Advisory {
            id
            iconIDEnum
            mrn
            title
            cvssScore {
              id
              value
              type
              vector
              source
            }
            publishedAt
          }
          ... on Cve {
            id
            mrn
            title
            publishedAt
            cvssScore {
              id
              source
              type
              value
              vector
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

/**
 * __useGetMondooVulnerabilityDatabaseQuery__
 *
 * To run a query within a React component, call `useGetMondooVulnerabilityDatabaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMondooVulnerabilityDatabaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMondooVulnerabilityDatabaseQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      queryTerms: // value for 'queryTerms'
 *      type: // value for 'type'
 *      severity: // value for 'severity'
 *   },
 * });
 */
export function useGetMondooVulnerabilityDatabaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMondooVulnerabilityDatabaseQuery,
    GetMondooVulnerabilityDatabaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMondooVulnerabilityDatabaseQuery,
    GetMondooVulnerabilityDatabaseQueryVariables
  >(GetMondooVulnerabilityDatabaseDocument, options);
}
export function useGetMondooVulnerabilityDatabaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMondooVulnerabilityDatabaseQuery,
    GetMondooVulnerabilityDatabaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMondooVulnerabilityDatabaseQuery,
    GetMondooVulnerabilityDatabaseQueryVariables
  >(GetMondooVulnerabilityDatabaseDocument, options);
}
export type GetMondooVulnerabilityDatabaseQueryHookResult = ReturnType<
  typeof useGetMondooVulnerabilityDatabaseQuery
>;
export type GetMondooVulnerabilityDatabaseLazyQueryHookResult = ReturnType<
  typeof useGetMondooVulnerabilityDatabaseLazyQuery
>;
export type GetMondooVulnerabilityDatabaseQueryResult = Apollo.QueryResult<
  GetMondooVulnerabilityDatabaseQuery,
  GetMondooVulnerabilityDatabaseQueryVariables
>;
export const GetOrganizationOverviewDocument = gql`
  query GetOrganizationOverview($input: OrganizationOverviewInput!) {
    organizationOverview(input: $input) {
      organizationMrn
      assetScores {
        date
        scores {
          total
          A
          B
          C
          D
          F
          U
          Error
        }
      }
      assets {
        scores {
          total
          A
          B
          C
          D
          F
          U
          Error
        }
        improved
        regressed
        unchanged
      }
      vulnerabilities {
        date
        critical
        high
      }
      topVulnerabilities {
        totalAffectedAssets
        stats {
          amountAssets
          spaceMrn
          spaceName
          spaceOwners
        }
        cve {
          id
          title
          description
          cvssScore {
            id
            type
            value
          }
        }
      }
      newVulnerabilities {
        date
        assetsAffected
        cve {
          id
          title
          description
          cvssScore {
            type
            value
          }
        }
      }
      spacesOverview {
        spaceName
        spaceMrn
        scores {
          total
          A
          B
          C
          D
          F
          U
          Error
        }
      }
      integrationSummaries {
        type
        totalCount
      }
      eolAssets
      reportDate
    }
  }
`;

/**
 * __useGetOrganizationOverviewQuery__
 *
 * To run a query within a React component, call `useGetOrganizationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationOverviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrganizationOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationOverviewQuery,
    GetOrganizationOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrganizationOverviewQuery,
    GetOrganizationOverviewQueryVariables
  >(GetOrganizationOverviewDocument, options);
}
export function useGetOrganizationOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationOverviewQuery,
    GetOrganizationOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationOverviewQuery,
    GetOrganizationOverviewQueryVariables
  >(GetOrganizationOverviewDocument, options);
}
export type GetOrganizationOverviewQueryHookResult = ReturnType<
  typeof useGetOrganizationOverviewQuery
>;
export type GetOrganizationOverviewLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationOverviewLazyQuery
>;
export type GetOrganizationOverviewQueryResult = Apollo.QueryResult<
  GetOrganizationOverviewQuery,
  GetOrganizationOverviewQueryVariables
>;
export const GetPolicyPropsDocument = gql`
  query GetPolicyProps($input: PolicyInput!) {
    policy(input: $input) {
      mrn
      properties {
        uid
        mrn
        title
        mql
      }
    }
  }
`;

/**
 * __useGetPolicyPropsQuery__
 *
 * To run a query within a React component, call `useGetPolicyPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyPropsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPolicyPropsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyPropsQuery,
    GetPolicyPropsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyPropsQuery, GetPolicyPropsQueryVariables>(
    GetPolicyPropsDocument,
    options,
  );
}
export function useGetPolicyPropsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyPropsQuery,
    GetPolicyPropsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyPropsQuery, GetPolicyPropsQueryVariables>(
    GetPolicyPropsDocument,
    options,
  );
}
export type GetPolicyPropsQueryHookResult = ReturnType<
  typeof useGetPolicyPropsQuery
>;
export type GetPolicyPropsLazyQueryHookResult = ReturnType<
  typeof useGetPolicyPropsLazyQuery
>;
export type GetPolicyPropsQueryResult = Apollo.QueryResult<
  GetPolicyPropsQuery,
  GetPolicyPropsQueryVariables
>;
export const GetRemediationDocument = gql`
  query GetRemediation($input: RemediationInput!) {
    remediation(input: $input) {
      ... on VulnerabilityRemediationResponse {
        scripts {
          ...RemediationScriptFields
        }
        packages {
          ...RemediationPackageFields
        }
        namespaces
        highestVersionNamespace
      }
    }
  }
  ${RemediationScriptFieldsFragmentDoc}
  ${RemediationPackageFieldsFragmentDoc}
`;

/**
 * __useGetRemediationQuery__
 *
 * To run a query within a React component, call `useGetRemediationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemediationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemediationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRemediationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRemediationQuery,
    GetRemediationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRemediationQuery, GetRemediationQueryVariables>(
    GetRemediationDocument,
    options,
  );
}
export function useGetRemediationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRemediationQuery,
    GetRemediationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRemediationQuery, GetRemediationQueryVariables>(
    GetRemediationDocument,
    options,
  );
}
export type GetRemediationQueryHookResult = ReturnType<
  typeof useGetRemediationQuery
>;
export type GetRemediationLazyQueryHookResult = ReturnType<
  typeof useGetRemediationLazyQuery
>;
export type GetRemediationQueryResult = Apollo.QueryResult<
  GetRemediationQuery,
  GetRemediationQueryVariables
>;
export const GetRenderedAssetDataQueryDocument = gql`
  query GetRenderedAssetDataQuery($input: RenderedAssetQueryDataInput!) {
    renderedAssetQueryData(input: $input) {
      ... on RenderedAssetQueryData {
        assessment
        data
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetRenderedAssetDataQueryQuery__
 *
 * To run a query within a React component, call `useGetRenderedAssetDataQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenderedAssetDataQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenderedAssetDataQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRenderedAssetDataQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRenderedAssetDataQueryQuery,
    GetRenderedAssetDataQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRenderedAssetDataQueryQuery,
    GetRenderedAssetDataQueryQueryVariables
  >(GetRenderedAssetDataQueryDocument, options);
}
export function useGetRenderedAssetDataQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRenderedAssetDataQueryQuery,
    GetRenderedAssetDataQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRenderedAssetDataQueryQuery,
    GetRenderedAssetDataQueryQueryVariables
  >(GetRenderedAssetDataQueryDocument, options);
}
export type GetRenderedAssetDataQueryQueryHookResult = ReturnType<
  typeof useGetRenderedAssetDataQueryQuery
>;
export type GetRenderedAssetDataQueryLazyQueryHookResult = ReturnType<
  typeof useGetRenderedAssetDataQueryLazyQuery
>;
export type GetRenderedAssetDataQueryQueryResult = Apollo.QueryResult<
  GetRenderedAssetDataQueryQuery,
  GetRenderedAssetDataQueryQueryVariables
>;
export const GetRiskFactorsDocument = gql`
  query GetRiskFactors($spaceMrn: String!, $mrns: [String!]) {
    riskFactors(spaceMrn: $spaceMrn, mrns: $mrns) {
      edges {
        mrn
        title
        docs {
          active
        }
      }
    }
  }
`;

/**
 * __useGetRiskFactorsQuery__
 *
 * To run a query within a React component, call `useGetRiskFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskFactorsQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      mrns: // value for 'mrns'
 *   },
 * });
 */
export function useGetRiskFactorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRiskFactorsQuery,
    GetRiskFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRiskFactorsQuery, GetRiskFactorsQueryVariables>(
    GetRiskFactorsDocument,
    options,
  );
}
export function useGetRiskFactorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRiskFactorsQuery,
    GetRiskFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRiskFactorsQuery, GetRiskFactorsQueryVariables>(
    GetRiskFactorsDocument,
    options,
  );
}
export type GetRiskFactorsQueryHookResult = ReturnType<
  typeof useGetRiskFactorsQuery
>;
export type GetRiskFactorsLazyQueryHookResult = ReturnType<
  typeof useGetRiskFactorsLazyQuery
>;
export type GetRiskFactorsQueryResult = Apollo.QueryResult<
  GetRiskFactorsQuery,
  GetRiskFactorsQueryVariables
>;
export const GetRiskFactorsDocsDocument = gql`
  query GetRiskFactorsDocs($spaceMrn: String!, $mrns: [String!]) {
    riskFactors(spaceMrn: $spaceMrn, mrns: $mrns) {
      edges {
        mrn
        title
        docs {
          active
          inactive
        }
      }
    }
  }
`;

/**
 * __useGetRiskFactorsDocsQuery__
 *
 * To run a query within a React component, call `useGetRiskFactorsDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskFactorsDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskFactorsDocsQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      mrns: // value for 'mrns'
 *   },
 * });
 */
export function useGetRiskFactorsDocsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRiskFactorsDocsQuery,
    GetRiskFactorsDocsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRiskFactorsDocsQuery,
    GetRiskFactorsDocsQueryVariables
  >(GetRiskFactorsDocsDocument, options);
}
export function useGetRiskFactorsDocsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRiskFactorsDocsQuery,
    GetRiskFactorsDocsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRiskFactorsDocsQuery,
    GetRiskFactorsDocsQueryVariables
  >(GetRiskFactorsDocsDocument, options);
}
export type GetRiskFactorsDocsQueryHookResult = ReturnType<
  typeof useGetRiskFactorsDocsQuery
>;
export type GetRiskFactorsDocsLazyQueryHookResult = ReturnType<
  typeof useGetRiskFactorsDocsLazyQuery
>;
export type GetRiskFactorsDocsQueryResult = Apollo.QueryResult<
  GetRiskFactorsDocsQuery,
  GetRiskFactorsDocsQueryVariables
>;
export const GetSearchResultsDocument = gql`
  query GetSearchResults(
    $input: AssetSearchInput!
    $orderBy: AssetOrder
    $first: Int!
    $after: String
  ) {
    assetSearch(
      input: $input
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          asset_type
          id
          mrn
          name
          platform {
            arch
            kind
            name
            runtime
            title
            version
          }
          referenceIDs
          score {
            completion
            grade
            id
            message
            type
            value
            weight
          }
          state
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetSearchResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchResultsQuery,
    GetSearchResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
    GetSearchResultsDocument,
    options,
  );
}
export function useGetSearchResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchResultsQuery,
    GetSearchResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchResultsQuery,
    GetSearchResultsQueryVariables
  >(GetSearchResultsDocument, options);
}
export type GetSearchResultsQueryHookResult = ReturnType<
  typeof useGetSearchResultsQuery
>;
export type GetSearchResultsLazyQueryHookResult = ReturnType<
  typeof useGetSearchResultsLazyQuery
>;
export type GetSearchResultsQueryResult = Apollo.QueryResult<
  GetSearchResultsQuery,
  GetSearchResultsQueryVariables
>;
export const GetSharedSpacesCountDocument = gql`
  query GetSharedSpacesCount {
    sharedSpaces(first: 0) {
      totalCount
    }
  }
`;

/**
 * __useGetSharedSpacesCountQuery__
 *
 * To run a query within a React component, call `useGetSharedSpacesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedSpacesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedSpacesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSharedSpacesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSharedSpacesCountQuery,
    GetSharedSpacesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSharedSpacesCountQuery,
    GetSharedSpacesCountQueryVariables
  >(GetSharedSpacesCountDocument, options);
}
export function useGetSharedSpacesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSharedSpacesCountQuery,
    GetSharedSpacesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSharedSpacesCountQuery,
    GetSharedSpacesCountQueryVariables
  >(GetSharedSpacesCountDocument, options);
}
export type GetSharedSpacesCountQueryHookResult = ReturnType<
  typeof useGetSharedSpacesCountQuery
>;
export type GetSharedSpacesCountLazyQueryHookResult = ReturnType<
  typeof useGetSharedSpacesCountLazyQuery
>;
export type GetSharedSpacesCountQueryResult = Apollo.QueryResult<
  GetSharedSpacesCountQuery,
  GetSharedSpacesCountQueryVariables
>;
export const GetSpaceRiskFactorsDocument = gql`
  query GetSpaceRiskFactors($spaceMrn: String!) {
    riskFactors(spaceMrn: $spaceMrn) {
      edges {
        mrn
        title
        docs {
          active
          inactive
        }
        magnitude {
          value
          isToxic
        }
        defaultMagnitude {
          value
          isToxic
        }
        action
      }
    }
  }
`;

/**
 * __useGetSpaceRiskFactorsQuery__
 *
 * To run a query within a React component, call `useGetSpaceRiskFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceRiskFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceRiskFactorsQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useGetSpaceRiskFactorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSpaceRiskFactorsQuery,
    GetSpaceRiskFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSpaceRiskFactorsQuery,
    GetSpaceRiskFactorsQueryVariables
  >(GetSpaceRiskFactorsDocument, options);
}
export function useGetSpaceRiskFactorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpaceRiskFactorsQuery,
    GetSpaceRiskFactorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSpaceRiskFactorsQuery,
    GetSpaceRiskFactorsQueryVariables
  >(GetSpaceRiskFactorsDocument, options);
}
export type GetSpaceRiskFactorsQueryHookResult = ReturnType<
  typeof useGetSpaceRiskFactorsQuery
>;
export type GetSpaceRiskFactorsLazyQueryHookResult = ReturnType<
  typeof useGetSpaceRiskFactorsLazyQuery
>;
export type GetSpaceRiskFactorsQueryResult = Apollo.QueryResult<
  GetSpaceRiskFactorsQuery,
  GetSpaceRiskFactorsQueryVariables
>;
export const GetSpaceVulnerabilityCountDocument = gql`
  query GetSpaceVulnerabilityCount($input: SpaceVulnerabilityReportInput!) {
    spaceVulnerabilityReport(input: $input) {
      ... on SpaceVulnerabilityReport {
        spaceMrn
        cveSummaries {
          totalCount
          stats {
            totalAssetCves
            totalDatabaseCves
          }
        }
      }
    }
  }
`;

/**
 * __useGetSpaceVulnerabilityCountQuery__
 *
 * To run a query within a React component, call `useGetSpaceVulnerabilityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceVulnerabilityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceVulnerabilityCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSpaceVulnerabilityCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSpaceVulnerabilityCountQuery,
    GetSpaceVulnerabilityCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSpaceVulnerabilityCountQuery,
    GetSpaceVulnerabilityCountQueryVariables
  >(GetSpaceVulnerabilityCountDocument, options);
}
export function useGetSpaceVulnerabilityCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpaceVulnerabilityCountQuery,
    GetSpaceVulnerabilityCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSpaceVulnerabilityCountQuery,
    GetSpaceVulnerabilityCountQueryVariables
  >(GetSpaceVulnerabilityCountDocument, options);
}
export type GetSpaceVulnerabilityCountQueryHookResult = ReturnType<
  typeof useGetSpaceVulnerabilityCountQuery
>;
export type GetSpaceVulnerabilityCountLazyQueryHookResult = ReturnType<
  typeof useGetSpaceVulnerabilityCountLazyQuery
>;
export type GetSpaceVulnerabilityCountQueryResult = Apollo.QueryResult<
  GetSpaceVulnerabilityCountQuery,
  GetSpaceVulnerabilityCountQueryVariables
>;
export const GetTicketContextDocument = gql`
  query GetTicketContext($input: TicketContextInput!) {
    ticketContext(input: $input) {
      ... on JiraTicketContextProjectsPayload {
        projects {
          key
          name
        }
      }
      ... on EmailTicketContextPayload {
        recipients {
          name
          email
          isDefault
          referenceUrl
        }
      }
    }
  }
`;

/**
 * __useGetTicketContextQuery__
 *
 * To run a query within a React component, call `useGetTicketContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketContextQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTicketContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTicketContextQuery,
    GetTicketContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketContextQuery, GetTicketContextQueryVariables>(
    GetTicketContextDocument,
    options,
  );
}
export function useGetTicketContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketContextQuery,
    GetTicketContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTicketContextQuery,
    GetTicketContextQueryVariables
  >(GetTicketContextDocument, options);
}
export type GetTicketContextQueryHookResult = ReturnType<
  typeof useGetTicketContextQuery
>;
export type GetTicketContextLazyQueryHookResult = ReturnType<
  typeof useGetTicketContextLazyQuery
>;
export type GetTicketContextQueryResult = Apollo.QueryResult<
  GetTicketContextQuery,
  GetTicketContextQueryVariables
>;
export const GetTicketingIntegrationsDocument = gql`
  query GetTicketingIntegrations($input: TicketingIntegrationsInput!) {
    ticketingIntegrations(input: $input) {
      integrations {
        mrn
        name
        type
        configurationOptions {
          ... on JiraConfigurationOptions {
            host
            email
            defaultProject
            autoCloseTickets
            autoCreateCases
          }
          ... on EmailConfigurationOptions {
            autoCreateTickets
            recipients {
              name
              email
              isDefault
              referenceUrl
            }
          }
          ... on ZendeskConfigurationOptions {
            subdomain
            email
            autoCloseTickets
            autoCreateTickets
          }
          ... on GithubTicketingConfigurationOptions {
            enterpriseUrl
            defaultRepoOwner
            defaultRepoName
            autoCloseTickets
            autoCreateTickets
          }
          ... on GitlabTicketingConfigurationOptions {
            gitlabBaseUrl: baseUrl
            gitlabDefaultProject: defaultProject
            autoCloseTickets
            autoCreateTickets
          }
        }
      }
    }
  }
`;

/**
 * __useGetTicketingIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetTicketingIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketingIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketingIntegrationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTicketingIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTicketingIntegrationsQuery,
    GetTicketingIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTicketingIntegrationsQuery,
    GetTicketingIntegrationsQueryVariables
  >(GetTicketingIntegrationsDocument, options);
}
export function useGetTicketingIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketingIntegrationsQuery,
    GetTicketingIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTicketingIntegrationsQuery,
    GetTicketingIntegrationsQueryVariables
  >(GetTicketingIntegrationsDocument, options);
}
export type GetTicketingIntegrationsQueryHookResult = ReturnType<
  typeof useGetTicketingIntegrationsQuery
>;
export type GetTicketingIntegrationsLazyQueryHookResult = ReturnType<
  typeof useGetTicketingIntegrationsLazyQuery
>;
export type GetTicketingIntegrationsQueryResult = Apollo.QueryResult<
  GetTicketingIntegrationsQuery,
  GetTicketingIntegrationsQueryVariables
>;
export const GetVulnerabilityScoresDocument = gql`
  query GetVulnerabilityScores(
    $entityMrn: String!
    $first: Int
    $after: String
    $orderBy: VulnerabilityScoreOrder
    $filter: VulnerabilityScoreFilter
  ) {
    vulnerabilityScores(
      entityMrn: $entityMrn
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      ... on VulnerabilityScoresConnection {
        totalCount
        edges {
          cursor
          node {
            mrn
            id
            asset {
              icon
              id
              mrn
              name
              tags {
                key
                value
              }
            }
            title
            state
            iconId
            epss {
              probability
              percentile
            }
            cvss {
              id
              value
              type
              vector
              source
            }
            riskScore
            scoreType
            riskFactors {
              mrn
              indicator
              title
              affected
              total
            }
            firstDetectedAt
            publishedAt
            updatedAt
            tags {
              key
              value
            }
          }
        }
        filteredTotalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetVulnerabilityScoresQuery__
 *
 * To run a query within a React component, call `useGetVulnerabilityScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVulnerabilityScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVulnerabilityScoresQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVulnerabilityScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVulnerabilityScoresQuery,
    GetVulnerabilityScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVulnerabilityScoresQuery,
    GetVulnerabilityScoresQueryVariables
  >(GetVulnerabilityScoresDocument, options);
}
export function useGetVulnerabilityScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVulnerabilityScoresQuery,
    GetVulnerabilityScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVulnerabilityScoresQuery,
    GetVulnerabilityScoresQueryVariables
  >(GetVulnerabilityScoresDocument, options);
}
export type GetVulnerabilityScoresQueryHookResult = ReturnType<
  typeof useGetVulnerabilityScoresQuery
>;
export type GetVulnerabilityScoresLazyQueryHookResult = ReturnType<
  typeof useGetVulnerabilityScoresLazyQuery
>;
export type GetVulnerabilityScoresQueryResult = Apollo.QueryResult<
  GetVulnerabilityScoresQuery,
  GetVulnerabilityScoresQueryVariables
>;
export const IsActivePolicyDocument = gql`
  query IsActivePolicy($input: PolicyInput!) {
    policy(input: $input) {
      action
      mrn
    }
  }
`;

/**
 * __useIsActivePolicyQuery__
 *
 * To run a query within a React component, call `useIsActivePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsActivePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsActivePolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsActivePolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsActivePolicyQuery,
    IsActivePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsActivePolicyQuery, IsActivePolicyQueryVariables>(
    IsActivePolicyDocument,
    options,
  );
}
export function useIsActivePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsActivePolicyQuery,
    IsActivePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsActivePolicyQuery, IsActivePolicyQueryVariables>(
    IsActivePolicyDocument,
    options,
  );
}
export type IsActivePolicyQueryHookResult = ReturnType<
  typeof useIsActivePolicyQuery
>;
export type IsActivePolicyLazyQueryHookResult = ReturnType<
  typeof useIsActivePolicyLazyQuery
>;
export type IsActivePolicyQueryResult = Apollo.QueryResult<
  IsActivePolicyQuery,
  IsActivePolicyQueryVariables
>;
export const ListClientIntegrationsDocument = gql`
  query ListClientIntegrations($input: ListClientIntegrationsInput!) {
    listClientIntegrations(input: $input) {
      integrations {
        mrn
        name
        status
        created
        type
        lastCheckin
        messages {
          message
          status
        }
        configurationOptions {
          ... on AWSConfigurationOptions {
            accountIds
            region
            isOrganization
            scanConfiguration {
              ec2ScanOptions {
                ebsScanOptions {
                  maxAsgInstances
                  targetInstancesPerScanner
                }
                ebsVolumeScan
                vaultType
                tagsFilter
                regionsFilter
                instanceIdsFilter
                secretsMetadataQuery
                awsSecretsManagerVault
                ssm
                instanceConnect
              }
              eventScanTriggers {
                eventDetailType
                eventSource
                scanType
              }
              ecrScan
              ecsScan
              cronScaninHours
              accountScan
              ec2Scan
            }
            snsEndpoint
            originAWSAccountId
          }
          ... on HostedAwsConfigurationOptions {
            accessKeyId
            role
          }
          ... on K8sConfigurationOptions {
            scanDeploys
            scanNodes
            scanWorkloads
            scanPublicImages
            certificateManager
          }
          ... on MicrosoftDefenderConfigurationOptions {
            tenantId
            clientId
            subscriptionsAllowlist
            subscriptionsDenylist
            secretType
          }
          ... on GithubConfigurationOptions {
            owner
            repository
            organization
            type
            reposAllowList
            reposDenyList
          }
        }
        lastStateInfo {
          ... on K8sIntegrationLastState {
            namespace
            k8sVersion
            operatorVersion
          }
        }
      }
    }
  }
`;

/**
 * __useListClientIntegrationsQuery__
 *
 * To run a query within a React component, call `useListClientIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientIntegrationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListClientIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListClientIntegrationsQuery,
    ListClientIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListClientIntegrationsQuery,
    ListClientIntegrationsQueryVariables
  >(ListClientIntegrationsDocument, options);
}
export function useListClientIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListClientIntegrationsQuery,
    ListClientIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListClientIntegrationsQuery,
    ListClientIntegrationsQueryVariables
  >(ListClientIntegrationsDocument, options);
}
export type ListClientIntegrationsQueryHookResult = ReturnType<
  typeof useListClientIntegrationsQuery
>;
export type ListClientIntegrationsLazyQueryHookResult = ReturnType<
  typeof useListClientIntegrationsLazyQuery
>;
export type ListClientIntegrationsQueryResult = Apollo.QueryResult<
  ListClientIntegrationsQuery,
  ListClientIntegrationsQueryVariables
>;
export const ListDiscoveryResultsDocument = gql`
  query ListDiscoveryResults($input: ListDiscoveryResultsInput!) {
    listDiscoveryResults(input: $input) {
      stats {
        identifier
        query
        count
        title
      }
    }
  }
`;

/**
 * __useListDiscoveryResultsQuery__
 *
 * To run a query within a React component, call `useListDiscoveryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDiscoveryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDiscoveryResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListDiscoveryResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListDiscoveryResultsQuery,
    ListDiscoveryResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListDiscoveryResultsQuery,
    ListDiscoveryResultsQueryVariables
  >(ListDiscoveryResultsDocument, options);
}
export function useListDiscoveryResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDiscoveryResultsQuery,
    ListDiscoveryResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListDiscoveryResultsQuery,
    ListDiscoveryResultsQueryVariables
  >(ListDiscoveryResultsDocument, options);
}
export type ListDiscoveryResultsQueryHookResult = ReturnType<
  typeof useListDiscoveryResultsQuery
>;
export type ListDiscoveryResultsLazyQueryHookResult = ReturnType<
  typeof useListDiscoveryResultsLazyQuery
>;
export type ListDiscoveryResultsQueryResult = Apollo.QueryResult<
  ListDiscoveryResultsQuery,
  ListDiscoveryResultsQueryVariables
>;
export const ListDocumentsDocument = gql`
  query ListDocuments($scopeMrn: String!, $first: Int!, $after: String) {
    listDocuments(scopeMRN: $scopeMrn, first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          mrn
          name
          type
          format
          status
          statusMessage
          createdBy {
            name
            email
          }
          requestedAt
          generatedAt
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useListDocumentsQuery__
 *
 * To run a query within a React component, call `useListDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDocumentsQuery({
 *   variables: {
 *      scopeMrn: // value for 'scopeMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useListDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListDocumentsQuery,
    ListDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(
    ListDocumentsDocument,
    options,
  );
}
export function useListDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDocumentsQuery,
    ListDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(
    ListDocumentsDocument,
    options,
  );
}
export type ListDocumentsQueryHookResult = ReturnType<
  typeof useListDocumentsQuery
>;
export type ListDocumentsLazyQueryHookResult = ReturnType<
  typeof useListDocumentsLazyQuery
>;
export type ListDocumentsQueryResult = Apollo.QueryResult<
  ListDocumentsQuery,
  ListDocumentsQueryVariables
>;
export const ListExceptionGroupsDocument = gql`
  query ListExceptionGroups($input: ExceptionGroupsInput!) {
    exceptionGroups(input: $input) {
      action
      author {
        email
        name
      }
      exceptions {
        ... on ControlsEdge {
          node {
            framework {
              name
              version
            }
            mrn
            description
            title
            manual
            completion
            tags {
              key
              value
            }
            state
            assetsStats {
              compliantAssets
              failedAssets
              totalAssets
            }
            checksStats {
              activeChecks
              snoozedChecks
              disabledChecks
              totalChecks
            }
          }
        }
        ... on AssetCheckException {
          impact {
            value
            rating
          }
          mrn
          policyMrn
          policyName
          score {
            id
            value
            type
            completion
            weight
            message
          }
          title
        }
        ... on SpaceCheckException {
          mrn
          title
          impact {
            value
            rating
          }
          assetsStats {
            passed
            failed
            errored
            total
          }
          policyMrn
          policyName
        }
        ... on AssetCveException {
          mrn
        }
        ... on AssetAdvisoryException {
          mrn
          id
          title
          iconId
          riskScore
          cvss {
            id
            value
            type
            vector
            source
          }
        }
      }
      createdAt
      id
      justification
      modifiedAt
      reviewStatus
      reviewer {
        email
        name
      }
      scopeMrn
      title
    }
  }
`;

/**
 * __useListExceptionGroupsQuery__
 *
 * To run a query within a React component, call `useListExceptionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExceptionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExceptionGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListExceptionGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListExceptionGroupsQuery,
    ListExceptionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListExceptionGroupsQuery,
    ListExceptionGroupsQueryVariables
  >(ListExceptionGroupsDocument, options);
}
export function useListExceptionGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListExceptionGroupsQuery,
    ListExceptionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListExceptionGroupsQuery,
    ListExceptionGroupsQueryVariables
  >(ListExceptionGroupsDocument, options);
}
export type ListExceptionGroupsQueryHookResult = ReturnType<
  typeof useListExceptionGroupsQuery
>;
export type ListExceptionGroupsLazyQueryHookResult = ReturnType<
  typeof useListExceptionGroupsLazyQuery
>;
export type ListExceptionGroupsQueryResult = Apollo.QueryResult<
  ListExceptionGroupsQuery,
  ListExceptionGroupsQueryVariables
>;
export const ListSharedSpacesDocument = gql`
  query ListSharedSpaces($first: Int, $after: String) {
    sharedSpaces(first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          id
          mrn
          name
          stats {
            ...RiskDistribution
          }
          organization {
            id
            name
            mrn
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${RiskDistributionFragmentDoc}
`;

/**
 * __useListSharedSpacesQuery__
 *
 * To run a query within a React component, call `useListSharedSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSharedSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSharedSpacesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useListSharedSpacesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListSharedSpacesQuery,
    ListSharedSpacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSharedSpacesQuery, ListSharedSpacesQueryVariables>(
    ListSharedSpacesDocument,
    options,
  );
}
export function useListSharedSpacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSharedSpacesQuery,
    ListSharedSpacesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListSharedSpacesQuery,
    ListSharedSpacesQueryVariables
  >(ListSharedSpacesDocument, options);
}
export type ListSharedSpacesQueryHookResult = ReturnType<
  typeof useListSharedSpacesQuery
>;
export type ListSharedSpacesLazyQueryHookResult = ReturnType<
  typeof useListSharedSpacesLazyQuery
>;
export type ListSharedSpacesQueryResult = Apollo.QueryResult<
  ListSharedSpacesQuery,
  ListSharedSpacesQueryVariables
>;
export const LoadAdvisoryDocument = gql`
  query LoadAdvisory($id: ID!, $spaceMrn: String) {
    advisory(id: $id, spaceMrn: $spaceMrn) {
      vendorscore
      title
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
      externalUrls {
        iconIDEnum
        url
        title
      }
      publishedAt
      mrn
      modifiedAt
      id
      assets {
        id
        mrn
        name
        platformName
        platformVersion
        advisoryInfo {
          activeAdvisoryCount
          worstCvssScore {
            id
            source
            type
            value
            vector
          }
        }
        score {
          type
          value
        }
      }
      fixedByPackages {
        id
        name
        version
        arch
        format
        namespace
        description
        status
        available
        origin
        score {
          id
          value
          type
          vector
          source
        }
      }
      description
      cvssScore {
        id
        value
        type
        vector
        source
      }
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
`;

/**
 * __useLoadAdvisoryQuery__
 *
 * To run a query within a React component, call `useLoadAdvisoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAdvisoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAdvisoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useLoadAdvisoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAdvisoryQuery,
    LoadAdvisoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAdvisoryQuery, LoadAdvisoryQueryVariables>(
    LoadAdvisoryDocument,
    options,
  );
}
export function useLoadAdvisoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAdvisoryQuery,
    LoadAdvisoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadAdvisoryQuery, LoadAdvisoryQueryVariables>(
    LoadAdvisoryDocument,
    options,
  );
}
export type LoadAdvisoryQueryHookResult = ReturnType<
  typeof useLoadAdvisoryQuery
>;
export type LoadAdvisoryLazyQueryHookResult = ReturnType<
  typeof useLoadAdvisoryLazyQuery
>;
export type LoadAdvisoryQueryResult = Apollo.QueryResult<
  LoadAdvisoryQuery,
  LoadAdvisoryQueryVariables
>;
export const LoadAdvisoryCvesDocument = gql`
  query LoadAdvisoryCves(
    $advisoryId: ID!
    $assetMrn: String
    $spaceMrn: String
  ) {
    advisory(id: $advisoryId, assetMrn: $assetMrn, spaceMrn: $spaceMrn) {
      cves {
        id
        mrn
        riskScore
        source {
          id
          name
          url
        }
        title
        description
        summary
        publishedAt
        modifiedAt
        url
        cvssScore {
          id
          value
          type
          vector
          source
        }
        cwe
        state
        assets {
          id
          mrn
          name
          score {
            id
            value
            type
            completion
            weight
            message
            grade
          }
          platformName
          platformVersion
          advisoryInfo {
            activeAdvisoryCount
            worstCvssScore {
              id
              value
              type
              vector
              source
            }
          }
        }
        vulnerabilityRiskFactors {
          ...VulnerabilityRiskFactorsFields
        }
      }
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
`;

/**
 * __useLoadAdvisoryCvesQuery__
 *
 * To run a query within a React component, call `useLoadAdvisoryCvesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAdvisoryCvesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAdvisoryCvesQuery({
 *   variables: {
 *      advisoryId: // value for 'advisoryId'
 *      assetMrn: // value for 'assetMrn'
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useLoadAdvisoryCvesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAdvisoryCvesQuery,
    LoadAdvisoryCvesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAdvisoryCvesQuery, LoadAdvisoryCvesQueryVariables>(
    LoadAdvisoryCvesDocument,
    options,
  );
}
export function useLoadAdvisoryCvesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAdvisoryCvesQuery,
    LoadAdvisoryCvesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAdvisoryCvesQuery,
    LoadAdvisoryCvesQueryVariables
  >(LoadAdvisoryCvesDocument, options);
}
export type LoadAdvisoryCvesQueryHookResult = ReturnType<
  typeof useLoadAdvisoryCvesQuery
>;
export type LoadAdvisoryCvesLazyQueryHookResult = ReturnType<
  typeof useLoadAdvisoryCvesLazyQuery
>;
export type LoadAdvisoryCvesQueryResult = Apollo.QueryResult<
  LoadAdvisoryCvesQuery,
  LoadAdvisoryCvesQueryVariables
>;
export const LoadAgentDocument = gql`
  query LoadAgent($mrn: String!) {
    agent(mrn: $mrn) {
      id
      mrn
      name
      status {
        version
        state
        lastCheckin
      }
      labels {
        key
        value
      }
      platform {
        name
        release
        arch
      }
      hostname
      ipaddress
      createdAt
      errors {
        timestamp
        message
      }
    }
  }
`;

/**
 * __useLoadAgentQuery__
 *
 * To run a query within a React component, call `useLoadAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAgentQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadAgentQuery(
  baseOptions: Apollo.QueryHookOptions<LoadAgentQuery, LoadAgentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAgentQuery, LoadAgentQueryVariables>(
    LoadAgentDocument,
    options,
  );
}
export function useLoadAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAgentQuery,
    LoadAgentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadAgentQuery, LoadAgentQueryVariables>(
    LoadAgentDocument,
    options,
  );
}
export type LoadAgentQueryHookResult = ReturnType<typeof useLoadAgentQuery>;
export type LoadAgentLazyQueryHookResult = ReturnType<
  typeof useLoadAgentLazyQuery
>;
export type LoadAgentQueryResult = Apollo.QueryResult<
  LoadAgentQuery,
  LoadAgentQueryVariables
>;
export const LoadAssetListPoliciesDocument = gql`
  query LoadAssetListPolicies(
    $input: AssetReportInput!
    $first: Int
    $after: String
    $orderBy: AssetReportPolicyOrder
    $filter: AssetReportListPoliciesFilter
  ) {
    assetReport(input: $input) {
      ... on AssetReport {
        listPolicies(
          first: $first
          after: $after
          orderBy: $orderBy
          filter: $filter
        ) {
          ...AssetReportListPoliciesFields
        }
      }
    }
  }
  ${AssetReportListPoliciesFieldsFragmentDoc}
`;

/**
 * __useLoadAssetListPoliciesQuery__
 *
 * To run a query within a React component, call `useLoadAssetListPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssetListPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssetListPoliciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadAssetListPoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssetListPoliciesQuery,
    LoadAssetListPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssetListPoliciesQuery,
    LoadAssetListPoliciesQueryVariables
  >(LoadAssetListPoliciesDocument, options);
}
export function useLoadAssetListPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssetListPoliciesQuery,
    LoadAssetListPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssetListPoliciesQuery,
    LoadAssetListPoliciesQueryVariables
  >(LoadAssetListPoliciesDocument, options);
}
export type LoadAssetListPoliciesQueryHookResult = ReturnType<
  typeof useLoadAssetListPoliciesQuery
>;
export type LoadAssetListPoliciesLazyQueryHookResult = ReturnType<
  typeof useLoadAssetListPoliciesLazyQuery
>;
export type LoadAssetListPoliciesQueryResult = Apollo.QueryResult<
  LoadAssetListPoliciesQuery,
  LoadAssetListPoliciesQueryVariables
>;
export const LoadAssetReportDocument = gql`
  query LoadAssetReport(
    $mrn: String!
    $assetLinkInput: AssetLinkInfoInput!
    $assetReportInput: AssetReportInput!
    $listPoliciesOrderBy: AssetReportPolicyOrder
    $listPoliciesFilter: AssetReportListPoliciesFilter
    $listPoliciesAfter: String
    $listPoliciesFirst: Int
    $policiesOrderBy: AssetReportPolicyOrder
    $policiesPolicyFilter: [String]
    $policiesAfter: String
    $policiesFirst: Int
    $recommendedFirst: Int
    $recommendedAfter: String
    $checksFirst: Int
    $checksAfter: String
    $checksPolicyFilter: [String]
    $checksOrderBy: AssetReportQueryOrder
    $checksFilter: String
    $checksImpact: [String!]
    $checksPolicyMrns: [String!]
  ) {
    getAssetLinkInfo(input: $assetLinkInput) {
      ... on FleetAssetLinkInfo {
        mrn
        assetLinkType
      }
      ... on CiAssetLinkInfo {
        mrn
        assetLinkType
        projectID
      }
    }
    asset(mrn: $mrn) {
      id
      mrn
      name
      state
      updatedAt
      labels {
        key
        value
      }
      annotations {
        key
        value
      }
      score {
        grade
        value
        type
        completion
        message
        weight
      }
      relatedAssets {
        count {
          isParent
          count
          type
        }
      }
      report {
        stats {
          packages {
            total
          }
        }
      }
      platform {
        name
        release
        arch
        kind
        runtime
        title
      }
      referenceIDs
      asset_type
    }
    assetReport(input: $assetReportInput) {
      ... on NotFoundError {
        code
        message
      }
      ... on AssetReport {
        lastCompletedScoreTime
        overviewGroups {
          items {
            key
            name
            sources {
              ... on KeyValueSource {
                key
                value
              }
              ... on QuerySource {
                mrn
              }
            }
            values {
              ... on AssetOverviewStringValue {
                stringValue: value
              }
              ... on AssetOverviewNullValue {
                nullValue: value
              }
              ... on AssetOverviewTimeValue {
                timeValue: value
              }
              ... on AssetOverviewArrayValue {
                arrayValue: value {
                  ... on AssetOverviewStringValue {
                    stringValue: value
                  }
                  ... on AssetOverviewNullValue {
                    nullValue: value
                  }
                  ... on AssetOverviewTimeValue {
                    timeValue: value
                  }
                  ... on AssetOverviewDictValue {
                    dictValue: value {
                      key
                      value
                    }
                  }
                  ... on AssetOverviewBoolValue {
                    boolValue: value
                  }
                  ... on AssetOverviewReferenceValue {
                    displayValue
                    mrn
                    referenceType
                  }
                  ... on AssetOverviewError {
                    value {
                      ... on AssetOverviewErrorExecutionFailed {
                        msg
                        queryMrn
                      }
                      ... on AssetOverviewGenericError {
                        code
                        msg
                      }
                    }
                  }
                  ... on AssetOverviewURLValue {
                    displayValue
                    url
                  }
                }
              }
              ... on AssetOverviewDictValue {
                dictValue: value {
                  key
                  value
                }
              }
              ... on AssetOverviewBoolValue {
                boolValue: value
              }
              ... on AssetOverviewReferenceValue {
                displayValue
                mrn
                referenceType
              }
              ... on AssetOverviewError {
                value {
                  ... on AssetOverviewErrorExecutionFailed {
                    msg
                    queryMrn
                  }
                  ... on AssetOverviewGenericError {
                    code
                    msg
                  }
                }
              }
              ... on AssetOverviewURLValue {
                displayValue
                url
              }
            }
          }
          key
          title
        }
        analysis {
          eol {
            status
            date
          }
        }
        score {
          grade
          value
          type
          completion
          message
          weight
        }
        listPolicies(
          first: $listPoliciesFirst
          after: $listPoliciesAfter
          filter: $listPoliciesFilter
          orderBy: $listPoliciesOrderBy
        ) {
          ...AssetReportListPoliciesFields
        }
        policies(
          orderBy: $policiesOrderBy
          policyFilter: $policiesPolicyFilter
          after: $policiesAfter
          first: $policiesFirst
        ) {
          edges {
            node {
              policy {
                name
                mrn
                action
                assigned
              }
              score {
                grade
                value
                type
                completion
                message
                weight
              }
              queries {
                id
                mquery {
                  uid
                  mrn
                  title
                  severity
                  action
                  code
                  mql
                  docs {
                    desc
                    audit
                    remediation
                  }
                }
                mqueryState
                mqueryType
                action
                assessment {
                  state
                  rendered
                  results {
                    actual
                    expected
                    error
                  }
                  needsUpgrade
                  rendered
                }
                score {
                  value
                  grade
                  type
                  completion
                  message
                  weight
                }
                data
                policies
              }
              scoreStats {
                total
                passed
                failed
                incomplete
                errors
                skipped
                unknown
                worst
                snoozed
                disabled
              }
            }
            cursor
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            endCursor
            startCursor
          }
          totalCount
        }
        checks(
          first: $checksFirst
          after: $checksAfter
          policyFilter: $checksPolicyFilter
          orderBy: $checksOrderBy
          filter: $checksFilter
          impact: $checksImpact
          policyMrns: $checksPolicyMrns
        ) {
          ...AssetReportChecksFields
        }
        recommendedActions(first: $recommendedFirst, after: $recommendedAfter) {
          totalCount
          edges {
            cursor
            node {
              ... on RecommendedActionItemAdvisory {
                id
                message
                severity
              }
              ... on RecommendedActionItemControl {
                mrn
                codeId
                message
                severity
                score {
                  grade
                  value
                  type
                  completion
                  message
                  weight
                }
              }
            }
          }
        }
        insights {
          security {
            policyCount
            score {
              id
              value
              type
              completion
              weight
              message
              grade
            }
          }
          vulnerability {
            score {
              id
              value
              type
              completion
              weight
              message
              grade
            }
          }
        }
      }
    }
  }
  ${AssetReportListPoliciesFieldsFragmentDoc}
  ${AssetReportChecksFieldsFragmentDoc}
`;

/**
 * __useLoadAssetReportQuery__
 *
 * To run a query within a React component, call `useLoadAssetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssetReportQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *      assetLinkInput: // value for 'assetLinkInput'
 *      assetReportInput: // value for 'assetReportInput'
 *      listPoliciesOrderBy: // value for 'listPoliciesOrderBy'
 *      listPoliciesFilter: // value for 'listPoliciesFilter'
 *      listPoliciesAfter: // value for 'listPoliciesAfter'
 *      listPoliciesFirst: // value for 'listPoliciesFirst'
 *      policiesOrderBy: // value for 'policiesOrderBy'
 *      policiesPolicyFilter: // value for 'policiesPolicyFilter'
 *      policiesAfter: // value for 'policiesAfter'
 *      policiesFirst: // value for 'policiesFirst'
 *      recommendedFirst: // value for 'recommendedFirst'
 *      recommendedAfter: // value for 'recommendedAfter'
 *      checksFirst: // value for 'checksFirst'
 *      checksAfter: // value for 'checksAfter'
 *      checksPolicyFilter: // value for 'checksPolicyFilter'
 *      checksOrderBy: // value for 'checksOrderBy'
 *      checksFilter: // value for 'checksFilter'
 *      checksImpact: // value for 'checksImpact'
 *      checksPolicyMrns: // value for 'checksPolicyMrns'
 *   },
 * });
 */
export function useLoadAssetReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssetReportQuery,
    LoadAssetReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAssetReportQuery, LoadAssetReportQueryVariables>(
    LoadAssetReportDocument,
    options,
  );
}
export function useLoadAssetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssetReportQuery,
    LoadAssetReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssetReportQuery,
    LoadAssetReportQueryVariables
  >(LoadAssetReportDocument, options);
}
export type LoadAssetReportQueryHookResult = ReturnType<
  typeof useLoadAssetReportQuery
>;
export type LoadAssetReportLazyQueryHookResult = ReturnType<
  typeof useLoadAssetReportLazyQuery
>;
export type LoadAssetReportQueryResult = Apollo.QueryResult<
  LoadAssetReportQuery,
  LoadAssetReportQueryVariables
>;
export const LoadAssetReportChecksDocument = gql`
  query LoadAssetReportChecks(
    $input: AssetReportInput!
    $first: Int
    $after: String
    $policyFilter: [String]
    $orderBy: AssetReportQueryOrder
    $filter: String
    $impact: [String!]
    $policyMrns: [String!]
  ) {
    assetReport(input: $input) {
      ... on AssetReport {
        checks(
          first: $first
          after: $after
          policyFilter: $policyFilter
          orderBy: $orderBy
          filter: $filter
          impact: $impact
          policyMrns: $policyMrns
        ) {
          ...AssetReportChecksFields
        }
      }
    }
  }
  ${AssetReportChecksFieldsFragmentDoc}
`;

/**
 * __useLoadAssetReportChecksQuery__
 *
 * To run a query within a React component, call `useLoadAssetReportChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssetReportChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssetReportChecksQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      policyFilter: // value for 'policyFilter'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *      impact: // value for 'impact'
 *      policyMrns: // value for 'policyMrns'
 *   },
 * });
 */
export function useLoadAssetReportChecksQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssetReportChecksQuery,
    LoadAssetReportChecksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssetReportChecksQuery,
    LoadAssetReportChecksQueryVariables
  >(LoadAssetReportChecksDocument, options);
}
export function useLoadAssetReportChecksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssetReportChecksQuery,
    LoadAssetReportChecksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssetReportChecksQuery,
    LoadAssetReportChecksQueryVariables
  >(LoadAssetReportChecksDocument, options);
}
export type LoadAssetReportChecksQueryHookResult = ReturnType<
  typeof useLoadAssetReportChecksQuery
>;
export type LoadAssetReportChecksLazyQueryHookResult = ReturnType<
  typeof useLoadAssetReportChecksLazyQuery
>;
export type LoadAssetReportChecksQueryResult = Apollo.QueryResult<
  LoadAssetReportChecksQuery,
  LoadAssetReportChecksQueryVariables
>;
export const LoadBestPoliciesDocument = gql`
  query LoadBestPolicies($input: RatedPoliciesInput!) {
    bestPolicies(input: $input) {
      mrn
      name
      score
    }
  }
`;

/**
 * __useLoadBestPoliciesQuery__
 *
 * To run a query within a React component, call `useLoadBestPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBestPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBestPoliciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadBestPoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadBestPoliciesQuery,
    LoadBestPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadBestPoliciesQuery, LoadBestPoliciesQueryVariables>(
    LoadBestPoliciesDocument,
    options,
  );
}
export function useLoadBestPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadBestPoliciesQuery,
    LoadBestPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadBestPoliciesQuery,
    LoadBestPoliciesQueryVariables
  >(LoadBestPoliciesDocument, options);
}
export type LoadBestPoliciesQueryHookResult = ReturnType<
  typeof useLoadBestPoliciesQuery
>;
export type LoadBestPoliciesLazyQueryHookResult = ReturnType<
  typeof useLoadBestPoliciesLazyQuery
>;
export type LoadBestPoliciesQueryResult = Apollo.QueryResult<
  LoadBestPoliciesQuery,
  LoadBestPoliciesQueryVariables
>;
export const LoadCategoriesDocument = gql`
  query LoadCategories($input: CategoriesListInput!) {
    categories(input: $input) {
      name
      packs
      policies
      queries
    }
  }
`;

/**
 * __useLoadCategoriesQuery__
 *
 * To run a query within a React component, call `useLoadCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCategoriesQuery,
    LoadCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadCategoriesQuery, LoadCategoriesQueryVariables>(
    LoadCategoriesDocument,
    options,
  );
}
export function useLoadCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCategoriesQuery,
    LoadCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadCategoriesQuery, LoadCategoriesQueryVariables>(
    LoadCategoriesDocument,
    options,
  );
}
export type LoadCategoriesQueryHookResult = ReturnType<
  typeof useLoadCategoriesQuery
>;
export type LoadCategoriesLazyQueryHookResult = ReturnType<
  typeof useLoadCategoriesLazyQuery
>;
export type LoadCategoriesQueryResult = Apollo.QueryResult<
  LoadCategoriesQuery,
  LoadCategoriesQueryVariables
>;
export const LoadCicdProjectJobsDocument = gql`
  query LoadCicdProjectJobs(
    $input: CicdProjectJobsInput!
    $first: Int
    $after: String
    $labelFilter: [KeyValueInput!]
  ) {
    cicdProjectJobs(input: $input) {
      ... on CicdProjectJobs {
        id
        spaceMrn
        project {
          id
          mrn
          type
          name
          projectURL
          projectID
        }
        jobs(first: $first, after: $after, labelFilter: $labelFilter) {
          totalCount
          edges {
            cursor
            node {
              ... on GitlabJob {
                id
                mrn
                name
                target
                projectUrl
                projectId
                projectTitle
                pipelineUrl
                jobId
                jobName
                jobURL
                jobStage
                commitSha
                commitDescription
                commitRefName
                mergeRequestId
                mergeRequestProjectUrl
                userName
                userId
                userEmail
                defaultBranch
                grade
                pipelineKind
                updatedAt
              }
              ... on GithubJob {
                id
                mrn
                name
                target
                action
                workflow
                job
                runId
                runNumber
                actor
                repository
                eventName
                eventPath
                workspace
                sha
                ref
                headRef
                baseRef
                serverUrl
                grade
                pipelineKind
                updatedAt
              }
              ... on CircleCIJob {
                id
                mrn
                name
                target
                projectUrl
                mergeRequestUrl
                job
                jobId
                buildUrl
                userName
                commitSha
                repositoryUrl
                branch
                associatedPullRequests
                workflowId
                workflowJobId
                workflowWorkspaceId
                grade
                pipelineKind
                updatedAt
              }
              ... on AzureDevopsJob {
                id
                mrn
                name
                target
                projectUrl
                job
                jobId
                buildUrl
                userName
                commitSha
                repositoryUrl
                branch
                grade
                pipelineKind
                updatedAt
              }
              ... on JenkinsJob {
                id
                mrn
                name
                target
                projectUrl
                job
                jobId
                buildUrl
                userName
                commitSha
                repositoryUrl
                branch
                grade
                pipelineKind
                updatedAt
              }
              ... on KubernetesJob {
                id
                mrn
                grade
                updatedAt
                clusterId
                namespace
                uid
                name
                target
                operation
                kind
                author
                ownerName
                ownerUid
                ownerKind
                resourceVersion
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

/**
 * __useLoadCicdProjectJobsQuery__
 *
 * To run a query within a React component, call `useLoadCicdProjectJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCicdProjectJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCicdProjectJobsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      labelFilter: // value for 'labelFilter'
 *   },
 * });
 */
export function useLoadCicdProjectJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCicdProjectJobsQuery,
    LoadCicdProjectJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadCicdProjectJobsQuery,
    LoadCicdProjectJobsQueryVariables
  >(LoadCicdProjectJobsDocument, options);
}
export function useLoadCicdProjectJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCicdProjectJobsQuery,
    LoadCicdProjectJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCicdProjectJobsQuery,
    LoadCicdProjectJobsQueryVariables
  >(LoadCicdProjectJobsDocument, options);
}
export type LoadCicdProjectJobsQueryHookResult = ReturnType<
  typeof useLoadCicdProjectJobsQuery
>;
export type LoadCicdProjectJobsLazyQueryHookResult = ReturnType<
  typeof useLoadCicdProjectJobsLazyQuery
>;
export type LoadCicdProjectJobsQueryResult = Apollo.QueryResult<
  LoadCicdProjectJobsQuery,
  LoadCicdProjectJobsQueryVariables
>;
export const LoadCicdProjectsDocument = gql`
  query LoadCicdProjects($input: CicdProjectsInput!) {
    cicdProjects(input: $input) {
      ... on CicdProjects {
        spaceMrn
        projects {
          totalCount
          edges {
            cursor
            node {
              id
              mrn
              type
              name
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

/**
 * __useLoadCicdProjectsQuery__
 *
 * To run a query within a React component, call `useLoadCicdProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCicdProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCicdProjectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadCicdProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCicdProjectsQuery,
    LoadCicdProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadCicdProjectsQuery, LoadCicdProjectsQueryVariables>(
    LoadCicdProjectsDocument,
    options,
  );
}
export function useLoadCicdProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCicdProjectsQuery,
    LoadCicdProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCicdProjectsQuery,
    LoadCicdProjectsQueryVariables
  >(LoadCicdProjectsDocument, options);
}
export type LoadCicdProjectsQueryHookResult = ReturnType<
  typeof useLoadCicdProjectsQuery
>;
export type LoadCicdProjectsLazyQueryHookResult = ReturnType<
  typeof useLoadCicdProjectsLazyQuery
>;
export type LoadCicdProjectsQueryResult = Apollo.QueryResult<
  LoadCicdProjectsQuery,
  LoadCicdProjectsQueryVariables
>;
export const LoadCveDocument = gql`
  query LoadCve($cveId: ID!) {
    cve(id: $cveId) {
      title
      summary
      state
      source {
        id
        name
        url
      }
      url
      references
      publishedAt
      modifiedAt
      mrn
      id
      description
      cvssScore {
        vector
        id
        value
        type
        source
      }
      epssScore {
        percentile
        probability
      }
      cwe
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
      exploits {
        description
        id
        modified
        mrn
        published
        title
      }
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
`;

/**
 * __useLoadCveQuery__
 *
 * To run a query within a React component, call `useLoadCveQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCveQuery({
 *   variables: {
 *      cveId: // value for 'cveId'
 *   },
 * });
 */
export function useLoadCveQuery(
  baseOptions: Apollo.QueryHookOptions<LoadCveQuery, LoadCveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadCveQuery, LoadCveQueryVariables>(
    LoadCveDocument,
    options,
  );
}
export function useLoadCveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCveQuery,
    LoadCveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadCveQuery, LoadCveQueryVariables>(
    LoadCveDocument,
    options,
  );
}
export type LoadCveQueryHookResult = ReturnType<typeof useLoadCveQuery>;
export type LoadCveLazyQueryHookResult = ReturnType<typeof useLoadCveLazyQuery>;
export type LoadCveQueryResult = Apollo.QueryResult<
  LoadCveQuery,
  LoadCveQueryVariables
>;
export const LoadCveSpaceDocument = gql`
  query LoadCveSpace($id: ID!, $spaceMrn: String!) {
    cve(id: $id, spaceMrn: $spaceMrn) {
      cvssScore {
        id
        source
        type
        value
        vector
      }
      epssScore {
        percentile
        probability
      }
      cwe
      description
      id
      modifiedAt
      mrn
      publishedAt
      source {
        id
        name
        url
      }
      url
      references
      state
      summary
      title
      vulnerabilityRiskFactors {
        ...VulnerabilityRiskFactorsFields
      }
      exploits {
        description
        id
        modified
        mrn
        published
        title
      }
    }
  }
  ${VulnerabilityRiskFactorsFieldsFragmentDoc}
`;

/**
 * __useLoadCveSpaceQuery__
 *
 * To run a query within a React component, call `useLoadCveSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCveSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCveSpaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useLoadCveSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCveSpaceQuery,
    LoadCveSpaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadCveSpaceQuery, LoadCveSpaceQueryVariables>(
    LoadCveSpaceDocument,
    options,
  );
}
export function useLoadCveSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCveSpaceQuery,
    LoadCveSpaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadCveSpaceQuery, LoadCveSpaceQueryVariables>(
    LoadCveSpaceDocument,
    options,
  );
}
export type LoadCveSpaceQueryHookResult = ReturnType<
  typeof useLoadCveSpaceQuery
>;
export type LoadCveSpaceLazyQueryHookResult = ReturnType<
  typeof useLoadCveSpaceLazyQuery
>;
export type LoadCveSpaceQueryResult = Apollo.QueryResult<
  LoadCveSpaceQuery,
  LoadCveSpaceQueryVariables
>;
export const LoadEmailPreferencesDocument = gql`
  query LoadEmailPreferences {
    emailPreferences {
      listID
      subscribed
      subscribedAt
    }
  }
`;

/**
 * __useLoadEmailPreferencesQuery__
 *
 * To run a query within a React component, call `useLoadEmailPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEmailPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEmailPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadEmailPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadEmailPreferencesQuery,
    LoadEmailPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadEmailPreferencesQuery,
    LoadEmailPreferencesQueryVariables
  >(LoadEmailPreferencesDocument, options);
}
export function useLoadEmailPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadEmailPreferencesQuery,
    LoadEmailPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadEmailPreferencesQuery,
    LoadEmailPreferencesQueryVariables
  >(LoadEmailPreferencesDocument, options);
}
export type LoadEmailPreferencesQueryHookResult = ReturnType<
  typeof useLoadEmailPreferencesQuery
>;
export type LoadEmailPreferencesLazyQueryHookResult = ReturnType<
  typeof useLoadEmailPreferencesLazyQuery
>;
export type LoadEmailPreferencesQueryResult = Apollo.QueryResult<
  LoadEmailPreferencesQuery,
  LoadEmailPreferencesQueryVariables
>;
export const LoadIntegrationsDocument = gql`
  query LoadIntegrations($mrn: String!) {
    integrations(mrn: $mrn) {
      mrn
      name
      enabled
      options {
        url
        chatid
        token
      }
    }
  }
`;

/**
 * __useLoadIntegrationsQuery__
 *
 * To run a query within a React component, call `useLoadIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadIntegrationsQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadIntegrationsQuery,
    LoadIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadIntegrationsQuery, LoadIntegrationsQueryVariables>(
    LoadIntegrationsDocument,
    options,
  );
}
export function useLoadIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadIntegrationsQuery,
    LoadIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadIntegrationsQuery,
    LoadIntegrationsQueryVariables
  >(LoadIntegrationsDocument, options);
}
export type LoadIntegrationsQueryHookResult = ReturnType<
  typeof useLoadIntegrationsQuery
>;
export type LoadIntegrationsLazyQueryHookResult = ReturnType<
  typeof useLoadIntegrationsLazyQuery
>;
export type LoadIntegrationsQueryResult = Apollo.QueryResult<
  LoadIntegrationsQuery,
  LoadIntegrationsQueryVariables
>;
export const LoadLegalPolicyConsentDocument = gql`
  query LoadLegalPolicyConsent($mrn: String!) {
    legalPolicies(mrn: $mrn) {
      name
      id
      consent {
        mrn
        email
        signatureAt
      }
    }
  }
`;

/**
 * __useLoadLegalPolicyConsentQuery__
 *
 * To run a query within a React component, call `useLoadLegalPolicyConsentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLegalPolicyConsentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLegalPolicyConsentQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadLegalPolicyConsentQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLegalPolicyConsentQuery,
    LoadLegalPolicyConsentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLegalPolicyConsentQuery,
    LoadLegalPolicyConsentQueryVariables
  >(LoadLegalPolicyConsentDocument, options);
}
export function useLoadLegalPolicyConsentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLegalPolicyConsentQuery,
    LoadLegalPolicyConsentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLegalPolicyConsentQuery,
    LoadLegalPolicyConsentQueryVariables
  >(LoadLegalPolicyConsentDocument, options);
}
export type LoadLegalPolicyConsentQueryHookResult = ReturnType<
  typeof useLoadLegalPolicyConsentQuery
>;
export type LoadLegalPolicyConsentLazyQueryHookResult = ReturnType<
  typeof useLoadLegalPolicyConsentLazyQuery
>;
export type LoadLegalPolicyConsentQueryResult = Apollo.QueryResult<
  LoadLegalPolicyConsentQuery,
  LoadLegalPolicyConsentQueryVariables
>;
export const LoadMqueryDocument = gql`
  query LoadMquery($input: MqueryInput!) {
    mquery(input: $input) {
      uid
      mrn
      title
      docs {
        ...MqueryDocsFields
      }
      impact {
        value
        rating
      }
      mql
      tags {
        key
        value
      }
      properties {
        uid
        mrn
        title
        mql
        setByPolicyMrn
      }
      parent
      variants {
        id
        mrn
        title
        icon
      }
      variantQueries {
        uid
        mrn
        title
        docs {
          ...MqueryDocsFields
        }
        impact {
          value
          rating
        }
        mql
        tags {
          key
          value
        }
        properties {
          uid
          mrn
          title
          mql
          setByPolicyMrn
        }
        filter
        filterID
      }
      action
    }
  }
  ${MqueryDocsFieldsFragmentDoc}
`;

/**
 * __useLoadMqueryQuery__
 *
 * To run a query within a React component, call `useLoadMqueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMqueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMqueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadMqueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadMqueryQuery,
    LoadMqueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadMqueryQuery, LoadMqueryQueryVariables>(
    LoadMqueryDocument,
    options,
  );
}
export function useLoadMqueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadMqueryQuery,
    LoadMqueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadMqueryQuery, LoadMqueryQueryVariables>(
    LoadMqueryDocument,
    options,
  );
}
export type LoadMqueryQueryHookResult = ReturnType<typeof useLoadMqueryQuery>;
export type LoadMqueryLazyQueryHookResult = ReturnType<
  typeof useLoadMqueryLazyQuery
>;
export type LoadMqueryQueryResult = Apollo.QueryResult<
  LoadMqueryQuery,
  LoadMqueryQueryVariables
>;
export const LoadOrganizationDocument = gql`
  query LoadOrganization($mrn: String!) {
    organization(mrn: $mrn) {
      id
      mrn
      name
      capabilities
    }
  }
`;

/**
 * __useLoadOrganizationQuery__
 *
 * To run a query within a React component, call `useLoadOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrganizationQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrganizationQuery,
    LoadOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadOrganizationQuery, LoadOrganizationQueryVariables>(
    LoadOrganizationDocument,
    options,
  );
}
export function useLoadOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrganizationQuery,
    LoadOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrganizationQuery,
    LoadOrganizationQueryVariables
  >(LoadOrganizationDocument, options);
}
export type LoadOrganizationQueryHookResult = ReturnType<
  typeof useLoadOrganizationQuery
>;
export type LoadOrganizationLazyQueryHookResult = ReturnType<
  typeof useLoadOrganizationLazyQuery
>;
export type LoadOrganizationQueryResult = Apollo.QueryResult<
  LoadOrganizationQuery,
  LoadOrganizationQueryVariables
>;
export const LoadOrganizationForSpaceDocument = gql`
  query LoadOrganizationForSpace($spaceMrn: String!) {
    space(mrn: $spaceMrn) {
      id
      mrn
      organization {
        id
        mrn
        name
        capabilities
      }
    }
  }
`;

/**
 * __useLoadOrganizationForSpaceQuery__
 *
 * To run a query within a React component, call `useLoadOrganizationForSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrganizationForSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrganizationForSpaceQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useLoadOrganizationForSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrganizationForSpaceQuery,
    LoadOrganizationForSpaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrganizationForSpaceQuery,
    LoadOrganizationForSpaceQueryVariables
  >(LoadOrganizationForSpaceDocument, options);
}
export function useLoadOrganizationForSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrganizationForSpaceQuery,
    LoadOrganizationForSpaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrganizationForSpaceQuery,
    LoadOrganizationForSpaceQueryVariables
  >(LoadOrganizationForSpaceDocument, options);
}
export type LoadOrganizationForSpaceQueryHookResult = ReturnType<
  typeof useLoadOrganizationForSpaceQuery
>;
export type LoadOrganizationForSpaceLazyQueryHookResult = ReturnType<
  typeof useLoadOrganizationForSpaceLazyQuery
>;
export type LoadOrganizationForSpaceQueryResult = Apollo.QueryResult<
  LoadOrganizationForSpaceQuery,
  LoadOrganizationForSpaceQueryVariables
>;
export const LoadOrganizationMembersDocument = gql`
  query LoadOrganizationMembers($mrn: String!) {
    organization(mrn: $mrn) {
      id
      mrn
      members {
        edges {
          cursor
          node {
            user {
              mrn
              email
              name
            }
            roles {
              title
              mrn
            }
          }
        }
      }
    }
  }
`;

/**
 * __useLoadOrganizationMembersQuery__
 *
 * To run a query within a React component, call `useLoadOrganizationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrganizationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrganizationMembersQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadOrganizationMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrganizationMembersQuery,
    LoadOrganizationMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrganizationMembersQuery,
    LoadOrganizationMembersQueryVariables
  >(LoadOrganizationMembersDocument, options);
}
export function useLoadOrganizationMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrganizationMembersQuery,
    LoadOrganizationMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrganizationMembersQuery,
    LoadOrganizationMembersQueryVariables
  >(LoadOrganizationMembersDocument, options);
}
export type LoadOrganizationMembersQueryHookResult = ReturnType<
  typeof useLoadOrganizationMembersQuery
>;
export type LoadOrganizationMembersLazyQueryHookResult = ReturnType<
  typeof useLoadOrganizationMembersLazyQuery
>;
export type LoadOrganizationMembersQueryResult = Apollo.QueryResult<
  LoadOrganizationMembersQuery,
  LoadOrganizationMembersQueryVariables
>;
export const LoadOrganizationSsoProviderDocument = gql`
  query LoadOrganizationSSOProvider($mrn: String!) {
    ssoProvider(mrn: $mrn) {
      provider {
        idpEntityID
        ssoUrl
        rpEntityID
        x509Fingerprints
        enabled
      }
    }
  }
`;

/**
 * __useLoadOrganizationSsoProviderQuery__
 *
 * To run a query within a React component, call `useLoadOrganizationSsoProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrganizationSsoProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrganizationSsoProviderQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadOrganizationSsoProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrganizationSsoProviderQuery,
    LoadOrganizationSsoProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrganizationSsoProviderQuery,
    LoadOrganizationSsoProviderQueryVariables
  >(LoadOrganizationSsoProviderDocument, options);
}
export function useLoadOrganizationSsoProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrganizationSsoProviderQuery,
    LoadOrganizationSsoProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrganizationSsoProviderQuery,
    LoadOrganizationSsoProviderQueryVariables
  >(LoadOrganizationSsoProviderDocument, options);
}
export type LoadOrganizationSsoProviderQueryHookResult = ReturnType<
  typeof useLoadOrganizationSsoProviderQuery
>;
export type LoadOrganizationSsoProviderLazyQueryHookResult = ReturnType<
  typeof useLoadOrganizationSsoProviderLazyQuery
>;
export type LoadOrganizationSsoProviderQueryResult = Apollo.QueryResult<
  LoadOrganizationSsoProviderQuery,
  LoadOrganizationSsoProviderQueryVariables
>;
export const LoadOrganizationStatsDocument = gql`
  query LoadOrganizationStats(
    $mrn: String!
    $first: Int
    $after: String
    $query: String
  ) {
    organization(mrn: $mrn) {
      id
      mrn
      name
      spacesCount
      spacesList(first: $first, after: $after, query: $query) {
        totalCount
        edges {
          cursor
          node {
            id
            mrn
            name
            stats {
              ...RiskDistribution
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${RiskDistributionFragmentDoc}
`;

/**
 * __useLoadOrganizationStatsQuery__
 *
 * To run a query within a React component, call `useLoadOrganizationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrganizationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrganizationStatsQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useLoadOrganizationStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrganizationStatsQuery,
    LoadOrganizationStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrganizationStatsQuery,
    LoadOrganizationStatsQueryVariables
  >(LoadOrganizationStatsDocument, options);
}
export function useLoadOrganizationStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrganizationStatsQuery,
    LoadOrganizationStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrganizationStatsQuery,
    LoadOrganizationStatsQueryVariables
  >(LoadOrganizationStatsDocument, options);
}
export type LoadOrganizationStatsQueryHookResult = ReturnType<
  typeof useLoadOrganizationStatsQuery
>;
export type LoadOrganizationStatsLazyQueryHookResult = ReturnType<
  typeof useLoadOrganizationStatsLazyQuery
>;
export type LoadOrganizationStatsQueryResult = Apollo.QueryResult<
  LoadOrganizationStatsQuery,
  LoadOrganizationStatsQueryVariables
>;
export const LoadPlatformsDocument = gql`
  query LoadPlatforms($input: PlatformsListInput!) {
    platforms(input: $input) {
      id
      name
      version
    }
  }
`;

/**
 * __useLoadPlatformsQuery__
 *
 * To run a query within a React component, call `useLoadPlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPlatformsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadPlatformsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadPlatformsQuery,
    LoadPlatformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadPlatformsQuery, LoadPlatformsQueryVariables>(
    LoadPlatformsDocument,
    options,
  );
}
export function useLoadPlatformsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPlatformsQuery,
    LoadPlatformsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadPlatformsQuery, LoadPlatformsQueryVariables>(
    LoadPlatformsDocument,
    options,
  );
}
export type LoadPlatformsQueryHookResult = ReturnType<
  typeof useLoadPlatformsQuery
>;
export type LoadPlatformsLazyQueryHookResult = ReturnType<
  typeof useLoadPlatformsLazyQuery
>;
export type LoadPlatformsQueryResult = Apollo.QueryResult<
  LoadPlatformsQuery,
  LoadPlatformsQueryVariables
>;
export const LoadPolicyDocument = gql`
  query LoadPolicy($input: PolicyInput!) {
    policy(input: $input) {
      mrn
      assigned
      action
      access
      authors {
        email
        name
      }
      category
      certifiedBy {
        name
      }
      createdAt
      docs
      featured
      githubUrl
      groups {
        title
        summary
        checks {
          action
          mrn
          impact {
            rating
            value
          }
        }
        queries {
          action
          impact {
            rating
            value
          }
          mrn
        }
        policies {
          action
          impact {
            rating
            value
          }
          mrn
        }
      }
      mutationStatistics {
        checks {
          active
          ignored
          removed
        }
        queries {
          removed
          ignored
          active
        }
      }
      name
      platforms {
        id
        name
        version
      }
      properties {
        uid
        mrn
        title
        mql
        setByPolicyMrn
      }
      queries {
        mrn
      }
      runCli {
        command
        installDocsURL
      }
      scoringSystem
      defaultScoringSystem
      statistics {
        checks
        policies
        queries
      }
      summary
      tags {
        key
        value
      }
      trustLevel
      uid
      updatedAt
      variants {
        icon
        id
        title
      }
      version
    }
  }
`;

/**
 * __useLoadPolicyQuery__
 *
 * To run a query within a React component, call `useLoadPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadPolicyQuery,
    LoadPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadPolicyQuery, LoadPolicyQueryVariables>(
    LoadPolicyDocument,
    options,
  );
}
export function useLoadPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPolicyQuery,
    LoadPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadPolicyQuery, LoadPolicyQueryVariables>(
    LoadPolicyDocument,
    options,
  );
}
export type LoadPolicyQueryHookResult = ReturnType<typeof useLoadPolicyQuery>;
export type LoadPolicyLazyQueryHookResult = ReturnType<
  typeof useLoadPolicyLazyQuery
>;
export type LoadPolicyQueryResult = Apollo.QueryResult<
  LoadPolicyQuery,
  LoadPolicyQueryVariables
>;
export const LoadRegistrationTokensDocument = gql`
  query LoadRegistrationTokens(
    $spaceMrn: String!
    $orderBy: RegistrationTokenOrder
    $first: Int
    $after: String
  ) {
    registrationTokens(
      spaceMrn: $spaceMrn
      orderBy: $orderBy
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          id
          mrn
          description
          token
          createdBy
          createdAt
          expiresAt
          revoked
          labels {
            key
            value
          }
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
    }
  }
`;

/**
 * __useLoadRegistrationTokensQuery__
 *
 * To run a query within a React component, call `useLoadRegistrationTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRegistrationTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRegistrationTokensQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useLoadRegistrationTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadRegistrationTokensQuery,
    LoadRegistrationTokensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadRegistrationTokensQuery,
    LoadRegistrationTokensQueryVariables
  >(LoadRegistrationTokensDocument, options);
}
export function useLoadRegistrationTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadRegistrationTokensQuery,
    LoadRegistrationTokensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadRegistrationTokensQuery,
    LoadRegistrationTokensQueryVariables
  >(LoadRegistrationTokensDocument, options);
}
export type LoadRegistrationTokensQueryHookResult = ReturnType<
  typeof useLoadRegistrationTokensQuery
>;
export type LoadRegistrationTokensLazyQueryHookResult = ReturnType<
  typeof useLoadRegistrationTokensLazyQuery
>;
export type LoadRegistrationTokensQueryResult = Apollo.QueryResult<
  LoadRegistrationTokensQuery,
  LoadRegistrationTokensQueryVariables
>;
export const LoadResourceInvitationsDocument = gql`
  query LoadResourceInvitations(
    $resourceMrn: String
    $inviteeMrn: String
    $first: Int
    $after: String
    $orderBy: InvitationOrder = { direction: ASC, field: CREATED }
  ) {
    invitations(
      resourceMrn: $resourceMrn
      inviteeMrn: $inviteeMrn
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          mrn
          resourceMrn
          senderEmail
          inviteeEmail
          roleMrn
          expiresAt
          message
          state
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useLoadResourceInvitationsQuery__
 *
 * To run a query within a React component, call `useLoadResourceInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadResourceInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadResourceInvitationsQuery({
 *   variables: {
 *      resourceMrn: // value for 'resourceMrn'
 *      inviteeMrn: // value for 'inviteeMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLoadResourceInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadResourceInvitationsQuery,
    LoadResourceInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadResourceInvitationsQuery,
    LoadResourceInvitationsQueryVariables
  >(LoadResourceInvitationsDocument, options);
}
export function useLoadResourceInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadResourceInvitationsQuery,
    LoadResourceInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadResourceInvitationsQuery,
    LoadResourceInvitationsQueryVariables
  >(LoadResourceInvitationsDocument, options);
}
export type LoadResourceInvitationsQueryHookResult = ReturnType<
  typeof useLoadResourceInvitationsQuery
>;
export type LoadResourceInvitationsLazyQueryHookResult = ReturnType<
  typeof useLoadResourceInvitationsLazyQuery
>;
export type LoadResourceInvitationsQueryResult = Apollo.QueryResult<
  LoadResourceInvitationsQuery,
  LoadResourceInvitationsQueryVariables
>;
export const LoadServiceAccountDocument = gql`
  query LoadServiceAccount($mrn: String!) {
    serviceAccount(mrn: $mrn) {
      id
      mrn
      name
      roles {
        mrn
        title
      }
      createdAt
      lastUsed
    }
  }
`;

/**
 * __useLoadServiceAccountQuery__
 *
 * To run a query within a React component, call `useLoadServiceAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadServiceAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadServiceAccountQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadServiceAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadServiceAccountQuery,
    LoadServiceAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadServiceAccountQuery,
    LoadServiceAccountQueryVariables
  >(LoadServiceAccountDocument, options);
}
export function useLoadServiceAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadServiceAccountQuery,
    LoadServiceAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadServiceAccountQuery,
    LoadServiceAccountQueryVariables
  >(LoadServiceAccountDocument, options);
}
export type LoadServiceAccountQueryHookResult = ReturnType<
  typeof useLoadServiceAccountQuery
>;
export type LoadServiceAccountLazyQueryHookResult = ReturnType<
  typeof useLoadServiceAccountLazyQuery
>;
export type LoadServiceAccountQueryResult = Apollo.QueryResult<
  LoadServiceAccountQuery,
  LoadServiceAccountQueryVariables
>;
export const LoadSpaceDocument = gql`
  query LoadSpace($spaceMrn: String!) {
    space(mrn: $spaceMrn) {
      id
      mrn
      name
      organization {
        id
        mrn
        name
      }
      settings {
        garbageCollectAssetsConfiguration {
          enable
          afterDays
        }
        terminatedAssetsConfiguration {
          cleanup
          after
        }
        unusedServiceAccountsConfiguration {
          cleanup
          after
        }
        platformVulnerabilityConfiguration {
          enable
        }
        eolAssetsConfiguration {
          enable
          monthsInAdvance
        }
        casesConfiguration {
          autoCreate
          aggregationWindow
        }
      }
      stats {
        ...RiskDistribution
      }
    }
  }
  ${RiskDistributionFragmentDoc}
`;

/**
 * __useLoadSpaceQuery__
 *
 * To run a query within a React component, call `useLoadSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSpaceQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useLoadSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<LoadSpaceQuery, LoadSpaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadSpaceQuery, LoadSpaceQueryVariables>(
    LoadSpaceDocument,
    options,
  );
}
export function useLoadSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSpaceQuery,
    LoadSpaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadSpaceQuery, LoadSpaceQueryVariables>(
    LoadSpaceDocument,
    options,
  );
}
export type LoadSpaceQueryHookResult = ReturnType<typeof useLoadSpaceQuery>;
export type LoadSpaceLazyQueryHookResult = ReturnType<
  typeof useLoadSpaceLazyQuery
>;
export type LoadSpaceQueryResult = Apollo.QueryResult<
  LoadSpaceQuery,
  LoadSpaceQueryVariables
>;
export const LoadSpaceMembersDocument = gql`
  query LoadSpaceMembers($mrn: String!) {
    space(mrn: $mrn) {
      id
      mrn
      members {
        edges {
          cursor
          node {
            user {
              mrn
              email
              name
            }
            roles {
              title
              mrn
            }
          }
        }
      }
    }
  }
`;

/**
 * __useLoadSpaceMembersQuery__
 *
 * To run a query within a React component, call `useLoadSpaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSpaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSpaceMembersQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadSpaceMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSpaceMembersQuery,
    LoadSpaceMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadSpaceMembersQuery, LoadSpaceMembersQueryVariables>(
    LoadSpaceMembersDocument,
    options,
  );
}
export function useLoadSpaceMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSpaceMembersQuery,
    LoadSpaceMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSpaceMembersQuery,
    LoadSpaceMembersQueryVariables
  >(LoadSpaceMembersDocument, options);
}
export type LoadSpaceMembersQueryHookResult = ReturnType<
  typeof useLoadSpaceMembersQuery
>;
export type LoadSpaceMembersLazyQueryHookResult = ReturnType<
  typeof useLoadSpaceMembersLazyQuery
>;
export type LoadSpaceMembersQueryResult = Apollo.QueryResult<
  LoadSpaceMembersQuery,
  LoadSpaceMembersQueryVariables
>;
export const LoadSpacePlanDocument = gql`
  query LoadSpacePlan($input: SpacePlanInput!) {
    spacePlan(input: $input) {
      __typename
      ... on SpacePlanInfo {
        planApplied
        scoringSummary {
          total
          pass
          fail
          error
        }
        active {
          queries {
            mquery {
              uid
              mrn
              title
              impact {
                rating
                value
              }
              action
            }
            policies
            scoreDistribution {
              pass
              fail
              error
              unknown
            }
          }
          title
        }
        milestones {
          uid
          queries {
            mquery {
              uid
              mrn
              title
              impact {
                rating
                value
              }
              action
            }
            policies
            scoreDistribution {
              pass
              fail
              error
              unknown
            }
          }
          title
          justification
          endDate
        }
        exceptions {
          queries {
            mquery {
              uid
              mrn
              title
              impact {
                rating
                value
              }
              action
            }
            policies
            scoreDistribution {
              pass
              fail
              error
              unknown
            }
          }
          title
        }
      }
    }
  }
`;

/**
 * __useLoadSpacePlanQuery__
 *
 * To run a query within a React component, call `useLoadSpacePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSpacePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSpacePlanQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadSpacePlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSpacePlanQuery,
    LoadSpacePlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadSpacePlanQuery, LoadSpacePlanQueryVariables>(
    LoadSpacePlanDocument,
    options,
  );
}
export function useLoadSpacePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSpacePlanQuery,
    LoadSpacePlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadSpacePlanQuery, LoadSpacePlanQueryVariables>(
    LoadSpacePlanDocument,
    options,
  );
}
export type LoadSpacePlanQueryHookResult = ReturnType<
  typeof useLoadSpacePlanQuery
>;
export type LoadSpacePlanLazyQueryHookResult = ReturnType<
  typeof useLoadSpacePlanLazyQuery
>;
export type LoadSpacePlanQueryResult = Apollo.QueryResult<
  LoadSpacePlanQuery,
  LoadSpacePlanQueryVariables
>;
export const LoadSpaceStatsDocument = gql`
  query LoadSpaceStats($mrn: String!) {
    space(mrn: $mrn) {
      id
      mrn
      stats {
        assetGroupStatistics {
          groupType
          count
          listsAssetTypes {
            assetType
            displayName
            shortName
            aliases
          }
          statistics {
            type {
              assetType
              displayName
              shortName
            }
            count
          }
        }
        ...RiskDistribution
        integrationsStatistics {
          active
          pending
          errored
          total
          type
        }
      }
    }
  }
  ${RiskDistributionFragmentDoc}
`;

/**
 * __useLoadSpaceStatsQuery__
 *
 * To run a query within a React component, call `useLoadSpaceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSpaceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSpaceStatsQuery({
 *   variables: {
 *      mrn: // value for 'mrn'
 *   },
 * });
 */
export function useLoadSpaceStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSpaceStatsQuery,
    LoadSpaceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadSpaceStatsQuery, LoadSpaceStatsQueryVariables>(
    LoadSpaceStatsDocument,
    options,
  );
}
export function useLoadSpaceStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSpaceStatsQuery,
    LoadSpaceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadSpaceStatsQuery, LoadSpaceStatsQueryVariables>(
    LoadSpaceStatsDocument,
    options,
  );
}
export type LoadSpaceStatsQueryHookResult = ReturnType<
  typeof useLoadSpaceStatsQuery
>;
export type LoadSpaceStatsLazyQueryHookResult = ReturnType<
  typeof useLoadSpaceStatsLazyQuery
>;
export type LoadSpaceStatsQueryResult = Apollo.QueryResult<
  LoadSpaceStatsQuery,
  LoadSpaceStatsQueryVariables
>;
export const LoadTopMisconfigsDocument = gql`
  query LoadTopMisconfigs($input: SpacePolicySummaryInput!) {
    spacePolicySummary(input: $input) {
      spaceMrn
      querySummaries {
        edges {
          cursor
          node {
            id
            mquery {
              impact {
                rating
                value
              }
              mrn
              parent
              title
              uid
              action
            }
            mqueryState
            mqueryType
          }
        }
        totalCount
      }
    }
  }
`;

/**
 * __useLoadTopMisconfigsQuery__
 *
 * To run a query within a React component, call `useLoadTopMisconfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTopMisconfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTopMisconfigsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadTopMisconfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTopMisconfigsQuery,
    LoadTopMisconfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadTopMisconfigsQuery,
    LoadTopMisconfigsQueryVariables
  >(LoadTopMisconfigsDocument, options);
}
export function useLoadTopMisconfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTopMisconfigsQuery,
    LoadTopMisconfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadTopMisconfigsQuery,
    LoadTopMisconfigsQueryVariables
  >(LoadTopMisconfigsDocument, options);
}
export type LoadTopMisconfigsQueryHookResult = ReturnType<
  typeof useLoadTopMisconfigsQuery
>;
export type LoadTopMisconfigsLazyQueryHookResult = ReturnType<
  typeof useLoadTopMisconfigsLazyQuery
>;
export type LoadTopMisconfigsQueryResult = Apollo.QueryResult<
  LoadTopMisconfigsQuery,
  LoadTopMisconfigsQueryVariables
>;
export const LoadTopPolicyActionsDocument = gql`
  query LoadTopPolicyActions($input: TopPolicyActionsInput!) {
    topPolicyActions(input: $input) {
      name
      severity
      score {
        total
      }
      codeId
    }
  }
`;

/**
 * __useLoadTopPolicyActionsQuery__
 *
 * To run a query within a React component, call `useLoadTopPolicyActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTopPolicyActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTopPolicyActionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadTopPolicyActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTopPolicyActionsQuery,
    LoadTopPolicyActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadTopPolicyActionsQuery,
    LoadTopPolicyActionsQueryVariables
  >(LoadTopPolicyActionsDocument, options);
}
export function useLoadTopPolicyActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTopPolicyActionsQuery,
    LoadTopPolicyActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadTopPolicyActionsQuery,
    LoadTopPolicyActionsQueryVariables
  >(LoadTopPolicyActionsDocument, options);
}
export type LoadTopPolicyActionsQueryHookResult = ReturnType<
  typeof useLoadTopPolicyActionsQuery
>;
export type LoadTopPolicyActionsLazyQueryHookResult = ReturnType<
  typeof useLoadTopPolicyActionsLazyQuery
>;
export type LoadTopPolicyActionsQueryResult = Apollo.QueryResult<
  LoadTopPolicyActionsQuery,
  LoadTopPolicyActionsQueryVariables
>;
export const LoadTopVulnerabilityDocument = gql`
  query LoadTopVulnerability($input: TopVulnerabilityInput!) {
    topVulnerability(input: $input) {
      id
      title
      score
      published
      assetsAffectedCount
    }
  }
`;

/**
 * __useLoadTopVulnerabilityQuery__
 *
 * To run a query within a React component, call `useLoadTopVulnerabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTopVulnerabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTopVulnerabilityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadTopVulnerabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTopVulnerabilityQuery,
    LoadTopVulnerabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadTopVulnerabilityQuery,
    LoadTopVulnerabilityQueryVariables
  >(LoadTopVulnerabilityDocument, options);
}
export function useLoadTopVulnerabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTopVulnerabilityQuery,
    LoadTopVulnerabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadTopVulnerabilityQuery,
    LoadTopVulnerabilityQueryVariables
  >(LoadTopVulnerabilityDocument, options);
}
export type LoadTopVulnerabilityQueryHookResult = ReturnType<
  typeof useLoadTopVulnerabilityQuery
>;
export type LoadTopVulnerabilityLazyQueryHookResult = ReturnType<
  typeof useLoadTopVulnerabilityLazyQuery
>;
export type LoadTopVulnerabilityQueryResult = Apollo.QueryResult<
  LoadTopVulnerabilityQuery,
  LoadTopVulnerabilityQueryVariables
>;
export const LoadUserInvitationsDocument = gql`
  query LoadUserInvitations(
    $resourceMrn: String
    $inviteeMrn: String
    $first: Int
    $after: String
    $orderBy: InvitationOrder = { direction: ASC, field: CREATED }
  ) {
    invitations(
      resourceMrn: $resourceMrn
      inviteeMrn: $inviteeMrn
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          mrn
          resourceMrn
          senderEmail
          inviteeEmail
          roleMrn
          expiresAt
          message
          state
        }
      }
    }
  }
`;

/**
 * __useLoadUserInvitationsQuery__
 *
 * To run a query within a React component, call `useLoadUserInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadUserInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadUserInvitationsQuery({
 *   variables: {
 *      resourceMrn: // value for 'resourceMrn'
 *      inviteeMrn: // value for 'inviteeMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLoadUserInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadUserInvitationsQuery,
    LoadUserInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadUserInvitationsQuery,
    LoadUserInvitationsQueryVariables
  >(LoadUserInvitationsDocument, options);
}
export function useLoadUserInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadUserInvitationsQuery,
    LoadUserInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadUserInvitationsQuery,
    LoadUserInvitationsQueryVariables
  >(LoadUserInvitationsDocument, options);
}
export type LoadUserInvitationsQueryHookResult = ReturnType<
  typeof useLoadUserInvitationsQuery
>;
export type LoadUserInvitationsLazyQueryHookResult = ReturnType<
  typeof useLoadUserInvitationsLazyQuery
>;
export type LoadUserInvitationsQueryResult = Apollo.QueryResult<
  LoadUserInvitationsQuery,
  LoadUserInvitationsQueryVariables
>;
export const LoadViewerDocument = gql`
  query LoadViewer {
    viewer {
      mrn
      email
      name
      state
      organizations {
        id
        mrn
        name
        capabilities
        spacesCount
        subscriptionInfo {
          basePlan {
            name
            id
          }
        }
        members {
          totalCount
        }
        owners: members(
          first: 1
          rolesFilter: "//iam.api.mondoo.app/roles/owner"
        ) {
          totalCount
          edges {
            cursor
            node {
              user {
                mrn
                name
                email
              }
            }
          }
        }
      }
      firstSpace {
        id
        mrn
        name
        organization {
          id
          mrn
          name
        }
        settings {
          eolAssetsConfiguration {
            enable
            monthsInAdvance
          }
          garbageCollectAssetsConfiguration {
            enable
            afterDays
          }
          terminatedAssetsConfiguration {
            cleanup
            after
          }
          unusedServiceAccountsConfiguration {
            cleanup
            after
          }
          platformVulnerabilityConfiguration {
            enable
          }
          casesConfiguration {
            autoCreate
            aggregationWindow
          }
        }
        stats {
          ...RiskDistribution
        }
      }
    }
    viewerSettings {
      key
      value
    }
  }
  ${RiskDistributionFragmentDoc}
`;

/**
 * __useLoadViewerQuery__
 *
 * To run a query within a React component, call `useLoadViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadViewerQuery,
    LoadViewerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadViewerQuery, LoadViewerQueryVariables>(
    LoadViewerDocument,
    options,
  );
}
export function useLoadViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadViewerQuery,
    LoadViewerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadViewerQuery, LoadViewerQueryVariables>(
    LoadViewerDocument,
    options,
  );
}
export type LoadViewerQueryHookResult = ReturnType<typeof useLoadViewerQuery>;
export type LoadViewerLazyQueryHookResult = ReturnType<
  typeof useLoadViewerLazyQuery
>;
export type LoadViewerQueryResult = Apollo.QueryResult<
  LoadViewerQuery,
  LoadViewerQueryVariables
>;
export const LoadWorstPoliciesDocument = gql`
  query LoadWorstPolicies($input: RatedPoliciesInput!) {
    worstPolicies(input: $input) {
      mrn
      name
      score
    }
  }
`;

/**
 * __useLoadWorstPoliciesQuery__
 *
 * To run a query within a React component, call `useLoadWorstPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadWorstPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadWorstPoliciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadWorstPoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadWorstPoliciesQuery,
    LoadWorstPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadWorstPoliciesQuery,
    LoadWorstPoliciesQueryVariables
  >(LoadWorstPoliciesDocument, options);
}
export function useLoadWorstPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadWorstPoliciesQuery,
    LoadWorstPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadWorstPoliciesQuery,
    LoadWorstPoliciesQueryVariables
  >(LoadWorstPoliciesDocument, options);
}
export type LoadWorstPoliciesQueryHookResult = ReturnType<
  typeof useLoadWorstPoliciesQuery
>;
export type LoadWorstPoliciesLazyQueryHookResult = ReturnType<
  typeof useLoadWorstPoliciesLazyQuery
>;
export type LoadWorstPoliciesQueryResult = Apollo.QueryResult<
  LoadWorstPoliciesQuery,
  LoadWorstPoliciesQueryVariables
>;
export const MqueryAssetScoresDocument = gql`
  query MqueryAssetScores(
    $input: MqueryAssetScoresInput!
    $first: Int!
    $after: String
    $orderBy: AssetOrder
  ) {
    mqueryAssetScores(
      input: $input
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      title
      description
      totalCount
      scoreDistribution {
        pass
        fail
        error
        unknown
      }
      edges {
        cursor
        node {
          assetMrn
          assetName
          assetPlatform {
            name
            title
            arch
            version
            kind
            runtime
            release
          }
          score {
            id
            value
            type
            completion
            weight
            message
            grade
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      docs {
        desc
        remediations {
          ... on Remediations {
            entries {
              id
              desc
            }
          }
          ... on AuthenticationRequired {
            message
            code
          }
        }
        references {
          title
          url
        }
      }
      mql
    }
  }
`;

/**
 * __useMqueryAssetScoresQuery__
 *
 * To run a query within a React component, call `useMqueryAssetScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useMqueryAssetScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMqueryAssetScoresQuery({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useMqueryAssetScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    MqueryAssetScoresQuery,
    MqueryAssetScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MqueryAssetScoresQuery,
    MqueryAssetScoresQueryVariables
  >(MqueryAssetScoresDocument, options);
}
export function useMqueryAssetScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MqueryAssetScoresQuery,
    MqueryAssetScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MqueryAssetScoresQuery,
    MqueryAssetScoresQueryVariables
  >(MqueryAssetScoresDocument, options);
}
export type MqueryAssetScoresQueryHookResult = ReturnType<
  typeof useMqueryAssetScoresQuery
>;
export type MqueryAssetScoresLazyQueryHookResult = ReturnType<
  typeof useMqueryAssetScoresLazyQuery
>;
export type MqueryAssetScoresQueryResult = Apollo.QueryResult<
  MqueryAssetScoresQuery,
  MqueryAssetScoresQueryVariables
>;
export const OrganizationIdSuggestionDocument = gql`
  query OrganizationIDSuggestion($input: IDSuggestionInput!) {
    organizationIDSuggestion(input: $input) {
      id
      available
    }
  }
`;

/**
 * __useOrganizationIdSuggestionQuery__
 *
 * To run a query within a React component, call `useOrganizationIdSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationIdSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationIdSuggestionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationIdSuggestionQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationIdSuggestionQuery,
    OrganizationIdSuggestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationIdSuggestionQuery,
    OrganizationIdSuggestionQueryVariables
  >(OrganizationIdSuggestionDocument, options);
}
export function useOrganizationIdSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationIdSuggestionQuery,
    OrganizationIdSuggestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationIdSuggestionQuery,
    OrganizationIdSuggestionQueryVariables
  >(OrganizationIdSuggestionDocument, options);
}
export type OrganizationIdSuggestionQueryHookResult = ReturnType<
  typeof useOrganizationIdSuggestionQuery
>;
export type OrganizationIdSuggestionLazyQueryHookResult = ReturnType<
  typeof useOrganizationIdSuggestionLazyQuery
>;
export type OrganizationIdSuggestionQueryResult = Apollo.QueryResult<
  OrganizationIdSuggestionQuery,
  OrganizationIdSuggestionQueryVariables
>;
export const PingIntegrationDocument = gql`
  query PingIntegration($input: PingIntegrationInput!) {
    pingIntegration(input: $input) {
      mrn
    }
  }
`;

/**
 * __usePingIntegrationQuery__
 *
 * To run a query within a React component, call `usePingIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingIntegrationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePingIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PingIntegrationQuery,
    PingIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PingIntegrationQuery, PingIntegrationQueryVariables>(
    PingIntegrationDocument,
    options,
  );
}
export function usePingIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PingIntegrationQuery,
    PingIntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PingIntegrationQuery,
    PingIntegrationQueryVariables
  >(PingIntegrationDocument, options);
}
export type PingIntegrationQueryHookResult = ReturnType<
  typeof usePingIntegrationQuery
>;
export type PingIntegrationLazyQueryHookResult = ReturnType<
  typeof usePingIntegrationLazyQuery
>;
export type PingIntegrationQueryResult = Apollo.QueryResult<
  PingIntegrationQuery,
  PingIntegrationQueryVariables
>;
export const QueryPackDocument = gql`
  query QueryPack($input: PolicyInput!) {
    policy(input: $input) {
      uid
      mrn
      name
      version
      summary
      docs
      authors {
        name
        email
      }
      platforms {
        id
        name
        version
      }
      category
      trustLevel
      access
      statistics {
        checks
        queries
        policies
      }
      tags {
        key
        value
      }
      certifiedBy {
        name
      }
      featured
      queries {
        uid
        mrn
        title
        docs {
          desc
          remediations {
            ... on Remediations {
              entries {
                id
                desc
              }
            }
            ... on AuthenticationRequired {
              message
              code
            }
          }
          references {
            title
            url
          }
        }
        impact {
          value
          rating
        }
        mql
        tags {
          key
          value
        }
        properties {
          uid
          mrn
          title
          mql
          setByPolicyMrn
        }
        action
      }
      properties {
        uid
        mrn
        title
        mql
        setByPolicyMrn
      }
      groups {
        title
        summary
        checks {
          mrn
          impact {
            value
            rating
          }
          action
        }
        queries {
          mrn
          impact {
            value
            rating
          }
          action
        }
        policies {
          mrn
          impact {
            value
            rating
          }
          action
        }
      }
      runCli {
        command
        installDocsURL
      }
      githubUrl
      assigned
      mutationStatistics {
        checks {
          active
          ignored
          removed
        }
        queries {
          active
          ignored
          removed
        }
      }
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useQueryPackQuery__
 *
 * To run a query within a React component, call `useQueryPackQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPackQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryPackQuery(
  baseOptions: Apollo.QueryHookOptions<QueryPackQuery, QueryPackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryPackQuery, QueryPackQueryVariables>(
    QueryPackDocument,
    options,
  );
}
export function useQueryPackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryPackQuery,
    QueryPackQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryPackQuery, QueryPackQueryVariables>(
    QueryPackDocument,
    options,
  );
}
export type QueryPackQueryHookResult = ReturnType<typeof useQueryPackQuery>;
export type QueryPackLazyQueryHookResult = ReturnType<
  typeof useQueryPackLazyQuery
>;
export type QueryPackQueryResult = Apollo.QueryResult<
  QueryPackQuery,
  QueryPackQueryVariables
>;
export const RegistryPolicyDocument = gql`
  query RegistryPolicy($input: PolicyInput!) {
    policy(input: $input) {
      uid
      mrn
      name
      version
      summary
      docs
      action
      authors {
        name
        email
      }
      platforms {
        id
        name
        version
      }
      category
      trustLevel
      access
      statistics {
        checks
        queries
        policies
      }
      tags {
        key
        value
      }
      certifiedBy {
        name
      }
      featured
      queries {
        uid
        mrn
        title
        parent
        docs {
          desc
          remediations {
            ... on Remediations {
              entries {
                id
                desc
              }
            }
            ... on AuthenticationRequired {
              message
              code
            }
          }
          references {
            title
            url
          }
        }
        impact {
          value
          rating
        }
        mql
        tags {
          key
          value
        }
        variants {
          id
          title
          icon
        }
        properties {
          uid
          mrn
          title
          mql
          setByPolicyMrn
        }
        action
      }
      variants {
        id
        title
        icon
      }
      variantPlatformFilters {
        id
        title
        icon
      }
      properties {
        uid
        mrn
        title
        mql
        setByPolicyMrn
      }
      groups {
        title
        summary
        checks {
          mrn
          impact {
            value
            rating
          }
          action
        }
        queries {
          mrn
          impact {
            value
            rating
          }
          action
        }
        policies {
          mrn
          impact {
            value
            rating
          }
          action
        }
      }
      runCli {
        command
        installDocsURL
      }
      githubUrl
      assigned
      mutationStatistics {
        checks {
          active
          ignored
          removed
        }
        queries {
          active
          ignored
          removed
        }
      }
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useRegistryPolicyQuery__
 *
 * To run a query within a React component, call `useRegistryPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistryPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegistryPolicyQuery,
    RegistryPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistryPolicyQuery, RegistryPolicyQueryVariables>(
    RegistryPolicyDocument,
    options,
  );
}
export function useRegistryPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistryPolicyQuery,
    RegistryPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegistryPolicyQuery, RegistryPolicyQueryVariables>(
    RegistryPolicyDocument,
    options,
  );
}
export type RegistryPolicyQueryHookResult = ReturnType<
  typeof useRegistryPolicyQuery
>;
export type RegistryPolicyLazyQueryHookResult = ReturnType<
  typeof useRegistryPolicyLazyQuery
>;
export type RegistryPolicyQueryResult = Apollo.QueryResult<
  RegistryPolicyQuery,
  RegistryPolicyQueryVariables
>;
export const SearchAggregateScoreDocument = gql`
  query SearchAggregateScore(
    $query: String!
    $type: SearchType!
    $first: Int!
    $after: String
    $scope: String
    $filters: SearchFilters
  ) {
    search(
      query: $query
      type: $type
      first: $first
      after: $after
      scope: $scope
      filters: $filters
    ) {
      scoreTypeDistribution {
        vulnerability
        advisory
        check
        policy
        risk
        asset
      }
      edges {
        node {
          ... on AggregateScore {
            nodeId: id
            iconId
            entity {
              ... on EntityInfoAsset {
                id
                mrn
                name
              }
              ... on EntityInfoSpace {
                id
                mrn
                name
              }
            }
            findingMrn
            rank
            baseScore
            riskScore
            scoreType
            blastRadius {
              indicator
              assets
              affected
              critical
              high
              medium
              low
              none
            }
            riskFactors {
              mrn
              indicator
              title
              affected
              total
            }
            nodeTitle: title
            nodeDescription: description
            tags {
              key
              value
            }
            epss {
              probability
              percentile
            }
            cvss {
              id
              value
              type
              vector
              source
            }
            lastScannedAt
            firstDetectedAt
            remediatedAt
            spaceId
          }
          ... on Advisory {
            id
            iconID
            iconIDEnum
            mrn
            source {
              id
              name
              url
            }
            externalUrls {
              title
              url
              iconIDEnum
            }
            title
            description
            cves {
              id
              mrn
              title
              description
              summary
              published
              modified
              publishedAt
              modifiedAt
              url
              cwe
              state
              exploitable
              references
            }
            score {
              id
              value
              type
              vector
              source
            }
            cvssScore {
              id
              value
              type
              vector
              source
            }
            vendorscore
            affected {
              id
              iconID
              iconIDEnum
              name
              version
              arch
              format
              namespace
              description
              status
              available
              origin
              advisories {
                id
                iconID
                iconIDEnum
                mrn
                title
                description
                vendorscore
                published
                modified
                publishedAt
                modifiedAt
              }
              exploitable
              vulnDiscoveryTime
              type
            }
            fixed {
              id
              iconID
              iconIDEnum
              name
              version
              arch
              format
              namespace
              description
              status
              available
              origin
              exploitable
              vulnDiscoveryTime
              type
            }
            affectedPackages {
              id
              iconID
              iconIDEnum
              name
              version
              arch
              format
              namespace
              description
              status
              available
              origin
              exploitable
              vulnDiscoveryTime
              type
            }
            fixedByPackages {
              id
              iconID
              iconIDEnum
              name
              version
              arch
              format
              namespace
              description
              status
              available
              origin
              exploitable
              vulnDiscoveryTime
              type
            }
            published
            modified
            publishedAt
            modifiedAt
            vulnerabilityRiskFactors {
              knownExploited
              remoteExploitable
            }
            assets {
              id
              mrn
              name
              score {
                id
                value
                type
                completion
                weight
                message
                grade
              }
              platformName
              platformVersion
              advisoryInfo {
                activeAdvisoryCount
                worstCvssScore {
                  id
                  value
                  type
                  vector
                  source
                }
              }
            }
          }
          ... on Cve {
            id
            mrn
            cveTitle: title
            description
            publishedAt
            modifiedAt
            cvssScores {
              id
              value
              type
              vector
              source
            }
            cwe
            state
            exploits {
              id
              title
              description
              published
              modified
            }
            references
            epssScore {
              probability
              percentile
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useSearchAggregateScoreQuery__
 *
 * To run a query within a React component, call `useSearchAggregateScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAggregateScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAggregateScoreQuery({
 *   variables: {
 *      query: // value for 'query'
 *      type: // value for 'type'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      scope: // value for 'scope'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchAggregateScoreQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAggregateScoreQuery,
    SearchAggregateScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAggregateScoreQuery,
    SearchAggregateScoreQueryVariables
  >(SearchAggregateScoreDocument, options);
}
export function useSearchAggregateScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAggregateScoreQuery,
    SearchAggregateScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAggregateScoreQuery,
    SearchAggregateScoreQueryVariables
  >(SearchAggregateScoreDocument, options);
}
export type SearchAggregateScoreQueryHookResult = ReturnType<
  typeof useSearchAggregateScoreQuery
>;
export type SearchAggregateScoreLazyQueryHookResult = ReturnType<
  typeof useSearchAggregateScoreLazyQuery
>;
export type SearchAggregateScoreQueryResult = Apollo.QueryResult<
  SearchAggregateScoreQuery,
  SearchAggregateScoreQueryVariables
>;
export const SearchPolicyDocument = gql`
  query SearchPolicy($input: ContentSearchInput!) {
    content(input: $input) {
      totalCount
      edges {
        cursor
        node {
          ... on Policy {
            uid
            mrn
            name
            action
            version
            summary
            docs
            authors {
              name
              email
            }
            category
            trustLevel
            access
            statistics {
              checks
              queries
              policies
            }
            certifiedBy {
              name
            }
            featured
            assigned
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useSearchPolicyQuery__
 *
 * To run a query within a React component, call `useSearchPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPolicyQuery,
    SearchPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPolicyQuery, SearchPolicyQueryVariables>(
    SearchPolicyDocument,
    options,
  );
}
export function useSearchPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPolicyQuery,
    SearchPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPolicyQuery, SearchPolicyQueryVariables>(
    SearchPolicyDocument,
    options,
  );
}
export type SearchPolicyQueryHookResult = ReturnType<
  typeof useSearchPolicyQuery
>;
export type SearchPolicyLazyQueryHookResult = ReturnType<
  typeof useSearchPolicyLazyQuery
>;
export type SearchPolicyQueryResult = Apollo.QueryResult<
  SearchPolicyQuery,
  SearchPolicyQueryVariables
>;
export const SearchQueryPackDocument = gql`
  query SearchQueryPack($input: ContentSearchInput!) {
    content(input: $input) {
      totalCount
      edges {
        cursor
        node {
          ... on Policy {
            uid
            mrn
            name
            version
            summary
            docs
            authors {
              name
              email
            }
            category
            trustLevel
            access
            statistics {
              checks
              queries
              policies
            }
            certifiedBy {
              name
            }
            featured
            assigned
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useSearchQueryPackQuery__
 *
 * To run a query within a React component, call `useSearchQueryPackQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQueryPackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQueryPackQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchQueryPackQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchQueryPackQuery,
    SearchQueryPackQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQueryPackQuery, SearchQueryPackQueryVariables>(
    SearchQueryPackDocument,
    options,
  );
}
export function useSearchQueryPackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchQueryPackQuery,
    SearchQueryPackQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchQueryPackQuery,
    SearchQueryPackQueryVariables
  >(SearchQueryPackDocument, options);
}
export type SearchQueryPackQueryHookResult = ReturnType<
  typeof useSearchQueryPackQuery
>;
export type SearchQueryPackLazyQueryHookResult = ReturnType<
  typeof useSearchQueryPackLazyQuery
>;
export type SearchQueryPackQueryResult = Apollo.QueryResult<
  SearchQueryPackQuery,
  SearchQueryPackQueryVariables
>;
export const ServiceAccountForwardPaginationDocument = gql`
  query ServiceAccountForwardPagination(
    $spaceMrn: String!
    $scopeMrn: String
    $first: Int
    $after: String
    $query: String
    $queryTerms: [String!]
    $orderBy: ServiceAccountOrder
  ) {
    serviceAccounts(
      spaceMrn: $spaceMrn
      scopeMrn: $scopeMrn
      first: $first
      after: $after
      query: $query
      queryTerms: $queryTerms
      orderBy: $orderBy
    ) {
      ...ServiceAccountFields
    }
  }
  ${ServiceAccountFieldsFragmentDoc}
`;

/**
 * __useServiceAccountForwardPaginationQuery__
 *
 * To run a query within a React component, call `useServiceAccountForwardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceAccountForwardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceAccountForwardPaginationQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      scopeMrn: // value for 'scopeMrn'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *      queryTerms: // value for 'queryTerms'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useServiceAccountForwardPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceAccountForwardPaginationQuery,
    ServiceAccountForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ServiceAccountForwardPaginationQuery,
    ServiceAccountForwardPaginationQueryVariables
  >(ServiceAccountForwardPaginationDocument, options);
}
export function useServiceAccountForwardPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceAccountForwardPaginationQuery,
    ServiceAccountForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceAccountForwardPaginationQuery,
    ServiceAccountForwardPaginationQueryVariables
  >(ServiceAccountForwardPaginationDocument, options);
}
export type ServiceAccountForwardPaginationQueryHookResult = ReturnType<
  typeof useServiceAccountForwardPaginationQuery
>;
export type ServiceAccountForwardPaginationLazyQueryHookResult = ReturnType<
  typeof useServiceAccountForwardPaginationLazyQuery
>;
export type ServiceAccountForwardPaginationQueryResult = Apollo.QueryResult<
  ServiceAccountForwardPaginationQuery,
  ServiceAccountForwardPaginationQueryVariables
>;
export const SpaceAdvisoryForwardPaginationDocument = gql`
  query SpaceAdvisoryForwardPagination(
    $spaceMrn: String!
    $after: String
    $first: Int
    $query: String
  ) {
    space(mrn: $spaceMrn) {
      id
      advisories(
        after: $after
        first: $first
        orderBy: { direction: DESC, field: SCORE }
        query: $query
      ) {
        ...AdvisoryFields
      }
    }
  }
  ${AdvisoryFieldsFragmentDoc}
`;

/**
 * __useSpaceAdvisoryForwardPaginationQuery__
 *
 * To run a query within a React component, call `useSpaceAdvisoryForwardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceAdvisoryForwardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceAdvisoryForwardPaginationQuery({
 *   variables: {
 *      spaceMrn: // value for 'spaceMrn'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSpaceAdvisoryForwardPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpaceAdvisoryForwardPaginationQuery,
    SpaceAdvisoryForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpaceAdvisoryForwardPaginationQuery,
    SpaceAdvisoryForwardPaginationQueryVariables
  >(SpaceAdvisoryForwardPaginationDocument, options);
}
export function useSpaceAdvisoryForwardPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceAdvisoryForwardPaginationQuery,
    SpaceAdvisoryForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpaceAdvisoryForwardPaginationQuery,
    SpaceAdvisoryForwardPaginationQueryVariables
  >(SpaceAdvisoryForwardPaginationDocument, options);
}
export type SpaceAdvisoryForwardPaginationQueryHookResult = ReturnType<
  typeof useSpaceAdvisoryForwardPaginationQuery
>;
export type SpaceAdvisoryForwardPaginationLazyQueryHookResult = ReturnType<
  typeof useSpaceAdvisoryForwardPaginationLazyQuery
>;
export type SpaceAdvisoryForwardPaginationQueryResult = Apollo.QueryResult<
  SpaceAdvisoryForwardPaginationQuery,
  SpaceAdvisoryForwardPaginationQueryVariables
>;
export const SpaceCveForwardPaginationDocument = gql`
  query SpaceCveForwardPagination(
    $after: String
    $first: Int
    $query: String
    $spaceMrn: String!
  ) {
    space(mrn: $spaceMrn) {
      id
      mrn
      cves(
        after: $after
        first: $first
        orderBy: { direction: DESC, field: SCORE }
        query: $query
      ) {
        ...CveFields
      }
    }
  }
  ${CveFieldsFragmentDoc}
`;

/**
 * __useSpaceCveForwardPaginationQuery__
 *
 * To run a query within a React component, call `useSpaceCveForwardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceCveForwardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceCveForwardPaginationQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      query: // value for 'query'
 *      spaceMrn: // value for 'spaceMrn'
 *   },
 * });
 */
export function useSpaceCveForwardPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpaceCveForwardPaginationQuery,
    SpaceCveForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpaceCveForwardPaginationQuery,
    SpaceCveForwardPaginationQueryVariables
  >(SpaceCveForwardPaginationDocument, options);
}
export function useSpaceCveForwardPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceCveForwardPaginationQuery,
    SpaceCveForwardPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpaceCveForwardPaginationQuery,
    SpaceCveForwardPaginationQueryVariables
  >(SpaceCveForwardPaginationDocument, options);
}
export type SpaceCveForwardPaginationQueryHookResult = ReturnType<
  typeof useSpaceCveForwardPaginationQuery
>;
export type SpaceCveForwardPaginationLazyQueryHookResult = ReturnType<
  typeof useSpaceCveForwardPaginationLazyQuery
>;
export type SpaceCveForwardPaginationQueryResult = Apollo.QueryResult<
  SpaceCveForwardPaginationQuery,
  SpaceCveForwardPaginationQueryVariables
>;
export const SpaceIdSuggestionDocument = gql`
  query SpaceIDSuggestion($input: IDSuggestionInput!) {
    spaceIDSuggestion(input: $input) {
      id
      available
    }
  }
`;

/**
 * __useSpaceIdSuggestionQuery__
 *
 * To run a query within a React component, call `useSpaceIdSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceIdSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceIdSuggestionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceIdSuggestionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpaceIdSuggestionQuery,
    SpaceIdSuggestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SpaceIdSuggestionQuery,
    SpaceIdSuggestionQueryVariables
  >(SpaceIdSuggestionDocument, options);
}
export function useSpaceIdSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceIdSuggestionQuery,
    SpaceIdSuggestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SpaceIdSuggestionQuery,
    SpaceIdSuggestionQueryVariables
  >(SpaceIdSuggestionDocument, options);
}
export type SpaceIdSuggestionQueryHookResult = ReturnType<
  typeof useSpaceIdSuggestionQuery
>;
export type SpaceIdSuggestionLazyQueryHookResult = ReturnType<
  typeof useSpaceIdSuggestionLazyQuery
>;
export type SpaceIdSuggestionQueryResult = Apollo.QueryResult<
  SpaceIdSuggestionQuery,
  SpaceIdSuggestionQueryVariables
>;
export const SubscriptionInfoDocument = gql`
  query SubscriptionInfo($orgMrn: ID!) {
    subscriptionInfo(orgMrn: $orgMrn) {
      isSelfService
      basePlan {
        id
        key
        name
      }
      additions {
        id
        key
        name
        quantity
      }
      utilizationMetrics {
        name
        description
        units
        value
        limit
      }
      pendingCancel
    }
    subscriptionPlans(orgMrn: $orgMrn) {
      basePlan {
        id
        key
        name
        description
        price {
          id
          currency
          cost
        }
        entitlements {
          name
          key
          description
          units
          value
        }
      }
      additions {
        id
        key
        name
        description
        maxQuantity
        price {
          id
          currency
          cost
        }
        entitlements {
          name
          key
          description
          units
          value
        }
      }
      isSelfService
      isCurrent
      isDowngrade
      isUpgrade
    }
  }
`;

/**
 * __useSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionInfoQuery({
 *   variables: {
 *      orgMrn: // value for 'orgMrn'
 *   },
 * });
 */
export function useSubscriptionInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionInfoQuery,
    SubscriptionInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionInfoQuery, SubscriptionInfoQueryVariables>(
    SubscriptionInfoDocument,
    options,
  );
}
export function useSubscriptionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionInfoQuery,
    SubscriptionInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubscriptionInfoQuery,
    SubscriptionInfoQueryVariables
  >(SubscriptionInfoDocument, options);
}
export type SubscriptionInfoQueryHookResult = ReturnType<
  typeof useSubscriptionInfoQuery
>;
export type SubscriptionInfoLazyQueryHookResult = ReturnType<
  typeof useSubscriptionInfoLazyQuery
>;
export type SubscriptionInfoQueryResult = Apollo.QueryResult<
  SubscriptionInfoQuery,
  SubscriptionInfoQueryVariables
>;
export const TestIamActionsDocument = gql`
  query TestIAMActions($resourceMrn: String!, $actions: [String!]!) {
    testIamActions(resourceMrn: $resourceMrn, actions: $actions)
  }
`;

/**
 * __useTestIamActionsQuery__
 *
 * To run a query within a React component, call `useTestIamActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestIamActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestIamActionsQuery({
 *   variables: {
 *      resourceMrn: // value for 'resourceMrn'
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useTestIamActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TestIamActionsQuery,
    TestIamActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestIamActionsQuery, TestIamActionsQueryVariables>(
    TestIamActionsDocument,
    options,
  );
}
export function useTestIamActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TestIamActionsQuery,
    TestIamActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TestIamActionsQuery, TestIamActionsQueryVariables>(
    TestIamActionsDocument,
    options,
  );
}
export type TestIamActionsQueryHookResult = ReturnType<
  typeof useTestIamActionsQuery
>;
export type TestIamActionsLazyQueryHookResult = ReturnType<
  typeof useTestIamActionsLazyQuery
>;
export type TestIamActionsQueryResult = Apollo.QueryResult<
  TestIamActionsQuery,
  TestIamActionsQueryVariables
>;
export const TriggerActionDocument = gql`
  query TriggerAction($input: TriggerActionInput!) {
    triggerAction(input: $input) {
      mrn
    }
  }
`;

/**
 * __useTriggerActionQuery__
 *
 * To run a query within a React component, call `useTriggerActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTriggerActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTriggerActionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    TriggerActionQuery,
    TriggerActionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TriggerActionQuery, TriggerActionQueryVariables>(
    TriggerActionDocument,
    options,
  );
}
export function useTriggerActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TriggerActionQuery,
    TriggerActionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TriggerActionQuery, TriggerActionQueryVariables>(
    TriggerActionDocument,
    options,
  );
}
export type TriggerActionQueryHookResult = ReturnType<
  typeof useTriggerActionQuery
>;
export type TriggerActionLazyQueryHookResult = ReturnType<
  typeof useTriggerActionLazyQuery
>;
export type TriggerActionQueryResult = Apollo.QueryResult<
  TriggerActionQuery,
  TriggerActionQueryVariables
>;
export const GetPackageScoresDocument = gql`
  query GetPackageScores(
    $first: Int
    $after: String
    $orderBy: PackageScoresOrder
    $input: PackageScoresInput
  ) {
    packageScores(
      first: $first
      after: $after
      orderBy: $orderBy
      input: $input
    ) {
      ... on PackageScores {
        totalCount
        edges {
          cursor
          node {
            id
            score
            assetName
            lastUpdated
            assetMrn
            riskFactors {
              mrn
              indicator
              title
              total
              affected
            }
            platform
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;

/**
 * __useGetPackageScoresQuery__
 *
 * To run a query within a React component, call `useGetPackageScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageScoresQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPackageScoresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPackageScoresQuery,
    GetPackageScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageScoresQuery, GetPackageScoresQueryVariables>(
    GetPackageScoresDocument,
    options,
  );
}
export function useGetPackageScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPackageScoresQuery,
    GetPackageScoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPackageScoresQuery,
    GetPackageScoresQueryVariables
  >(GetPackageScoresDocument, options);
}
export type GetPackageScoresQueryHookResult = ReturnType<
  typeof useGetPackageScoresQuery
>;
export type GetPackageScoresLazyQueryHookResult = ReturnType<
  typeof useGetPackageScoresLazyQuery
>;
export type GetPackageScoresQueryResult = Apollo.QueryResult<
  GetPackageScoresQuery,
  GetPackageScoresQueryVariables
>;
export const GetSpaceSoftwareDocument = gql`
  query GetSpaceSoftware(
    $entityMrn: String!
    $filter: AggregateScoreFilter
    $first: Int
    $after: String
    $orderBy: AggregateScoreOrder
  ) {
    aggregateScores(
      entityMrn: $entityMrn
      filter: $filter
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      ... on AggregateScoresConnection {
        totalCount
        edges {
          cursor
          node {
            id
            iconId
            state
            versionDistribution {
              total
              assets
              installations
              distribution {
                version
                count
                riskScore
              }
            }
            entity {
              ... on EntityInfoAsset {
                id
                mrn
                name
              }
              ... on EntityInfoSpace {
                id
                mrn
                name
              }
            }
            findingMrn
            rank
            baseScore
            riskScore
            scoreType
            blastRadius {
              ...BlastRadiusFields
            }
            riskFactors {
              mrn
              indicator
              title
              total
              affected
            }
            tags {
              key
              value
            }
            epss {
              percentile
              probability
            }
            cvss {
              value
              id
              value
              type
              vector
              source
            }
            title
            description
            lastScannedAt
            firstDetectedAt
            remediatedAt
            spaceId
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
  ${BlastRadiusFieldsFragmentDoc}
`;

/**
 * __useGetSpaceSoftwareQuery__
 *
 * To run a query within a React component, call `useGetSpaceSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceSoftwareQuery({
 *   variables: {
 *      entityMrn: // value for 'entityMrn'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSpaceSoftwareQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSpaceSoftwareQuery,
    GetSpaceSoftwareQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSpaceSoftwareQuery, GetSpaceSoftwareQueryVariables>(
    GetSpaceSoftwareDocument,
    options,
  );
}
export function useGetSpaceSoftwareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpaceSoftwareQuery,
    GetSpaceSoftwareQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSpaceSoftwareQuery,
    GetSpaceSoftwareQueryVariables
  >(GetSpaceSoftwareDocument, options);
}
export type GetSpaceSoftwareQueryHookResult = ReturnType<
  typeof useGetSpaceSoftwareQuery
>;
export type GetSpaceSoftwareLazyQueryHookResult = ReturnType<
  typeof useGetSpaceSoftwareLazyQuery
>;
export type GetSpaceSoftwareQueryResult = Apollo.QueryResult<
  GetSpaceSoftwareQuery,
  GetSpaceSoftwareQueryVariables
>;
